import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import IssueItem from "./IssueItem";
import EmptyDnDTarget from "./EmptyDnDTarget";
import IssueStore from "../../stores/IssueStore";

/**
 * Колонки статуса задачи с сответствующим списком задач
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {Object} params.status набор данных статуса задачи
 * @param {LayoutItem} params.layoutItem сущность в Layout
 */
const StatusColumn = observer((props) => {
  const { status, store, layoutItem } = props;
  const list = store.issueListGroupedByStatus[status.id] || [];
  const moveIssue = useCallback((issueUid, afterIssueUid) => {
    if (!requestedFrame) {
      requestedFrame = requestAnimationFrame(async() => {
        await store.changeIssueStatus(issueUid, afterIssueUid, status.id, false);
        requestedFrame = undefined;
      });
    }
  }, [list, status]);

  const changeIssueStatus = useCallback(async(issueUid, statusId) => {
    await store.changeIssueStatus(issueUid, undefined, statusId || status.id, true);
  }, [list, status]);

  let requestedFrame;

  useEffect(() => {
    requestedFrame = undefined;
    return (() => {
      if (requestedFrame !== undefined) {
        cancelAnimationFrame(requestedFrame);
      }
    });
  }, []);

  return (
    <div className="status-column">
      <div className="status-column-header">
        <span>{status.title}</span>
      </div>
      <div className="status-column-body">
        {list.length === 0 &&
          <EmptyDnDTarget
            store={store}
            statusId={status.id}
            moveIssue={moveIssue}
            changeIssueStatus={changeIssueStatus}
          />
        }
        {
          list.map((item) => {
            return (
              <div
                key={item.uid}
                className={"issue"}
              >
                <IssueItem
                  issue={item}
                  store={store}
                  statusId={status.id}
                  moveIssue={moveIssue}
                  layoutItem={layoutItem}
                  changeIssueStatus={changeIssueStatus}
                />
              </div>
            );
          })
        }
      </div>
    </div>
  );
});

StatusColumn.propTypes = {
  status:     PropTypes.object.isRequired,
  store:      PropTypes.instanceOf(IssueStore).isRequired,
  layoutItem: PropTypes.object.isRequired
};

export default StatusColumn;
