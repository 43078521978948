import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Preloader } from "@ais3p/ui-framework-old";
import { layout, components } from "@ais3p/ui-framework";

import AboutStore from "../data/strores/AboutStore";
import "./css/index.scss";
import useStores from "../../../core/utils/useStores";

const { modal } = layout;
const { buttons } = components;

const About = observer(({ isVisible: visible, onShow }) => {
  const { rootStore } = useStores();
  const [store] = React.useState(() => {
    return new AboutStore(rootStore);
  });

  const [isVisible, setIsVisible] = useState(visible);

  const toggleModal = useCallback(() => {
    onShow(false);
  }, [onShow]);

  useEffect(() => {
    setIsVisible(visible);
    if (visible) {
      store.init();
    }
  }, [visible]);

  const { isPending, data } = store;
  const items = [];
  let content = (
    <div className="preloader-holder">
      <Preloader size={3} color="#fff" />
    </div>
  );
  if (!isPending) {
    const nameAttrs = {
      "data-tooltip":    "Сервис",
      "data-tooltip-at": "bottom"
    };
    const verAttrs = {
      "data-tooltip":    "Версия",
      "data-tooltip-at": "bottom"
    };
    const statusAttrs = {
      "data-tooltip":    "Статус",
      "data-tooltip-at": "bottom"
    };
    data.forEach((status, i) => {
      items.push(
        <div key={i} className="service">
          <div {...nameAttrs} className="name">
            {status.name}
          </div>
          <div {...verAttrs} className="version">
            {status.version}
          </div>
          <div
            {...{
              "data-tooltip": `Сборка${
                status.commit ? `, commit: ${status.commit}` : ""
              }`,
              "data-tooltip-at": "bottom"
            }}
            className="build"
          >
            {status.build}
          </div>
          <div
            {...statusAttrs}
            className={`status ${status.alive ? "alive" : "dead"}`}
          />
        </div>
      );
    });
    content = <div className="services-content">{items}</div>;
  }
  const button = (
    <buttons.Button 
      text="Ok"
      icon="ok-M"
      onPress={toggleModal}
      color="action"
    />
  );
  return (
    <modal.Window
      name="about"
      icon="plane-M"
      show={isVisible}
      title="О системе"
      buttons={button}
      onKeyPressEsc={toggleModal}
      onBackdropClick={toggleModal}
    >
      {content}
    </modal.Window>
  );
});

export default About;
