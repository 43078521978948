const fileTypeFromContentType = (contentType) => {
  switch (contentType) {
    case "image/gif":
    case "image/jpg":
    case "image/jpeg":
    case "image/pjpeg":
    case "image/png":
    case "image/webp":
      return "image";
    case "application/pdf":
      return "pdf";
    default:
      return "download";
  }
};

export default fileTypeFromContentType;
