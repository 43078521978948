import { action, computed } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class ListItem extends BaseBoxClass {
  @action
  init(data, parent) {
    super.init(data, parent);
    if (data.isNew) {
      this.creationPromise = this.persistCreate(data.forceEmpty);
    }
  }

  @action
  async persistCreate(noIndent = false) {
    await super.persistCreate();
    if (!noIndent) {
      await this.createOnlyIndent();
    }
  }

  @action
  async createOnlyIndent() {
    if (!this.store) {
      return null;
    }
    if (this.idsArray.length < 1) {
      const item = this.store.createItem(
        {
          class: "text.element.Indent",
          uid:   this.store.getUid(),
          isNew: true
        },
        this
      );
      this.addItemById(item.uid);
    }
  }

  @action
  async persistDelete() {
    await super.persistDelete();
    (await this.parent) && this.parent.checkForEmptinessAndRemove();
  }

  @computed
  get isSplitable() {
    return true;
  }

  @computed
  get title() {
    let title = " ";
    this.items.forEach((item) => {
      if (item) {
        title += item.title;
      }
    });
    return title;
  }

  @computed
  get innerHierarchyItemsArray() {
    const array = [
      {
        class:     "text.container.EnumerationItem",
        isSibling: true,
        ancorId:   this.uid
      }
    ];
    return array;
  }

  @computed
  get flatItemsArray() {
    let data = [];
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      data = data.concat(item.flatItemsArray);
    });
    return data;
  }

  @computed
  get slug() {
    return "enumeration-items";
  }

  @computed
  get category() {
    return "enumeration-items";
  }

  @computed
  get output() {
    return {
      class:       this.className,
      uid:         this.uid,
      elements:    [],
      "@position": this.position
    };
  }

  @computed
  get hierarchyItemsArray() {
    const array = [];
    if (!this.isLast) {
      return array;
    }
    return [].concat(this.parent.hierarchyItemsArray, array);
  }

  @computed
  get removedDescendantListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (this.nextSibling && this.nextSibling.diffClass === "removed") {
      return [this.nextSibling, ...this.nextSibling.removedDescendantListItemsArray];
    }
    if (this.diffCompatitor.nextSibling && this.diffCompatitor.nextSibling.diffClass === "removed") {
      return [this.diffCompatitor.nextSibling, ...this.diffCompatitor.nextSibling.removedDescendantListItemsArray];
    }
    return [];
  }

  @computed
  get availableItemsArray() {
    if (!this.parent) {
      return [];
    }
    if (this.isLast) {
      return [{ class: "text.container.EnumerationItem", icon: "list-bullet-M" }].concat(
        this.parent.availableItemsArray
      );
    }
    return this.parent.defaultItemsArray || [];
  }
}

export default ListItem;
