/**
 * Объект, хранящий в себе координаты объекта, согласно типа координат - `Point`, `TextSlice`, `Rect`
 *  https://ph.bocp.ru/w/ais3p/cmp/services/app/binder/
 *
 * @type {Coordinate}
 */

import { action, computed, observable, runInAction, toJS } from "mobx";

export default class Workspace {
  @observable uid;
  @observable name;
  @observable description;
  @observable layout;

  @observable isPending = false;

  constructor({ uid, name, description, layout }, store) {
    this.id = uid;
    this.uid = uid;
    this.name = name;
    this.store = store;
    this.description = description;

    this.api = store.api;
    if (layout !== undefined) {
      this.layout = layout;
    } else {
      this.getLayout();
    }

    this.loadToLayout = this.loadToLayout.bind(this);
  }

  @action
  async getLayout() {
    this.isPending = true;
    const layoutData = await this.api.loadWorkspace(this.id);
    runInAction(() => {
      this.layout = layoutData.layout;
      this.isPending = false;
    });
  }

  @action
  loadToLayout() {
    this.store.setWorkspace(JSON.parse(toJS(this.layout)));
  }

  @action
  async change({ name, description, layout }) {
    if (description) {
      this.description = description;
    }
    if (name) {
      this.name = name;
    }
    if (layout) {
      this.layout = layout;
    }
    await this.api.changeWorkspace({
      description: this.description,
      layout:      this.layout,
      name:        this.name,
      uid:         this.id
    });
  }

  @computed
  get buttonConfig() {
    const config = {
      id:      this.id,
      icon:    "table-divide-M",
      loading: this.isPending,
      item:    {
        hasMenu: true,
        fixed:   true,
        name:    this.name,
        id:      this.id,
        props:   {
          label: this.name
        }
      },
      onClick: this.loadToLayout
    };
    return config;
  }
}
