import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Button, Field, Preloader, ToolBar } from "@ais3p/ui-framework-old";

import "../css/index.scss";
import AisIcon from "../../../core/components/AisIcon";
import KindView from "./KindView";

const WizardTool = observer(
  ({ config, id, onSuccess, onCancel, layoutStore, parent }) => {
    const { instantConfirm, views, initialName, withName, nameTitle } = config;
    const [page, setPage] = useState(0);
    const [name, setName] = useState(initialName || "");
    const [isPending, setIsPending] = useState(false);

    const data = useMemo(() => {
      return { name, id };
    }, [name, id]);

    const onSave = useCallback(async() => {
      setIsPending(true);
      if (onSuccess) {
        await onSuccess(data, parent); // TODO: send some data
      }
      setIsPending(false);
      layoutStore.closeFullScreen();
    }, [onSuccess, setIsPending, layoutStore, data, parent]);

    const onPrev = useCallback(() => {
      setPage(page - 1);
    }, [page, setPage]);

    const onNext = useCallback(() => {
      setPage(page + 1);
    }, [page, setPage]);

    const onAbort = useCallback(() => {
      if (onCancel) {
        onCancel(id);
      }
      setIsPending(false);
      layoutStore.closeFullScreen();
    }, [setIsPending, layoutStore, onCancel]);

    const onChangeName = useCallback(
      (name) => {
        setName(name);
      },
      [setName]
    );

    useEffect(() => {
      if (instantConfirm) {
        onSave();
      }
    }, [instantConfirm]);

    const pagesLength = useMemo(() => {
      return views.length;
    }, [views]);

    const canNext = useMemo(() => {
      return page < pagesLength;
    }, [pagesLength, page]);

    const isSaveEnabled = useMemo(() => {
      return true;
    }, []);

    const nameRender = useMemo(() => {
      if (!withName) {
        return null;
      }
      return (
        <div className="name-holder">
          <div className="kind-name">{nameTitle || "Наименование"}</div>
          <Field.String
            leftIcon={<AisIcon icon={"data-string-M"} />}
            key={"name"}
            id={"name"}
            labelOnTop={true}
            onChange={onChangeName}
            value={name}
          />
        </div>
      );
    }, [name, withName, setName, nameTitle, onChangeName]);

    const pageRender = useMemo(() => {
      const viewConfig = views[page];
      if (!viewConfig) {
        return null;
      }
      const { type, kindId } = viewConfig;
      if (type === "kind") {
        return (
          <div className="page-holder">
            <KindView uid={id} kindId={kindId} />
          </div>
        );
      }
      return null;
    }, [page, views, id]);

    if (instantConfirm) {
      return (
        <div className={"wizard-tool-holder"}>
          <ToolBar buttons={{ left: [], right: [] }} />
          <div className="wizard-tool-content">
            <div style={{ margin: "auto" }}>
              {isPending && <Preloader size={3} />}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={"wizard-tool-holder"}>
        <ToolBar buttons={{ left: [], right: [] }} />
        <div className="wizard-tool-content">
          {nameRender}
          {pageRender}
        </div>
        <div className="buttons-holder">
          {pagesLength > 1 && (
            <Button
              disabled={page < 1}
              leftIcon="chevron-left-M"
              text="Назад"
              size={2}
              onClick={onPrev}
              loading={isPending}
            />
          )}
          {pagesLength > 1 && (
            <Button
              disabled={!canNext}
              rightIcon="chevron-right-M"
              text="Далее"
              size={2}
              onClick={onNext}
              loading={isPending}
            />
          )}
          <Button
            leftIcon="ok-M"
            theme="success"
            text="Сохранить"
            size={2}
            onClick={onSave}
            disabled={!isSaveEnabled}
            loading={isPending}
          />
          <Button
            leftIcon="delete-M"
            theme="danger"
            text="Отмена"
            disabled={isPending}
            size={2}
            onClick={onAbort}
            loading={isPending}
          />
        </div>
      </div>
    );
  }
);

export default WizardTool;
