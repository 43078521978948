import BaseRedmineModel from "./baseRedmineModel";

import { observable, computed, action } from "mobx";

/**
 * Модель приоритета задачи
 *
 * @class PriorityModel
 */
class PriorityModel extends BaseRedmineModel {
  /**
   * Название приоритета
   *
   * @type String
   */
  @observable
  name = undefined;

  /**
   * Приоритет, который выставляется задаче по умолчанию
   *
   * @type Boolean
   */
  @observable
  isDefault = undefined;

  /**
   * Цвет представления
   *
   * @type String
   */
  @observable
  color = undefined;

  /**
   * Валидатор названия приоритета
   *
   * @param {String} name название приоритета
   *
   * @return {Object} { success: true|false, message: ""}
   */
  static validateName(name = "") {
    const res = name.length >= 2;
    if (!res) {
      return {
        success: false,
        message: "Название должно быть больше одного символа"
      };
    }
    return { success: true };
  }

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id названия приоритета задачи в Redmine
   * @param {String} params.name название приоритета задачи
   * @param {String} params.isDefault выставялется ли приоритет задаче по умолчанию
   * @param {String} params.color Цвет представления
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {PriorityModel}
   */
  static create({
    id,
    name,
    isDefault,
    color
  }, issueStore) {
    return new PriorityModel({
      id,
      name,
      isDefault,
      color
    }, issueStore);
  }

  constructor(params, issueStore) {
    super(params, issueStore);

    this.name = params.name;
    this.isDefault = params.isDefault;
    this.color = params.color;
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "priorityModel";
  }

  /**
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.name название проекта
   * @param {String} params.isDefault выставялется ли приоритет задаче по умолчанию
   * @param {String} params.color цвет представления
   */
  @action
  update(data) {
    this.name = data.name || this.name;
    this.isDefault = data.isDefault || this.isDefault;
    this.color = data.color || this.color;
  }

  /**
   * Строковое представление приоритета
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    return {
      id:        this.id,
      name:      this.name,
      color:     this.color,
      isDefault: this.isDefault
    };
  }
}

export default PriorityModel;
