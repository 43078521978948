import { observable, action, computed, toJS, runInAction } from "mobx";

import AttrValue from "./AttrValue";

class KindMember {
  @observable pending = false;

  @observable id = null;
  @observable kindId = null;
  @observable memberUid = null;
  @observable number = null;
  @observable origin = null;
  @observable originCode = null;
  @observable index = null;
  @observable item = null;
  @observable codes = new Map();
  @observable attributes = new Map();
  @observable hasRedmineProject = false;
  
  constructor(data, store, item) {
    this.store = store;
    this.id = data.uid;
    this.item = item;
    this.kindId = data.kindUid;
    this.memberUid = data.memberUid;
    this.number = data.number;
    this.origin = data.origin;
    this.originCode = data.originCode;
    this.index = data.index;

    this.store = store;
    this.api = store.api;

    if (this.kind) {
      this.kind.attributes.forEach((attribute) => {
        this.attributes.set(
          attribute.id,
          new AttrValue({ id: attribute.id, type: attribute.type }, this.store)
        );
      });
    }

    if (data.values) {
      Object.keys(data.values).forEach((key) => {
        const value = data.values[key];
        const attribute = this.store.getAttr(key);
        if (attribute) {
          this.attributes.set(
            key,
            new AttrValue({ value, id: key, type: attribute.type }, this.store)
          );
        }
      });
    }
    if (data.codeValues) {
      this.codes.replace(data.codeValues);
    }
  }

  @action
  setId(uid) {
    this.id = uid;
  }

  @action setHasRedmineProject(value) {
    this.hasRedmineProject = value;
  }

  @action
  async delete() {
    this.item.removeKind(this.memberUid);
    await this.item.save();
  }

  @action
  revert() {
    if (this.needsPersist) {
      this.attributes.forEach((attr) => {
        attr.revert();
      });
    }
  }

  @action
  async save() {
    if (this.needsPersist) {
      this.pending = true;
      let data = null;
      if (this.hasUid) {
        data = await this.update();
      } else {
        data = await this.create();
      }
      runInAction(() => {
        this.attributes.forEach((attr) => {
          attr.save();
        });
        this.pending = false;
      });
      return data;
    }
  }

  @action
  async update() {
    const attributes = {};
    this.attributes.forEach((attr) => {
      attributes[attr.id] = attr.valueToSend;
    });
    const data = await this.api.changeAttributes(this.memberUid, { values: attributes });
    return data;
  }

  @action
  async create() {
    const attributes = {};
    this.attributes.forEach((attr) => {
      attributes[attr.id] = attr.valueToSend;
    });

    let origin;
    if (this.item.contentMemberUids) {
      const originItem = this.item.contentMemberUids[0];
      if (originItem) {
        origin = originItem.memberUid;
      }
    }

    const members = {
      objectId: this.id,
      origin,
      values:   attributes
    };
    const data = await this.api.addKindMember(this.kindId, members);
    if (data && data.uid) {
      runInAction(() => {
        this.memberUid = data.uid;
      });
    }
    return data;
  }

  @action
  setAttrValue(id, value, isValid) {
    const attr = this.attributes.get(id);
    if (attr) {
      attr.change(value, isValid);
    } else {
      const attribute = this.store.getAttr(id);
      this.attributes.set(
        id,
        new AttrValue({ id, value, type: attribute.type, isValid }, this.store)
      );
    }
  }

  @action
  getAttrValue(id) {
    const attr = this.attributes.get(id);
    if (!attr) {
      return undefined;
    }
    return attr;
  }

  @computed
  get uid() {
    return this.id;
  }

  @computed
  get kind() {
    if (this.store.isPending) {
      return null;
    }
    return this.store.getKind(this.kindId);
  }

  @computed
  get allowedTypes() {
    return this.kind ? new Set(toJS(this.kind.allowedTypes)) : null;
  }

  @computed
  get allowedKinds() {
    return this.kind ? new Set(toJS(this.kind.allowedKinds)) : null;
  }

  @computed
  get hasUid() {
    return !!this.memberUid;
  }

  @computed
  get needsPersist() {
    return !this.hasUid || this.edited;
  }

  @computed
  get edited() {
    let edited = false;
    this.attributes.forEach((attr) => {
      edited = edited || attr.isEdited;
    });
    return edited;
  }

  @computed
  get kindUid() {
    return this.kindId;
  }

  @computed
  get kindName() {
    return this.kind && this.kind.name;
  }

  @computed
  get iconString() {
    return (this.kindName && this.store.rootStore.accountStore.getIcon(this.kindName)) || null;
  }

  @computed
  get codesObject() {
    const object = {};
    this.codes.forEach((value, key) => {
      object[key] = value;
    });
    return object;
  }

  @computed
  get isValid() {
    let valid = true;
    this.attributes.forEach((attr) => {
      if (attr.isValid === false) {
        valid = false;
      }
    });
    return valid;
  }

  @computed
  get allValues() {
    const vals = {};
    this.attributes.forEach((attr) => {
      vals[attr.id] = attr.value;
    });
    return vals;
  }

  @computed
  get initValues() {
    const vals = {};
    this.attributes.forEach((attr) => {
      vals[attr.id] = attr.initialValue;
    });
    return vals;
  }
}

export default KindMember;
