import moment from "moment";

/**
 * Сформировать набор данных для запроса получения списка задач
 *
 * @param {Object} values данные с панели с формы фильтров
 * @param {String} projectUid uid активного проекта
 *
 * @retrun {Object}
 */
const generateFiltersData = (values, projectUid) => {
  let status = [];
  if (values.status) {
    status = Array.isArray(values.status) ? values.status : [values.status];
  }
  return {
    tracker:        values.tracker,
    status,
    priority:       values.priority,
    author:         values.author,
    assignedTo:     values.assignedTo,
    project:        [projectUid],
    createDateFrom: values.createDateFrom && moment(values.createDateFrom).format("YYYY-MM-DD"),
    createDateTo:   values.createDateTo && moment(values.createDateTo).format("YYYY-MM-DD"),
    updateDateFrom: values.updateDateFrom && moment(values.updateDateFrom).format("YYYY-MM-DD"),
    updateDateTo:   values.updateDateTo && moment(values.updateDateTo).format("YYYY-MM-DD"),
    startDateFrom:  values.startDateFrom && moment(values.startDateFrom).format("YYYY-MM-DD"),
    startDateTo:    values.startDateTo && moment(values.startDateTo).format("YYYY-MM-DD"),
    dueDateFrom:    values.dueDateFrom && moment(values.dueDateFrom).format("YYYY-MM-DD"),
    dueDateTo:      values.dueDateTo && moment(values.dueDateTo).format("YYYY-MM-DD"),
    subject:        values.subject
  };
};

export default generateFiltersData;
