import { observable, computed, action } from "mobx";

import  MessageModel from "../models/messageModel";
import  Api from "../api/wsApi";
import { wsStateTitle } from "../constants/wsApiStates";


/** 
 * Хранилище для работы с пакетами, полученными по web socket
 *
 * @class WSStore
 */
class WSStore {
  /**
   * Максимальное кол-во полученных пакетов, которое будет храниться в памяти
   *
   * @type {Number}
   */
  maxMessages = 150;

  /**
   * Массив полученных пакетов
   *
   * @type {Array<MessageModel>}
   */
  @observable
  messages = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.api = new Api({
      onOpen:    this.onOpen.bind(this),
      onMessage: this.onMessage.bind(this),
      onError:   this.onError.bind(this),
      onClose:   this.onClose.bind(this)
    });
  }

  /**
   * Инициализация хранилища
   *
   */
  init(token) {
    this.api.connect(token);
  }

  /**
   * Разъединить соединение
   *
   */
  disconnect() {
    this.api.disconnect();
  }

  /**
   * Статус соединиения
   *@return {Boolean}
   */
  @computed
  get isConnected() {
    return this.api.isConnected;
  }

  /**
   * Callback функция на открытие web socket
   *
   * @param {Boolean} value
   */
  onOpen() {
  }

  /**
   * Callback функция на получения сообщения по web socket
   *
   * @param {String} data
   */
  onMessage(data) {
    try {
      if (data) {
        this.addMessage(MessageModel.create(JSON.parse(data), this));
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * Обработка ошибки
   *
   * @param {String} message текст ошибки
   */
  onError(message) {
    this.rootStore.onError(message);
  }

  /**
   * Callback функция на закрытие web socket
   *
   * @param {Number} code код закрытия зарытия сокета
   * @param {String} reason текст причины закрытия сокета
   */
  onClose() {
    // console.log("onClose", code, reason);
  }

  /**
   * Добавить сообщение в список полученных сообщений
   *
   * @param {MessageModel} message
   */
  @action
  addMessage(message) {
    const lastMsg = this.messages.length > 0 ? this.messages[0] : undefined;
    const lastNum = (lastMsg ? lastMsg.num : 0) || 0;
    message.setNum(lastNum + 1);
    this.messages.unshift(message);
    this.messages.splice(this.maxMessages);
  }

  /**
   * Состояние соединения
   * @return {String}
   */
  @computed
  get readyState() {
    return wsStateTitle(this.api.readyState);
  }

  /**
   * Отправить данные по web socket
   * @param {String} data
   */
  sendData(data) {
    if (!this.isConnected) {
      this.onError("Нет соединения с сервером");
      return;
    }
    this.api.send(data);
  }

  /**
   * Очистить очередь сообщений
   */
  @action
  clearMessages() {
    this.messages = [];
  }

  /**
   * Получить объект пользователя по его uid
   *
   * @param {String} uid пользователя
   * @return {User}
   */
  getUser(uid) {
    return this.rootStore.userStore.get(uid);
  }

  /**
   * Деструктор хранилища
   */
  destroy() {
    if (this.api) {
      this.api.destroy();
    }
    this.clearMessages();
  }
}

export default WSStore;
