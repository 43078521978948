import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import IssueTab from "./IssueTab";
import IssuePanel from "./IssuePanel";
import IssuesKanban from "./IssuesKanban";
import IssuesTable from "./IssuesTable";

import { ISSUES_VIEW_KANBAN, ISSUES_VIEW_TABLE } from "../../constants/issuesViews";
import { DOMAIN_ISSUE } from "~/core/constants/Domains";
import IssueStore from "../../stores/IssueStore";

/**
 * Панель для отображения вкладок с открытыми задачами
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {Boolean} params.canBeEditable признак, можно ли редактирвоать Задачу
 * @param {String} params.projectUid uid проекта
 * @param {String} params.tabId id вкладки в Layout
 * @param {LayoutStore} params.layoutStore хранилище для работы с Layout
 * @param {LayoutItem} params.layoutItem сущность в Layout
 * @param {Object} params.isSubVisible флаг отображения боковой доп панели
 * @param {Function} params.reloadIssues метод для перезагрузки списка задач
 */
const IssueTabs = observer(({
  store,
  canBeEditable,
  projectUid,
  tabId,
  layoutStore,
  layoutItem,
  isSubVisible,
  reloadIssues
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  // const { objectStore } = useStores();
  const tabs = [
    <Tab
      key={"issues"}
      className={classNames("issue-tabs-tab issue-tabs-tab-list", {
        selected: tabIndex === 0
      })}
    >
      <IssueTab
        index={0}
      />
    </Tab>
  ];
  const panels = [
    <TabPanel key={"panel-issues"}>
      {store.issuesViewMode  === ISSUES_VIEW_KANBAN &&
        <IssuesKanban
          store={store}
          canBeEditable={canBeEditable}
          projectUid={projectUid}
          tabId={tabId}
          layoutStore={layoutStore}
          layoutItem={layoutItem}
          isSubVisible={isSubVisible}
          reload={reloadIssues}
        />
      }
      {store.issuesViewMode  === ISSUES_VIEW_TABLE &&
        <IssuesTable
          store={store}
          canBeEditable={canBeEditable}
          projectUid={projectUid}
          tabId={tabId}
          layoutStore={layoutStore}
          layoutItem={layoutItem}
          isSubVisible={isSubVisible}
          reload={reloadIssues}
        />
      }
    </TabPanel>
  ];


  useEffect(() => {
    onActiveTab(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    if (!store.activeIssue) {
      setTabIndex(0);
      return;
    }
    const index = store.openedIssueList.indexOf(store.activeIssue);
    setTabIndex(index + 1);
  }, [store.activeIssue]);

  const closeTab = useCallback((issue, index) => {
    store.deleteOpenedIssue(issue.uid);
    let newIndex = index - 1;
    if (index <= 0) {
      newIndex = 0;
    }

    setTabIndex(newIndex);
  }, []);

  const onClick = useCallback(() => {
    // setTabIndex(index);
  }, []);

  const onDoubleClick = useCallback(() => {
    store.toggleShowIssueTabs();
    // setTabIndex(index);
  }, []);

  const onActiveTab = (index) => {
    if (index > 0) { // под 0 индексом всегда находится список задач
      const issue = store.openedIssueList[index - 1];
      store.setActiveIssue(issue);
      changeContext(issue);
    } else {
      store.setActiveIssue(undefined);
      changeContext();
    }
  };

  const onSelect = useCallback((index) => {
    setTabIndex(index);
  }, []);

  const changeContext = useCallback((issue) => {
    if (!issue) {
      layoutItem.changeContext();
      return;
    }

    if (issue.isNew) {
      layoutItem.changeContext();
      return;
    }

    layoutItem.changeContext(issue.project.uid, issue.uid, {
      uid:         issue.uid,
      trackedItem: {
        uid:   issue.uid,
        name:  issue.title,
        class: issue.className,
        tool:  DOMAIN_ISSUE
      }
    });
  }, []);

  store.openedIssueList.forEach((issue, i) => {
    tabs.push(
      <Tab
        key={`tab-${i}`}
        className={classNames("issue-tabs-tab", {
          selected: (i + 1) === tabIndex
        })}
      >
        <IssueTab
          issue={issue}
          index={i + 1}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          closeTab={closeTab}
        />
      </Tab>
    );
    panels.push(
      <TabPanel
        key={`panel-${i}`}
      >
        <IssuePanel
          issue={issue}
          store={store}
          canBeEditable={canBeEditable}
          tabId={tabId}
          layoutStore={layoutStore}
          layoutItem={layoutItem}
          isSubVisible={isSubVisible}
          changeContext={changeContext}
        />
      </TabPanel>
    );
  });

  return (
    <div
      className="issue-tabs"
    >
      <Tabs
        // defaultIndex={0}
        selectedIndex={tabIndex}
        onSelect={onSelect}
        className="issue-tabs-body"
      >
        <TabList className="issue-tabs-list">{tabs}</TabList>
        {panels}
      </Tabs>
    </div>
  );
});

IssueTabs.propTypes = {
  store:         PropTypes.instanceOf(IssueStore).isRequired,
  canBeEditable: PropTypes.bool,
  projectUid:    PropTypes.string.isRequired,
  tabId:         PropTypes.string,
  layoutStore:   PropTypes.object,
  layoutItem:    PropTypes.object,
  isSubVisible:  PropTypes.object,
  reloadIssues:  PropTypes.func
};

export default IssueTabs;
