import { observable, action, computed } from "mobx";

class RelationUIStore {
  @observable
  id = null;
  @observable
  version = null;
  @observable
  pending = false;

  constructor(relationStore) {
    this.relationStore = relationStore;
    this.kindsStore = this.relationStore.rootStore.kindsStore;
  }

  @action
  async init(id, version = 0) {
    this.setPending(true);
    this.id = id;
    this.version = version;
    await this.relationStore.getRelations(id, version);
    this.setPending(false);
  }

  @action
  setPending(pending = false) {
    this.pending = pending;  
  }

  getRelationKindsForItem(uid, parentUid, direction) {
    let relationKinds = this.relationStore.relationKinds;
    const endType = direction === "in" ?  "dest" : "source";
    const kindNames = this.kindsStore.getItemKindNames(uid);
    const parentKindNames = this.kindsStore.getItemKindNames(parentUid);
    const names = ["none", ...kindNames];
    parentKindNames.forEach((name) => {
      names.push(`Версия ${name}`);
    });
    if (names.length) {
      relationKinds = [];
      this.relationStore.relationKinds.forEach((kind) => {
        if (this.relationRules[kind.name]) {
          const rule = this.relationRules[kind.name][endType];
          let fits = false;
          names.forEach((name) => {
            if (rule.has(name)) {
              fits = true;
            }
          });
          if (fits) {
            relationKinds.push(kind);
          }
        } else {
          relationKinds.push(kind);
        }
      });
    }
    return relationKinds;
  }

  @computed
  get relationRules() {
    return {
      "Корректирующее действие": {
        source: new Set(["Запрос на изменение"]),
        dest:   new Set(["Сообщение о проблеме"])
      },
      "Основание для изменения": {
        source: new Set(["Запрос на изменение"]),
        dest:   new Set(["Раздел", "Требование", "Описание", "Версия Спецификация"])
      },
      "Источник проблемы": {
        source: new Set([
          "Раздел",
          "Требование",
          "Описание",
          "Версия Спецификация"
        ]),
        dest: new Set(["Сообщение о проблеме"])
      },
      Обоснование: {
        source: new Set(["Проект"]),
        dest:   new Set([
          "Требование", "Проект"
        ])
      },
      Влияние: {
        source: new Set(["Запрос на изменение", "Сообщение о проблеме"]),
        dest:   new Set([
          "Раздел",
          "Требование",
          "Описание",
          "Версия Спецификация",
          "Спецификация"
        ])
      },
      Информация: {
        source: new Set([
          "Раздел",
          "Требование",
          "Описание",
          "Спецификация",
          "Версия Спецификация",
          "Запрос на изменение",
          "Сообщение о проблеме"
        ]),
        dest: new Set(["Запрос на изменение", "Сообщение о проблеме"])
      },
      "Принадлежность проекту": {
        source: new Set(["Запрос на изменение", "Сообщение о проблеме"]),
        dest:   new Set(["Проект"])
      }
    };
  }

  @computed
  get relationKinds() {
    return this.relationStore.relationKinds;
  }

  @computed
  get kindsForSelect() {
    return this.relationStore.kindsForSelect;
  }

  @computed
  get groupedConnections() {
    const relations = [];
    if (this.isPending || this.relationStore.isPending) {
      return [];
    }
    const tempObj = {};
    const data = this.relationStore.getGroupedConnections(this.id, this.version);
    data.forEach(({ kind, items }) => {
      if (kind) {
        if (tempObj[kind.id]) {
          tempObj[kind.id].items = tempObj[kind.id].items.concat([...items]);
        } else {
          tempObj[kind.id] = { kind, items: [...items] };
        }
      }
    });
    for (const key in tempObj) {
      if (Object.hasOwnProperty.call(tempObj, key)) {
        relations.push(tempObj[key]);
      }
    }
    return relations;
  }

  @computed
  get relationsByKindArray() {
    return this.groupedConnections;
  }

  @computed
  get relationsByKindArrayLength() {
    return this.relationsByKindArray.length;
  }

  @computed
  get isPending() {
    return this.relationStore.pending;
  }

  @computed
  get hasConnections() {
    return this.groupedConnections.length;
  }
}

export default RelationUIStore;
