import BaseRedmineModel from "./baseRedmineModel";
import RedmineUserModel from "./userModel";
import ChangesModel from "./changesModel";
import moment from "moment";

import { observable, computed, action } from "mobx";

/**
 * Модель журнала изменений в задаче.
 *
 * @class JournalModel
 */
class JournalModel extends BaseRedmineModel {
  /**
   * Дата изменений
   *
   * @type DateTime
   */
  @observable
  createdOn = undefined;

  /**
   * Набор изменений
   *
   * @type Array<ChangesModel>
   */
  @observable
  changes = [];

  /**
   * Пользователь, который внес изменения
   *
   * @type String
   */
  @observable
  notes = undefined;

  /**
   * Пользователь, который внес изменения
   *
   * @type RedmineUserModel
   */
  @observable
  user = undefined;

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.createdOn createdOn дата создания
   * @param {Array<Object>} params.changes набор изменений
   * @param {String} params.notes текст комменатария
   * @param {Object} params.user информация о пользователе, который оставил сообщение
   * @param {String} params.user.id id пользователя в Redmine
   * @param {String} params.user.uid uid пользователя в АИС
   * @param {String} params.user.name имя пользователя в Redmine
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {JournalModel}
   */
  static create({
    createdOn,
    changes = [],
    notes,
    user
  }, issueStore) {
    return new JournalModel({
      createdOn,
      changes,
      notes,
      user
    }, issueStore);
  }

  constructor(params, issueStore) {
    super(params, issueStore);

    this.createdOn = params.createdOn && moment(params.createdOn, "DD.MM.YYYY HH:mm:ss").toDate(),
    this.changes = (params.changes || []).map((item) => {
      return ChangesModel.create(item, issueStore);
    });
    this.notes = params.notes;
    this.user = params.user && RedmineUserModel.create(params.user, issueStore);
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "jurnalModel";
  }

  /**
   * Обновить св-ва модели
   * @param {Object} data параметры модели
   */
  @action
  update(data) {
    this.createdOn = data.createdOn || this.createdOn;
    this.user = data.user && RedmineUserModel.create(data.user, this.issueStore) || data.user;
    this.notes = data.notes || this.notes;
    this.changes = data.changes && data.changes.map((ch) => {
      return ChangesModel.create(ch, this.store);
    }) || this.changes;
  }

  /**
   * Строковое представление записи комментария
   *
   * @return {String}
   */
  @computed
  get title() {
    return `${this.changes.length} изменений`;
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    return {
      createdOn: this.createdOn,
      notes:     this.notes,
      changes:   this.changes && this.changes.map((change) => {
        return change.config;
      }),
      user: this.user && this.user.config
    };
  }
}

export default JournalModel;
