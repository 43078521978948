import { observer } from "mobx-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

const FlatItem = observer(({ renderItem, item, dataStore }) => {
  const itemRender = renderItem(item);
  const { isLongList } = dataStore;

  const element = useRef();

  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isFirstCall, setIsFirstCall] = useState(true);

  const onMouseLeave = useCallback(() => {
    item.setExpanded(false);
  }, [item]);

  const processIntersection = useCallback(
    (entries) => {
      const intersecting = entries[0] && entries[0].isIntersecting;
      if (isFirstCall) {
        if (isIntersecting !== intersecting) {
          setIsIntersecting(intersecting);
        }
        setIsFirstCall(false);
        return false;
      }
      if (isIntersecting !== intersecting) {
        setIsIntersecting(intersecting);
        if (intersecting) {
          item.setBoundingIndex();
        }
      }
    },
    [isIntersecting, isFirstCall, item]
  );

  const observer = useMemo(() => {
    return new IntersectionObserver(processIntersection);
  }, [processIntersection]);

  const sensor = useMemo(() => {
    if (isLongList) {
      return false;
    }
    return new ResizeObserver((entries) => {
      const rect =
        entries[0] &&
        entries[0].target &&
        entries[0].target.getBoundingClientRect();
      if (rect && isIntersecting) {
        item.setHeight(rect.height);
      }
    });
  }, [item, isIntersecting]);

  useEffect(() => {
    if (element && element.current) {
      if (sensor) {
        sensor.observe(element.current);
      }
      if (observer) {
        observer.observe(element.current);
      }
    }
    return () => {
      sensor && sensor.disconnect();
      observer && observer.disconnect();
    };
  }, [sensor, observer, element && element.current]);

  if (!itemRender) {
    return null;
  }

  return (
    <div id={item.id} onMouseLeave={onMouseLeave} className={`virt-list-item ${item.diffClass}`}>
      <div ref={element} className="virt-list-item-content">
        {itemRender}
      </div>
    </div>
  );
});

export default FlatItem;
