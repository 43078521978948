import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Prism from "prismjs";
// import "prismjs/themes/prism-coy.css";

const PrismHilight = ({ children, language = "markdown", className }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [children, language]);

  return (
    <code className={`code language-${language} ${className ? className : ""}`}>
      {children}
    </code>
  );
};

PrismHilight.propTypes = {
  children:  PropTypes.array,
  language:  PropTypes.string,
  className: PropTypes.string
};
export default PrismHilight;
