import React, { useCallback } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { getPropsToOpenLayoutTool } from "~/core/utils";
import AisObject from "~/core/components/AisObject";
import useStores from "~/core/utils/useStores";
import BooleanContent from "./BooleanContent";
import AttrContent from "./AttrContent";
import StringContent from "./StringContent";

const TableRow = observer(({ item }) => {
  const { layoutStore, rootStore, objectStore } = useStores();

  const onClickUrsNum = useCallback(async() => {
    if (item && item.urs) {
      const toolToOpen = {
        component: item.urs.tool || item.urs.domain
      };
      let itemToOpen;

      try {
        itemToOpen = await getPropsToOpenLayoutTool(item.urs, objectStore);
      } catch (exc) {
        rootStore.onError(exc.message);
      } 

      if (!itemToOpen) return;
      layoutStore.open({ ...itemToOpen, ...toolToOpen });
    }
  }, [item && item.urs]);

  const onClickTpNum = useCallback(async() => {
    if (item && item.tp) {
      const toolToOpen = {
        component: item.tp.tool || item.tp.domain
      };
      let itemToOpen;

      try {
        itemToOpen = await getPropsToOpenLayoutTool(item.tp, objectStore);
      } catch (exc) {
        rootStore.onError(exc.message);
      } 

      if (!itemToOpen) return;
      layoutStore.open({ ...itemToOpen, ...toolToOpen });
    }
  }, [item && item.tp]);

  const onChangeField = useCallback((name, value) => {
    switch (name) {
      case "isFullyMet":{
        item.setIsFullyMet(value);
        break;
      }
      case "testResult":{
        item.setTestResult(value);
        break;
      }
      case "comment":{
        item.setComment(value);
        break;
      }
      case "isNeedMitigation":{
        item.setIsNeedMitigation(value);
        break;
      }

      case "whereVeriffied":{
        item.setWhereVeriffied(value);
        break;
      }
    }
    // item.saveToLocalStorage();
  }, [item]);

  if (item.isTP) {
    return (
      <tr>
        <td className="urs-num">
          {item.tpIndexNumber && (
            <div 
              className="urs-num-content"
              onClick={onClickTpNum}
            >
              #{item.tpIndexNumber}
            </div>
          )}
        </td>
        <td className="object">
          {item.tp && <AisObject object={item.tp} />}
        </td>
        <td className="object">
          {item.frs && <AisObject object={item.frs} />}
        </td>
        <td className="urs-num">
          {item.frs && item.frs.version}
        </td>
        <td 
          className={classNames("user-value", {
          // yes: item.isNeedMitigation === true,
          // no:  item.isNeedMitigation === false
          })}
        >
          <BooleanContent 
            name="testResult"
            value={item.testResult}
            onChange={onChangeField}
          />
        </td>
        <td 
          className={classNames("user-value text", {
          // yes: item.isNeedMitigation === true,
          // no:  item.isNeedMitigation === false
          })}
        >
          <StringContent 
            name="comment"
            value={item.comment}
            onChange={onChangeField}
          />
        </td>
      </tr>
    );
  }

  if (item.isURS) {
    return (
      <tr>
        <td className="urs-num">
          {item.indexNumber !== undefined && (
            <div 
              className="urs-num-content"
              onClick={onClickUrsNum}
            >
              #{item.indexNumber}
            </div>
          )}
        </td>
        <td className="object">
          {item.urs && <AisObject object={item.urs} />}
        </td>
        <td className={classNames("user-value", {
        // yes: item.isFullyMet === true,
        // no:  item.isFullyMet === false
        })}
        >
          <BooleanContent 
            name="isFullyMet"
            value={item.isFullyMet}
            onChange={onChangeField}
          />
        </td>
        <td className="user-value">
          <StringContent 
            name="comment"
            value={item.comment}
            onChange={onChangeField}
          />
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td className="urs-num">
        {item.indexNumber && (
          <div 
            className="urs-num-content"
            onClick={onClickUrsNum}
          >
            #{item.indexNumber}
          </div>
        )}
      </td>
      <td className="object">
        {item.urs && <AisObject object={item.urs} />}
      </td>
      <td className="object">
        {item.frs && <AisObject object={item.frs} />}
      </td>
      <td className={classNames("user-value", {
        // yes: item.isFullyMet === true,
        // no:  item.isFullyMet === false
      })}
      >
        <BooleanContent 
          name="isFullyMet"
          value={item.isFullyMet}
          onChange={onChangeField}
        />
      </td>
      <td 
        className={classNames("user-value", {
          // yes: item.isNeedMitigation === true,
          // no:  item.isNeedMitigation === false
        })}
      >
        <BooleanContent 
          name="isNeedMitigation"
          value={item.isNeedMitigation}
          onChange={onChangeField}
        />
      </td>
      <td className="user-value">
        <AttrContent
          name="whereVeriffied"
          attrName={item.attrName}
          value={item.whereVeriffied || item.ursAttrValue}
          onChange={onChangeField}
        />
      </td>
    </tr>
  );
});

export default TableRow;
