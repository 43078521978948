const STATUS_OPEN = "open";
const STATUS_CLOSED = "closed";
const STATUS_ALL = "*";
const STATUS_USER = "user";

const statusTitle = (status) => {
  switch (status) {
    case STATUS_OPEN:
      return "Открытые";
    case STATUS_CLOSED:
      return "Закрытые";
    case STATUS_ALL:
      return "Все";
    case STATUS_USER:
      return "Произвольный набор";
    default:
      return status;
  }
};

const STATUSES = [
  STATUS_OPEN,
  STATUS_CLOSED,
  STATUS_ALL,
  STATUS_USER
];

export {
  STATUS_OPEN,
  STATUS_CLOSED,
  STATUS_ALL,
  STATUS_USER,
  STATUSES,
  statusTitle
};
