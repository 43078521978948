import { observable, action, toJS, computed } from "mobx";
import moment from "moment";

/**
 * Модель для хранения инофрмации о коммите
 * 
 * @class {Commit}
 */
class Commit {
  /**
   * @type {String}
   * Автор коммита
   */
  @observable 
  author = null;

  /**
   * @type {String}
   * id коммита
   */
  @observable 
  commit = null;
  
  /**
   * @type {String}
   * Авторское сообщение о коммите
   */
  @observable commitMessage = null;

  /**
   * @type {Date}
   * Дата коммита
   */
  @observable date = null;

  /**
   * Статичный конструктор модели.
   * При создании модели, лучше использовать статичный метод, тк если поменяются названия входных параметров,
   * то эти названия можно будет здесь только поменять, не меняя названия в самой модели
   * 
   * @param {Object} params
   * @param {String} params.author автор ккоммита
   * @param {String} params.commit id коммита 
   * @param {String} params.commitMessage авторское сообщение на коммит
   * @param {Date} params.date дата коммита
   *  
   * @returns Commit
   */
  static create({
    author,
    commit,
    commitMessage,
    date
  }) {
    return new Commit({
      author,
      commit,
      commitMessage,
      date
    });
  }

  /**
   * Конструктор модели.
   * 
   * @param {Object} props
   * @param {String} props.author автор ккоммита
   * @param {String} props.commit id коммита 
   * @param {String} props.commitMessage авторское сообщение на коммит
   * @param {Date} props.date дата коммита
   *  
   * @returns Commit
   */
  constructor(props) {
    this.author = props.author;
    this.commit = props.commit;
    this.commitMessage = props.commitMessage;
    this.date = props.date;
  }

  /**
   * Обновить данные о коммите
   * 
   * @param {Object} props
   * @param {String} props.author автор ккоммита
   * @param {String} props.commit id коммита 
   * @param {String} props.commitMessage авторское сообщение на коммит
   * @param {Date} props.date дата коммита
   *  
   * @returns Commit
   */
  @action
  update(props) {
    if (props.author) {
      this.author = props.author;
    }
    if (props.commit) {
      this.commit = props.commit;
    }
    if (props.commitMessage) {
      this.commitMessage = props.commitMessage;
    }
    if (props.date) {
      this.date = props.date;
    }
    return this;
  }

  /**
   * Получить id коммита
   * 
   * @return {String}
   */
  @computed
  get id() {
    return this.commit;
  }

  /**
   * Получить текстовое представление даты коммита
   * 
   * @return {String}
   */
  @computed
  get dateString() {
    return moment(this.date).format("HH:mm DD.MM.YYYY");
  }

  /**
   * Получить данные о коммите
   * 
   * @return {Object}
   */
  @computed
  get info() {
    return toJS({
      id:            this.id,
      author:        this.author,
      commit:        this.commit,
      commitMessage: this.commitMessage,
      date:          this.date,
      dateString:    this.dateString
    });
  }

  /**
   * Деструктор модели
   */
  destroy() {
  }
}

export default Commit;
