import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { v4 as uuid } from "uuid";

import { Layout } from "@ais3p/ui-framework-old";

import { Login, WorkspaceForm } from "~/modules/account";
import AppHeader from "~/core/components/AppHeader";
import Tooltip from "~/core/components/Tooltip";

import "~/App.scss";
import Library from "~/modules/library";
import Viewer from "~/modules/viewer";
import RepoTool from "~/modules/repo";
import WizardTool from "~/modules/wizard/components/WizardTool";
import About from "~/modules/about/components/About";
import Material from "~/modules/newText";

import TraceAnalyzerTool from "~/modules/traceReporter/tools/TraceAnalyzer";

import ConnectionSidepanel from "~/modules/relations/tools/ConnectionSidepanel";
import KindsSidepanel from "~/modules/kindsAndAttrs/tools/KindsSidepanel";

import IssuesTool from "~/modules/issues/tools/IssuesTool";
import WebSocketTool from "~/modules/webSocket/tools/WebSocketTool";
import SearchEntitiesTool from "~/modules/searchEntities";
import CheckListToolTool from "~/modules/checkList";

import JournalSidepanel from "~/modules/journal/tools/JournalSidepanel";

import {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS,
  SIDEPANEL_JOURNAL,
  SIDEPANEL_VALIDATION
} from "~/core/constants/SidePanels";
import ValidationSidepanel from "../../modules/validation";

const modeButtonsDummie = [
  {
    id:            "common.Workspaces",
    icon:          "table-divide-M",
    type:          "drop",
    labelPosition: "top",
    item:          {
      name: "Действия с рабочим пространством",
      id:   "layout"
    },
    children: []
  }
];
const buttonsDummie = [
  { type: "spacer", id: "s1" },
  {
    id:   "library.Tree",
    icon: "app-library-M",
    item: {
      name:      "Библиотека",
      id:        uuid(), // id root библиотеки
      props:     {},
      icon:      "app-library-M",
      component: "library"
    }
  },
  // {
  //   id:   "text.Tracker",
  //   icon: "app-texteditor-M",
  //   item: {
  //     name:  "Редактор текста",
  //     id:    "app-editor",
  //     props: {
  //       useGlobalTracking: true
  //     },
  //     icon:      "app-texteditor-M",
  //     component: "text"
  //   }
  // },
  { type: "spacer", id: "s2" },
  // {
  //   id:   "issues.Tracker",
  //   icon: "app-spzi-M",
  //   item: {
  //     name:      "Задачи",
  //     id:        uuid(),
  //     props:     {},
  //     icon:      "app-spzi-M",
  //     component: "issues"
  //   }
  // },
  {
    id:   "websocket.Tracker",
    icon: "arrow-rightleft-M",
    item: {
      name:      "WebSocket",
      id:        uuid(),
      props:     {},
      icon:      "arrow-rightleft-M",
      component: "websocket"
    }
  },
  {
    id:   "relations.TraceAnalyzer",
    icon: "tracer-gap-analyser-M",
    item: {
      name:      "Анализ связей",
      id:        uuid(),
      props:     {},
      icon:      "tracer-gap-analyser-M",
      component: "traceAnalyzer"
    }
  },
  {
    id:   "search.SearchEntities",
    icon: "search-M",
    item: {
      name:      "Поиск артефактов",
      id:        uuid(),
      props:     {},
      icon:      "search-M",
      component: "searchEntities"
    }
  }
];

@inject("accountStore", "rootStore", "uiStore")
@observer
class App extends Component {
  constructor(props) {
    super(props);

    this.savePresset = this.savePresset.bind(this);
    this.onSaveLayout = this.onSaveLayout.bind(this);
    this.onMountLayout = this.onMountLayout.bind(this);
    this.onShowAbout = this.onShowAbout.bind(this);

    this.state = {
      isAboutVisible:  false,
      layoutFunctions: undefined
    };
  }

  onSaveLayout() {
    if (this.state.layoutFunctions) {
      const presset = this.state.layoutFunctions.getPresset();
      this.props.accountStore.showForm(false, presset);
    }
  }

  onMountLayout(layoutFunctions) {
    this.setState({
      layoutFunctions
    });
    this.props.accountStore.setWSFunc(layoutFunctions.setStore);
  }

  savePresset(data, name) {
    this.props.accountStore.savePresset(data, name);
  }

  onShowAbout(isVisible) {
    this.setState({
      isAboutVisible: isVisible
    });
  }

  render() {
    const { accountStore, uiStore, hasError } = this.props;

    const { layoutFunctions, isAboutVisible } = this.state;
    const { showError, showConfirm } = uiStore;

    const {
      isLoggedIn,
      layoutPresset,
      loadingPresset,
      pendingWorkspaces,
      workspacesArray,
      permissions
    } = accountStore;

    const layoutPermissions = permissions.get("layout");
    let multipleTabs = false;
    if (layoutPermissions) {
      const multipleTabsPermission = layoutPermissions.get(
        "common.MultipleTabs"
      );
      multipleTabs =
        multipleTabsPermission && multipleTabsPermission.has("use");
    }

    const allowedTools = permissions.get("tool");
    const allowedObjects = permissions.get("object");
    const modeButtons = [];
    const buttons = [];

    if (allowedTools && allowedObjects) {
      modeButtonsDummie.forEach((button) => {
        if (button.type === "spacer") {
          modeButtons.push(button);
        } else {
          const toolPerms = allowedTools.get(button.id);
          if (toolPerms && toolPerms.has("use")) {
            const buttonObj = { ...button };
            if (button.id === "common.Workspaces") {
              const wsChildren = [];
              const wssPerms = allowedObjects.get("common.Workspaces");
              const wsPerms = allowedObjects.get("common.Workspace");
              if (wssPerms && wssPerms.has("read")) {
                workspacesArray.forEach((ws) => {
                  wsChildren.push(ws.buttonConfig);
                });
              }
              if (wsPerms && wsPerms.has("create")) {
                wsChildren.push({
                  id:            "common.Workspaces.edit",
                  icon:          "save-M",
                  labelPosition: "top",
                  loading:       pendingWorkspaces,
                  item:          {
                    fixed: true,
                    name:  "Сохранить текущее рабочее пространство",
                    id:    "saveLayout"
                  },
                  onClick: this.onSaveLayout
                });
              }
              buttonObj.children = wsChildren;
            }

            modeButtons.push(buttonObj);
            if (button.id === "common.Workspaces") {
              modeButtons.push({ type: "spacer", id: `mb${button.id}` });
            }
          }
        }
      });

      buttonsDummie.forEach((button) => {
        if (button.type === "spacer") {
          buttons.push(button);
        } else {
          if (allowedTools.has(button.id)) {
            const buttonObj = { ...button };
            buttons.push(buttonObj);
          }
          if (button.id === "repo.Viewer") {
            const buttonObj = { ...button };
            buttons.push(buttonObj);
          }
          if (button.id === "issues.Tracker") {
            const buttonObj = { ...button };
            buttons.push(buttonObj);
          }
          if (button.id === "websocket.Tracker") {
            const buttonObj = { ...button };
            buttons.push(buttonObj);
          }
          if (button.id === "relations.TraceAnalyzer") {
            const buttonObj = { ...button };
            buttons.push(buttonObj);
          }
          if (button.id === "search.SearchEntities") {
            const buttonObj = { ...button };
            buttons.push(buttonObj);
          }
        }
      });
    }
    const toolComponents = {
      wizard:                  <WizardTool />,
      library:                 <Library.LibraryTree />,
      text:                    <Material />,
      viewer:                  <Viewer />,
      sourcecode:              <RepoTool />,
      issues:                  <IssuesTool />,
      websocket:               <WebSocketTool />,
      traceAnalyzer:           <TraceAnalyzerTool />,
      searchEntities:          <SearchEntitiesTool />,
      checkList:               <CheckListToolTool />,
      [SIDEPANEL_RELATIONS]:   <ConnectionSidepanel />,
      [SIDEPANEL_KINDS_ATTRS]: <KindsSidepanel />,
      [SIDEPANEL_JOURNAL]:     <JournalSidepanel />,
      [SIDEPANEL_VALIDATION]:  <ValidationSidepanel />
    };
    const toolIcons = {
      issues:                  "app-spzi-M",
      websocket:               "arrow-rightleft-M",
      wizard:                  "wizard-M",
      library:                 "app-library-M",
      text:                    "app-texteditor-M",
      viewer:                  "wmaterial-M",
      sourcecode:              "app-tree-M",
      viewer3d:                "object-M",
      traceAnalyzer:           "tracer-gap-analyser-M",
      searchEntities:          "search-M",
      checkList:               "domain-M",
      [SIDEPANEL_RELATIONS]:   "app-relations-M",
      [SIDEPANEL_KINDS_ATTRS]: "app-attributes-M",
      [SIDEPANEL_JOURNAL]:     "log-M",
      [SIDEPANEL_VALIDATION]:  "ok-M"
    };

    const toolNames = {
      issues:                  "Задачи",
      websocket:               "Web Socket",
      wizard:                  "Мастер создания артефакта",
      library:                 "Библиотека",
      text:                    "Редактор текста",
      viewer:                  "Просмотр",
      sourcecode:              "Репозитории",
      viewer3d:                "Просмотр 3Д",
      traceAnalyzer:           "Анализ связей",
      searchEntities:          "Поиск артефактов",
      checkList:               "DQ Check list",
      [SIDEPANEL_RELATIONS]:   "Отслеживание связей",
      [SIDEPANEL_KINDS_ATTRS]: "Виды и атрибуты",
      [SIDEPANEL_JOURNAL]:     "Журнал изменений",
      [SIDEPANEL_VALIDATION]:  "Утверждение"
    };

    const header = (
      <AppHeader
        onShowAbout={this.onShowAbout}
        menuCollect={this.props.menuCollect}
        buttons={buttons}
        modeButtons={modeButtons}
        setStore={layoutFunctions && layoutFunctions.setStore}
        getPresset={layoutFunctions && layoutFunctions.getPresset}
        open={layoutFunctions && layoutFunctions.open}
        setMode={layoutFunctions && layoutFunctions.setMode}
        setIsDragging={layoutFunctions && layoutFunctions.setIsDragging}
      />
    );
    let uid = undefined;
    if (isLoggedIn) {
      uid = accountStore.uid;
    }

    return (
      <div
        id="ais-app-root"
        className={`app-container ${!isLoggedIn ? "login" : ""} ${hasError ? "error" : ""
        }  ${showConfirm ? "confirm" : ""}`}
      >
        {isLoggedIn && header}
        {isLoggedIn && !loadingPresset && (
          <Layout
            savePresset={this.savePresset}
            presset={layoutPresset}
            persistId={uid}
            menuCollect={this.props.menuCollect}
            onMount={this.onMountLayout}
            panels={[]}
            components={toolComponents}
            toolIcons={toolIcons}
            toolNames={toolNames}
            multipleTabs={true}
            blocks={[
              {
                id:        "root",
                direction: "row",
                isRoot:    true
              }
            ]}
          />
        )}
        <WorkspaceForm />
        <Login />
        <About isVisible={isAboutVisible} onShow={this.onShowAbout} />
        <Tooltip delay={{ show: 100, hide: 100 }} />
        <div className="error-overlay" />
      </div>
    );
  }
}

export default App;
