import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { components } from "@ais3p/ui-framework";
import AisIcon from "~/core/components/AisIcon";
import fileTypeFromContentType from "~/modules/viewer/utils/fileTypeFromContentType";
import useStores from "~/core/utils/useStores";
import { humanFileSize } from "~/core/utils";
import IssueStore from "../../stores/IssueStore";
import { AttachmentModel, IssueModel } from "../../models";


const { buttons } = components;

/**
 * Компонент для отображения прикрепленного файла в списке задач
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {IssueModel} props.issue текущая задача
 * @param {AttachmentModel}  props.attachment прикрепленный файл
 * @param {Boolean} props.readOnly флаг указывающий, что файлы в спсиске только можно просмматривать
 * @param {Function} props.onDeleteFile callback функция при удалении файла из списка
 */
const AttachmentItem = observer(({ store, attachment, issue, readOnly, onDeleteFile }) => {
  const [isUploading, setIsUploading] = useState(false);
  const { layoutStore, uiStore } = useStores();

  const downloadFile = async() => {
    setIsUploading(true);
    try {
      const blobFile = await store.downloadFile(attachment.aisId);
      const link = document.createElement("a");
      link.setAttribute("type", "hidden");
      link.setAttribute("download", attachment.title);
      link.setAttribute("target", "_blank");
      link.setAttribute("href", blobFile);
      link.click();
    } catch (ex) {
      store.onError(ex.message);
    } finally {
      setIsUploading(false);
    }
  };

  const previewFile = () => {
    const tool = {
      icon:      "download-M",
      component: "viewer"
    };
    const type = fileTypeFromContentType(attachment.contentType);
    const item = {
      name:  attachment.title,
      id:    attachment.aisId,
      props: {
        type,
        className: `test-viewer-${type}`,
        name:      attachment.title,
        file:      attachment.aisId
      }
    };
    layoutStore.open({ ...item, ...tool });
  };

  const onPreviewFile = useCallback(() => {
    previewFile();
  }, [attachment]);

  const onDownloadFile = useCallback(() => {
    downloadFile();
  }, [attachment]);

  const onDelete = useCallback(() => {
    if (!onDeleteFile) {
      return;
    }

    uiStore.setConfirm(
      `Вы действительно хотите удалить файл "${attachment.title}"?`,
      "Удаление файла",
      [
        {
          onPress: () => {
            issue.deleteAttachment(attachment);
            onDeleteFile(attachment);
            uiStore.hideConfirm();
          },
          color: "positive",
          icon:  "ok-M",
          text:  "Да"
        },
        {
          onPress: () => {
            uiStore.hideConfirm();
          },
          color: "negative",
          icon:  "cancel-M",
          text:  "Нет"
        }
      ]
    );
  }, [attachment, issue]);

  return (
    <div className="issue-attachments-attachment">
      <div className="issue-attachments-attachment-title">
        <AisIcon item={attachment.fileIconItem} className={"file-icon"} />
        <span className="name">{attachment.title}</span>
        <span className="size">{`(${humanFileSize(attachment.size)})`}</span>
      </div>
      <div className="issue-attachments-attachment-buttons">
        <buttons.Button
          onPress={onPreviewFile}
          icon="mode-view-M"
          tooltip="Просмотреть файл"
          color="positive"
        />
        <buttons.Button
          onPress={onDownloadFile}
          isLoading={isUploading}
          icon="download-M"
          tooltip="Скачать файл"
          color="action"
        />
        {!readOnly && (
          <buttons.Button
            onPress={onDelete}
            icon="delete-M"
            tooltip="Удалить файл"
            color="negative"
          />
        )}
      </div>
    </div>
  );
});

AttachmentItem.propTypes = {
  store:        PropTypes.instanceOf(IssueStore), 
  attachment:   PropTypes.instanceOf(AttachmentModel), 
  issue:        PropTypes.instanceOf(IssueModel), 
  readOnly:     PropTypes.bool, 
  onDeleteFile: PropTypes.func
};

export default AttachmentItem;
