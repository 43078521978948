import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Контрл для переключения статуса задачи
 * 
 * @param {Object} props набор параметров
 * @param {String} params.className  пользовательский className
 * @param {String} params.title заголовок поля
 * @param {String} params.value значение статуса задачи
 * @param {Boolran} params.isActive активен ли контрол
 * @param {Function} params.oClick callback ф-я на клик по контролу
 */
const StatusToggleButton = ({
  className,
  title,
  value,
  isActive,
  onClick
}) => {
  const onClickButton = useCallback(() => {
    if (onClick) {
      onClick(value);
    }
  }, [onClick, value]);
  return (
    <div
      className={classNames("filter-status-toggles-toggle-button", className, {
        active: isActive
      })}
      onClick={onClickButton}
    >
      {title}
    </div>
  );
};

StatusToggleButton.propTypes = {
  className: PropTypes.string,
  title:     PropTypes.string,
  value:     PropTypes.string,
  isActive:  PropTypes.bool,
  onClick:   PropTypes.func
};

export default StatusToggleButton;
