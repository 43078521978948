import { action, computed, observable } from "mobx";
import Chunk from "./Chunk";

class InlinePicture extends Chunk {
  @observable
  captionId = null;
  @observable
  isEditingPicture = false;

  @action
  init(data, parent) {
    if (parent) {
      this.setParent(parent);
    }
    this.className = data.class;
    this.isNew = !!data.isNew;
    this.setValue(data.value || "", true);
  }

  @action
  async uploadFile(file) {
    return await this.store.uploadFile(file);
  }
  @action
  setEditingPicture(isEditingPicture = false) {
    this.isEditingPicture = isEditingPicture;
  }
  @action
  setEditing(offset) {
    if (!this.store) {
      return null;
    }
    if (this.delta < 0) {
      if (this.isFirst || !this.prevSibling.isEdiatbleChunk) {
        const newId = this.store.getUid();
        this.parent.createAfter(this.isFirst ? null : this.prevSiblingId, {
          uid:   newId,
          class: "text.chunk.Varchar",
          value: "",
          isNew: true
        });
        this.store.setEditingChunkId(newId);
      } else {
        this.parent.setPrevChild(this.uid);
      }
    } else if (this.delta > 0) {
      if (this.isLast || !this.nextSibling.isEdiatbleChunk) {
        const newId = this.store.getUid();
        this.parent.createAfter(this.uid, {
          uid:   newId,
          class: "text.chunk.Varchar",
          value: "",
          isNew: true
        });
        this.store.setEditingChunkId(newId);
      } else {
        this.parent.setNextChild(this.uid);
      }
    } else {
      this.store.setEditingChunkId(this.uid, offset);
    }
  }

  @computed
  get flatItemsArray() {
    return [this];
  }

  @computed
  get isPictureChunk() {
    return true;
  }
}

export default InlinePicture;
