import React from "react";

import NormalButton from "./NormalButton";
import DropButton from "./DropButton";

const HeaderButton = (props) => {
  const { type, id } = props;
  if (type === "drop") {
    return <DropButton key={id} {...props} />;
  } else if (type === "spacer") {
    return <div key={id} className="spacer" />;
  } else {
    return <NormalButton key={id} {...props} />;
  }
};

export default HeaderButton;
