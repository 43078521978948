import React from "react";
import { observer } from "mobx-react";

import Header from "./Header";
import Info from "./Info";
import Payload from "./Payload";

/**
 * Запись журнала изменений
 */
const JournalItem = observer(({ item, store }) => {
  return (
    <div className={`journal-item order-${store.filters.order}`}>
      <Header
        item={item}
        className="journal-item-block"
      />
      <Info
        item={item}
        className="journal-item-block"
      />
      <Payload
        item={item}
        className="journal-item-block"
      />
      <div className="journal-item-bottom-direction" />
    </div>
  );
});

export default JournalItem;
