import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Кнопка для выставления кол-ва записей на странице
 * @class Button
 */
const Button = ({
  /**
   * Флаг - является ли текущим значением кол-ва записей на странице
   * @type {Boolean}
   */
  active,

  /**
   * Ф-я для обработки нажатия кнопки
   * @type {Function}
   */
  onClick,

  /**
   * Пользовательский className
   * @type {String}
   */
  className,

  /**
   * Значение - кол-ва записей на странице
   * @type {number}
   */
  size
}) => {
  const onButtonClick = useCallback(() => {
    if (onClick) {
      onClick(size);
    }
  }, [onClick, size]);

  return (
    <div
      className={classNames("page-size", className, {
        active
      })}
      onClick={onButtonClick}
    >
      {size}
    </div>
  );
};

Button.propTypes = {
  active:    PropTypes.bool,
  onClick:   PropTypes.func,
  className: PropTypes.string,
  size:      PropTypes.number  
};


export default Button;
