import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import SearchStore from "../../stores/searchStore";
import { conditionIcon } from "../../constants/condtions";

/**
 * Контрол для выбора условия сравнения
 * 
 * @param {Object} params  набор параметров 
 * @param {Any} params.value значение поля ввода
 * @param {String} params.type тип поля ввода
 * @param {Array<String>} params.conditions набор доступных значений для сравнения
 * @param {SearchStore} params.store хранилище для поиска
 * @param {Functiun} params.onChange callback ф-я при изменении значения поля ввода
 
 * @returns 
 */
const Condition = ({ value, type, conditions, store, onChange }) => {
  const elRef  = useRef(null);
  
  /**
   * Кликнули на контрол с условием
   */
  const onClick = useCallback((e) => {
    e.stopPropagation();
    if (store.conditionPickerData && store.conditionPickerData.conditionRef.current === elRef.current) {
      // если уже вызывали для этого контрола picker, то скрываем его
      store.setConditionPickerData(null);  
    } else {
      store.setConditionPickerData({
        conditionRef: elRef,
        value,
        type,
        conditions,
        onChange
      });
    }
  }, [value, type, conditions, store, onChange]);

  return (
    <div 
      className="attr-field-condition"
      ref={elRef}
      {...{
        // "data-tooltip": conditionTitle(value)
      }}
      onClick={onClick}
    >
      <label>{conditionIcon(value)}</label>
    </div>
  );
};

Condition.propTypes = {
  value:      PropTypes.any,
  type:       PropTypes.string,
  conditions: PropTypes.arrayOf(String),
  store:      PropTypes.instanceOf(SearchStore), 
  onChange:   PropTypes.func
};

export default Condition;
