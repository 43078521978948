import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";
import AttrsForm from "./AttrsForm";

const KindGroup = observer(({ member, objectUid, version }) => {
  const { kind, edited } = member;
  const { uiStore } = useStores();
  if (!kind) {
    return null;
  }
  const { name, id } = kind;
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded, setIsExpanded]);

  const onDeleteKind = useCallback(() => {
    uiStore.setConfirm(`Вы уверены что хотите удалить вид ${name}?`, "Удаление вида", [
      {
        onPress: () => {
          uiStore.setConfirmPending(true);
          onConfirmDelete();
          uiStore.hideConfirm();
        },
        theme: "positive",
        icon:  "ok-M",
        text:  "Да"
      },
      {
        onPress: () => {
          uiStore.hideConfirm();
        },
        theme: "negative",
        icon:  "cancel-M",
        text:  "Нет"
      }
    ]);
  }, []);

  const onSave = useCallback(async() => {
    await member.save();
  }, [member]);

  const onConfirmDelete = useCallback(async() => {
    await member.delete();
  }, [member]);

  return (
    <div className="kind-group">
      <div className="item-header">
        <div className="item-name">{name}</div>
        {isExpanded && (
          <AisIcon onClick={toggleExpanded} icon={"chevron-up-M"} />
        )}
        {!isExpanded && (
          <AisIcon onClick={toggleExpanded} icon={"chevron-down-M"} />
        )}
        <div className="spacer"></div>
        {!!edited && !version && (
          <AisIcon
            onClick={onSave}
            className="save"
            data-tooltip="Сохранить"
            icon={"save-M"}
          />
        )}
        {!version && (
          <AisIcon
            onClick={onDeleteKind}
            className="delete"
            data-tooltip="Удалить вид"
            icon={"delete-M"}
          />
        )}
      </div>
      {isExpanded && (
        <div className="related-list">
          <AttrsForm
            isView={!!version}
            disabled={false}
            version={version}
            itemUid={objectUid}
            kindUid={id}
          />
        </div>
      )}
    </div>
  );
});

export default KindGroup;
