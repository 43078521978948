import React from "react";
import PropTypes from "prop-types";
import AisIcon from "~/core/components/AisIcon";

/**
 * @class FormTitle
 * Заголовок формы
 * 
 * @param {Object} param набор параметров 
 * @param {String} param.title заголовок формы
 * @param {Boolean} param.isExpanded свернута/развернута форма
 * @param {Function} param.onToggleExpanded callback функция для сворачивания/развоарчивания
 * @returns 
 */
const FormTitle = ({ title, isExpanded, onToggleExpanded }) => {
  return (
    <div className="form-title">
      <div className="form-title-text">{title}</div>
      <AisIcon
        onClick={onToggleExpanded}
        icon={isExpanded ? "chevron-up-M" : "chevron-down-M"}
      />
      <div className="form-title-spacer"></div>
    </div>
  );
};

FormTitle.propTypes = {
  title:            PropTypes.string,
  isExpanded:       PropTypes.bool,
  onToggleExpanded: PropTypes.func.isRequired 
};

export default FormTitle;
