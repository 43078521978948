import {
  DOMAIN_TEXT,
  DOMAIN_REPO,
  DOMAIN_LIBRARY,
  DOMAIN_WF,
  DOMAIN_ISSUE,
  DOMAIN_BINDER
  // DOMAIN_POF,
  // DOMAIN_KINDS,
  // DOMAIN_GROUPS,
} from "./Domains";

/**
 * Набор class, которые используются в АИС ППП
 */
// Репозиторий
const CLS_REPO_DIR = `${DOMAIN_REPO}.Directory`;
const CLS_REPO_FILE = `${DOMAIN_REPO}.File`;
const CLS_REPO_FILE_FUNCTION = `${CLS_REPO_FILE}.Function`;
const CLS_REPO_BRANCHES = `${DOMAIN_REPO}.branches`;
const CLS_REPO_TAGS = `${DOMAIN_REPO}.tags`;
const CLS_REPO_GIT_REPOSITORY = `${DOMAIN_REPO}.git.repository`;
const CLS_REPO_GIT_TAG = `${DOMAIN_REPO}.git.tag`;
const CLS_REPO_GIT_BRANCH = `${DOMAIN_REPO}.git.branch`;

// Задачи
const CLS_ISSUE = `${DOMAIN_ISSUE}.Issue`;

// Work Flow
const CLS_WF_INSTANCE = `${DOMAIN_WF}.Instance`;

// Библиотека
const CLS_LIBRARY_TEXT_MATERIAL = `${DOMAIN_LIBRARY}.TextMaterial`;
const CLS_LIBRARY_TEXT_VERSION = `${DOMAIN_LIBRARY}.TextVersion`;
const CLS_LIBRARY_COLLECTION = `${DOMAIN_LIBRARY}.Collection`;
const CLS_LIBRARY_REPOSITORY = `${DOMAIN_LIBRARY}.RepositoryMaterial`; 
const CLS_LIBRARY_FILE = `${DOMAIN_LIBRARY}.FileMaterial`;

// Текстовый редактор: Тектовая форма
const CLS_TEXT_FORM_TEXT = `${DOMAIN_TEXT}.form.Text`;

// Текстовый редактор: Контейнеры
const CLS_TEXT_CONTAINER = `${DOMAIN_TEXT}.container`;
const CLS_TEXT_RUBRIC = `${CLS_TEXT_CONTAINER}.Rubric`;
const CLS_TEXT_ENUMERATION_ITEM = `${CLS_TEXT_CONTAINER}.EnumerationItem`;
const CLS_TEXT_TABLE_ROW = `${CLS_TEXT_CONTAINER}.TableRow`;
const CLS_TEXT_TABLE_CELL = `${CLS_TEXT_CONTAINER}.TableCell`;

// Текстовый редактор: Элементы
const CLS_TEXT_ELEMENT = `${DOMAIN_TEXT}.element`;
const CLS_TEXT_INDENT = `${CLS_TEXT_ELEMENT}.Indent`;
const CLS_TEXT_PICTURE = `${CLS_TEXT_ELEMENT}.Picture`;
const CLS_TEXT_FORMULA = `${CLS_TEXT_ELEMENT}.Formula`;
const CLS_TEXT_CAPTION = `${CLS_TEXT_ELEMENT}.Caption`;
const CLS_TEXT_ENUMERATION = `${CLS_TEXT_ELEMENT}.Enumeration`;
const CLS_TEXT_TABLE = `${CLS_TEXT_ELEMENT}.Table`;
const CLS_TEXT_CODE = `${CLS_TEXT_ELEMENT}.CodeBlock`;
const CLS_TEXT_CODE_LINE = `${CLS_TEXT_ELEMENT}.CodeLine`;

// Текстовый редактор: Чанки
const CLS_TEXT_CHUNK = `${DOMAIN_TEXT}.chunk`;
const CLS_TEXT_CHUNK_VARCHAR = `${CLS_TEXT_CHUNK}.Varchar`;
const CLS_TEXT_CHUNK_INLINE_CODE = `${CLS_TEXT_CHUNK}.InlineCode`;
const CLS_TEXT_CHUNK_INLINE_PICTURE = `${CLS_TEXT_CHUNK}.InlinePicture`;
const CLS_TEXT_CHUNK_INLINE_FORMULA = `${CLS_TEXT_CHUNK}.InlineFormula`;

// Виды
const CLS_KIND = "kindsattrs.Kind";

// Трассировка
const CLS_TRACER_BELONGS_TO = "tracer.BelongsTo";
const CLS_TRACER_CONTAINS = "tracer.Contains";

// Cвязь
const CLS_BINDER_LINK = `${DOMAIN_BINDER}.Link`;


const getClassTitle = (cls) => {
  switch (cls) {
    case CLS_REPO_DIR:
      return "Директория";
    case CLS_REPO_FILE:
      return "Файл";
    case CLS_REPO_FILE_FUNCTION:
      return "Функция в файле";

    case CLS_ISSUE: 
      return "Задача";

    case CLS_WF_INSTANCE:
      return "WF Insance";

    case CLS_LIBRARY_TEXT_MATERIAL:
      return "Текстовое представление";
    case CLS_LIBRARY_TEXT_VERSION:
      return "Версия текстового представления";
    case CLS_LIBRARY_COLLECTION:
      return "Коллекция в библиотеке";
    case CLS_LIBRARY_REPOSITORY:
      return "Репозиторий";
    case CLS_LIBRARY_FILE:
      return "Файл"; 

    case CLS_TEXT_FORM_TEXT: 
      return "Рабочий материал";
    case CLS_TEXT_RUBRIC:
      return "Рубрика";
    case CLS_TEXT_INDENT:
      return "Абзац";
    case CLS_TEXT_PICTURE:
      return "Рисунок";
    case CLS_TEXT_FORMULA:
      return "Формула";
    case CLS_TEXT_CAPTION:
      return "Подпись";
    case CLS_TEXT_ENUMERATION:
      return "Список";
    case CLS_TEXT_ENUMERATION_ITEM:
      return "Пункт списка";
    case CLS_TEXT_TABLE:
      return "Таблица";
    case CLS_TEXT_TABLE_ROW:
      return "Строка таблицы";
    case CLS_TEXT_TABLE_CELL:
      return "Ячейка таблицы";
    case CLS_TEXT_CODE:
      return "Код";
    case CLS_TEXT_CODE_LINE:
      return "Строка кода";
    case CLS_TEXT_CHUNK_VARCHAR:
      return "Чанк";
    case CLS_TEXT_CHUNK_INLINE_CODE:
      return "Inline строка кода";
    case CLS_TEXT_CHUNK_INLINE_PICTURE:
      return "Inline рисунок";
    case CLS_TEXT_CHUNK_INLINE_FORMULA:
      return "Inline формула";

    case CLS_KIND:
      return "Вид";

    case CLS_TRACER_BELONGS_TO:
      return "Имеет вид";
    case CLS_TRACER_CONTAINS:
      return "Содержится в";
    
    case CLS_BINDER_LINK :
      return "Связь";

    default:
      return cls;
  }
};
export {
  CLS_REPO_DIR,
  CLS_REPO_FILE,
  CLS_REPO_FILE_FUNCTION,
  CLS_REPO_BRANCHES,
  CLS_REPO_TAGS,
  CLS_REPO_GIT_REPOSITORY,
  CLS_REPO_GIT_TAG,
  CLS_REPO_GIT_BRANCH,

  CLS_ISSUE,
  CLS_WF_INSTANCE,

  CLS_LIBRARY_TEXT_MATERIAL,
  CLS_LIBRARY_TEXT_VERSION,
  CLS_LIBRARY_COLLECTION,
  CLS_LIBRARY_REPOSITORY,
  CLS_LIBRARY_FILE,

  CLS_TEXT_FORM_TEXT,
  CLS_TEXT_RUBRIC,
  CLS_TEXT_ENUMERATION_ITEM,
  CLS_TEXT_TABLE_ROW,
  CLS_TEXT_TABLE_CELL,
  CLS_TEXT_INDENT,
  CLS_TEXT_PICTURE,
  CLS_TEXT_FORMULA,
  CLS_TEXT_CAPTION,
  CLS_TEXT_ENUMERATION,
  CLS_TEXT_TABLE,
  CLS_TEXT_CODE,
  CLS_TEXT_CODE_LINE,
  CLS_TEXT_CHUNK_VARCHAR,
  CLS_TEXT_CHUNK_INLINE_CODE,
  CLS_TEXT_CHUNK_INLINE_PICTURE,
  CLS_TEXT_CHUNK_INLINE_FORMULA,

  CLS_KIND,

  CLS_TRACER_BELONGS_TO,
  CLS_TRACER_CONTAINS,

  CLS_BINDER_LINK,

  getClassTitle
};
