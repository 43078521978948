import { action, computed, observable } from "mobx";
import RestrictionsForRole from "./RestrictionsForRole";

/**
 * Базовый  объект
 *
 * @type {BaseObject}
 */
class RestrictionsForObject {
  @observable criterion = null;
  @observable value = null;
  @observable configStore = null;
  @observable restrictionsForTool = null;
  @observable subjectsByRoleMap = new Map();

  constructor(criterion, value, parent, store) {
    this.restrictionsForTool = parent;
    this.criterion = `${criterion}`;
    this.value = `${value}`;
    this.configStore = store;
  }

  @action
  addItem({ key, role, subjectCriterion, subjectCriterionValue }) {
    if (`${key}` !== this.key) {
      throw new Error("Переданы ограничения для другого объекта");
    }
    let subjectsByRole = this.subjectsByRoleMap.get(role);
    if (!subjectsByRole) {
      subjectsByRole = new RestrictionsForRole(role, this, this.configStore);
      this.subjectsByRoleMap.set(role, subjectsByRole);
    }
    subjectsByRole.addItem({
      role,
      subjectCriterion,
      subjectCriterionValue
    });
  }

  @computed
  get key() {
    return `${this.criterion}-${this.value}`;
  }

  @computed
  get classByKind() {
    const classByKind = {};
    this.subjectsByRoleMap.forEach((restrictionsForRole, role) => {
      if (role === "as") {
        Object.assign(classByKind, restrictionsForRole.classByKind);
      }
    });
    return classByKind;
  }

  @computed
  get config() {
    const config = [];
    const restrictionsForRole = this.subjectsByRoleMap.get("contains");
    if (restrictionsForRole) {
      return restrictionsForRole.config;
    }
    return config;
  }
}

export default RestrictionsForObject;
