import { observable, action, computed, runInAction } from "mobx";

import KindMember from "./KindMember";
import { DOMAIN_TEXT, DOMAIN_LIBRARY } from "~/core/constants/Domains";
import AisVersion from "../../../core/data/models/AisVersion";

const arrayDiff = (a, b) => {
  return a.filter((i) => {
    return b.indexOf(i) < 0;
  });
};

class KindItem extends AisVersion {
  @observable
  pendingItem = false;

  @observable
  uid = null;
  @observable
  kinds = new Map();

  @observable
  kindsToRemove = [];
  @observable
  kindsToAdd = [];

  @observable
  displayName = null;
  @observable
  etype = null;
  @observable
  contentUid = null;
  @observable
  domain = "kinds";
  
  constructor(data, store) {
    super(data, store.objectStore);
    this.store = store;
    this.init(data);
  }

  @action
  init(data) {
    this.uid = data.objectId;
    this.displayName = data.name;
    this.etype = data.etype;
    this.kinds = new Map();
    this.contentUid = data.contentUid;

    this.addKindData(data);
  }

  @action
  getKind(uid) {
    return this.kinds.get(uid);
  }

  @action
  setContentUid(contentUid) {
    return (this.contentUid = contentUid);
  }

  @action
  setPending(pending = false) {
    this.pendingItem = pending;
  }

  @action
  setId(uid) {
    this.uid = uid;
    this.kinds.forEach((member) => {
      member && member.setId(uid);
    });
  }

  @action
  processKinds(memberKinds) {
    Object.keys(memberKinds).forEach((kindUid) => {
      const kind = memberKinds[kindUid];
      const attributes = [];

      Object.keys(kind.values).forEach((id) => {
        const value = kind.values[id];
        attributes.push({
          id,
          value
        });
      });

      const memberData = {
        uid:        this.uid,
        kindUid,
        number:     kind.number,
        origin:     kind.origin,
        codeValues: kind.codeValues,
        index:      kind.index,
        memberUid:  kind.member,
        attributes
      };

      const member = new KindMember(memberData, this.store, this);
      this.kinds.set(kindUid, member);
      this.store.addKindMember(member);
    });
  }

  @action
  replaceKinds(uidArray) {
    const removeDiff = arrayDiff(this.kindUids, uidArray);
    const addDiff = arrayDiff(uidArray, this.kindUids);
    removeDiff.forEach((uid) => {
      const member = this.kinds.get(uid);
      this.kindsToRemove.push(member.memberUid);
      this.kinds.delete(uid);
    });
    addDiff.forEach((uid) => {
      this.addKind(uid);
    });
  }

  @action
  addKind(uid) {
    if (!uid) {
      return null;
    }
    this.kindsToAdd.push(uid);
    const member =       new KindMember(
      {
        kindUid: uid,
        uid:     this.uid
      },
      this.store,
      this
    );
    this.kinds.set(
      uid,
      member
    );
    this.store.addKindMember(member);
    return this.getKind(uid);
  }

  @action
  addKindData(data) {
    if (!data || !data.kindUid) {
      return null;
    }

    const memberData = {
      kindUid:    data.kindUid,
      uid:        this.uid,
      memberUid:  data.uid,
      codeValues: data.codeValues,
      values:     data.values
    };
    const member = new KindMember(memberData, this.store, this);
    this.kinds.set(data.kindUid, member);
    this.store.addKindMember(member);
  }

  @action
  deleteKind(uid) {
    this.kinds.delete(uid);
  }

  @action
  removeKind(memberUid) {
    this.kindsToRemove.push(memberUid);
  }

  later(delay) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }

  @action
  revert() {
    this.kindsToRemove.clear();
    this.kindsToAdd.clear();
    this.kinds.forEach((kindMember, key) => {
      if (kindMember.hasUid) {
        kindMember.revert();
      } else {
        this.kinds.delete(key);
      }
    });
  }

  @action
  async save() {
    this.setPending(true);
    const deletePromises = [];
    this.kindsToRemove.forEach((memberUid) => {
      const member = this.store.getKindMemberSync(memberUid);

      this.kinds.delete(member.kindId);
      this.store.deleteKindMember(memberUid);
      deletePromises.push(this.store.api.deleteKindMember(memberUid));
    });
    await Promise.all(deletePromises);
    this.kindsToRemove.clear();
    const savePromises = [];
    this.kinds.forEach((kindMember) => {
      savePromises.push(kindMember.save());
    });
    const data = await Promise.all(savePromises);
    await this.store.processMembers(data);

    runInAction(() => {
      this.etype =
        this.kindsSize > 0
          ? "aggr.kindsattrs.item"
          : "aggr.kindsattrs.item.empty";
      this.setPending(false);
    });
  }

  @action
  setAttrValue(kindUid, id, value, isValid) {
    const member = this.kinds.get(kindUid);
    if (member) {
      member.setAttrValue(id, value, isValid);
    }
  }

  @action
  getAttrValue(kindUid, id) {
    const member = this.kinds.get(kindUid);
    if (member) {
      return member.getAttrValue(id);
    }
    return null;
  }

  @computed
  get name() {
    const { objectStore } = this.store;

    const itemReady = objectStore.getObject(this.uid);

    let representation;
    if (itemReady.etype.indexOf("text") >= 0) {
      representation = objectStore.getVersion(this.uid, DOMAIN_TEXT);
    } else if (itemReady.etype.indexOf("library") >= 0) {
      const lib = objectStore.getVersion(this.uid, DOMAIN_LIBRARY);
      if (lib && lib.name) {
        representation = objectStore.getVersion(lib.name.uid, DOMAIN_TEXT);
      }
    }
    let title = this.uid;
    if (representation && representation.title) {
      title = representation.title;
    }
    return title;
  }

  @computed
  get kindsToAddForSelect() {
    if (this.kindUids.length === 0) {
      return this.store.kindsForSelect;
    }
    const kindsArray = [];
    this.store.kindsForSelect.forEach((kind) => {
      if (!this.kindNamesSet.has(kind.label)) {
        kindsArray.push(kind);
      }
    });
    return kindsArray;
  }

  @computed
  get kindsForSelect() {
    if (this.kindUids.length === 0) {
      return undefined;
    }
    const kindsArray = [];
    this.kindUids.forEach((kindUid) => {
      const kind = this.store.getKind(kindUid);
      if (kind) {
        kindsArray.push({
          value: kindUid,
          label: kind.name
        });
      }
    });
    return kindsArray;
  }

  @computed
  get kindsArray() {
    const array = [];
    this.kinds.forEach((value) => {
      array.push(value);
    });
    return array;
  }

  @computed
  get kindNames() {
    const array = [];
    this.kindsForSelect &&
      this.kindsForSelect.forEach((value) => {
        array.push(value.label);
      });
    return array;
  }

  @computed
  get kindNamesSet() {
    return new Set(this.kindNames);
  }

  @computed
  get contentMemberUids() {
    let uids = [];
    const item = this.store.getItemSync(this.contentUid);
    if (item) {
      uids = item.kindsArray;
    }
    return uids;
  }

  /**
   * Массив uid'ов участников вида
   * 
   * @return {Array<String>}
   */
  @computed
  get memberUids() {
    const uids = Array.from(this.kinds.values()).map((member) => {
      return member.memberUid;
    });
    
    return uids;
  }

  @computed
  get initialKinds() {
    if (this.kindInitialUids.length === 0) {
      return [];
    }
    const kindsArray = [];
    this.kindInitialUids.forEach((kindUid) => {
      const kind = this.kinds.get(kindUid);
      kindsArray.push(kind);
    });
    return kindsArray;
  }

  @computed
  get kindsInitSelect() {
    if (this.kindInitialUids.length === 0) {
      return undefined;
    }
    const kindsArray = [];
    this.kindInitialUids.forEach((kindUid) => {
      const kind = this.store.getKind(kindUid);
      kindsArray.push({
        value: kindUid,
        label: kind.name
      });
    });
    return kindsArray;
  }

  @computed
  get kindsSize() {
    return this.kinds.size;
  }

  @computed
  get kindUids() {
    return Array.from(this.kinds.keys());
  }

  @computed
  get isValid() {
    let valid = true;
    this.kinds.forEach((member) => {
      if (member.isValid === false) {
        valid = false;
      }
    });
    return valid;
  }

  @computed
  get kindInitialUids() {
    const uids = [];

    if (this.isPending) {
      return uids;
    }
    this.kinds.forEach((kind, key) => {
      if (kind.hasUid) {
        uids.push(key);
      }
    });
    return uids;
  }

  @computed
  get canSave() {
    let canSave = false;
    this.kinds.forEach((member) => {
      canSave = canSave || member.needsPersist;
    });
    return (canSave || this.kindsToRemove.length > 0) && this.isValid;
  }

  @computed
  get iconString() {
    const iconStrings = [];
    this.kinds.forEach((kind) => {
      const str = kind.iconString;
      if (str) {
        iconStrings.push(str);
      }
    });
    if (iconStrings.length > 0) {
      return iconStrings[iconStrings.length - 1];
    } else if (this.kinds.size > 0) {
      return "token-M";
    } else {
      return null;
    }
  }

  @computed
  get isPending() {
    return this.pendingItem;
  }
  @computed
  get pending() {
    let p = false;
    this.kinds.forEach((kindMember) => {
      if (kindMember.pending) {
        p = true;
      }
    });

    return p || this.isPending;
  }

  @computed
  get allowedTypes() {
    let allowedTypes = undefined;
    this.kinds.forEach((kindMember) => {
      if (kindMember.allowedTypes !== undefined) {
        if (allowedTypes) {
          new Set(
            [allowedTypes].filter((x) => {
              return kindMember.allowedTypes.has(x);
            })
          );
        } else {
          allowedTypes = kindMember.allowedTypes;
        }
      }
    });
    return allowedTypes;
  }

  @computed
  get allowedKinds() {
    let allowedKinds = undefined;
    this.kinds.forEach((kindMember) => {
      if (kindMember.allowedKinds !== undefined) {
        if (allowedKinds) {
          new Set(
            [allowedKinds].filter((x) => {
              return kindMember.allowedKinds.has(x);
            })
          );
        } else {
          allowedKinds = kindMember.allowedKinds;
        }
      }
    });
    return allowedKinds;
  }

  @computed 
  get traceSchemas() {
    if (this.kindUids.length === 0) {
      return [];
    }
    const res = new Map();
    this.kindUids.forEach((kindUid) => {
      const kind = this.store.getKind(kindUid);
      if (kind) {
        kind.traceSchemasList.forEach((schema) => {
          res.set(schema.id, schema);
        });
      }
    });
    return Array.from(res.values());
  }
}

export default KindItem;
