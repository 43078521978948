import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import "react-table/react-table.css";
import UserAvatar from "~/modules/users/components/avatar";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";

import "../css/index.scss";
import { Button, Icon, Preloader } from "@ais3p/ui-framework-old";

const ValidationSidepanel = observer((props) => {
  const { objectStore } = useStores();
  const { uid, version, trackedItem } = props;

  const aggr = useMemo(() => {
    if (trackedItem) {
      let domain = trackedItem.domain || trackedItem.tool;
      if (!domain && trackedItem.class) {
        domain = trackedItem.class.split(".")[0];
      }
      return objectStore.getVersion(
        trackedItem.uid,
        domain,
        trackedItem.version
      );
    }
    return trackedItem;
  }, [trackedItem, uid, objectStore]);

  const onValidate = useCallback(() => {
    if (aggr) {
      aggr.validate();
    }
  }, [uid, aggr]);
  const content = useMemo(() => {
    if (!aggr) {
      return (
        <div className="preload-wrapper">
          <Preloader
            size={4}
            style={{
              margin:       "0.25rem",
              borderRadius: "3rem"
            }}
            color="#01abfb"
          />
        </div>
      );
    }

    if (!aggr.isValidatable) {
      return (
        <div className="header">
          <span className="text-holder">Объект не может быть согласован</span>
        </div>
      );
    }

    return (
      <div className="validation-list">
        <div className="user-list">
          {aggr.validatorsList.map((user) => {
            return (
              <div key={user.uid} className="user-holder">
                <UserAvatar className="image" fileId={user.image} />
                <div className="user-name">{user.label}</div>
                {user.isMe && !user.isValidated && (
                  <Button
                    onClick={onValidate}
                    size={2}
                    theme="success"
                    leftIcon="ok-M"
                    text={"Согласовать"}
                  />
                )}
                {!(user.isMe && !user.isValidated) && (
                  <Icon
                    {...{
                      "data-tooltip": !user.isValidated
                        ? "На согласовании"
                        : "Согласовано",
                      "data-tooltip-at": props["data-tooltip-at"]
                    }}
                    className={`expander big validate ${
                      !user.isValidated ? "in-progress" : ""
                    }`}
                    name={true}
                    icon={!user.isValidated ? "processing-M" : "ok-M"}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="header">
          <Icon
            {...{
              "data-tooltip": !aggr.isValidated
                ? "На согласовании"
                : "Согласовано",
              "data-tooltip-at": props["data-tooltip-at"]
            }}
            className={`expander big validate ${
              !aggr.isValidated ? "in-progress" : ""
            }`}
            name={true}
            icon={!aggr.isValidated ? "processing-M" : "ok-M"}
          />
          <span className="text-holder">
            Объект {aggr.isValidated ? "согласован" : "на согласовании"}
          </span>
        </div>
      </div>
    );
  }, [aggr, uid, aggr && aggr.isValidated]);

  return (
    <div className={"validation-tool-holder"}>
      <div className={"validation-tracker"}>
        <Target id={uid} version={version} trackedItem={trackedItem} />
        <div className="list-holder">
          <div className="items-holder">{content}</div>
        </div>
      </div>
    </div>
  );
});

export default ValidationSidepanel;
