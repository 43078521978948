import React, { Component } from "react";
import { Provider, observer } from "mobx-react";

import { ContextMenu, CustomDragLayer, Button } from "@ais3p/ui-framework-old";

import "@ais3p/ui-framework-old/dist/index.css";

import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";

import AppContainer from "~/core/components/AppContainer";

import RootStore from "~/core/data/stores/rootStore";

import "~/App.scss";
import Confirm from "./Confirm";

import { layout } from "@ais3p/ui-framework";
import { errorNotify } from "../utils";
import ReloadBtn from "./ReloadBtn";
const { notification } = layout;

// import { updateRemSize } from "~/core/utils";

const menuTypes = {
  STATIC: [
    {
      icon:  "refresh-M",
      title: "Обновить",
      data:  { action: "refresh" }
    },
    { isDivider: true },
    {
      icon:  "editing-M",
      title: "Переименовать",
      data:  { action: "rename" }
    },
    {
      icon:  "cancel-M",
      title: "Удалить",
      data:  { action: "remove" }
    }
  ],
  MATERIAL: [
    {
      icon:  "open-M",
      title: "Открыть редакцию",
      data:  { action: "openRedaction" }
    },
    { isDivider: true },
    {
      icon:  "refresh-M",
      title: "Обновить",
      data:  { action: "refresh" }
    },
    { isDivider: true },
    {
      icon:  "editing-M",
      title: "Переименовать",
      data:  { action: "rename" }
    },
    {
      icon:  "cancel-M",
      title: "Удалить",
      data:  { action: "remove" }
    }
  ],
  KINDS: [
    {
      icon:  "editing-M",
      title: "Изменить",
      data:  { action: "edit" }
    },
    {
      icon:  "cancel-M",
      title: "Удалить",
      data:  { action: "delete" }
    }
  ],
  COLLECTION: [
    {
      icon:     "collection-create-M",
      title:    "Создать...",
      children: [
        {
          icon:  "collection-create-M",
          title: "Коллекцию",
          data:  { action: "createCollection" }
        },
        {
          icon:  "file-ais-M",
          title: "Рабочий материал",
          data:  { action: "createWM" }
        }
      ]
    },
    {
      icon:  "plus-M",
      title: "Загрузить файл",
      data:  { action: "upload" }
    },
    { isDivider: true },
    {
      icon:  "refresh-M",
      title: "Обновить",
      data:  { action: "refresh" }
    },
    {
      icon:  "open-M",
      title: "Открыть в новом окне",
      data:  { action: "openCollection" }
    },
    { isDivider: true },
    {
      icon:  "editing-M",
      title: "Переименовать",
      data:  { action: "rename" }
    },
    {
      icon:  "cancel-M",
      title: "Удалить",
      data:  { action: "remove" }
    }
  ],
  BUILDS: [
    {
      icon:  "editing-M",
      title: "Редактировать",
      data:  { action: "rename" }
    },
    { isDivider: true },
    {
      icon:  "cancel-M",
      title: "Удалить",
      data:  { action: "remove" }
    }
  ],
  VERSION:           [],
  VERSION_FORM_FILE: [
    {
      icon:  "download-M",
      title: "Скачать",
      data:  { action: "download" }
    }
  ],
  VERSION_FORM_FILE_WITH_IMPORT: [
    {
      icon:  "download-M",
      title: "Скачать",
      data:  { action: "download" }
    },
    {
      icon:  "import-M",
      title: "Импортировать",
      data:  { action: "import" }
    }
  ],
  TEXT_FORM: [
    {
      icon:  "open-M",
      title: "Открыть",
      data:  { action: "open" }
    }
    // {
    //   icon: false,
    //   title: "Экспортировать в PDF",
    //   data: { action: "exportPDF" }
    // }
  ],
  HEADER: [
    {
      icon:  "save-M",
      title: "Сохранить рабочее пространство",
      data:  { action: "save" }
    },
    {
      icon:  "editing-M",
      title: "Изменить",
      data:  { action: "edit" }
    },
    {
      icon:  "delete-M",
      title: "Удалить",
      data:  { action: "delete" }
    }
  ]
};

@observer
class App extends Component {
  constructor(props) {
    super(props);

    this.contextMenuCollect = this.contextMenuCollect.bind(this);
    this.onReload = this.onReload.bind(this);
    this.onDismissError = this.onDismissError.bind(this);
    const rootStore = new RootStore();

    this.state = {
      rootStore
    };
  }

  // componentWillMount() {
  //   updateRemSize();
  // }

  componentWillUnmount() {
    const { rootStore } = this.state;
    rootStore.destroy();
  }

  contextMenuCollect(props) {
    this.setState({
      menuItems:   props.menuItems,
      menuType:    props.menuType,
      onMenuClick: props.onItemClick
    });

    return props;
  }
  onReload() {
    const { rootStore } = this.state;
    const { accountStore } = rootStore;
    accountStore.clearUserData();
    window.location.reload(true);
  };

  onDismissError() {
    const { rootStore } = this.state;
    const { uiStore } = rootStore;
    uiStore.dismissError();
  }
  render() {
    const { hasError, error } = this.props;
    const { rootStore, menuType, menuItems } = this.state;
    const {
      userStore,
      accountStore,
      relationStore,
      marksStore,
      objectStore,
      kindsStore,
      groupsStore,
      uiStore,
      configStore,
      wsStore
    } = rootStore;

    if (hasError) {
      return (
        <div className="App">
          <div id="ais-app-root" className="app-container login error"></div>
          <div className="overlay-blur visible"></div>
          <div className={"app-error visible"}>
            <div className="error-header">{"Критическая ошибка"}</div>
            <div className="error-text">{error && error.message}</div>
            <Button // eslint-disable-next-line
              onClick={() => {
                accountStore.clearUserData();
                window.location.reload(true);
              }}
              size={2}
              theme="default"
              leftIcon="close-M"
              text={"Перезапустить"}
            />
          </div>
        </div>
      );
    }
    const { showError, errorText, errorIsCritical } = uiStore;
    if (showError && errorText) {
      const content = <ReloadBtn onReload={this.onReload} errorText={errorText} />;
      errorNotify(errorIsCritical ? content : errorText, this.onDismissError);
    }
    return (
      <Provider
        rootStore={rootStore}
        uiStore={uiStore}
        userStore={userStore}
        accountStore={accountStore}
        relationStore={relationStore}
        marksStore={marksStore}
        objectStore={objectStore}
        kindsStore={kindsStore}
        groupsStore={groupsStore}
        configStore={configStore}
        wsStore={wsStore}
      >
        <div className={`App ${uiStore.showConfirm ? "confirm" : ""}`}>
          <AppContainer menuCollect={this.contextMenuCollect} />
          <div className="overlay-blur"></div>
          <notification.Area />
          <Confirm />
          <CustomDragLayer />
          <ContextMenu
            id={"treeId"}
            data={menuItems || menuTypes[menuType]}
            onMenuClick={this.state.onMenuClick}
          />
        </div>
      </Provider>
    );
  }
}

export default DragDropContext(HTML5Backend)(App); //eslint-disable-line
