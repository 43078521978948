import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Dnd } from "@ais3p/ui-framework-old";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import { getDomainByClass } from "~/core/utils";
import SchemaBoundaryModel from "../../models/schemaBoundary";


const availableDnDTypes = [
  Dnd.types.NODEITEM, 
  Dnd.types.EDITORITEM
];


/**
 * Компонент для отображения места drop зоны
 * 
 * @param {Object} props набор параметров
 * @param {SchemaBoundaryModel} props.boundary  запись ограничения Доли в схеме отчетов о трассировке
 */
const DropPlace = observer(({ boundary }) => {
  const { parameter, object } = boundary;
  const { objectStore } =  useStores();
  
  const onCanDrop = useCallback((props, monitor) => {
    const droppedItem = monitor.getItem();
    const { data } = droppedItem;
    if (!data) {
      return false;
    }

    // уже объект задан
    if (!!object) {
      return false;
    }


    const { payload } = data;
    const { restrictions } = parameter; 
    if (!payload || !restrictions) {
      return false;
    }

    if (restrictions.class && payload.class) {
      if (Array.isArray(restrictions.class)) {
        return restrictions.class.includes(payload.class);
      } else {
        return restrictions.class === payload.class;
      }
    }
    
    return false;
  }, [parameter]);

  const onDrop = useCallback((props, monitor) => {
    const droppedItem = monitor.getItem();
    const { data } = droppedItem;

    if (!boundary || !data) {
      return;
    }
    
    const { payload } = data;
    if (!payload) {
      return;
    }

    const version = payload.version;
    const uid = payload.uid || payload.id;
    const domain = getDomainByClass(payload.class);
    const obj = objectStore.getVersion(uid, domain, version);
    boundary.setValue(obj);
  }, [boundary]);

  return (
    <div className="schema-trace-item-drop">
      <Dnd.Target
        canDrop={onCanDrop}
        drop={onDrop}
        types={[
          ...availableDnDTypes
        ]}
        isRelative={true}
      >
        <span>{parameter.description}</span>
      </Dnd.Target>
    </div>
  );
});

DropPlace.propTypes = {
  boundary: PropTypes.instanceOf(SchemaBoundaryModel).isRequired
};


export default DropPlace;
