import { observable, computed } from "mobx";
import DataGapItemModel from "./dataGapItem";

/**
 * Модель записи GAP ошибки в данных отчета о трассировке
 *
 * @class  DataGapsModel
 */
class DataGapsModel {
  /**
   * Набор Gap ошибок
   *
   * @type Array<Object>
   */
  @observable
  items = [];

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {Array<Object>} params.items набор GAP ошибок
   *
   * @retrun {DataGapsModel}
   */
  static create({
    items = []
  }, rootStore) {
    return new  DataGapsModel({
      items
    }, rootStore);
  }

  constructor(params, rootStore) {
    this.items = params.items.map((item) => {
      return DataGapItemModel.create(item, rootStore);
    });

    this.rootStore = rootStore;
  }

  get dataType() {
    return "gaps";
  }

  /**
   * Строковое представление ошибки
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.items.map((item) => {
      return item.id;
    }).join(", ");
  }
}

export default DataGapsModel;
