import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import MessageList from "./MessageList";
import WSStore from "../../stores/wsStore";

/**
 * @type MessageList
 * 
 * Компонент для отображения сообщений, полученных по ws
 * 
 * @prop {WSStore} store хранилище для работы с ws пакетами
 */
const WSContent = observer(({ store }) => {
  return (
    <div className="ws-content">
      <MessageList store={store} />
    </div>
  );
});

WSContent.propTypes =  {
  store: PropTypes.instanceOf(WSStore)
};

export default WSContent;
