import React from "react";
import { observer } from "mobx-react";

import { Button } from "@ais3p/ui-framework-old";
import ChildButton from "./ChildButton";

@observer
class DropButton extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.canDrag = this.canDrag.bind(this);
  }

  onClick() {
    const { onClick, item, mode } = this.props;
    if (onClick) {
      if (mode) {
        onClick(mode);
      } else {
        onClick(item);
      }
    }
  }

  canDrag() {
    return this.props.canDrag;
  }

  render() {
    const { icon, children, getPresset } = this.props;
    const attributes = {
      // "data-tooltip":    item.name,
      // "data-tooltip-at": "right",
    };

    const childArray = children.map((child) => {
      return (
        <ChildButton
          getPresset={getPresset}
          key={child.id}
          {...{ ...this.props, ...child }}
        />
      );
    });
    return (
      <div className={"button-wrapper"} {...attributes}>
        <Button
          size={3}
          leftIcon={icon}
          rightIcon={"chevron-down-S"}
          mergeIcons={true}
          mergeColor={"#333"}
          theme={"outline"}
        />
        <div className={"children"}>{childArray}</div>
      </div>
    );
  }
}

export default DropButton;
