import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import StatusToggleButton from "./StatusToggleButton";

import {
  STATUSES,
  statusTitle,
  STATUS_USER,
  STATUS_OPEN
} from "../../constants/statuses";
import IssueStore from "../../stores/IssueStore";
import { Field } from "@ais3p/ui-framework";



/**
 * Контрл для отображения статуса задачи
 * 
 * @param {Object} props набор параметров
 * @param {String} params.className  пользовательский className
 * @param {IssueStore} params.store хранилизе для работы с задачами
 * @param {String} params.label заголовок поля
 * @param {String} params.name название поля
 * @param {String} params.value значение поля - статус задачи
 * @param {Function} params.oChange callback ф-я на изменение значения поля
 */
const Status = ({
  label = "Статус",
  name = "status",
  className = "",
  value = STATUS_OPEN,
  store,
  onChange
}) => {
  const [statuses, setStatuses] = useState(Array.isArray(value) ? value : []);
  const [toggleValue, setToggleValue] = useState(
    Array.isArray(value) ? STATUS_USER : value
  );

  const onChangeUserStatuses = useCallback(
    (val) => {
      setStatuses(val);
      if (onChange) {
        // onChange(val[0], name);
        onChange(val, name);
      }
    },
    [statuses, onChange]
  );

  const onClickToggleButton = useCallback(
    (val) => {
      setToggleValue(val);
      if (onChange) {
        if (val === STATUS_USER) {
          onChange(statuses, name);
        } else {
          onChange(val, name);
        }
      }
    },
    [statuses, onChange]
  );

  return (
    <div className={classNames("filter-status", className)}>
      <div className="filter-status-label">{label}</div>
      <div className="filter-status-values">
        <div className="filter-status-toggles">
          {STATUSES.map((st) => {
            return (
              <StatusToggleButton
                key={st}
                title={statusTitle(st)}
                value={st}
                isActive={st === toggleValue}
                onClick={onClickToggleButton}
              />
            );
          })}
        </div>
        {STATUS_USER === toggleValue && (
          <Field.MultiSelect
            label=""
            placeholder="Выберите статус(ы) задачи"
            icon="data-enum-multi-M"
            name={name}
            value={statuses}
            onChange={onChangeUserStatuses}
            multiselect={true}
            isClearable={true}
            isRequired={false}
            readOnly={false}
            options={store.statusList.map((st) => {
              return { label: st.title, value: st.id };
            })}
          />
        )}
      </div>
    </div>
  );
};

Status.propTypes = {
  className: PropTypes.string,
  label:     PropTypes.string,
  name:      PropTypes.string,
  value:     PropTypes.any,
  store:     PropTypes.instanceOf(IssueStore).isRequired,
  onChange:  PropTypes.func
};

export default Status;
