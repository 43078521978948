import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";

import MathfieldComponent from "~/core/components/MathField";
import useStores from "~/core/utils/useStores";

import { ContextMenuTrigger } from "@ais3p/ui-framework-old";
import mathml2latex from "mathml2latex";

const InlineFormula = observer(({ data, measure, useMathml = true }) => {
  const { uid, value, isEditable, diffClass } = data;
  const { layoutStore } = useStores();

  const mathFieldRef = useRef(null);

  useEffect(() => {
    measure && measure();
  }, [value, measure]);

  const setRef = useCallback((ref) => {
    mathFieldRef.current = ref;
  }, []);

  const latex = useMemo(() => {
    if (!useMathml || !value || value === "") {
      return value;
    }
    let latex = "";
    try {
      latex = mathml2latex.convert(
        `${value.replace(/<annotation.*\/annotation>/gim, "")}`
      );
    } catch (error) {
      console.warn(error);
    }
    return latex;
  }, [value, useMathml]);

  const contextMenuCollect = useCallback((props) => {
    const menuItems = [
      {
        icon:  "editing-M",
        title: "Изменить формулу",
        data:  { action: "editFormula" }
      },
      {
        icon:  "delete-M",
        title: "Удалить",
        data:  { action: "delete" }
      }
    ];
    layoutStore.menuCollect({
      menuItems,
      onItemClick: props.onItemClick
    });
    return props;
  }, []);

  const onMenuClick = useCallback((e, contextMenuData) => {
    const { action } = contextMenuData;
    switch (action) {
      case "delete":
        data.selfDestruct();
        break;
      case "editFormula":
        data.setEditingFormula(true);
        break;
      default:
        break;
    }
  }, []);

  const onChangeFormula = useCallback(
    async(latex = null) => {
      if (mathFieldRef.current && useMathml) {
        const mathml = `<math>${mathFieldRef.current.getValue(
          "math-ml"
        )}</math>`;
        await data.setValue(mathml);
      } else {
        await data.setValue(latex);
      }
    },
    [data, mathFieldRef.current, useMathml]
  );

  return (
    <span
      id={`${uid}`}
      contentEditable={false}
      className={`inline-Formula  ${diffClass}`}
    >
      <ContextMenuTrigger
        item={data}
        id={"contextMenuId"}
        menuType={"LOCAL"}
        onItemClick={onMenuClick}
        holdToDisplay={-1}
        collect={contextMenuCollect}
      >
        <MathfieldComponent
          mathfieldConfig={{
            virtualKeyboardMode: "onfocus",
            readOnly:            false,
            focus:               true
          }}
          setRef={setRef}
          latex={latex || value}
          disabled={!isEditable}
          onChange={onChangeFormula}
        />
      </ContextMenuTrigger>
    </span>
  );
});
export default InlineFormula;
