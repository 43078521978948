import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import AisIcon from "~/core/components/AisIcon";

import TraceSchemaModel from "../../models/traceSchema";

/**
 * Заголовок таблица для отображения записей отчета
 * 
 * @param {Object} props набор параметров
 * @param {String} props.type тип отчета - трассировка("trace")| GAP анализ ("gap")
 * @param {TraceSchema} props.schema схема трассировки
 */
const TableHeader = observer(({ type, schema }) => {
  if (!schema) {
    return null;
  }
  const renderItem = (item) => {
    if (item.schemaItemType === "link") {
      const icon = item.direction === "out" ? "arrow-right-M" : "arrow-left-M";
      return (
        <React.Fragment>
          <span>{item.title}</span><br />
          <AisIcon icon={icon} className="link-direction" />
        </React.Fragment>
      );
    } else {
      return item.title;
    }
  };
  return (
    <thead>
      <tr className="tracer-table-header">
        <th className="tracer-table-header-cell number">#</th>
        {type === "gap" &&  <th className="tracer-table-header-cell gap">GAP ошибка</th>}
        {schema.items && schema.items.map((item, i) => {
          return (
            <th key={i} className="tracer-table-header-cell"> 
              {renderItem(item)}
            </th>
          );
        })}
      </tr>
    </thead>
  );
});

TableHeader.propTypes = {
  schema: PropTypes.instanceOf(TraceSchemaModel),
  type:   PropTypes.string
};


export default TableHeader;
