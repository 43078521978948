import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { Icon } from "@ais3p/ui-framework-old";

/**
 * Компонент для отображения таба вкладки октрытого файла
 *
 * @param {Object} params набор параметров
 * @param {Object} params.index индекс линии
 * @param {CodeFile} params.codeFile объект, хранящий в себе информацию об открытом файле
 * @param {Function} params.closeTab callback функция, чтобы закрыть вкладку с файлом
 *
 * @type {CodeTab}
 * @returns {Component}
 */
const CodeTab = observer((props) => {
  const { index, codeFile, closeTab } = props;

  const onCloseTab = useCallback(
    (e) => {
      e.stopPropagation();
      closeTab && closeTab(codeFile, index);
    },
    [closeTab, codeFile, index]
  );

  return (
    <div className={"code-tab-body"}>
      <p>{codeFile.title}</p>
      <div className="code-tab-close" onClick={onCloseTab}>
        <Icon className="close" size={2} icon="close-M" />
      </div>
    </div>
  );
});

export default CodeTab;
