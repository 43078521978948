import { observable, computed, action } from "mobx";
/**
 * Группа пользователей
 *
 * @type {Group}
 */

class Group {
  @observable uid;
  @observable class;
  @observable type = "group";
  @observable description;
  @observable image;
  @observable name;
  @observable members = new Map();

  @observable newName;
  /**
   * Check if a `value` is a `Group` instance.
   *
   * @param {Any} value
   * @return {Boolean}
   */

  static isGroup(value) {
    return value instanceof Group;
  }

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid uid группы
   * @param {String} params.name название группы
   * @param {String} params.description описание
   * @param {String} params.image id файла аватарка группы
   *
   * @retrun {GroupModel}
   */
  static create({
    uid,
    name,
    class: klass,
    description,
    image
  }, store) {
    return new Group({
      uid,
      name,
      class: klass,
      description,
      image
    }, store);
  }

  constructor(data, store) {
    this.store = store;

    this.uid = data.uid;
    this.class = data.class;
    this.name = data.name;
    this.description = data.description;
    this.image = data.image;
  }

  @action
  update(data) {
    this.name = data.name || this.name;
    this.description = data.description || this.description;
    this.image = data.image || this.image;
  }

  @action
  changeNewName(value) {
    this.newName = value;
  }

  @action
  changeNewDescription(value) {
    this.newDescription = value;
  }

  @action
  async insertUser(uid, withRequest) {
    if (withRequest) {
      await this.store.api.addMember(this.uid, [uid]);
    }
    const member = this.store.getItem(uid);
    if (member) {
      this.addUser(member);
      member.addGroup(this);
    }
  }

  @action
  async removeUser(uid, withRequest) {
    if (withRequest) {
      await this.store.api.removeMember(this.uid, [uid]);
    }
    const member = this.store.getItem(uid);
    if (member) {
      this.deleteUser(member);
      member.removeGroup(this);
    }
  }

  @action
  addUser(user) {
    this.members.set(user.id, user);
  }

  @action
  deleteUser(member) {
    this.members.delete(member.id);
  }

  @action
  undo() {
    this.newName = undefined;
    this.newImage = undefined;
    this.newDescription = undefined;
  }

  @action
  async save() {
    const data = await this.store.api.updateGroup(this.id, {
      name:        this.newName || this.name,
      description: this.newDescription,
      photo:       this.newImage
    });
    this.update(data);
    this.undo();
    this.store.setItem(null);
  }

  @action
  async getMembers() {
    const groupData = await this.store.api.getMembers(this.uid);
    this.members.clear();
    groupData.users && groupData.users.forEach((uid) => {
      const member = this.store.get(uid);
      member.addGroup(this);
      this.members.set(uid, member);
    });
  }

  @computed
  get hasChanges() {
    return this.newName || this.newImage || this.newDescription;
  }

  @computed
  get userArray() {
    return Array.from(this.members.values());
  }
}

export default Group;
