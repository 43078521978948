import { observable, computed } from "mobx";

/**
 * Модель записи GAP ошибки в данных отчета о трассировке
 *
 * @class  DataGapItemModel
 */
class DataGapItemModel {
  /**
   * id ошибки
   *
   * @type String
   */
  @observable
  id = undefined;

  
  /**
   * Описание ошибки
   *
   * @type String
   */
  @observable
  description = undefined;

  
  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id ошибки
   * @param {Object} params.description описание ошибки
   *
   * @retrun {DataGapItemModel}
   */
  static create({
    id,
    description
  }, rootStore) {
    return new  DataGapItemModel({
      id,
      description
    }, rootStore);
  }

  constructor(params, rootStore) {
    this.id = params.id;
    this.description = params.description;
    
    this.rootStore = rootStore;
  }

  /**
   * Строковое представление ошибки
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.description;
  }
}

export default DataGapItemModel;
