const ACTION_READ = "read";
const ACTION_CREATE = "create";
const ACTION_UPDATE = "update";
const ACTION_DELETE = "delete";

const translateAction = (action) => {
  switch (action) {
    case ACTION_READ:
      return "Прочитан";
    case ACTION_CREATE:
      return "Создан";
    case ACTION_UPDATE:
      return "Изменен";
    case ACTION_DELETE:
      return "Удален";
  
    default:
      return action;
  }
};

export {
  ACTION_READ,
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_DELETE,
  translateAction
};
