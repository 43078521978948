import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import TableCell from "./TableCell";

import TraceStore from "../../stores/traceStore";
import TraceSchemaModel from "../../models/traceSchema";


/**
 * Компонент для отображения записи трассировки в таблице результата
 * 
 * @param {Object} props набор параметров
 * @param {Number} props.num порядковый номер записи
 * @param {String} props.type тип отчета - трассировка("trace")| GAP анализ ("gap")
 * @param {Array<Object>} props.itemRow запись данных о трассировке
 * @param {TraceStore} props.store хранилище
 * @param {TraceSchema} props.schema схема трассировки
 */
const TableRow = observer(({ num, type, itemRow, store, schema }) => {
  const startIndex = type === "gap" ? 1 : 0;

  return (
    <tr>
      {/* Номер строки */}
      <td className="number">{num}</td>

      {/* Тип ошибки */}
      {type === "gap" &&
        <td 
          className="gap"
        >
          {itemRow[0].items.map((gap, i) => {
            return (
              <span
                key={i}
                data-tooltip={gap.description}
              >
                {gap.id}
              </span>
            );
          })}
        </td>
      }
      {schema.items.map((schemaItem, i) => {
        return (
          <TableCell
            key={i}
            store={store}
            dataItem={itemRow[startIndex + i]}
            schemaItem={schemaItem}
          />
        );
      })}
    </tr>
  );
});

TableRow.propTypes = {
  num:     PropTypes.number,
  store:   PropTypes.instanceOf(TraceStore).isRequired,
  schema:  PropTypes.instanceOf(TraceSchemaModel).isRequired,
  type:    PropTypes.string,
  itemRow: PropTypes.arrayOf(Object)
};


export default TableRow;
