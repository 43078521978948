import { action, computed, observable } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class Code extends BaseBoxClass {
  @observable
  lang = "clike";
  @observable
  captionId = null;
  @observable
  isNew = false;
  @observable
  creationPromice = null;

  @action
  init(data, parent) {
    super.init(data, parent);
    if (data.caption) {
      this.captionId = data.caption;
    } else {
      this.createCaption();
    }
    if (data.isNew) {
      this.creationPromice = this.persistCreate(data.forceEmpty);
    }
  }

  @action
  async persistCreate(forceEmpty = false) {
    await super.persistCreate();
    if (!forceEmpty) {
      await this.createOnlyLine();
    }
  }

  @action
  async createOnlyLine() {
    if (!this.store) {
      return null;
    }
    if (this.idsArray.length < 1) {
      const item = this.store.createItem(
        {
          class: "text.element.CodeLine",
          uid:   this.store.getUid(),
          isNew: true
        },
        this
      );
      this.addItemById(item.uid);
    }
  }

  @action
  async createCaption() {
    if (!this.store) {
      return null;
    }
    const item = this.store.createItem(
      {
        class: "text.element.Caption",
        uid:   this.store.getUid()
      },
      this
    );
    if (item) {
      this.captionId = item.uid;
    }
  }


  @action
  getPrevId(id) {
    if (id === this.captionId) {
      return null;
    }
    const index = this.getItemIndexById(id);
    return index > 0 && this.idsArray.length
      ? this.idsArray[index - 1]
      : this.captionId;
  }

  @action
  getNextId(id) {
    if (id === this.captionId && this.idsArray.length) {
      return this.idsArray[0];
    }
    const index = this.getItemIndexById(id);
    return this.idsArray.length && index < this.idsArray.length - 1
      ? this.idsArray[index + 1]
      : null;
  }

  @action
  setEditing() {
    let itemId = this.captionId;
    if (this.store.delta < 0 && this.idsArray.length) {
      itemId = this.idsArray[this.idsArray.length - 1];
    }
    const item = this.getItemById(itemId);
    item && item.setEditing();
  }

  @action
  delete(id) {
    if (this.captionId === id) {
      this.captionId = null;
    }
    this.deleteItemId(id);
    this.store.deleteItemById(id);
  }

  @computed
  get title() {
    return `${(this.caption && this.caption.title) || ""}`;
  }

  @computed
  get caption() {
    return this.getItemById(this.captionId);
  }

  @computed
  get flatItemsArray() {
    return [this];
  }

  @computed
  get slug() {
    return "code-blocks";
  }

  @computed
  get category() {
    return "elements";
  }

  @computed
  get innerHierarchyItemsArray() {
    const array = [
      {
        class:    "text.element.CodeLine",
        icon:     "paragraph-M",
        isParent: true,
        isNew:    true,
        ancorId:  this.uid
      }
    ];
    return array;
  }

  @computed
  get hierarchyItemsArray() {
    return [].concat(
      this.parent.hierarchyItemsArray,
      this.parent.innerHierarchyItemsArray
    );
  }

  @computed
  get removedFirstChildListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (
      this.diffCompatitor.items && 
      this.diffCompatitor.items.length > 0 && 
      this.diffCompatitor.items[0].diffClass === "removed"
    ) {
      return [this.diffCompatitor.items[0], ...this.diffCompatitor.items[0].removedDescendantListItemsArray];
    }
    return [];
  }

  @computed
  get itemsForRender() {
    let data = [...this.removedFirstChildListItemsArray];
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      data = data.concat([item, ...item.removedDescendantListItemsArray]);
    });
    return data;
  }
  
  @computed
  get output() {
    return [
      {
        class:       this.className,
        uid:         this.uid,
        lines:       this.idsArray,
        caption:     this.captionId,
        "@position": this.position
      },
      this.caption.output,
      this.caption.text.output
    ];
  }
}

export default Code;
