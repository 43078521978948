import { action, computed, observable } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class Table extends BaseBoxClass {
  @observable
  captionId = null;
  @observable
  hoverBounds = null;
  @observable
  columns = null;
  @observable
  rows = null;

  @action
  init(data, parent) {
    super.init(data, parent);
    if (data.caption) {
      this.captionId = data.caption;
      this.caption && this.caption.setParent(this);
    } else {
      this.createCaption();
    }
    if (data["@columns"]) {
      this.columns = data["@columns"];
    }
    if (data["@rows"]) {
      this.rows = data["@rows"];
    }
  }

  @action
  async persistCreate(input) {
    if (this.store && this.parent && this.parent.slug && this.category) {
      await this.parent.creationPromise;
      this.creationPromise = this.store.api.addElement(
        this.parent.slug,
        this.parent.uid,
        this.category,
        {
          ...this.output,
          "@columns": (input && input["@columns"]) || this.output["@columns"],
          "@rows":    (input && input["@rows"]) || this.output["@rows"]
        }
      );

      const data = await this.creationPromise;
      this.setNew(false);
      this.store.updateItems(data);
    }
  }

  @action
  async createCaption() {
    if (!this.store) {
      return null;
    }
    const item = this.store.createItem(
      {
        class: "text.element.Caption",
        uid:   this.store.getUid()
      },
      this
    );
    if (item) {
      this.captionId = item.uid;
    }
  }

  @action
  setHoverRect(from, to) {
    if (this.store) {
      this.store.setHoverTableUid(this.uid);
    }
    if (!from || !to) {
      this.hoverBounds = null;
      return false;
    }
    this.hoverBounds = { from, to };
  }

  @action
  dropSelection() {
    this.hoverBounds = null;
  }

  @action
  getPrevId(id) {
    if (id === this.captionId) {
      return null;
    }
    const index = this.getItemIndexById(id);
    return index > 0 ? this.idsArray[index - 1] : this.captionId;
  }

  @action
  getNextId(id) {
    if (id === this.captionId) {
      return this.idsArray[0];
    }
    const index = this.getItemIndexById(id);
    return index < this.idsArray.length ? this.idsArray[index + 1] : null;
  }

  @action
  setEditing() {
    let itemId = this.captionId;
    if (this.store.delta < 0) {
      itemId = this.idsArray[this.idsArray.length - 1];
    }
    const item = this.getItemById(itemId);
    item && item.setEditing();
    this.store.setFocusUid(this.uid);
  }

  @action
  async insertColumn(position = 0) {
    return await this.store.insertColumn({ uid: this.uid, position });
  }

  @action
  async insertRow(position = 0) {
    return await this.store.insertRow({ uid: this.uid, position });
  }

  @action
  async mergeCells() {
    const result = await this.store.mergeCells(this.hoveredUidArray);
    this.setHoverRect();
    return result;
  }

  @action
  async splitCell(uid, direction) {
    return await this.store.splitCell(uid, direction);
  }

  @action
  processDeleteRow(number) {
    this.rows = Math.max(this.rows - 1, 1);
    this.items.forEach((row, i) => {
      row.items.forEach((cell) => {
        if (cell.rowNumber <= number && cell.rowEnd >= number) {
          if (cell.rs === 1) {
            row.deleteItemId(cell.uid);
          } else {
            cell.decreaseRowspan();
            if (cell.rowNumber === number) {
              row.deleteItemId(cell.uid);
              const nextRow = this.items[i + 1];
              if (nextRow) {
                nextRow.addItemById(cell.uid);
              }
            }
          }
        }
      });
    });
  }

  @action
  processDeleteColumn(number) {
    this.columns = Math.max(this.columns - 1, 1);
    const cellsToDelete = {};
    this.items.forEach((row) => {
      for (let i = 0; i < row.countedItems.length; i += 1) {
        const cell = row.countedItems[i];
        if (cell.columnNumber <= number && cell.columnEnd > number) {
          cellsToDelete[cell.uid] = cell.uid;
          break;
        }
      }
    });
    Object.keys(cellsToDelete).forEach((uid) => {
      const cell = this.store.getItemById(uid);
      if (cell.cs === 1) {
        cell.parent.deleteItemId(uid);
      } else {
        cell.decreaseColspan();
      }
    });
  }


  @action
  async deleteRow(rowNumber) {
    try {
      const result = await this.store.deleteRow(rowNumber, this.uid);
      this.setHoverRect();
      this.processDeleteRow(rowNumber);
      return result;
    } catch (error) {
      console.warn(error);
    }
  }

  @action
  async deleteColumn(columnNumber) {
    try {
      const result = await this.store.deleteColumn(columnNumber, this.uid);
      this.setHoverRect();
      this.processDeleteColumn(columnNumber);
      return result;
    } catch (error) {
      console.warn(error);
    }
  }

  @computed
  get hoveredUidArray() {
    const array = [];
    this.items.forEach((row) => {
      row.items.forEach((cell) => {
        if (cell.isHovered) {
          array.push(cell.uid);
        }
      });
    });
    return array;
  }

  @computed
  get hoveredItemArray() {
    const array = [];
    this.hoveredUidArray.forEach((uid) => {
      array.push(this.store.getItemById(uid));
    });
    return array;
  }

  @computed
  get isHoverValid() {
    let valid = true;
    for (let index = 0; index < this.hoveredItemArray.length; index += 1) {
      const element = this.hoveredItemArray[index];
      // columnNumber
      // rowNumber
      // columnEnd
      // rowEnd
      if (
        element.columnNumber < this.countedHoverBounds.from.column ||
        element.columnEnd > this.countedHoverBounds.to.column ||
        element.rowNumber < this.countedHoverBounds.from.row ||
        element.rowEnd > this.countedHoverBounds.to.row
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  @computed
  get slug() {
    return "tables";
  }

  @computed
  get category() {
    return "elements";
  }

  @computed
  get columnsCount() {
    let size = 0;
    this.itemsForRender.forEach((item) => {
      size = Math.max(size, item.columnsCount);
    });
    return size;
  }

  @computed
  get caption() {
    return this.getItemById(this.captionId);
  }

  @computed
  get flatItemsArray() {
    return [this];
  }

  @computed
  get defaultItemsArray() {
    return this.parent.defaultItemsArray;
  }

  @computed
  get output() {
    return {
      class:       this.className,
      uid:         this.uid,
      rows:        this.idsArray,
      "@position": this.position,
      "@columns":  this.columns || 2,
      "@rows":     this.rows || 2
    };
  }

  @computed
  get removedFirstChildListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (
      this.diffCompatitor.items && 
      this.diffCompatitor.items.length > 0 && 
      this.diffCompatitor.items[0].diffClass === "removed"
    ) {
      return [this.diffCompatitor.items[0], ...this.diffCompatitor.items[0].removedDescendantListItemsArray];
    }
    return [];
  }

  @computed
  get itemsForRender() {
    let data = [...this.removedFirstChildListItemsArray];
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      data = data.concat([item, ...item.removedDescendantListItemsArray]);
    });
    return data;
  }

  @computed
  get countedHoverBounds() {
    const fromCell = this.store.getItemById(this.hoverBounds?.from);
    const toCell = this.store.getItemById(this.hoverBounds?.to);
    const bounds = {
      from: {
        column: Math.min(fromCell?.columnNumber, toCell?.columnNumber),
        row:    Math.min(fromCell?.rowNumber, toCell?.rowNumber)
      },
      to: {
        column: Math.max(fromCell?.columnEnd, toCell?.columnEnd),
        row:    Math.max(fromCell?.rowEnd, toCell?.rowEnd)
      }
    };

    return bounds;
  }
}

export default Table;
