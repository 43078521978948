import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Field } from "@ais3p/ui-framework-old";
import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";
import AttrsForm from "./AttrsForm";

const AddKind = observer(({ id, version }) => {
  const { kindsStore } = useStores();

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [kindId, setKindId] = useState(null);
  const [itemUid, setItemUid] = useState(null);

  const item = useMemo(() => {
    return kindsStore.getItemSync(id, version);
  }, [id, version]);

  const canSave = useMemo(() => {
    return item && item.canSave;
  }, [item && item.canSave]);

  useEffect(() => {
    setIsFormVisible(false);
    setItemUid(null);
    setKindId(null);

    return () => {
      item && item.revert();
    };
  }, [id, item]);

  const onToggleForm = useCallback(() => {
    if (isFormVisible) {
      if (item) {
        setKindId(null);
        item.revert();
      }
    }
    setIsFormVisible(!isFormVisible);
  }, [isFormVisible, item]);

  const onSave = useCallback(() => {
    if (item) {
      item.save();
    }
    setIsFormVisible(false);
    setItemUid(null);
    setKindId(null);
  }, [isFormVisible, item, version]);

  const onSelectKindChange = useCallback(
    (value) => {
      // value = {value: <kindUid>, label: <kindName>}
      setKindId(null);
      if (item) {
        item.revert();
      }
      if (value && value.value) {
        const item = kindsStore.addKind(id, version, value.value);
        if (item) {
          setItemUid(item.uid);
        }
        setKindId(value);
      }
    },
    [setKindId, id, version, setItemUid]
  );

  const kindsForSelect = useMemo(() => {
    if (item) {
      return item.kindsToAddForSelect;
    }
    return kindsStore.kindsForSelect;
  }, [kindsStore, item]);

  return (
    <div className={`new-item ${isFormVisible ? "form-expanded" : ""}`}>
      <Button
        leftIcon={isFormVisible ? "close-M" : "plus-M"}
        text={isFormVisible ? "Закрыть" : "Добавить вид"}
        theme="info"
        onClick={onToggleForm}
      />
      {isFormVisible && (
        <div className="add-kind-form">
          <div className="related-list">
            <Field.Select
              leftIcon={<AisIcon icon={"data-enum-single-M"} />}
              onChange={onSelectKindChange}
              uid={itemUid}
              value={kindId}
              isMulti={false}
              label="Вид"
              labelOnTop={true}
              isRequired={true}
              name="itemKinds"
              options={kindsForSelect}
              className="required"
              size={2}
              classNamePrefix="select"
            />
            {kindId && <div className="divider"></div>}
            {kindId && (
              <AttrsForm
                itemUid={id}
                version={version}
                kindUid={kindId.value || kindId}
              />
            )}
          </div>
        </div>
      )}
      {isFormVisible && (
        <Button
          leftIcon={"save-M"}
          text={"Сохранить"}
          theme="info"
          disabled={!canSave}
          onClick={onSave}
        />
      )}
    </div>
  );
});

export default AddKind;
