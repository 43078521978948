import React from "react";

import moment from "moment";

import UserItem from "../user/UserItem";

/**
 * Компонет для отображения заголовка записи журнала изменений
 */
const Header = ({ item, className }) => {
  const { user, datetime } = item;

  return (
    <div className={`journal-item-header ${className ? className : ""}`}>
      <div className="journal-item-header-body">
        <UserItem
          user={user}
          className="journal-item-header-user"
        />
        <div className="delimiter-line" />
        <div className="date-time">
          {datetime && moment(datetime).format("DD MMMM YYYY, HH:mm")}
        </div>
      </div>
    </div>
  );
};

export default Header;
