const CHG_PROPERTY_ATTR = "attr"; // стандартный атрибут;
const CHG_PROPERTY_ATTACHMENT = "attachment"; //  прикрепленный файл
const CHG_PROPERTY_CF = "cf"; // кастомное поле
const CHG_PROPERTY_RELATION = "relation"; // связи;

/**
 * Текстовое представление типа изменяемого элемента
 *
 * @param {String} property тип изменяемого элемента
 *
 * @return {String}
 */
const propertyTypeText = (property) => {
  switch (property) {
    case CHG_PROPERTY_ATTR:
    case CHG_PROPERTY_CF:
      return "Параметр";
    case CHG_PROPERTY_ATTACHMENT:
      return "Файл";
    case CHG_PROPERTY_RELATION:
      return "Связь";
    default:
      return `Тип св-ва - ${property} не определен`;
  }
};


export {
  CHG_PROPERTY_ATTR,
  CHG_PROPERTY_ATTACHMENT,
  CHG_PROPERTY_CF,
  CHG_PROPERTY_RELATION,
  propertyTypeText
};
