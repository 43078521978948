import React from "react";
import PropTypes from "prop-types";
import TableValue from "./TableValue";

/**
 * Компонент для отображения ячейки в табличном представлении списка задач
 * 
 * @param {Object} props набор параметров
 * @param {Object} params.column данные колонки таблицы
 */
const TableCell = (props) => {
  const {
    column
  } = props;
  
  return (
    <div
      className={`issues-table-cell ${column.id}-cell`}
    > 
      <TableValue {...props} />
    </div>
  );
};

TableCell.propTypes = {
  column: PropTypes.object.isRequired
};

export default TableCell;
