import { observable, computed, action } from "mobx";
import { DOMAIN_REPO } from "~/core/constants/Domains";
import AisVersion from "~/core/data/models/AisVersion";

/**
 * Модель для хранения информации об объектах структуры кода в файле.
 * Данные о струтуре возвращает сервис по работе с репозиторием - vcs
 * На текщий момент структура рампонзанется только для языка с и с++
 * 
 * @class {CodeObject}
 */
export default class CodeObject extends AisVersion {
  /**
   * @type {String}
   * uid объекта кода
   */
  @observable 
  uid = undefined;

  /**
   * @type {String}
   * Название объекта кода
   */
  @observable 
  name = undefined;

  /**
   * @type {String}
   * Тип объекта кода
   */
  @observable 
  type = undefined;

  /**
   * @type {String}
   * Содержимое объекта кода
   */
  @observable 
  contents = undefined;

  /**
   * @type {Object}
   * Набор параметров объекта кода
   */
  @observable 
  parameters = undefined;

  /**
   * @type {Number}
   * Номер строки начала объекта кода в файле
   */
  @observable 
  startLine = undefined;

  /**
   * @type {Number}
   * Номер строки окончания объекта кода в файле
   */
  @observable 
  endLine = undefined;

  /**
   * @type {String}
   * id репозитория
   */
  @observable 
  repositoryId = undefined;

  /**
   * @type {String}
   * id коммита
   */
  @observable 
  commitId = undefined;

  /**
   * @type {String}
   * Путь до файла, в котром располагается объект кода
   */
  @observable 
  path = undefined;

  /**
   * @type {String}
   * Тип языка, на котром написан объект кода
   */
  @observable 
  language;
  
  /**
   * @type {String}
   * Домен объекта, для хранения его в глобальном хранилище ObjectStore
   */
  domain = DOMAIN_REPO;

  
  /**
   * Статичный конструктор модели.
   * При создании модели, лучше использовать статичный метод, тк если поменяются названия входных параметров,
   * то эти названия можно будет здесь только поменять, не меняя названия в самой модели
   * 
   * @param {Object} params
   * @param {String} params.nameElement название объекта кода
   * @param {String} params.typeElement тип объекта кода
   * @param {Array<String>} params.contentElement содержимое объекта кода
   * @param {Number} params.startLine номер строки начала объекта кода в файле
   * @param {Number} params.endLine номер строки окончания объекта кода в файле
   * @param {String} params.repositoryId id репозитория
   * @param {String} params.commitId id коммита файла
   * @param {String} params.path путь до файла
   * @param {String} params.language язык объекта кода
   * @param {ObjectStore} objectStore глобальное хранилище с объектами АИС
   *  
   * @returns CodeObject
   */
  static create({
    id,
    nameElement,
    typeElement,
    contentElement,
    parametersElement,
    startLine,
    endLine,
    repositoryId,
    commitId,
    fileId,
    path,
    language
  }, objectStore) {
    return new CodeObject({
      id,
      name:       nameElement,
      type:       typeElement,
      contents:   contentElement,
      parameters: parametersElement,
      startLine,
      endLine,
      repositoryId,
      commitId,
      fileId,
      path,
      language
    }, objectStore);
  }

  /**
   * Конструктор модели.
   * 
   * @param {Object} params
   * @param {String} params.name название объекта кода
   * @param {String} params.type тип объекта кода
   * @param {Array<String>} params.contents содержимое объекта кода
   * @param {Number} params.startLine номер строки начала объекта кода в файле
   * @param {Number} params.endLine номер строки окончания объекта кода в файле
   * @param {String} params.repositoryId id репозитория
   * @param {String} params.commitId id коммита файла
   * @param {String} params.path путь до файла
   * @param {String} params.language язы кода
   * @param {ObjectStore} objectStore глобальное хранилище с объектами АИС
   *  
   * @returns CodeObject
   */
  constructor({
    id,
    name,
    type,
    contents,
    parameters,
    startLine,
    endLine,
    repositoryId,
    commitId,
    path,
    language
  }, objectStore) {
    super({}, objectStore);
    this.uid = id;
    this.name = name;
    this.type = type;
    this.contents = contents || [];
    this.parameters = parameters;
    this.startLine = startLine;
    this.endLine = endLine;
    this.repositoryId = repositoryId;
    this.commitId = commitId;
    this.path = path;
    this.language = language;

    objectStore && objectStore.addVersion(this);
    // this.uid = CodeObject.generateUid(repositoryId, commitId, path, type, this.signature);
  }

  /**
   * Получить className объекта кода в системе АИС
   * 
   * @return {String}
   */
  @computed
  get className() {
    let type = this.type || "";
    type = type.charAt(0).toUpperCase() + type.slice(1);
    return [DOMAIN_REPO, type].join(".");
  }

  /**
   * Получить содержимое объекта кода
   * 
   * @return {String}
   */
  @computed
  get content() {
    return this.contents.join(/\n/);
  }

  /**
   * Получить текстовое название объекта кода
   * 
   * @return {String}
   */
  @computed
  get title() {
    return (this.content || this.name || "").trim();
  }

  /**
   * Получить иконку объекта кода
   * 
   * @return {String}
   */
  @computed
  get icon() {
    switch (this.type) {
      case "function":{
        return "function-M";
      }
      default:
        return "query-M";
    }
  }

  /**
   * Получить текстовое назание иконкиобъекта кода.
   * Сделано так, тк есть компоненты, которые при отрисовке объектов АИС обращаются к свойствам: icon | iconString
   * 
   * @return {String}
   */
  @computed
  get iconString() {
    return this.icon;
  }  
  
  /**
   * Получить сигнатуру объекта кода
   * 
   * @return {String}
   */
  @computed
  get signature() {
    switch (this.type) {
      case "function":{
        const params = this.parameters || [];
        return `${this.name}(${params.join(",")})`;
      }
      default:
        return this.name;
    }
  }

  /**
   * Определяем, есть ли связь с объектом кода
   * 
   * @return {Boolean}
   */
  @computed
  get hasRelations() {
    if (!this.objectStore) {
      return false;
    }

    const  relationStore = this.objectStore.rootStore.relationStore;
    
    if (!relationStore) {
      return false;
    }
    if (relationStore.isPending) {
      return false;
    }
    const relsArray = relationStore.getGroupedConnections(
      this.uid,
      0
    );
    return !!relsArray.length;
  }

  @action
  update(data) {
    if (data.hasOwnProperty("nameElement")) {
      this.name = data.nameElement;
    }

    if (data.hasOwnProperty("typeElement")) {
      this.type = data.typeElement;
    }

    if (data.hasOwnProperty("contentElement")) {
      this.contents = data.contentElement || [];
    }

    if (data.hasOwnProperty("parametersElement")) {
      this.parameters = data.parametersElement;
    }

    if (data.hasOwnProperty("startLine")) {
      this.startLine = data.startLine;
    }

    if (data.hasOwnProperty("endLine")) {
      this.endLine = data.endLine;
    }

    if (data.hasOwnProperty("repositoryId")) {
      this.repositoryId = data.repositoryId;
    }

    if (data.hasOwnProperty("commitId")) {
      this.commitId = data.commitId;
    }

    if (data.hasOwnProperty("fileId")) {
      this.fileId = data.fileId;
    }

    if (data.hasOwnProperty("path")) {
      this.path = data.path;
    }

    if (data.hasOwnProperty("language")) {
      this.language = data.language;
    }
  }

  /**
   * Деструктор модели
   */
  destroy() {
    
  }
}
