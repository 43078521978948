import { observable, action, computed, toJS } from "mobx";

class AttrValue {
  @observable id;
  @observable attrValue;
  @observable initValue;
  @observable type;
  @observable isRequired;
  @observable valid;

  constructor(data, store) {
    this.store = store;
    this.id = data.id;
    this.type = data.type;
    this.isRequired = this.attribute.required;

    if (this.type === "datetime") {
      this.attrValue = new Date(data.value);
      this.initValue = new Date(data.value);
    } else {
      this.attrValue = data.value;
      this.initValue = data.value;
    }
    this.valid = data.isValid;
  }

  @action
  change(value, isValid) {
    this.attrValue = value;
    this.valid = isValid;
  }

  @action
  save() {
    this.initValue = toJS(this.attrValue);
  }

  @action
  revert() {
    this.attrValue = toJS(this.initValue);
    this.valid = true;
  }

  @computed
  get uid() {
    return this.id;
  }

  @computed
  get attribute() {
    return this.store.getAttr(this.id) || {};
  }

  @computed
  get value() {
    return toJS(this.attrValue);
  }

  @computed
  get initialValue() {
    return toJS(this.initValue);
  }

  @computed
  get isValid() {
    let valid = this.valid;
    if (
      this.isRequired &&
      (this.value === undefined || this.value === [] || this.value === "")
    ) {
      valid = false;
    }
    return valid;
  }

  @computed
  get isEdited() {
    let edited = toJS(this.initValue) !== toJS(this.attrValue);
    if (this.type === "string") {
      const blankStrings = new Set(["", undefined, null]);
      edited =
        edited &&
        !(blankStrings.has(this.initValue) && blankStrings.has(this.attrValue));
    } else if (this.type === "enum") {
      edited =
        JSON.stringify(toJS(this.initValue)) !==
        JSON.stringify(toJS(this.attrValue));
    }
    return edited;
  }

  @computed
  get valueToSend() {
    if (this.type === "datetime") {
      return this.attrValue.toISOString();
    }
    return toJS(this.attrValue);
  }
}

export default AttrValue;
