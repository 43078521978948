import Server from "~/core/api/server";

/**
 * Api для трассировки и анализа связей
 * 
 * @class TraceApi
 */
class TraceApi extends Server {
  methods = {
    getBaseTraceSchemas: {
      url:         this.getServiceUrl("tracer", "api/v2/schemes"),
      method:      "GET",
      textProcess: "Загрузка списка схем отчетов о трассировке ...",
      textSuccess: null,
      // eslint-disable-next-line max-len
      textError:   this.template `Во время загрузки списка схем отчетов о трассировке произошла ошибка: ${0}`
    },

    requestComposeData: {
      url:         this.getServiceUrl("composer", ""),
      method:      "POST",
      accept:      "application/json",
      textProcess: "Загрузка данных трассировки ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки данных трассировки произошла ошибка: ${0}`,
      timeout:     600000
    }
  };


  /**
   * Загрузить список схем таблиц отчетов о трассировке
   *
   * @return {Promise}
   */
  async getBaseTraceSchemas() {
    return await this.request("getBaseTraceSchemas");
  }

  /**
   * Произвести трассировку связей. Запрос просисходит напряму в Composer 
   *
   * @param {TraceSchemaModel} schema выбранная схема трассировки
   * @param {Object} values набор заданных значений, которые будет использоваться в схеме при трассировке
   * 
   * @return {Promise}
   */
  async traceAnalyize(schema, values) {
    const reqData = {
      operationName: "TraceQuery",
      variables:     {
        scheme: { ...schema,
          parameterValues: values
        }
      },
      query: `query TraceQuery($scheme: JSON!) {
  Trace(scheme: $scheme) {
    __typename
    ... on ObjectWithClass {
      id
      class
      representation
      version
      members {
        uid
        objectId
        kindUid
        kind {
          uid
          class
          name
        }
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
  __typename
    ... on Link {
      uid
      type {
        uid
        class
        name
        sourceCaption
        destCaption
      }
      source {
        uid
        class
        version
      }
      dest {
        uid
        class
        version
      }
    }
  }
}` };

    const data = await this.request("requestComposeData", reqData);
    return data.Trace;
  }

  /**
   * Произвести GAP анализ связей. Запрос просисходит напряму в Composer 
   *
   * @param {TraceSchemaModel} schema выбранная схема трассировки
   * @param {Object} values набор заданных значений, которые будет использоваться в схеме при анализе
   * 
   * @return {Promise}
   */
  async gapAnalyize(schema, values) {
    const reqData = {
      operationName: "AnalyzeQuery",
      variables:     {
        scheme: { ...schema,
          parameterValues: values
        }
      },
      query: `query AnalyzeQuery($scheme: JSON!) {
  Analyze(scheme: $scheme) {
    __typename
    ... on ObjectWithClass {
      id
      class
      version
      representation
      members {
        uid
        objectId
        kindUid
        kind {
          uid
          class
          name
        }
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
    __typename
    ... on Link {
      uid
      type {
        uid
        class
        name
        sourceCaption
        destCaption
      }
      source {
        uid
        class
        version
      }
      dest {
        uid
        class
        version
      }
    }
    __typename
    ... on GAPs {
      items
    }
    __typename
    ... on ExpectedItem {
      class
      expected
    }
  }
}` };

    const data = await this.request("requestComposeData", reqData);
    return data.Analyze;
  }
}

export default TraceApi;
