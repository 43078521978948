import { action, observable } from "mobx";
import {
  DOMAIN_TEXT,
  DOMAIN_LIBRARY,
  DOMAIN_REPO,
  DOMAIN_ISSUE,
  DOMAIN_BINDER
} from "~/core/constants/Domains";

import {
  CLS_LIBRARY_TEXT_VERSION
} from "~/core/constants/Classes";

import LibraryApi from "../../../modules/library/api/LibraryApi";
import NodeItem from "../../../modules/library/models/NodeItem";
import RepoApi from "../../../modules/repo/api/RepoApi";
import IssueApi from "~/modules/issues/api/issueApi";
import RealtionsApi from "~/modules/relations/api/relationsApi";
import TextApi from "~/modules/newText/api/TextApi";
import TextStore from "~/modules/newText/stores/DataStore";
import { IssueModel } from "~/modules/issues/models";
import { Relation as RelationModel } from "~/modules/relations/models";
import AisObject from "../models/AisObject";
import AisRepresentation from "../models/AisRepresentation";
import RepoNode from "../../../modules/repo/models/RepoNode";
import RepoCodeObject from "~/modules/repo/models/CodeObject";

/**
 * Единое хранилище объектов
 *
 * Требования:
 *
 * Получить данные об объекте от api по связке: domain, uid[, version = 0]
 * Сериализовать данные в модель соответствующего домена
 * Записать объект по: domain, uid[, version = 0]
 *
 * Получить минимально необходимый набор данных по uid (iconString + title)
 * Получить объект по: domain, uid[, version = 0]
 *
 */
export default class ObjectStore {
  /**
   * хранение объектов как модели с вложенными доменными репрезентациями, ключ = uid
   *
   * @memberof ObjectStore
   */
  @observable
  objects = new Map();

  /**
   * хранение репрезентаций как модели с вложенными версиями, ключ = `${uid}-${domain}`
   *
   * @memberof ObjectStore
   */
  @observable
  representations = new Map();

  /**
   * хранение версий как модели с вложенными данными, ключ = `${uid}-${domain}-${version}`,
   * где version === 0 для объектов без версии/редакций
   *
   * @memberof ObjectStore
   */
  @observable
  versions = new Map();

  /**
   * Creates an instance of ObjectStore.
   * @param {RootStore} rootStore
   * @memberof ObjectStore
   */
  constructor(rootStore) {
    this.rootStore = rootStore;

    this.textApi = new TextApi(this.rootStore);
    this.repoApi = new RepoApi(this.rootStore);
    this.libraryApi = new LibraryApi(this.rootStore);
    this.issueApi = new IssueApi(this.rootStore);
    this.relationsApi = new RealtionsApi(this.rootStore);
  }

  @action
  clear() {
    this.objects.clear();
    this.representations.clear();
    this.versions.clear();
  }

  /**
   * Fetches an object version from `backend`
   *
   * @param {String} uid
   * @param {String} domain
   * @param {String} [version=0]
   * @param {Object} [payload={}]
   * @param {Object} [params={}] набор параметров, которые могут потребоваться для дальнейшей обработки объектов
   * @param {Boolean} [params.force] делать запрос на сервис для получения актуальных данных объекта, даже если объект
   * был загружен уже в ObjectStore
   * @param {Boolean} [params.loadKinds] загружать ли виды для текстовых объектов сразу или они будут загружены позже 
   * одним bulk запросом
   *
   * @return {Object} backend response object
   *
   * @memberof ObjectStore
   */
  @action
  async fetchRepresentation(
    uid,
    domain,
    version = 0,
    payload = {},
    params = {} 
  ) {
    const { force = false } = params;
    const presentVersion = this.getVersion(uid, domain, version);
    if (
      presentVersion && 
      (
        !force || 
        (presentVersion.permissions && !presentVersion.permissions.get("read"))
      )
    ) {
      return presentVersion;
    }
    presentVersion && presentVersion.setPending(true);
    let result = null;
    switch (domain) {
      case DOMAIN_LIBRARY:{
        result = await this.libraryApi.getNode(uid || "root");
        let node = await this.processLibraryItem(result, domain, payload);

        if (version > 0) {
          // Если есть версия, значит это версия ноды текстового материала.
          // Предка мы уже получили выше.
          // Чтобы теперь получить ноду версии не зная uid самой ноды, а зная только uid ноды 
          // текстового материала ее версию, нужно отправить специальный запрос
          result = await this.libraryApi.getTextMaterialNodeVersion(uid, version);
          node = await this.processLibraryItem(result, domain, payload);
        }
        return node;
      }
      case DOMAIN_REPO: {
        const objects = await this.repoApi.getObjects([uid]);
        // console.log("objects", objects);
        return await this.processRepositoryItem(objects[0], domain);        
      }
      case DOMAIN_ISSUE:
        result = await this.issueApi.loadIssue(uid);
        return this.processIssueItem(result);
      case DOMAIN_TEXT:
        result = await this.textApi.loadTextObject(uid, version);
        return await this.processTextItem(result, version, { ...payload, uid }, params);
      case DOMAIN_BINDER:
        result = await this.relationsApi.loadRelation(uid);
        return await this.processRelationItem(result);
      default:
        throw new Error("No domain specified");
    }
    // return result;
  }

  /**
   * processes item of Library domain data to AisObject
   *
   * @param {Object} nodeData
   * @param {String} domain
   * @param {Object} { isPlain, tool, rootID } - optional payload used for repo e.g.
   *
   * @return {AisObject}
   *
   * @memberof ObjectStore
   */
  @action
  async processLibraryItem(nodeData, domain, { isPlain, tool, rootID }, params = {}) {
    const { loadKinds = true } = params;
    if (nodeData) {
      nodeData.children?.forEach((child) => {
        if (child.class === CLS_LIBRARY_TEXT_VERSION) {
          child.children = null; // HACK to avoid version node expanding
        }
      });
      const uidArray = [nodeData.uid];
      const item = this.getVersion(nodeData.uid, domain);
      if (item && typeof item.update === "function") {
        item.update(nodeData);
        item.setPending(null);
      } else {
        this.addVersion(
          new NodeItem({
            ...nodeData,
            tool,
            rootID,
            objectStore: this
          })
        );
      }
      const parent = nodeData.uid; //
      if (!isPlain) {
        nodeData.children?.forEach((child) => {
          uidArray.push(child.uid);
          this.addVersion(
            new NodeItem({
              ...child,
              parent,
              tool,
              rootID,
              children:    child.children, 
              objectStore: this
            })
          );
        });
      }
      const { kindsStore } = this.rootStore;

      if (loadKinds) {
        // Получаем набор участников Вида, чтобы определить у кого есть связь с Redmine Проектом
        const kindItems = await kindsStore.getItems(uidArray);
        const membersMap = new Map();
        const membersUids = [];
        kindItems.forEach((kindItem) => {
          kindItem && kindItem.kinds.forEach((member) => {
            if (!membersMap.has(member.memberUid)) {
              membersUids.push(member.memberUid);
            }          
            membersMap.set(member.memberUid, member);
          });
        });

        try {
          const projectsLinks = await this.issueApi.checkProjectsLinks(membersUids);
          for (const uid of Object.keys(projectsLinks)) {
            membersMap.get(uid).setHasRedmineProject(projectsLinks[uid]);
          }
        } catch (ex) {
          console.error(ex.message);
        }
      }

      const result = this.getVersion(nodeData.uid, domain, nodeData.version);
      return result;
    } else {
      return null;
    }
  }

  @action
  async processRepositoryItem(nodeData, domain) {
    if (!nodeData) {
      return null;
    }
    const item = this.getVersion(nodeData.id, domain);
    if (item) {
      item.update(nodeData);
      return item;
    }
    if (nodeData.typeElement) {
      // у объекта кода есть тип элемента. У дерева репозитория такого признака нет
      return RepoCodeObject.create(nodeData, this);
    }
    return RepoNode.create(nodeData, this);
  }

  @action
  async processTextItem(data, version, payload = {}, params = {}) {
    const { loadKinds = true } = params;
    const items = [];
    const uidArray = [];
    data.forEach((itemData) => {
      const textItem = TextStore.textItemFabric(itemData, version, this);
      if (textItem) {
        uidArray.push(textItem.uid);
        items.push(textItem);
        this.addVersion(textItem);
      }
    });
    if (loadKinds) {
      await this.rootStore.kindsStore.getItems(uidArray, version);
    }
    items.forEach((item, i) => {
      item.init(data[i]);
    });
    const { withPath, uid } = payload;
    if (withPath) {
      const path = await this.textApi.loadObjectPath(uid, version);
      items[0].setPath(path);
    }

    return items[0];
  }

  @action
  processIssueItem(data) {
    if  (!data) {
      return;
    }
    const issue = IssueModel.create(data, this.rootStore);
    this.addVersion(issue);

    return issue;
  }

  async processRelationItem(data) {
    if (!data) {
      return;
    }

    const relation = RelationModel.create(data);
    await relation.init(this);
    this.addVersion(relation);
    return relation;
  }

  /**
   * Получить объект
   *
   * @param  {String} uid объекта
   *
   * @return {AisObject} инстанс AisObject
   * @memberof ObjectStore
   */
  @action
  getObject(uid) {
    return this.objects.get(`${uid}`);
  }

  /**
   * Получить предстваление объекта
   *
   * @param  {String} uid объекта
   * @param  {String} domain домен объекта
   *
   * @return {AisRepresentation} инстанс AisRepresentation
   * @memberof ObjectStore
   */
  @action
  getRepresentation(uid, domain) {
    return this.representations.get(`${uid}-${domain}`);
  }

  /**
   * Получить версию представления объекта
   *
   * @param  {String} uid объекта
   * @param  {String} domain домен объекта
   * @param  {String} version версия представления объекта (по умолчанию = default)
   *
   * @return {AisVersion} инстанс AisVersion
   * @memberof ObjectStore
   */
  @action
  getVersion(uid, domain, version = 0) {
    return this.versions.get(`${uid}-${domain}-${version}`);
  }

  /**
   * Добавить объект
   *
   * @param  {AisObject} object инстанс AisObject
   *
   * @return {AisObject} инстанс AisObject
   * @memberof ObjectStore
   */
  @action
  addObject(object) {
    this.objects.set(object.uid, object);
    return object;
  }

  /**
   * Добавить предстваление объекта
   *
   * @param  {AisRepresentation} representation инстанс AisRepresentation
   *
   * @return {AisRepresentation} инстанс AisRepresentation
   * @memberof ObjectStore
   */
  @action
  addRepresentation(representation) {
    this.representations.set(representation.id, representation);
    let object = this.getObject(representation.objectId);
    if (!object) {
      object = this.addObject(new AisObject(representation, this));
    }
    object.addDomain(representation.domain);

    return representation;
  }

  /**
   * Добавить версию представления объекта
   *
   * @param  {AisVersion} version инстанс AisVersion
   *
   * @return {AisVersion} инстанс AisVersion
   * @memberof ObjectStore
   */
  @action
  addVersion(version) {
    this.versions.set(version.id, version);
    let representation = this.getRepresentation(version.representationId);
    if (!representation) {
      representation = this.addRepresentation(
        new AisRepresentation(version, this)
      );
    }
    representation.addVersion(version.version);

    return version;
  }

  /**
   * Удалить версию представления объекта
   *
   * @param  {String} uid объекта
   * @param  {String} domain домен объекта
   * @param  {String} version версия представления объекта (по умолчанию = default)
   *
   * @memberof ObjectStore
   */
  @action
  deleteVersion(uid, domain, version = 0) {
    this.versions.delete(`${uid}-${domain}-${version}`);
  }

  /**
   * Получить путь из uid'ов до текстового элемента
   *
   * @param {String} uid элемента
   * @param {String} version версия элемента
   *
   * @return {Array<String>}
   */
  async loadTextPath(uid, version) {
    return await this.textApi.loadObjectPath(uid, version);
  }

  /**
   * Поиск нод Библиотеки, согласно переданным параметрам
   * 
   * @param {Object} params 
   * @param {String} params.editable uid текстового представления рабочего материала 
   * 
   * @return {Array<Object>}
   */
  async librarySearch(params) {
    return await this.libraryApi.search(params);
  }

  /**
   * Получить представление ноды в Библиотеке по uid editable (Текстовое предсавление РМ)
   * 
   * @param {String} editableUid uid Текстового предсавления РМ
   * @param {Number} version  версия
   * 
   * @return {NodeItem}
   */
  async getLibraryNodeByEditableUid(editableUid, version = 0) {
    const data = await this.librarySearch({ editable: editableUid });
    const editableData = data[0];
    if (!editableData) {
      return null;
    }
    
    const node = await this.fetchRepresentation(editableData.uid, DOMAIN_LIBRARY, version);
    return node;
  }

  /**
   * Получить uid текстового представления документа, куда входит текстовый элемент
   *
   * @param {String} uid элемента
   * @param {String} version версия элемента
   *
   * @return {String}
   */
  async loadEditableUid(uid, version) {
    const path = await this.loadTextPath(uid, version);
    if ((path || []).length === 0) {
      // если массив пустой, значит переданный uid и есть сам текстовый документ
      return uid;
    }

    return (path || [])[0];
  }

  /**
   * Обработчик ошибки
   *
   * @param {String} error текст ошибки
   */
  @action
  onError(error) {
    this.rootStore.onError(error);
  }
}
