import { observable, action, toJS, computed } from "mobx";
import moment from "moment";
import { DOMAIN_REPO } from "~/core/constants/Domains";
import { CLS_REPO_FILE } from "~/core/constants/Classes";
import AisVersion from "~/core/data/models/AisVersion";

/**
 * Модель для хранения информации о ноде в дереве репозитория
 * 
 * @class {RepoNode}
 */
class RepoNode extends AisVersion {
  /**
   * @type {String} 
   * Автор коммита
   */
  @observable 
  author = null;

  /**
   * @type {String} 
   * id коммита
   */
  @observable 
  commitId = null;

  /**
   * @type {String} 
   * Авторское сообщение к коммиту
   */
  @observable 
  commitMessage = null;

  /**
   * @type {Date} 
   * Дата коммита
   */
  @observable 
  date = null;

  /**
   * @type {String} 
   * Вид ноды
   */
  @observable kind = null;

  /**
   * @type {String} 
   * Назание ноды
   */
  @observable 
  name = null;

  /**
   * @type {String} 
   * id репозитория
   */
  @observable 
  repositoryId = null;

  /**
   * @type {String} 
   * тип репозитория - git, svn, hg
   */
  @observable 
  repositoryType = undefined;

  /**
   * @type {String} 
   * Путь до ноды
   */
  @observable 
  path = null;

  /**
   * @type {String} 
   * сlass ноды
   */
  @observable 
  class = null;

  /**
   * @type {Number} 
   * Размер файла в байтах
   */
  @observable 
  size = null;

  /**
   * @type {Array<RepoNode>} 
   * Вложенные ноды
   */
  @observable 
  children = [];

  static create({
    id,
    branch,
    tag,
    commitId,
    class:klass,
    language,
    name, 
    path,
    repositoryId,
    repositoryType,
    size,
    kind,
    date,
    author,
    commitMessage,
    url,
    entries,
    domain = DOMAIN_REPO
  }, objectStore) {
    return new RepoNode({
      uid:   id,
      branch,
      tag,
      commitId,
      class: klass,
      language,
      name, 
      path,
      repositoryId,
      repositoryType,
      size,
      kind,
      date,
      author,
      commitMessage,
      url,
      entries,
      domain
    }, objectStore);
  }

  /**
   * Конструктор ноды дерева репозитория
   * 
   * @param {Object} props набор параметров для создания ноды
   * @param {ObjectStore} objectStore глобальное хранилище для работы с объектами АИС
   * 
   * @return {RepoNode}
   */
  constructor({
    uid,
    domain = DOMAIN_REPO,
    branch,
    tag,
    commitId,
    class: klass,
    language,
    name, 
    path,
    repositoryId,
    repositoryType,
    size,
    kind,
    date,
    author,
    commitMessage,
    url,
    entries = []
  }, objectStore) {
    super(
      {
        uid,
        domain
      },
      objectStore
    );
    
    this.branch = branch;
    this.tag = tag;
    this.commitId = commitId;
    this.class = klass;
    this.language = language;
    this.name = name;
    this.path = path;
    this.repositoryId = repositoryId;
    this.repositoryType = repositoryType;
    this.size = size;
    this.kind = kind;
    this.date = date;
    this.url = url;
    this.author = author;
    this.commitMessage = commitMessage;
    
    this.isExpandable = this.kind === "directory";
    this.processEntries(entries);

    objectStore && objectStore.addVersion(this);
  }

  @action
  processEntries(entries) {
    if (!entries) {
      return;
    }
    this.children = [];
    entries.forEach((entry) => {
      let node = RepoNode.create({ 
        ...entry, 
        parent:         this,
        repositoryId:   this.repositoryId,
        repositoryType: this.repositoryType,
        branch:         this.branch,
        tag:            this.tag 
      }, this.objectStore);
      this.objectStore.addVersion(node);
      node = this.objectStore.getVersion(node.uid, DOMAIN_REPO);
      this.children.push(node);
    });
  }
  
  /**
   * Обновить данные ноды
   * 
   * @param {Object} props 
   * @param {String} props.repositoryId id репозитория 
   * @param {String} props.path путь до ноды
   * @param {String} props.сommit id коммита
   * @param {String} props.commitMessage авторское сообщение к коммиту
   * @param {String} props.name название ноды
   * @param {String} props.class class ноды
   * @param {String} props.class class ноды
   * @param {String} props.url url ноды
   * @param {Array<RepoNode>} props.children набор вложенных нод
   * @param {Date} props.date дата коммита
   * @param {Number} props.size размер файла в байтах
   * @param {String} props.author автор коммита
   * @param {String} props.kind тип ноды
   * 
   * @return {RepoNode} this
   */
  @action
  update(props) {
    if (props.repositoryId) {
      this.repositoryId = props.repositoryId;
    }
    if (props.repositoryType) {
      this.repositoryType = props.repositoryType;
    }
    if (props.path) {
      this.path = props.path;
    }
    if (props.commitId) {
      this.commitId = props.commitId;
    }
    if (props.commitMessage) {
      this.commitMessage = props.commitMessage;
    }
    if (props.name) {
      this.name = props.name;
    }
    if (props.class) {
      this.class = props.class;
    }
    if (props.url) {
      this.url = props.url;
    }
    // if (props.parent) {
    //   this.parent = props.parent;
    // }
    if (props.entries) {
      this.processEntries(props.entries);
    }
    if (props.date) {
      this.date = props.date;
    }
    // if (props.payload) {
    //   this.payload = props.payload;
    // }
    // if (props.props) {
    //   this.props = props.props;
    // }
    if (props.kind) {
      this.kind = props.kind;
    }
    // this.isExpandable = true;
    this.isExpandable = this.kind === "directory";
    
    if (props.size) {
      this.size = props.size;
    }
    if (props.author) {
      this.author = props.author;
    }
    return this;
  }

  /** 
   * Получить тектовое представление даты создания коммита
   * 
   * @return {String}
   */
  @computed
  get dateString() {
    return moment(this.date).format("HH:mm DD.MM.YYYY");
  }

  /**
   * Получить набор вложеных нод для построения дерева
   * 
   * @return {Array<Object>}
   */
  @computed
  get childrenForTree() {
    const children = [];
    this.children &&
      this.children.forEach((child) => {
        children.push(child.expandData);
      });
    if (this.class === CLS_REPO_FILE) {
      return null;
    }
    return children;
  }

  /**
   * Получить текстовое название ноды
   * 
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }

  @computed
  get className() {
    return this.class;
  }

  /**
   * Получить обьект для отображения ноды в дереве
   * 
   * @return {Object}
   */
  @computed
  get expandData() {
    return toJS({
      id:             this.id,
      uid:            this.uid,
      name:           this.name,
      parent:         this.parent,
      children:       toJS(this.childrenForTree),
      isExpandable:   this.kind === "directory",
      author:         this.author,
      commitId:       this.commitId,
      branch:         this.branch,
      tag:            this.tag,
      iconString:     this.iconString,
      commitMessage:  this.commitMessage,
      date:           this.date,
      class:          this.class,
      url:            this.url,
      repositoryId:   this.repositoryId,
      repositoryType: this.repositoryType,
      dateString:     this.dateString,
      kind:           this.kind,
      tool:           "sourcecode",
      path:           this.path,
      size:           this.size,
      language:       this.language
    });
  }

  /**
   * Деструктор модели
   */
  destroy() {
  }
}

export default RepoNode;
