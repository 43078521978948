const PROPERTY_NAME_PROJECT = "project";
const PROPERTY_NAME_SUBJECT = "subject";
const PROPERTY_NAME_DESCIPTION = "description";
const PROPERTY_NAME_AUTHOR = "author_id";
const PROPERTY_NAME_ASSIGNED_TO = "assigned_to_id";
const PROPERTY_NAME_START_DATE = "start_date";
const PROPERTY_NAME_DUE_DATE = "due_date";
const PROPERTY_NAME_DONE_RATION = "done_ratio";
const PROPERTY_NAME_ESTIMATED_HOURS = "estimated_hours";
const PROPERTY_NAME_ORDER = "order";
const PROPERTY_NAME_PARENT = "parent";
const PROPERTY_NAME_PRIORITY_ID = "priority_id";
const PROPERTY_NAME_STATUS_ID = "status_id";
const PROPERTY_NAME_TRACKER = "tracker";
const PROPERTY_NAME_ATTACHMENT = "attachment";


/**
 * Текстовое представление названия переменной
 *
 * @param {String} name название изменяемого элемента
 *
 * @return {String}
 */
const propertyNameText = (name) => {
  switch (name) {
    case PROPERTY_NAME_PROJECT:
      return "Проект";
    case PROPERTY_NAME_SUBJECT:
      return "Заголовок проблемы";
    case PROPERTY_NAME_DESCIPTION:
      return "Описание проблемы";
    case PROPERTY_NAME_AUTHOR:
      return "Автор";
    case PROPERTY_NAME_ASSIGNED_TO:
      return "Исполнитель";
    case PROPERTY_NAME_START_DATE:
      return "Дата начала";
    case PROPERTY_NAME_DUE_DATE:
      return "Завершить до";
    case PROPERTY_NAME_ESTIMATED_HOURS:
      return "Оценка временных затрат";
    case PROPERTY_NAME_DONE_RATION:
      return "Готовность";
    case PROPERTY_NAME_ORDER:
      return "order";
    case PROPERTY_NAME_PARENT:
      return "Предок проблемы";
    case PROPERTY_NAME_PRIORITY_ID:
      return "Приоритет";
    case PROPERTY_NAME_STATUS_ID:
      return "Статус";
    case PROPERTY_NAME_TRACKER:
      return "Трекер";
    case PROPERTY_NAME_ATTACHMENT:
      return "";
    default:
      return name;
  }
};

export {
  PROPERTY_NAME_PROJECT,
  PROPERTY_NAME_SUBJECT,
  PROPERTY_NAME_DESCIPTION,
  PROPERTY_NAME_AUTHOR,
  PROPERTY_NAME_ASSIGNED_TO,
  PROPERTY_NAME_START_DATE,
  PROPERTY_NAME_DUE_DATE,
  PROPERTY_NAME_DONE_RATION,
  PROPERTY_NAME_ESTIMATED_HOURS,
  PROPERTY_NAME_ORDER,
  PROPERTY_NAME_PARENT,
  PROPERTY_NAME_PRIORITY_ID,
  PROPERTY_NAME_STATUS_ID,
  PROPERTY_NAME_TRACKER,
  PROPERTY_NAME_ATTACHMENT,
  propertyNameText
};
