import React from "react";
import PropTypes from "prop-types";
import { components } from "@ais3p/ui-framework";

const { Icon } = components;

/**
 * Экран с сообщением, что не выбран объект отслеживания
 * 
 * @param {Object} props набор параметров
 * @param {String} props.undefinedUidText отображаемый текст
 */
const UndefinedUid = ({
  undefinedUidText = "Не задан объект для отслеживания"
}) => {
  return (
    <div className="undefined-uid">
      <Icon className="undefined-uid-icon" name="cancel-M" />
      <div className="undefined-uid-text">{undefinedUidText}</div>
    </div>
  );
};

UndefinedUid.propTypes = {
  undefinedUidText: PropTypes.string
};

export default UndefinedUid;
