import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { CLS_BINDER_LINK } from "~/core/constants/Classes";
import AisIcon from "~/core/components/AisIcon";
import AisObject from "~/core/components/AisObject";


/**
 * Компонент для отображения объектов в ячейке таблицы
 * 
 * @param {Object} props набор параметров
 * @param {Object} props.dataItem набор данных для отображения
 * @param {Array<Object>} props.schemaItem набор данных
 */
const TableCell = observer(({ dataItem }) => {
  const content = useMemo(() => {
    if (!dataItem) {
      return null;
    }
    if (dataItem.dataType === "link") {
      const icon = dataItem.direction === "out" ? "arrow-right-M" : "arrow-left-M";
      return <AisIcon icon={icon} className="link-direction" />;
    }

    if (dataItem.dataType === "object") {
      return (
        <AisObject 
          object={dataItem.object} 
          isPending={dataItem.isPending} 
        />
      );
    }

    if (dataItem.dataType === "expected") {
      if (dataItem.expectedClass.toLowerCase() === CLS_BINDER_LINK.toLowerCase()) {
        // const icon = dataItem.direction === "out" ? "arrow-right-M" : "arrow-left-M";
        const icon = "increment-S";
        return <AisIcon icon={icon} className={`expected-direction ${dataItem.direction}`} />;
      }
    }

    return dataItem.dataType;
  }, [dataItem, dataItem && dataItem.isPending]);

  return (
    <td>
      {content}
    </td>
  );
});

TableCell.propTypes = {
  dataItem: PropTypes.object
};


export default TableCell;
