import { observable, computed } from "mobx";

/**
 * Модель записи ожидания данных
 * Используется для отображения ошибки в GAP анализе
 *
 * @class  DataExpectedItemModel
 */
class DataExpectedItemModel {
  /**
   * uid объекта
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * Класс объекта
   *
   * @type String
   */
  @observable
  class = undefined;

  /**
   * Что ожидается
   *
   * @type Object
   */
  @observable
  expected = undefined;

  
  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.class класс объекта
   * @param {Object} params.expected описание, что ожидается
   *
   * @retrun {DataExpectedItemModel}
   */
  static create({
    class: klass,
    expected
  }, rootStore) {
    return new  DataExpectedItemModel({
      class: klass,
      expected
    }, rootStore);
  }

  constructor(params, rootStore) {
    this.class = params.class;
    this.expected = params.expected;

    this.rootStore = rootStore;
  }

  get dataType() {
    return "expected";
  }

  /**
   * Строковое представление объкта
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.expected && this.expected.direction;
  }

  /**
   * Направление связи - in | out
   *
   * @return {String}
   */
  @computed
  get direction() {
    return this.expected && this.expected.direction;
  }

  /**
   * Ожидаемый тип
   *
   * @return {String}
   */
  @computed
  get expectedClass() {
    return this.expected && this.expected.class;
  }
}

export default DataExpectedItemModel;
