import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Components } from "@ais3p/ui-framework";
import Target from "~/core/components/Target";

import { UndefinedUid, NoRepresantation } from "../components/common";
import { IssuesContent } from "../components/issues";
import useStores from "~/core/utils/useStores";

import "./css/issues.scss";
import IssueStore from "../stores/IssueStore";
import { Tracker } from "@ais3p/ui-framework-old";

/**
 * Инструмент для работы с задачами СП и ЗИ
 * 
 * @param {Object} props набор параметров
 * @param {LayoutStore} params.layoutStore хранилище для работы с Layout
 * @param {LayoutItem} params.layoutItem сущность в Layout
 * @param {Boolean} params.isTracking признак отслеживания в Layout
 * @param {String} params.id id сущности, для которой октрылась вкладка в Layout. В нашем случае это uid проекта,
 *  для которого нужно отобразить список задач
 * @param {Boolean} params.hasTarget 
 * @param {String} params.tabId id вкладки в Layout
 * @param {Object} params.trackedItem  данные об отслеживаемом объекте в Layout
 * @param {Object} params.isSubVisible набор отображения боковой доп панели - тип панели:boolean
 * @param {String} params.focusUid если задан focusUid, значит происходит переход к задаче по ссылке
 */
const IssuesTool = observer((props) => {
  const {
    layoutItem,
    isTracking,
    id,
    hasTarget,
    tabId,
    trackedItem,
    layoutStore,
    isSubVisible,
    focusUid
  } = props;

  const { rootStore } = useStores();

  const [isPending, setIsPending] = useState(false);
  const [targetIsProject, setTargetIsProject] = useState(true);

  const store = useMemo(() => {
    const store = new IssueStore({ rootStore });
    return store;
  }, []);

  useEffect(() => {
    init();
  }, [id, focusUid]);

  const init = async() => {
    // setIsPending(true);
    try {
      if (id && !focusUid) {
        const res = await store.checkProjectsLinks(id);
        setTargetIsProject(res);
      }
      await store.init();
    } finally {
      setIsPending(false);
    }
  };

  const targetIsIssue = !!focusUid;
  const noRepresantation = !targetIsProject && !targetIsIssue && !!id;
  const showContent = useMemo(() => {
    return !!id;
  }, [id, isTracking, hasTarget]);

  const target = useMemo(() => {
    return trackedItem && <Target trackedItem={trackedItem} />;
  }, [trackedItem, id]);

  const permIssues = useMemo(() => {
    // const { permissions } = accountStore;
    // const allowedObjects = permissions.get("object");
    // const permIssues = allowedObjects.get("issues.Tracker");

    // TODO: После того как в конфигурации системы данный инструмент будет прописан в permissions
    // для пользователей, то нужно будет проверять права - раскоментировать предыдущие три строчки
    const perms = new Map();
    return perms.set("write", true);
  }, []);
  return (
    <div className="issues-tool">
      {(!showContent || noRepresantation) && !targetIsIssue && (
        <Components.ToolBar />
      )}
      {!(showContent && (targetIsProject || targetIsIssue)) && target}
      <div className="issues-tool-content">
        {isTracking && !hasTarget && !showContent && <Tracker tabId={tabId} />}
        {noRepresantation && <NoRepresantation />}
        {!showContent && <UndefinedUid />}
        {isPending && <Components.Preloader size={3} className={"issues-preloader"} />}
        {showContent && (targetIsProject || targetIsIssue) && (
          <IssuesContent
            store={store}
            trackedItem={trackedItem}
            projectUid={targetIsProject && id}
            issueUid={targetIsIssue ? focusUid : undefined}
            canBeEditable={permIssues && permIssues.has("write")}
            tabId={tabId}
            layoutStore={layoutStore}
            layoutItem={layoutItem}
            isSubVisible={isSubVisible}
          />
        )}
      </div>
    </div>
  );
});

IssuesTool.propTypes = {
  layoutItem:   PropTypes.object,
  isTracking:   PropTypes.bool,
  id:           PropTypes.string,
  hasTarget:    PropTypes.bool,
  tabId:        PropTypes.string,
  trackedItem:  PropTypes.object,
  layoutStore:  PropTypes.object,
  isSubVisible: PropTypes.object,
  focusUid:     PropTypes.string
};


export default IssuesTool;
