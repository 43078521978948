import React from "react";
import { translateAction } from "../../constants/actions";

/**
 * Компонет для отображения ключевой инфорации записи журнала изменений
 */
const Info = ({ item, className }) => {
  const { 
    action
    // version 
  } = item;

  return (
    <div className={`journal-item-info ${className ? className : ""}`}>
      <div className="journal-item-info-body">
        <div className="action">
          {translateAction(action)}
        </div>
        {/*
        <div className="object">
          <div className="class">
            {item.class}
          </div>
          {
            version !== undefined &&
            <div className="version">
              {`v.${version}`}
            </div>
          }
        </div>
        */}
      </div>
    </div>
  );
};

export default Info;
