import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";

import {
  PROPERTY_NAME_DESCIPTION,
  PROPERTY_NAME_AUTHOR,
  PROPERTY_NAME_ASSIGNED_TO,
  PROPERTY_NAME_START_DATE,
  PROPERTY_NAME_DUE_DATE,
  PROPERTY_NAME_DONE_RATION,
  PROPERTY_NAME_ESTIMATED_HOURS,
  PROPERTY_NAME_PRIORITY_ID,
  PROPERTY_NAME_STATUS_ID,
  PROPERTY_NAME_TRACKER
} from "../../constants/propertyNames";
import {
  CHG_PROPERTY_ATTACHMENT,
  CHG_PROPERTY_RELATION
} from "../../constants/propertyTypes";

import UserItem from "../user/UserItem";
import IssueStore from "../../stores/IssueStore";

/**
 * Компонент для отображения значения сделанного изменения параметра Задачи в записи журнала
 * 
 * @param {Object} props набор параметров
 * @param {String} params.property тип параметра Задачи
 * @param {String} params.name название параметра Задачи
 * @param {Boolean} params.isRemoved признак, что значение было удалено
 * @param {Any} params.value значение параметра
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {Boolean} params.isOldValue признак, что значение старое
 * @param {Boolean} params.isShownDescription признак, что нужно отобразить или скрыть изменнение поля - "Описание"
 * @param {Function} params.toggleShowDescription ф-я для переключения режима отображения поля - "Описание"
 */
const Value = ({
  name,
  property,
  isRemoved,
  value,
  store,
  isOldValue,
  isShownDescription,
  toggleShowDescription
}) => {
  const onToggleShowDescription = useCallback(() => {
    if (toggleShowDescription) {
      toggleShowDescription();
    }
  }, [isShownDescription, toggleShowDescription]);

  let content = value;
  if (property === CHG_PROPERTY_RELATION) {
    content = `#${value}`;
  }

  switch (name) {
    case PROPERTY_NAME_AUTHOR:
    case PROPERTY_NAME_ASSIGNED_TO:
      content = <UserItem userId={parseInt(value)} className="inline" store={store} />;
      break;
    case PROPERTY_NAME_START_DATE:
    case PROPERTY_NAME_DUE_DATE:
      content = moment(value).format("DD.MM.YYYY");
      break;
    case PROPERTY_NAME_DONE_RATION:
      content = `${value}%`;
      break;
    case PROPERTY_NAME_ESTIMATED_HOURS:
      content = `${value}ч`;
      break;
    case PROPERTY_NAME_PRIORITY_ID:
      content = store.getPriority(parseInt(value)) ? store.getPriority(parseInt(value)).title : value;
      break;
    case PROPERTY_NAME_STATUS_ID:
      content = store.getStatus(parseInt(value)) ? store.getStatus(parseInt(value)).title : value;
      break;
    case PROPERTY_NAME_TRACKER:
      content = store.getTracker(parseInt(value)) ? store.getTracker(parseInt(value)).title : value;
      break;
    case PROPERTY_NAME_DESCIPTION:
      content = (
        <div
          className="toggle-description inline"
          onClick={onToggleShowDescription}
        >
          {isShownDescription ? "скрыть" : "отобразить" }
        </div>
      );
      break;
  }

  return (
    <div className={classNames("value", "inline", {
      removed:     isRemoved,
      attachment:  property === CHG_PROPERTY_ATTACHMENT,
      relation:    property === CHG_PROPERTY_RELATION,
      property:    property !== CHG_PROPERTY_RELATION && property !== CHG_PROPERTY_ATTACHMENT,
      "old-value": isOldValue
    })}
    >
      {content}
    </div>
  );
};

Value.propTypes = {
  name:                  PropTypes.string.isRequired,
  property:              PropTypes.string.isRequired,
  isRemoved:             PropTypes.bool,
  value:                 PropTypes.any,
  store:                 PropTypes.instanceOf(IssueStore).isRequired,
  isOldValue:            PropTypes.bool,
  isShownDescription:    PropTypes.bool,
  toggleShowDescription: PropTypes.func
};

export default Value;
