import React from "react";

/**
 * Компонент для отображения информации о коммите на панельке
 * 
 * @param {Object} params набор параметров
 * @param {String} params.label строковое значение, котрое будет отображено перед инофмацией о коммите
 * @param {Commit} params.commitItem объект коммита
 * @param {Object} params.commitItem.info информация о коммите
 * @param {String} params.commitItem.info.commit номер коммита
 * @param {String} params.commitItem.info.author автор коммита
 * @param {String} params.commitItem.info.commitMessage пользователькое сообщение о коммите
 * @param {String} params.commitItem.info.dateString датаи время сделанного коммита в строковом представлении
 * 
 * 
 * @type {Revision}
 * @returns {Component}
 */
const Revision = ({ commitItem, label = "Текущий коммит:" }) => {
  if (!commitItem) {
    return (
      <div className="current-revision">
        No commit info yet.
      </div>
    );
  }
  const { author, commit, commitMessage, dateString } = commitItem.info;
  return (
    <div className="current-revision">
      <label>{label}</label>
      <span>{`#${commit}`}</span>
      <p>{commitMessage}</p>
      <span>{author}</span>
      <span>{dateString}</span>
    </div>
  );
};

export default Revision;
