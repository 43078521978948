import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import ReactTable, { ReactTableDefaults } from "react-table";
import "react-table/react-table.css";

import { components } from "@ais3p/ui-framework";

import TableColHeader from "./TableColHeader";
import TableCell from "./TableCell";
import ColumnGroups from "./ColumnGroups";
import Pagination from "~/core/components/pagination";
// import TableDescription from "./TableDescription";

import { getRemSizeInPx } from "~/core/utils";

import {
  ISSUE_TABLE_COLUMNS,
  ISSUE_TABLE_COLUMN_GROUPS,
  ISSUE_VALUE_ID,
  ISSUE_VALUE_AUTHOR,
  ISSUE_VALUE_ASSIGNED_TO,
  ISSUE_VALUE_CREATED_ON,
  // ISSUE_VALUE_DESCRIPTION,
  ISSUE_VALUE_DONE_RATIO,
  ISSUE_VALUE_DUE_DATE,
  // ISSUE_VALUE_ESTIMATED_HOURS,
  ISSUE_VALUE_PRIORITY_ID,
  ISSUE_VALUE_PROJECT,
  ISSUE_VALUE_START_DATE,
  ISSUE_VALUE_STATUS_ID,
  ISSUE_VALUE_SUBJECT,
  ISSUE_VALUE_TRACKER,
  ISSUE_VALUE_UPDATED_ON,
  ISSUE_VALUE_WATCHERS
  // getIssueValueLabel,
} from "../../constants/values";

import { FiltersPanel } from "../filters";

import { TABLE_COLUMNS_CFG } from "../../constants/config";
import IssueStore from "../../stores/IssueStore";

const { Icon } = components;

Object.assign(ReactTableDefaults, {
  // Text
  previousText: "Предыдущая",
  nextText:     "Следующая",
  loadingText:  "Загрузка...",
  noDataText:   "Нет записей",
  pageText:     "Страница",
  ofText:       "из",
  rowsText:     "строк",

  // Accessibility Labels
  pageJumpText:    "перейти на страницу",
  rowsSelectorTex: "строк на странице"
});

/**
 * Панель для отображения списка задач в табличном предтавлении
 * 
 * @param {Object} props набор параметров
 * @param {String} params.projectUid uid проекта
 * @param {String} params.className пользовательский className
 * @param {Boolean} params.canBeEditable признак, можно ли редактирвоать Задачу
 * @param {IssueStore} params.store хранилище для работы с Задачами
 * @param {LayoutItem} params.layoutItem сущность в Layout
 * @param {Function} params.reload ф-я для перезагрузки списка задач
 */
const IssuesTable = observer((props) => {
  // const IssuesTable = ((props) => {
  let timer = 0; // таймер для принятия решения - закончено ли изменение ширины столбца
  const delay = 1000; // задержка мс для принятия решения - закончено ли изменение ширины столбца

  const [isShownCheckColumns, setShownCheckColumns] = useState(false);
  const remSizeInPx = getRemSizeInPx();
  const { className, store, canBeEditable, projectUid, layoutItem, reload } =
    props;

  const tableActiveColumnsCfg =
    store.getItemConfig(TABLE_COLUMNS_CFG).active || {};
  const tableWidthColumnsCfg =
    store.getItemConfig(TABLE_COLUMNS_CFG).width || {};

  const [activeColumns, setActiveColumns] = useState(
    Object.keys(ISSUE_TABLE_COLUMNS).reduce((arr, key) => {
      const isActive = Object.prototype.hasOwnProperty.call(
        tableActiveColumnsCfg,
        key
      )
        ? tableActiveColumnsCfg[key]
        : ISSUE_TABLE_COLUMNS[key].defaultActive;
      return { ...arr, [key]: isActive };
    }, {})
  );

  const onPageSizeChange = useCallback(
    (size) => {
      store.setPageSize(size);
      store.setCurrentPage(1);
      reload && reload();
    },
    [store.pageSize]
  );

  const onPageChange = useCallback(
    (p) => {
      store.setCurrentPage(p);
      reload && reload();
    },
    [store.currentPage]
  );

  const onCheckColumn = useCallback(
    (e) => {
      const { name } = e.target;
      setActiveColumns((columns) => {
        return {
          ...columns,
          [name]: !columns[name]
        };
      });

      const cfg = store.getItemConfig(TABLE_COLUMNS_CFG);
      store.setItemConfig(TABLE_COLUMNS_CFG, {
        ...cfg,
        active: {
          ...activeColumns,
          [name]: !activeColumns[name]
        }
      });
    },
    [activeColumns]
  );

  const onToggleShowFilters = useCallback(() => {
    store.toggleShowFilters();
    setShownCheckColumns(false);
  }, [store.isShownFilters]);

  const onToggleShowCheckColumns = useCallback(() => {
    store.hideFilters();
    setShownCheckColumns((state) => {
      return !state;
    });
  }, [isShownCheckColumns, store.isShownFilters]);

  const getColumnWidth = useCallback(
    (id) => {
      if (Object.prototype.hasOwnProperty.call(tableWidthColumnsCfg, id)) {
        return {
          width: tableWidthColumnsCfg[id]
        };
      }
      switch (id) {
        case ISSUE_VALUE_TRACKER:
          return {
            width:    remSizeInPx * 2.5,
            minWidth: remSizeInPx * 2.5,
            maxWidth: remSizeInPx * 4
          };
        case ISSUE_VALUE_ID:
          return {
            width: remSizeInPx * 4.5
          };
        case ISSUE_VALUE_SUBJECT: {
          return {
            minWidth: remSizeInPx * 16
          };
        }

        // case ISSUE_VALUE_TRACKER: {
        //   return {
        //     width: remSizeInPx * 6.5,
        //   };
        // }

        case ISSUE_VALUE_STATUS_ID:
        case ISSUE_VALUE_PRIORITY_ID:
        case ISSUE_VALUE_DONE_RATIO:
          return {
            width: remSizeInPx * 8.5
          };

        case ISSUE_VALUE_START_DATE:
        case ISSUE_VALUE_DUE_DATE:
        case ISSUE_VALUE_CREATED_ON:
        case ISSUE_VALUE_UPDATED_ON:
          return {
            width: remSizeInPx * 8.5
          };

        case ISSUE_VALUE_AUTHOR:
        case ISSUE_VALUE_ASSIGNED_TO:
          return {
            width: remSizeInPx * 10
          };
        case ISSUE_VALUE_WATCHERS:
          return {
            width: remSizeInPx * 13
          };
        case ISSUE_VALUE_PROJECT:
          return {
            width: remSizeInPx * 10
          };
        default:
          return {};
      }
    },
    [remSizeInPx, tableWidthColumnsCfg]
  );

  // const columns2 = useMemo(() => {
  //   return Object.keys(activeColumns)
  //     .filter((key) => {
  //       return activeColumns[key];
  //     })
  //     .map((key) => {
  //       return {
  //         Header: TableColHeader,
  //         Cell:   (props) => {
  //           return <div>111</div>;
  //         },
  //         accessor: key,
  //         ...getColumnWidth(key)
  //       };
  //     });
  // }, [activeColumns]);

  const columns = useMemo(() => {
    return Object.keys(activeColumns)
      .filter((key) => {
        return activeColumns[key];
      })
      .map((key) => {
        return {
          Header: TableColHeader,
          Cell:   (props) => {
            return (
              <TableCell
                {...props}
                key={key}
                store={store}
                layoutItem={layoutItem}
                canBeEditable={canBeEditable}
              />
            );
          },
          accessor: key,
          ...getColumnWidth(key)
        };
      });
  }, [canBeEditable, activeColumns, tableWidthColumnsCfg]);

  const getTrProps = useCallback((state, rowInfo) => {
    if (!rowInfo) {
      return {};
    }
    if (rowInfo.original.isExpired) {
      return { className: "expired" };
    }
    const nextIssue = (rowInfo.viewIndex < store.issueList.length - 1) && store.issueList[rowInfo.viewIndex + 1];
    if (nextIssue && nextIssue.isExpired) {
      return { className: "next-expired" };
    }

    return {};
  }, []);

  const onResizedChange = useCallback(
    (newResized) => {
      clearTimeout(timer);
      // сделал такую задержку, чтобы исключить множественное сохраненение ширины столбца
      timer = setTimeout(() => {
        const cfg = store.getItemConfig(TABLE_COLUMNS_CFG);
        const { width = {} } = cfg;
        newResized.forEach(({ id, value }) => {
          width[id] = value;
        });
        store.setItemConfig(TABLE_COLUMNS_CFG, {
          ...cfg,
          width
        });
      }, delay);
    },
    [tableWidthColumnsCfg]
  );

  const getPaginationProps = useCallback(() => {
    return {
      startPageIndex: 1,
      canPrevious:    store.canPreviousPage,
      canNext:        store.canNextPage
    };
  }, [store]);

  return (
    <div className={classNames("issues-table-wrapper", className)}>
      <div className={"issues-header"}>
        <div className={"issues-header-panels"}>
          <FiltersPanel store={store} projectUid={projectUid} />
          {isShownCheckColumns && (
            <div className="issues-table-columns-сhecks">
              {Object.keys(ISSUE_TABLE_COLUMN_GROUPS).map((key) => {
                return (
                  <ColumnGroups
                    key={key}
                    title={key}
                    groups={ISSUE_TABLE_COLUMN_GROUPS[key]}
                    activeColumns={activeColumns}
                    onCheckColumn={onCheckColumn}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className={"issues-header-buttons"}>
          <div
            className={classNames("header-button", {
              active: store.isShownFilters
            })}
            onClick={onToggleShowFilters}
          >
            <Icon size={2} name="filiter-M" />
          </div>
          <div
            className={classNames("header-button", {
              active: isShownCheckColumns
            })}
            onClick={onToggleShowCheckColumns}
          >
            <Icon size={2} name="table-col-plus-M" />
          </div>
        </div>
      </div>
      <div className={classNames("issues-table-content")}>
        <ReactTable
          loading={store.isProcessing}
          manual={true} // данные с сервера
          data={store.issueList}
          columns={columns}
          filterable={false}
          sortable={false}
          getTrProps={getTrProps}
          onResizedChange={onResizedChange}
          pages={store.pages}
          page={store.currentPage}
          pageSize={store.pageSize}
          minRows={0}
          pageSizeOptions={[5, 20, 50, 100]}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          PaginationComponent={Pagination}
          getPaginationProps={getPaginationProps}
        />
      </div>
    </div>
  );
});

IssuesTable.propTypes = {
  className:     PropTypes.string, 
  store:         PropTypes.instanceOf(IssueStore).isRequired, 
  canBeEditable: PropTypes.bool, 
  projectUid:    PropTypes.string.isRequired, 
  layoutItem:    PropTypes.object,
  reload:        PropTypes.func

};


export default IssuesTable;
