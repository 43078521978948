import React from "react";
import { observer } from "mobx-react";

import useStores from "~/core/utils/useStores";
import WSContent from "../components/ws";
import WSToolbar from "../components/toolbar";

import "./css/ws.scss";

/**
 * @type WebSocketTool
 * 
 * Инструмент для отображения данных, приходящих по WebSocket
 */
const WebSocketTool = observer(() => {
  const { wsStore } = useStores();
  return (
    <div className="ws-tool">
      <WSToolbar store={wsStore} />
      <WSContent store={wsStore} />
    </div>
  );
});


export default WebSocketTool;
