import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { MathfieldElement } from "mathlive";
import "mathlive/dist/mathlive-fonts.css";
import "mathlive/dist/mathlive.min";
import { observer } from "mobx-react";

/** A react-control that hosts a mathlive-mathfield in it. */
const MathfieldComponent = observer(
  ({ latex, mathfieldConfig, onChange, setRef }) => {
    const insertElement = useRef();
    const [ready, setReady] = useState(false);
    const mathfield = useMemo(() => {
      const field = new MathfieldElement();

      field.setOptions(mathfieldConfig);
      field.addEventListener("blur", (ev) => {
        onChange(ev.target.value);
      });
      return field;
    }, []); // @sithartha: здесь пустой массив, чтоб инициализировать только 1 раз

    useLayoutEffect(() => {
      if (latex && ready) {
        mathfield.setValue(latex, {
          focus:         true,
          insertionMode: "replaceAll"
        });
      }
    }, [latex, ready]);

    useEffect(() => {
      if (setRef) {
        setRef(mathfield);
      }
      if (insertElement && insertElement.current) {
        insertElement.current.appendChild(mathfield);
        window.requestAnimationFrame(() => {
          setReady(true);
        });
      }
    }, [insertElement]);

    return <div ref={insertElement} />;
  }
);
export default MathfieldComponent;
