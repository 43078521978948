import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames  from "classnames";
import { components } from "@ais3p/ui-framework";
import SchemaTraceList from "../schema-trace-list/SchemaTraceList";
import Schemas from "./Schemas";

import TraceStore from "../../stores/traceStore";
import TraceSchemaModel from "../../models/traceSchema";


const { buttons } = components;
/**
 * Панель для задания парметров схемы, чтобы получить анализ трассировки
 * 
 * @param {Object} props набор параметров
 * @param {String} props.mode режим панели - tool | sidePanel
 * @param {String} props.type type тип отчета - трассировка | GAP анализ
 * @param {TraceStore} props.store хранилище TraceStore
 * @param {Function} props.onProcessSchema callback функция при получении даных трасировке, согласно схемы
 */
const SchemaParameters = observer(({ 
  store, 
  schema,
  onProcessSchema,
  onChangeSchema
}) => {
  const onSelectSchema = useCallback((schema) => {
    if (schema) {
      onChangeSchema && onChangeSchema(schema);
    }
  }, [onChangeSchema]);

  const isValid = useMemo(() => {
    return schema && schema.isValid;
  }, [schema && schema.isValid]);

  const onResetSchema = useCallback(() => {
    schema && schema.reset();
    onProcessSchema(null, schema, []);
  }, [schema]);

  const onTraceAnalize = useCallback(async() => {
    if (!schema) {
      return;
    }
    const data = await store.doTraceAnalize(schema);
    onProcessSchema("trace", schema, data);
  }, [schema, onProcessSchema]);

  const onGAPAnalize  = useCallback(async() => {
    if (!schema) {
      return;
    }
    const data = await store.doGapAnalize(schema);
    onProcessSchema("gap", schema, data);
  }, [schema]);

  return (
    <div 
      className={classNames("schema-parameters", {
        visible: store.showSchemaParams
      })}
    >
      <Schemas
        store={store}
        value={schema} 
        onSelectSchema={onSelectSchema}
      />
      <div className="schema-parameters-body" >
        <SchemaTraceList 
          schema={schema}
        />
      </div>
      <div className="buttons-holder">
        <buttons.Button
          icon="clean-M"
          text="Сбросить"
          color="light"
          size={2}
          onClick={onResetSchema}
        />
        <buttons.Button
          icon="tracer-mode-report-M"
          text="Трассировка"
          color="positive"
          isLoading={store.isPending}
          isDisabled={!isValid || store.isPending}
          onPress={onTraceAnalize}
        />
        <buttons.Button
          icon="tracer-gap-analyser-M"
          text="GAP Анализ"
          color="action"
          isLoading={store.isPending}
          isDisabled={!isValid || store.isPending}
          onPress={onGAPAnalize}
        />
        
      </div>
    </div>
  );
});

SchemaParameters.propTypes = {
  store:           PropTypes.instanceOf(TraceStore).isRequired,
  schema:          PropTypes.instanceOf(TraceSchemaModel),
  onProcessSchema: PropTypes.func.isRequired,
  onChangeSchema:  PropTypes.func.isRequired
};


export default SchemaParameters;
