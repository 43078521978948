import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { components } from "@ais3p/ui-framework";
import classNames from "classnames";

const { Icon } = components;

/**
 * Кнопка для постраничного перехода
 * @class Button
 */
const Button = ({
  /**
   * Флаг - активна/неактивна кнопка
   * @type {Boolean}
   */
  disabled,

  /**
   * Название иконки для кнопки
   * @type {String}
   */
  icon,

  /**
   * Tooltip кнопки
   * @type {String}
   */
  tooltip,

  /**
   * Ф-я для обработки нажатия кнопки
   * @type {Function}
   */
  onClick,

  /**
   * Пользовательский className
   * @type {String}
   */
  className
}) => {
  const onButtonClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick();
    }
  }, [onClick, disabled]);

  const attributes = tooltip
    ? {
      "data-tooltip":    tooltip,
      "data-tooltip-at": "top"
    }
    : {};

  return (
    <div
      className={classNames("pagination-button", className, {
        disabled
      })}
      onClick={onButtonClick}
      {...attributes}
    >
      <Icon name={icon} />
    </div>
  );
};

Button.propTypes = {
  disabled:  PropTypes.bool,
  icon:      PropTypes.string,
  tooltip:   PropTypes.string,
  onClick:   PropTypes.func,
  className: PropTypes.string
};


export default Button;
