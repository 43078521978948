import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { DOMAIN_TEXT } from "~/core/constants/Domains";
import useStores from "~/core/utils/useStores";
import AisObject from "~/core/components/AisObject";

import {
  ENTITY_VALUE_NUM,
  // ENTITY_VALUE_UID,
  ENTITY_VALUE_TITLE,
  ENTITY_VALUE_VERSION
} from "../../constants/values";

const TableValue = observer(({ value, column, page, pageSize, original, index }) => {
  const { objectStore } = useStores();

  const [object, setObject] = useState(undefined);

  useEffect(async() => {
    if (column && column.id === ENTITY_VALUE_TITLE) {
      const obj = await objectStore.getVersion(
        original.objectId,
        DOMAIN_TEXT,
        original.version
      );
      setObject(obj);
    }
  }, [column && column.id, original]);

  if (column.keyValues) {
    return (
      <span className="entity-table-cell-text">
        {original[column.keyValues][column.uid]}
      </span>
    );
  }

  switch (column.id) {
    case ENTITY_VALUE_NUM:
      return (
        <div className="entity-table-cell-num">
          {`${(page - 1) * pageSize + index + 1}`}
        </div>
      );
    case ENTITY_VALUE_TITLE:
      return (
        <span className="entity-table-cell-text">
          <AisObject object={object} />
        </span>
      );
    case ENTITY_VALUE_VERSION:
      return <span className="entity-table-cell-text">{value}</span>;
    default:
      return <span className="entity-table-cell-text">{value}</span>;
  }
});

TableValue.propTypes = {
  value:    PropTypes.any,
  column:   PropTypes.object, 
  page:     PropTypes.number, 
  pageSize: PropTypes.number, 
  original: PropTypes.object, 
  index:    PropTypes.number
};


export default TableValue;
