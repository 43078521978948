import React from "react";
import { inject, observer } from "mobx-react";

import AisIcon from "~/core/components/AisIcon";
import { DOMAIN_WF } from "../../../../../core/constants/Domains";

const statuses = {
  created: {
    name: "Создан",
    icon: "ok-M"
  },
  suspended: {
    name: "Создан",
    icon: "ok-M"
  },
  initial: {
    name: "Создан",
    icon: "ok-M"
  },
  positive: {
    name: "Готов",
    icon: "ok-M"
  },
  negative: {
    name: "Отменен",
    icon: "cancel-M"
  },
  neutral: {
    name: "В работе",
    icon: "rework-M"
  },
  base: {
    name: "В работе",
    icon: "rework-M"
  },
  ready: {
    name: "Готов",
    icon: "ok-M"
  },
  accepted: {
    name: "Готов",
    icon: "ok-M"
  },
  examined: {
    name: "Проверен",
    icon: "ok-M"
  },
  verified: {
    name: "Подтвержден",
    icon: "ok-M"
  },
  agreed: {
    name: "Согласован",
    icon: "ok-M"
  },
  assigned: {
    name: "Назначен",
    icon: "ok-M"
  },
  approved: {
    name: "Утвержден",
    icon: "ok-M"
  },
  use: {
    name: "Используется",
    icon: "ok-M"
  },
  canceled: {
    name: "Отменен",
    icon: "cancel-M"
  },
  closed: {
    name: "Отменен",
    icon: "cancel-M"
  },
  rejected: {
    name: "Отменен",
    icon: "cancel-M"
  },
  rework: {
    name: "На переработке",
    icon: "rework-M"
  }
};

@inject("objectStore")
@observer
class WFStatus extends React.Component {
  render() {
    const { objectStore, uid } = this.props;
    const wfData = objectStore.getRepresentation(uid, DOMAIN_WF);
    if (!wfData) {
      return null;
    }

    const { context } = wfData;
    const { status, statusName, badge } = context;

    if (!status && !badge) {
      return null;
    }
    if (!statuses[badge || status]) {
      return `Статус "${badge || status}" не распознан`;
    }
    const { icon, name } = statuses[badge || status];
    return (
      <div className={`wf-status ${badge || status || ""}`}>
        <div className="status-icon">
          <AisIcon icon={icon} />
        </div>
        <div className="status-text">{statusName || name}</div>
      </div>
    );
  }
}

export default WFStatus;
export { statuses };
