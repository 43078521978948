import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import classNames from "classnames";
import { colorShade } from "~/core/utils";
import useStores from "~/core/utils/useStores";
// import { LUMINANCE_BORDER_COLOR, LUMINANCE_TEXT_COLOR } from "../../constants/luminances";
import { SHADE_BACKGROUND_COLOR, SHADE_BORDER_COLOR, SHADE_TEXT_COLOR } from "../../constants/colorShade";

/**
 * Компонент для отображения Статуса, Проекта и Приоеритета в карточке Задача
 * 
 * @param {Object} props набор параметров
 * @param {String} params.placeholder текст placeholder
 * @param {String} params.className пользовательский className
 * @param {Array<Object>} params.options занчения в выпадающем списке
 * @param {Object} params.value значение контрола
 * @param {String} params.name название контрола
 * @param {Boolean} params.useColor использовать ли цветовоую гамму
 * @param {Boolean} params.readOnly флаг только чтение
 * @param {Object} params.components набор доп. компонент для отображения выпадающего списка
 * @param {Function} params.onChange callback ф-я при изменении значения
 */
const SelectField = (props) => {
  const {
    placeholder = "Выберите",
    className,
    options,
    value,
    name,
    useColor,
    readOnly = false,
    components
  } = props;

  const { uiStore } = useStores();

  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    setCurrentValue(options.filter((option) => {
      return option.value === value;
    })[0]);
  }, [value, options]);

  const onChange = useCallback((val) => {
    if (props.onChange) {
      props.onChange({
        target: {
          name,
          value: val.value
        }
      });
    }
  }, [props]);

  let customStyles = {};

  if (useColor) {
    customStyles = {
      option: (provided, { data, isDisabled, isFocused, isSelected }) => {
        const clrName = uiStore.colors.getBaseColorName(uiStore.colors.getColorNameByHex(data.color));
        let backgroundColor = provided.backgroundColor;
        let textColor = data.color;

        if (isDisabled) {
          textColor = provided.color;
          backgroundColor = undefined;
        } else if (isSelected) {
          backgroundColor = clrName && uiStore.colors.getColorByName(`${clrName}1`) ||
                                        colorShade(data.color, SHADE_BACKGROUND_COLOR);
          textColor = clrName && uiStore.colors.getColorByName(`d${clrName}3`) ||
                        colorShade(data.color, SHADE_TEXT_COLOR);
        } else if (isFocused) {
          // backgroundColor = colorShade(data.color, SHADE_BACKGROUND_COLOR);
          // textColor = colorShade(data.color, SHADE_TEXT_COLOR);
        }

        return {
          ...provided,
          color: textColor,
          backgroundColor
        };
      }
    };
    if (currentValue && !!currentValue.color) {
      const colorName = uiStore.colors.getBaseColorName(uiStore.colors.getColorNameByHex(currentValue.color));
      const bgColor = colorName && uiStore.colors.getColorByName(`${colorName}1`) ||
                                    colorShade(currentValue.color, SHADE_BACKGROUND_COLOR);
      const borderColor = colorName && uiStore.colors.getColorByName(`${colorName}3`) ||
                                    colorShade(currentValue.color, SHADE_BORDER_COLOR);
      const txtColor = colorName && uiStore.colors.getColorByName(`d${colorName}3`) || currentValue.color;
      customStyles = {
        ...customStyles,
        container: (provided) => {
          return { ...provided, ...{
            backgroundColor: bgColor,
            borderColor,
            borderStyle:     "solid",
            borderWidth:     ".0625rem"
          } };
        },
        singleValue: (provided) => {
          return { ...provided, ...{
            color: txtColor
          } };
        }
      };
    }
  }

  return (
    <Select
      className={classNames("select-field", className, {
        "read-only": readOnly
      })}
      styles={customStyles}
      placeholder={placeholder}
      inputClassName="select-field-input"
      classNamePrefix="select-field"
      value={currentValue}
      isDisabled={readOnly}
      isLoading={false}
      isClearable={false}
      isRtl={false}
      isSearchable={false}
      name={name}
      options={options}
      components={components}
      onChange={onChange}
    />
  );
};

SelectField.propTypes  = {
  placeholder: PropTypes.string,
  className:   PropTypes.string,
  options:     PropTypes.arrayOf(Object),
  value:       PropTypes.any,
  name:        PropTypes.string,
  useColor:    PropTypes.bool,
  readOnly:    PropTypes.bool,
  components:  PropTypes.object,
  onChange:    PropTypes.func
};

export default SelectField;
