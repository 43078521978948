import React from "react";
import PropTypes from "prop-types";
import { getIssueValueLabel } from "../../constants/values";

/**
 * Компонент для отображения ячейки заголовка в табличном представлении списка задач
 * 
 * @param {Object} props набор параметров
 * @param {Object} params.column данные колонки таблицы
 */
const TableColHeader = (props) => {
  const {
    column
  } = props;

  return (
    <div
      className={`issues-table-column-header ${column.id}-header`}
    >
      <span className="issues-table-column-header-text">
        {getIssueValueLabel(column.id)}
      </span>
    </div>
  );
};

TableColHeader.propTypes = {
  column: PropTypes.object.isRequired
};


export default TableColHeader;
