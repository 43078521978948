import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { components } from "@ais3p/ui-framework";
import IssueIcon from "../issues/IssueIcon";
import IssueStore from "../../stores/IssueStore";
import { IssueModel } from "../../models";

const { buttons } = components;

/**
 * Компонент для отображения подзадач Задачи
 * 
 * @param {Object} props набор параметров
 * @param {Object} props.item набор данных о связанной задаче
 * @param {IssueModel} props.issue текущая задача
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {Function} props.onSelectIssue callback функция при выборе найденной задачи
 */
const SearchIssueItem = observer(
  ({
    item,
    issue,
    store,
    onSelectIssue
  }) => {
    const [isLoading, setIsLoading] = useState(false);

    const isCurrentTask = useMemo(() => {
      return issue.uid === item.uid;
    }, [item.uid, issue.uid]);

    const onSelectClick = useCallback(() => {
      onSelectIssue && onSelectIssue(item);
    }, [item]);

    const onIssueItemClick = useCallback(async() => {
      if (isLoading) {
        // уже идет загрука задачи
        return;
      }
      setIsLoading(true);
      try {
        await store.openIssue(item.uid);
      } finally {
        setIsLoading(false);
      }
    }, [isLoading, item]);
    
    return (
      <div 
        className={"search-issues-item "}
      >
        <div 
          className={"search-issues-item-title"}
        >
          <div 
            className="title-prefix"
            onClick={onIssueItemClick}
          >
            <IssueIcon 
              issue={item}
              isLoading={isLoading}
            />
            <label>{item.titlePrefix}</label>
          </div>
          <span>{item.title}</span>
        </div>
        <div className={"search-issues-item-controls"}>
          {!isCurrentTask &&
            <buttons.Button
              onPress={onSelectClick}
              icon="ok-M"
              tooltip="Выбрать задачу"
              color="action"
            />
          }
        </div>
      </div>
    );
  }
);

SearchIssueItem.propTypes = {
  item:          PropTypes.object, 
  store:         PropTypes.instanceOf(IssueStore), 
  issue:         PropTypes.instanceOf(IssueModel), 
  onSelectIssue: PropTypes.func.isRequired
};

export default SearchIssueItem;
