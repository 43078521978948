/**
 * Отобразить размер в байтах в читаемом виде
 *
 * @param {Number} bytes число байт
 * @param {Number} dp размерность после запятой
 *
 * @return {String}
 */
const humanFileSize = function(bytes, dp = 1) {
  let b = bytes;
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    b /= thresh;
    u += 1;
  } while (Math.round(Math.abs(b) * r) / r >= thresh && u < units.length - 1);


  return `${b.toFixed(dp)} ${units[u]}`;
};

export default humanFileSize;
