import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ru";
import classNames from "classnames";
import { getRemSizeInPx } from "~/core/utils";
import TimeAxis from "./TimeAxis";

moment.locale("ru");

const RemSize = 2.5;

/**
 * TimeLine для отображения хода выполения задачи. Срок задачи задается в часах, поэтому минимальное
 * значение шкалы - 1 час.
 *
 * @param {Object} props набор параметров
 * @param {Date} props.startDate  дата начала задачи
 * @param {Date} props.endDate  дата окончания задачи. Если данный параметр не задан, 
 *     то это значит, что срок задачи не указан
 */
const TimeLine = ({ startDate, endDate }) => {
  const [axisValues, setAxisValues] = useState([]);
  const [goneRem, setGoneRem] = useState(0);
  const [widthInRem, setWidthInRem] =  useState(0);
  const [axisValueRem, setAxisValueRem] = useState(RemSize);
  const elRef = useRef(null);
  const today = Date.now();

  // просрочен ли срок выполенения задачи
  const isOverdue = useMemo(() => {
    return endDate && moment(today).diff(moment(endDate), "hours") > 0;
  }, [endDate, today]);

  const diffHours = useMemo(() => {
    if (endDate) {
      return (isOverdue ? moment(today).diff(moment(startDate), "hours") :
        moment(endDate).diff(moment(startDate), "hours"));
    } else {
      return moment(today).diff(moment(startDate), "hours");
    }
  }, [isOverdue, today, startDate, endDate]);


  const diffDays = useMemo(() => {
    if (endDate) {
      return (isOverdue ? moment(today).diff(moment(startDate), "days") :
        moment(endDate).diff(moment(startDate), "days"));
    } else {
      return moment(today).diff(moment(startDate), "days");
    }
  }, [isOverdue, today, startDate, endDate]);

  const diffYears = useMemo(() => {
    if (endDate) {
      return isOverdue ? moment(today).diff(moment(startDate), "years") :
        moment(endDate).diff(moment(startDate), "years");
    } else {
      return moment(today).diff(moment(startDate), "years");
    }
  }, [isOverdue, today, startDate, endDate]);

  const goneHours = useMemo(() => {
    if (endDate) {
      return !isOverdue ? moment(today).diff(moment(startDate), "hours") :
        moment(endDate).diff(moment(startDate), "hours");
    } else {
      return moment(today).diff(moment(startDate), "hours");
    }
  }, [isOverdue, today, startDate, endDate]);

  const goneDays = useMemo(() => {
    if (endDate) {
      return !isOverdue ? moment(today).diff(moment(startDate), "days") :
        moment(endDate).diff(moment(startDate), "days");
    } else {
      return moment(today).diff(moment(startDate), "days");
    }
  }, [isOverdue, today, startDate, endDate]);

  // let diffHours = 1;
  // let diffDays =  1;
  // let diffYears =  1;
  // let goneDays = 1;
  // let goneHours = 1;
  //
  // if (endDate) {
  //   diffHours = (isOverdue ? moment(today).diff(moment(startDate), "hours") :
  //     moment(endDate).diff(moment(startDate), "hours"));
  //   diffDays = (isOverdue ? moment(today).diff(moment(startDate), "days") :
  //     moment(endDate).diff(moment(startDate), "days"));
  //   diffYears = isOverdue ? moment(today).diff(moment(startDate), "years") :
  //     moment(endDate).diff(moment(startDate), "years");
  //   goneHours = !isOverdue ? moment(today).diff(moment(startDate), "hours") :
  //     moment(endDate).diff(moment(startDate), "hours");
  //   goneDays = !isOverdue ? moment(today).diff(moment(startDate), "days") :
  //     moment(endDate).diff(moment(startDate), "days");
  // } else {
  //   diffHours = moment(today).diff(moment(startDate), "hours");
  //   diffDays = moment(today).diff(moment(startDate), "days");
  //   diffYears = moment(today).diff(moment(startDate), "years");
  //   goneDays = moment(today).diff(moment(startDate), "days");
  //   goneHours = moment(today).diff(moment(startDate), "hours");
  // }

  const isShowHours = useMemo(() => {
    return diffDays <= 2;
  }, [diffDays]);

  const gonePercent = useMemo(() => {
    if (isShowHours) {
      return  (goneHours / diffHours) * 100;
    } else {
      return  (goneDays / diffDays) * 100;
    }
  }, [isShowHours, goneHours, goneDays, diffHours, diffDays]);

  // const gonePercent = diffDays === 0 ? 0 : (goneDays / diffDays) * 100;

  let sensor;
  useEffect(() => {
    if (elRef.current) {
      // Вешаем обработчик события на изменение размеров шкалы timeline
      sensor = new ResizeObserver((entries) => {
        // Оборачиваю в requestAnimationFrame чтобы избежать ошибки - "ResizeObserver loop limit exceeded"
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          onResize();
        });
      });
      sensor.observe(elRef.current, { box: "border-box" });
    }

    return () => {
      if (elRef.current) {
        sensor.unobserve(elRef.current);
      }
    };
  }, [elRef, startDate, endDate]);

  const format = useMemo(() => {
    if (diffYears > 1) {
      return "D MMMM YYYY";
    }
    return isShowHours ? "D MMMM HH:00" : "D MMMM";
  }, [diffYears, isShowHours]);

  /**
   * Вызывается при изменении размеров timeline
   * Здесь производится расчет масштаба шкалы и позиционирование маркера текущего дня
   */
  const onResize = useCallback(() => {
    if (!elRef.current) {
      return;
    }
    // получаем ширину контейнера
    const rect = elRef.current.getBoundingClientRect();
    const { width } = rect;
    const widthInRem = (width / getRemSizeInPx());

    if (width === 0) {
      return;
    }


    let maxValuesOnAxis = 1;
    let step = 1;
    const values = [];


    const diffValues = isShowHours ? diffHours : diffDays;
    const goneValue = isShowHours ? goneHours : goneDays;

    if (diffValues === 0) { // если дата начала и окончания тот же день
      setGoneRem(0);
      setAxisValues([]);
      setWidthInRem(0);
      return;
    }

    // если срок не указан, то делим шкалу пополам
    const w = endDate ? widthInRem : widthInRem / 2;

    maxValuesOnAxis = Math.min(diffValues + 1, Math.floor((w - RemSize) / RemSize));
    step = diffValues / maxValuesOnAxis;

    // если шаг меньше 1, то уменьшаем кол-во часов/дней, которое может разместиться на шкале, чтобы
    // избежать дублирования значений на шкале
    while (step < 1) {
      maxValuesOnAxis -= 1;
      step = diffValues / maxValuesOnAxis;
    }

    // получаем ширину шага в rem
    const stepAxisInRem = w / (maxValuesOnAxis + 1);
    setAxisValueRem(stepAxisInRem);

    // задаем ширину индикатора прошедших часов/дней в rem
    setGoneRem((goneValue / step) * stepAxisInRem);

    let lastDay;
    let lastMonth;
    let lastYear;
    let shouldBeAtCenter = false;
    const markerDate = endDate && today > endDate ? endDate : today;
    for (let i = 0; i <= maxValuesOnAxis; i += 1) {
      const m = moment(startDate).add(step * i, isShowHours ? "hours" : "days");
      const hour = isShowHours ? m.format("HH") : "";

      // если значение маркера текущего дня совпадает с меткой на шкале, то маркер нужно отцентровать
      // относительно метки на шкале
      const format = isShowHours ? "DDMMYYYYHH" : "DDMMYYYY";
      shouldBeAtCenter =  (shouldBeAtCenter || m.format(format) === moment(markerDate).format(format)) &&
                          moment(markerDate).format(format) !== moment(startDate).format(format);

      let day = isShowHours ? "" :  m.format("DD");
      let month = "";
      let year = "";


      // запоминаем последний отображенный день, месяц и год на шкале
      if (lastDay !== m.format("DD")) {
        lastDay = m.format("DD");
        day = lastDay;
      }

      if (lastMonth !== m.format("MMM")) {
        lastMonth = m.format("MMM");
        month = lastMonth;
      }

      if (diffYears > 1 && lastYear !== m.format("YYYY")) {
        lastYear = m.format("YYYY");
        year = lastYear;
      }

      values.push({ hour, day, month, year });
    }

    // центруем маркер относительно метки на шкале если это необходимо
    if (shouldBeAtCenter) {
      setGoneRem(Math.round((goneValue / step)) * stepAxisInRem - RemSize / 2 + stepAxisInRem / 2);
    } else {
      setGoneRem((goneValue / step) * stepAxisInRem);
    }

    // задаем список меток на шкале
    setAxisValues(values);
    // задаем ширину шкалы
    setWidthInRem(widthInRem);
  }, [elRef, isShowHours, endDate, today, diffHours, diffDays, goneHours, goneDays]);

  return (
    <div
      className="timeline"
      ref={elRef}
    >
      <div className="timeline-points">
        <div className={classNames("timeline-points-up", {
          overdue: isOverdue
        })}
        >
          <div
            className="timeline-point start"
          >
            <div className="title">
              <span className="date">{`${moment(startDate).format(format)},`}</span>
              <span className="postfix"> начало</span>
            </div>
          </div>
          <div className={classNames("timeline-point", {
            end:           !isOverdue,
            "end-overdue": isOverdue
          })}
          >
            {endDate && !isOverdue &&
              <div className="title">
                <span className="date">{`${moment(endDate).format(format)},`}</span>
                <span className="postfix"> срок</span>
              </div>
            }
            {endDate && isOverdue &&
              <div className="title">
                <span className="date">{`${moment(today).format(format)},`}</span>
                <span className="postfix"> сейчас</span>
              </div>
            }
            {!endDate &&
              <div className="title">
                <span className="postfix">Cрок не указан</span>
              </div>
            }
          </div>
        </div>
        <div className={classNames("timeline-points-down", {
          overdue: isOverdue
        })}
        >
          <div
            className={classNames("timeline-point", {
              reverse: gonePercent > 50,
              now:     !isOverdue,
              overdue: isOverdue
            })}
            style={{
              paddingLeft:  gonePercent <= 50 ? `${goneRem}rem` : 0,
              paddingRight: gonePercent > 50 ? `${widthInRem - goneRem - RemSize}rem` : 0
            }}
          >
            <div
              className={classNames("marker", {
                now:     !isOverdue,
                overdue: isOverdue
              })}
            />
            {!isOverdue &&
              <div className="title">
                <span className="date">{`${moment().format(format)},`}</span>
                <span className="postfix"> сейчас</span>
              </div>
            }

            {isOverdue &&
              <div className="title">
                <span className="date">{`${moment(endDate).format(format)},`}</span>
                <span className="postfix"> срок</span>
              </div>
            }

          </div>
        </div>
      </div>
      <div className={classNames("timeline-slider", {
        overdue: isOverdue
      })}
      >
        <div
          className="timeline-slider-gone"
          style={{
            minWidth: goneRem ? `${goneRem}rem` : `${gonePercent}%`
          }}
        />
        <div
          className={classNames("timeline-slider-left", {
            "end-undefined": !endDate,
            overdue:         isOverdue
          })}
        />
      </div>
      <TimeAxis
        startDate={startDate}
        endDate={endDate}
        axisValues={axisValues}
        axisValueRem={axisValueRem}
      />
    </div>
  );
};

TimeLine.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate:   PropTypes.instanceOf(Date)
};


export default React.memo(TimeLine, (props, nextProps) => {
  if (props.startDate === nextProps.startDate && props.endDate === nextProps.endDate) {
    // don't re-render/update
    return true;
  }
});
