import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { components } from "@ais3p/ui-framework";
import { IssueModel } from "../../models";

const { Icon } = components;

/**
 * Компонент вкладки задачи
 * 
 * @param {Object} props набор параметров
 * @param {IssueModel} params.issue объект задачи
 * @param {Number} params.index индекс вкладки в списке открытых вкладок
 * @param {Function} params.closeTab метод для закрытия вкладки
 */
const Tab = observer((props) => {
  const {
    issue,
    index,
    closeTab
  } = props;

  const onCloseTab = useCallback(
    (e) => {
      e.stopPropagation();
      closeTab && closeTab(issue, index);
    },
    [closeTab, index]
  );
  
  if (!issue) {
    return (
      <div className={"issue-tabs-tab-body issue-tabs-tab-list"}>
        <p>{"Список задач"}</p>
      </div>
    );
  }
  return (
    <div
      className={"issue-tabs-tab-body"}
    >
      <p>{issue.titlePrefix}</p>
      <div className="issue-tabs-tab-close" onClick={onCloseTab}>
        <Icon className="close" size={2} name="close-M" />
      </div>
    </div>
  );
});

Tab.propTypes = {
  issue:    PropTypes.instanceOf(IssueModel),
  index:    PropTypes.number.isRequired,
  closeTab: PropTypes.func
};

export default Tab;
