import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import LinkItem from "./SchemaLinkItem";
import TraceItem from "./SchemaTraceItem";
import TraceSchemaModel from "../../models/traceSchema";


/**
 * Компонент для отображения списка перенесенных DnD записей для формирования последовательности аналаза страссировки 
 * связей
 * 
 * @param {Object} props набор параметров
 * @param {TraceSchemaModel} props.schema  запись связи в схеме отчетов о трассировке
 */
const SchemaTraceList = observer(({ schema }) => {
  return (
    <div className={"schema-trace-list"}>
      <div className="label">Последовательный список объектов для анализа связей</div>
      <div className={"schema-trace-list-items"}>
        {!schema && 
        <div className="schema-trace-list-empty">
          <span>Выберите схему для трассировки</span>
        </div>
        }
        {schema && schema.items.length === 0  &&
        <div className="schema-trace-list-empty">
          {schema &&
            <span>Не задана последовательность объектов для анализа связей</span>
          }
        </div>
        }
        {schema && schema.items.map((item, i) => {
          if (item.schemaItemType === "link") {
            return (
              <LinkItem
                key={i}
                item={item}
              />
            );
          }

          if (item.schemaItemType === "object") {
            return (
              <TraceItem
                key={i}
                item={item}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
});

SchemaTraceList.propTypes = {
  schema: PropTypes.instanceOf(TraceSchemaModel)
};

export default SchemaTraceList;
