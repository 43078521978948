import Server from "~/core/api/server";


class SearchApi extends Server {
  methods = {
    searchEntities: {
      // поиск артефактов
      url:           this.getServiceUrl("kinds", "api/v2/members/found-members"),
      method:        "POST",
      returnHeaders: true,
      textProcess:   "Поиск артефактов АИС...",
      textSuccess:   null,
      textError:     this.template`Во время поиска артефактов АИС произошла ошибка: ${0}`,
      timeout:       300000
    },
    searchEntitiesByPage: {
      // поиск артефактов с пагинацией
      url: (params) => {
        return this.getServiceUrl("kinds", this.template`api/v2/members/found-members?page=${0}&perPage=${1}`, params);
      },
      method:        "POST",
      returnHeaders: true,
      textProcess:   "Поиск артефактов АИС...",
      textSuccess:   null,
      textError:     this.template`Во время поиска артефактов АИС произошла ошибка: ${0}`,
      timeout:       300000

    },
    foundTextObjects: {
      url:         this.getServiceUrl("text", "api/v0/text-objects/found-objects"),
      method:      "POST",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      textProcess: "Поиск текстовых объектов...",
      timeout:     60000,
      textSuccess: null,
      textError:   this.template`Во поиска текстовы объектов произошла ошибка: ${0}`
    }
  };


  
  /**
   * Поиск артефактов используя набор фильтров
   *
   * @params {Object} filter набор фильтров
   * @param {Number} page номер страницы
   * @param {Number} perPage максимальное кол-во записей на странице
   * 
   * @return {Promise}
   */
  async searchEntities(filters, page, perPage) {
    if (page && perPage) {
      const reqData = {
        params: [page, perPage],
        data:   filters
      };
      return await this.request("searchEntitiesByPage", reqData);
    } 
    return await this.request("searchEntities", filters);
  }

  async foundTextObjects(uids) {
    return await this.request("foundTextObjects", uids);
  }
}

export default SearchApi;
