import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { layout, components } from "@ais3p/ui-framework";

const { modal } = layout;
const { buttons } = components;

@inject("uiStore")
@observer
class Confirm extends Component {
  constructor(props) {
    super(props);
    this.uiStore = props.uiStore;
    this.onEscPress = this.onEscPress.bind(this);
  }
  onEscPress() {
    this.uiStore.hideConfirm();
  }
  render() {
    const {
      showConfirm,
      confirmText,
      confirmLabel,
      buttonsConfig,
      isConfirmPending
    } = this.uiStore;
    if (!showConfirm) {
      return <div className={"app-confirm"}></div>;
    }

    const button = [];
    buttonsConfig.forEach((buttonConfig, i) => {
      button.push(
        <buttons.Button 
          key={`button-${i}`}
          text={buttonConfig.text}
          icon={buttonConfig.icon}
          onPress={buttonConfig.onPress}
          isLoading={isConfirmPending || buttonConfig.loading}
          color={buttonConfig.theme || ""}
        />
      );
    });
    return (
      <modal.Window
        name="confirm"
        icon="plane-M"
        show={showConfirm}
        title={confirmLabel}
        buttons={button}
        onKeyPressEsc={this.onEscPress}
      >
        {confirmText}
      </modal.Window>
    );
  }
}

export default Confirm;
