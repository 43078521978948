import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import AisIcon from "../../../core/components/AisIcon";
import Indicator from "../../../core/components/Indicator";
import PlusMenu from "./PlusMenu";
import TextItemIcon from "./TextItemIcon";

const List = observer(({ data, renderItem }) => {
  const { itemsForRender, id, idsArray, isExpanded, isFocusUid, scrollItemId, uid, diffClass } = data;
  const itemsRender = useMemo(() => {
    return itemsForRender.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, itemsForRender, itemsForRender.length]);

  const onClickIcon = useCallback(
    () => {
      data.setEditing();
      data.setFocusUid();
    },
    [data]
  );

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current.scrollIntoView(true);
      }, 10); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div id={id} ref={element} className={`list element ${diffClass} ${isFocusUid ? "focused" : ""}`}>
      <AisIcon
        onClick={toggleExpanded}
        icon={"plus-M"}
        className={`expand-menu ${isExpanded ? "expanded" : ""}`}
      />
      <div className="list-holder">
        <Indicator data={data} />
        <TextItemIcon onClick={onClickIcon} data={data} />
        <div className="axis" onClick={onClickIcon}></div>
        <div className="list-body">{itemsRender}</div>
      </div>
      {isExpanded && <PlusMenu data={data} />}
    </div>
  );
});

export default List;
