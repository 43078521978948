import BaseRedmineModel from "./baseRedmineModel";
import { propertyTypeText } from "../constants/propertyTypes";
// import { relationTypeText } from "../constants/relationTypes";
import { propertyNameText } from "../constants/propertyNames";

import { observable, computed, action } from "mobx";

/**
 * Модель изменений в задаче.
 *
 * @class ChangesModel
 */
class ChangesModel extends BaseRedmineModel {
  /**
   * Тип изменяемого элемента может иметь значения
   *
   * @type String
   */
  @observable
  property = undefined;

  /**
   * "название" изменяемого поля
   *
   * @type String
   */
  @observable
  name = undefined;

  /**
   * Cтарое значение (если null, то считается, что значение добавлено)
   *
   * @type Any
   */
  @observable
  oldValue = undefined;

  /**
   * Новое значение (если null, то считается, что значение удалено)
   *
   * @type Any
   */
  @observable
  newValue = undefined;

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.property тип изменяемого элемента может иметь значения
   * @param {String} params.name название изменяемого поля
   * @param {String} params.oldValue старое значение
   * @param {String} params.newValue новое значение
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {ChangesModel}
   */
  static create({
    property,
    name,
    oldValue,
    newValue
  }, issueStore) {
    return new ChangesModel({
      property,
      name,
      oldValue,
      newValue
    }, issueStore);
  }

  constructor(params, issueStore) {
    super(params, issueStore);

    this.property = params.property;
    this.name = params.name;
    this.oldValue = params.oldValue;
    this.newValue = params.newValue;
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "changeModel";
  }

  /**
   * Обновить св-ва модели
   * @param {Object} data
   */
  @action
  update(data) {
    this.property = data.property || this.property;
    this.name = data.name || this.name;
    this.oldValue = data.oldValue || this.oldValue;
    this.newValue = data.newValue || this.newValue;
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    return {
      property: this.property,
      name:     this.name,
      oldValue: this.oldValue,
      newValue: this.newValue
    };
  }

  /**
   * Флаг указывающий, было ли значение добавлено
   *
   * @return {Boolean}
   */
  @computed
  get wasAdded() {
    return !this.oldValue;
  }

  /**
   * Флаг указывающий, было ли значение удалено
   *
   * @return {String}
   */
  @computed
  get wasDeleted() {
    return !this.newValue;
  }

  /**
   * Флаг указывающий, было ли значение изменено
   *
   * @return {String}
   */
  @computed
  get wasModified() {
    return !this.newValue && !this.oldValue;
  }

  /**
   * Строковое представление записи изменений
   *
   * @return {String}
   */
  @computed
  get title() {
    if (this.wasModified) {
      return `${propertyTypeText(this.property)} ${propertyNameText(this.name)} изменился с ${this.oldValue} на ${this.newValue}`; // eslint-disable-line
    }

    if (this.wasAdded) {
      return `${propertyTypeText(this.property)} ${propertyNameText(this.name)} добавлен`; // eslint-disable-line
    }
    if (this.wasDeleted) {
      return `${propertyTypeText(this.property)} ${propertyNameText(this.name)} удален`; // eslint-disable-line
    }

    return `${propertyTypeText(this.property)} ${propertyNameText(this.name)} изменился с ${this.oldValue} на ${this.newValue}`; // eslint-disable-line
  }
}

export default ChangesModel;
