import { observable, computed, action } from "mobx";

import Server from "~/core/api/server";

import {
  WS_STATE_PENDING,
  WS_STATE_OPEN,
  WS_STATE_CONNECTING,
  WS_STATE_RECONNECTING,
  WS_STATE_CLOSED
} from "../constants/wsApiStates";

/**
 * Интерфейс для работы с WebSocket
 */
class WebSocketApi {
  /**
   *  Значения по-умолчанию
   */
  static defaultParams = {
    reconnectDelay: 2, // secs
    flushOnOpen:    true,
    pingMessage:    "ping",
    // failedReconnectTimes: 2 //secs
    pingInterval:   15 // secs
  };

  /**
   * @type {WebSocket} websocket
   */
  socket = undefined;

  /**
   * @type {Array<Strings>} набор пакетов, которые ожидают отправки при открытии сокета
   */
  pendingCommands = [];

  /**
   * @type {Number} таймер для реконекта сокета
   */
  reconnectTimer = undefined;

  /**
   * @type {Number} Кол-во неудачных подключений
   */
  reconnectTimes = 0;

  /**
   * @type {Number} таймер для пинга
   */
  pingTimer = undefined;

  /**
   * @type {String} текущее состояние сокета: WS_STATE_PENDING | WS_STATE_OPEN | WS_STATE_CONNECTING |
   *                WS_STATE_RECONNECTING | WS_STATE_CLOSED
   */
  @observable
  readyState = WS_STATE_PENDING;

  /**
   * Конструктор интерфейса работы с WebSocket
   *
   * @params {Object} params набор параметров
   * @params {Boolean} params.flushOnOpen отправлять ли ожидающие пакеты при открытии сокета
   * @params {Number} params.reconnectDelay сколько секунд ожидать, перед новым передподключением, если произошла ошибка
   *                  Если выставить false, то переподключения не будет
   * @params {Number} params.failedReconnectTimes Кол-во неудачных подключений, после которого
   *                  произойдет сообщение о проблеме. Если значение не задано, то будет все время
   *                  пытаться переподключиться
   * @params {String} params.pingMessage сообещение для ping'а
   * @params {Number} params.pingInterval если значение >0, то будет пинг с этим интервалом в секундах
   * @params {Function} params.onOpen callback ф-я на открытие соединения
   * @params {Function} params.onClose callback ф-я на закрытие соединения
   * @params {Function} params.onMessage callback ф-я на получение сообщения
   * @params {Function} params.onError callback ф-я при возникновении ошибки
   */
  constructor(params) {
    this.params = {
      ...WebSocketApi.defaultParams,
      ...params
    };
    // const { pingInterval } = this.params;

    // if (pingInterval) {
    //   this.pingTimer = window.setInterval(() => {
    //     return this.ping();
    //   }, pingInterval * 1000);
    // }
  }

  /**
   * Есть ли соединение
   *
   * @return {Boolean}
   */
  @computed
  get isConnected() {
    return this.readyState === WS_STATE_OPEN;
  }

  /**
   * Задать статус сокета
   *
   * @param {String} state
   */
  @action
  setReadyState(state) {
    this.readyState = state;
  }

  /**
   * Создать соединение по соету
   *
   * @param {String} token авторизации
   * @param {String} url сервера
   */
  connect(token, url = Server.wsServer) {
    if (!token) {
      this.params.onError &&
        this.params.onError("Не задан \"token\" для авторизации!");
      return false;
    }
    // закрываем предыдущее соединение, если оно было
    if (this.socket && this.socket.readyState === 1) {
      this.socket.close();
    }
    try {
      // создаем новое соединение
      this.token = token;
      this.socket = new WebSocket(url);
      this.socket.onopen = this.onOpen.bind(this);
      this.socket.onmessage = this.onMessage.bind(this);
      this.socket.onerror = this.onError.bind(this);
      this.socket.onclose = this.onClose.bind(this);
      this.setReadyState(WS_STATE_CONNECTING);
      this.reconnectTimes = 0;
      return true;
    } catch (ex) {
      this.onError(
        `Во время инициализации сокета произошла ошибка: ${ex.message}`,
        ex
      );
      this.setReadyState(WS_STATE_PENDING);
    }

    return false;
  }

  /**
   * Разъединить соединение
   */
  disconnect() {
    if (this.socket && this.socket.readyState === 1) {
      this.socket.close(1000, "logout");
      this.socket = undefined;
    }

    this.pendingCommands = [];
    clearTimeout(this.reconnectTimer);
    clearInterval(this.pingTimer);
    this.setReadyState(WS_STATE_PENDING);
  }

  /**
   * Сделать переподключение
   *
   */
  reconnect() {
    console.warn("Reconnect webSocket");
    if (!this.socket) {
      this.onError(
        "Невозможно сделать переподлючение сокета, если сам сокет не был еще проинициализирован!"
      );
      return;
    }

    if (!this.token) {
      this.onError(
        "Невозможно сделать переподлючение сокета, тк не задан \"token\" для авторизации"
      );
      return;
    }

    this.connect(this.token, this.socket.url);
  }

  /**
   * Отправить ping
   *
   */
  ping() {
    if (this.isConnected) {
      this.send(this.params.pingMessage);
    }
  }

  /**
   * Отправить команду
   *
   * @param {String} command  команда
   */
  send(command) {
    if (this.isConnected) {
      this.socket.send(command);
    } else {
      this.pendingCommands.push(command);
    }
  }

  /**
   * Отправить команды, стоящие в очереди
   *
   */
  flush() {
    const commands = this.pendingCommands;
    this.pendingCommands = [];
    for (const command of commands) {
      this.send(command);
    }
  }

  /**
   * Callback ф-ия на открытие соединения по сокету
   *
   * @param {Event} e
   */
  onOpen() {
    this.setReadyState(WS_STATE_OPEN);
    this.send(this.token);
    if (this.params.flushOnOpen) {
      this.flush();
    }

    if (this.params.onOpen) {
      this.params.onOpen();
    }
  }

  /**
   * Callback ф-ия на получение сообщения по сокету
   *
   * @param {Event} e
   */
  onMessage({ data }) {
    if (this.params.onMessage) {
      this.params.onMessage(data);
    }
  }

  /**
   * Callback ф-ия при вощникновении ошибки у сокета
   *
   * @param {Event} e
   */
  onError(message, e) {
    console.error("onWSError", message, e);
  }

  /**
   * Callback ф-ия на закрытие соединения по сокету
   *
   * @param {Event} e
   */
  onClose(e) {
    const isError = e.code !== 1000 || !e.wasClean;
    let reason = `Непонятная причина - ${e.reason}.`;

    switch (e.code) {
      case 1001:
        reason = "Потеряна свзь с сервером.";
        break;
      case 1002:
        reason = "Сервер разорвал соединения из-за ошибки протокола.";
        break;
      case 1003:
        reason =
          "Сервер разорвал соединения, тк он получил тип данных, который он не может принять.";
        break;
      case 1004:
        reason =
          "Reserved. The specific meaning might be defined in the future.";
        break;
      case 1005:
        reason = "No status code was actually present.";
        break;
      case 1006:
        reason = "Соединение было закрыто аварийно (нет фрейма закрытия).";
        break;
      case 1007:
        reason = `Сервер разорвал соединение, потому что он получил данные в сообщении, которые
       не соответствовали типу сообщения.`;
        break;
      case 1008:
        reason =
          "Сервер разорвал соединение, так как получил сообщение, которое \"нарушает его политику\".";
        break;
      case 1009:
        reason =
          "Сервер разорвал соединение, так как получил сообщение, которое слишком велико для обработки.";
        break;
      case 1010:
        reason = `Клиент разрывает соединение, так как ожидала, что сервер согласует одно или несколько расширений,
                но сервер не вернул их в ответном сообщении рукопожатия WebSocket.
                В частности, необходимы следующие расширения - ${e.reason}.`;
        break;
      case 1011:
        reason = `Сервер разрывает соединение, так как он столкнулся с непредвиденной ситуацией,
                которая не позволила ему выполнить запрос.`;
        break;
      case 1015:
        reason = "Соединение было закрыто из-за сбоя подтверждения TLS.";
        break;
    }

    if (isError) {
      const { reconnectDelay, failedReconnectTimes } = this.params;
      if (
        reconnectDelay &&
        this.socket &&
        (!failedReconnectTimes ||
          (failedReconnectTimes && this.reconnectTimes < failedReconnectTimes))
      ) {
        this.reconnectTimer = window.setTimeout(() => {
          return this.reconnect();
        }, reconnectDelay * 1000);
        this.reconnectTimes += 1;
        this.setReadyState(WS_STATE_RECONNECTING);
      } else {
        this.params.onError &&
          this.params.onError(`WebSocket[${e.code}]: ${reason}`);
        this.disconnect();
      }
    } else {
      this.setReadyState(WS_STATE_CLOSED);
      if (this.params.onClose) {
        this.params.onClose(e.code, reason);
      }
    }
  }

  /**
   * Деструктор
   */
  destroy() {
    this.disconnect();
  }
}

export default WebSocketApi;
