import { action, computed, observable } from "mobx";

/**
 * Базовый  объект
 *
 * @type {BaseObject}
 */
class RestrictionsForRole {
  @observable role = null;
  @observable configStore = null;
  @observable restrictionsForObject = null;
  @observable restrictionsForTool = null;
  @observable subjectByCriterionMap = new Map();

  constructor(role, parent, store) {
    this.role = `${role}`;
    this.restrictionsForObject = parent;
    this.restrictionsForTool = this.restrictionsForObject.restrictionsForTool;
    this.configStore = store;
  }

  @action
  addItem({ role, subjectCriterion, subjectCriterionValue }) {
    if (`${role}` !== this.role) {
      throw new Error("Переданы ограничения для другой роли");
    }
    let subjectCriterionArray = this.subjectByCriterionMap.get(
      subjectCriterion
    );
    if (!subjectCriterionArray) {
      this.subjectByCriterionMap.set(subjectCriterion, []);
      subjectCriterionArray = this.subjectByCriterionMap.get(subjectCriterion);
    }
    subjectCriterionArray.push(subjectCriterionValue);
  }

  @computed
  get classByKind() {
    const classByKind = {};
    this.subjectByCriterionMap.forEach((subjectArray, criterion) => {
      if (criterion === "kind") {
        const classString = this.restrictionsForObject.value;
        subjectArray.forEach((subject) => {
          classByKind[subject] = classString;
        });
      }
    });
    return classByKind;
  }

  @computed
  get config() {
    const config = [];
    this.subjectByCriterionMap.forEach((subjectArray, criterion) => {
      subjectArray.forEach((subject) => {
        let object = null;
        if (criterion === "class") {
          const name = this.configStore.classAliasMap.get(subject);
          if (name) {
            object = { as: subject, name };
          } else {
            console.warn(`No name in configuration for class: ${subject}`);
          }
        } else if (criterion === "kind") {
          const classString = this.restrictionsForTool.classByKind[subject];
          if (classString) {
            object = { as: classString, kind: subject };
          } else {
            console.warn(`No class in configuration for kind: ${subject}`);
          }
        }

        if (object) {
          config.push(object);
        }
      });
    });
    return config;
  }
}

export default RestrictionsForRole;
