import Server from "~/core/api/server";

/**
 * Api для запросов к сервису для работы с репозиторием
 * 
 * @class {RepoApi}
 */
class RepoApi extends Server {
  servises = {
    vcs: "/services/app.sourcecode"
  };

  methods = {
    getTree: {
      url: this.getServiceUrl(
        "vcs",
        "api/v1/found-tree"
      ),
      timeout:     30000,
      method:      "POST",
      textProcess: "Получение источников...",
      textSuccess: null,
      textError:   this
        .template`Во время получения источников произошла ошибка: ${0}`
    },
    getContent: {
      url: this.getServiceUrl(
        "vcs",
        "api/v1/found-blobs"
      ),
      timeout:     30000,
      method:      "POST",
      textProcess: "Получение blob...",
      textSuccess: null,
      textError:   this.template`Во время получения blob произошла ошибка: ${0}`
    },
    getCommits: {
      url: this.getServiceUrl(
        "vcs",
        "api/v1/found-history",
      ),
      timeout:     30000,
      method:      "POST",
      textProcess: "Получение коммитов...",
      textSuccess: null,
      textError:   this
        .template`Во время получения коммитов произошла ошибка: ${0}`
    },
    getOutlines: {
      url: this.getServiceUrl(
        "vcs",
        "api/v1/found-outlines"
      ),
      timeout:     30000,
      method:      "POST",
      textProcess: "Получение структуры кода...",
      textSuccess: null,
      textError:   this.template`Во время получения струтуры кода произошла ошибка: ${0}`
    },
    getObjects: {
      url: this.getServiceUrl(
        "vcs",
        "api/v1/found-objects"
      ),
      timeout:     30000,
      method:      "POST",
      textProcess: "Поиск объектов репозитория...",
      textSuccess: null,
      textError:   this
        .template`Во время поиска объектов репозитория произошла ошибка: ${0}`
    },
    getTags: {
      url: (params) => {
        return this.getServiceUrl(
          "vcs",
          this
            .template`api/v1/repositories/${0}/tags`,
          params
        );
      },
      timeout:     30000,
      method:      "GET",
      textProcess: "Получение списка тэгов в репозитории ...",
      textSuccess: null,
      textError:   this.template`Во время получения списка тэгов в репозитории произошла ошибка: ${0}`
    },
    getBranches: {
      url: (params) => {
        return this.getServiceUrl(
          "vcs",
          this
            .template`api/v1/repositories/${0}/branches`,
          params
        );
      },
      timeout:     30000,
      method:      "GET",
      textProcess: "Получение списка веток в репозитории ...",
      textSuccess: null,
      textError:   this.template`Во время получения списка веток в репозитории произошла ошибка: ${0}`
    },
    getRepository: {
      url: (params) => {
        return this.getServiceUrl(
          "vcs",
          this
            .template`api/v1/repositories/${0}`,
          params
        );
      },
      timeout:     30000,
      method:      "GET",
      textProcess: "Получение информации о репозитории ...",
      textSuccess: null,
      textError:   this.template`Во время получения информации о репозитории произошла ошибка: ${0}`
    }
  };

  /**
   * Загрузить ноду дерева репозитория
   * @param {String} repositoryId id репозитория
   * @param {String} path путь в дереве
   * @param {Object} entity описание сущности { ключ:значение}, где ключ: commitId, branch, tag 
   * @returns 
   */
  async getNode(repositoryId, path = "/", entity = {}) {
    const reqData = { 
      repositoryId,
      path,
      ...entity
    };
    return await this.request("getTree", reqData);
  }

  /**
   * Загрузить историю изменений - список коммитов
   * 
   * @param {String} repositoryId id репозитория
   * @param {String} path путь в дереве
   * @param {Object} entity описание сущности  { ключ:значение}, где ключ: commitId, branch, tag 
   * @param {Number} start номер начальной записи для постраничного вывода
   * @param {Number} limit максимальное кол-во записей в ответе для постраничного вывода
   * @returns 
   */
  async getCommits(repositoryId, path = "/", entity = {}, start, limit) {
    const reqData = { 
      repositoryId, 
      path,
      ...entity, 
      start, limit 
    };
    return await this.request("getCommits", reqData);
  }

  /**
   * Загрузить содержимое файла
   * 
   * @param {String} repositoryId id репозитория
   * @param {String} path путь в дереве
   * @param {Object} entity описание сущности  { ключ:значение}, где ключ: commitId, branch, tag 
   * @returns 
   */
  async getContent(repositoryId, path, entity = {}) {
    const reqData = { 
      repositoryId, 
      path,
      ...entity 
    };
    return await this.request("getContent", reqData);
  }

  /**
   * Загрузить структуру файла
   * 
   * @param {String} repositoryId id репозитория
   * @param {String} commit номер коммита
   * @param {String} path путь в дереве
   * @param {Object} dditionalParams набор дополнительных параметров
   * @returns 
   */
  async getOutlines(repositoryId, commitId, path, additionalParams = {}) {
    const reqData = [{ 
      repositoryId, 
      commitId, 
      path,
      ...additionalParams
    }];
    return await this.request("getOutlines", reqData);
  }

  /**
   * Найти объекты в репозитории
   * 
   * @param {Array<String>} ids массив id объектов репозитория
   * @returns Array<RepoNode|CodeObject>
   */
  async getObjects(ids) {
    const reqData = Array.isArray(ids) ? ids.map((id) => {
      return { id };
    }) : [{ id: ids }];
    return await this.request("getObjects", reqData);
  }

  /**
   * Загрузить набор тэгов в репозитотрии
   * 
   * @param {String} repositoryId id репозитория
   * @returns 
   */
  async getTags(repositoryId) {
    const reqData = { params: [repositoryId] };
    return await this.request("getTags", reqData);
  }

  /**
   * Загрузить веток тэгов в репозитотрии
   * 
   * @param {String} repositoryId id репозитория
   * @returns 
   */
  async getBranches(repositoryId) {
    const reqData = { params: [repositoryId] };
    return await this.request("getBranches", reqData);
  }

  /**
   * Загрузить данные о репозитотрии
   * 
   * @param {String} repositoryId id репозитория
   * @returns 
   */
  async getRepository(repositoryId) {
    const reqData = { params: [repositoryId] };
    const data = await this.request("getRepository", reqData);
    return data;
  }
}

export default RepoApi;
