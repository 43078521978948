import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { components } from "@ais3p/ui-framework";
import AisObject from "~/core/components/AisObject";
import { getClassTitle } from "~/core/constants/Classes";
import DropPlace from "./DropPlace";

import SchemaBoundaryModel from "../../models/schemaBoundary";
import SchemaObjectItemModel from "../../models/schemaObjectItem";

const { Icon } = components;
/**
 * Компонент для отображения записи в последовательном списке объектов
 * 
 * @param {Object} props набор параметров
 * @param {Number} props.index  индекс записи
 * @param {SchemaObjectItemModel} props.schemaObjectItem  запись Доли в схеме отчетов о трассировке
 * @param {SchemaBoundaryModel} props.boundary  запись ограничения Доли в схеме отчетов о трассировке
 */
const BoundaryItem = observer(({ index, schemaObjectItem, boundary }) => {
  const onClear = useCallback(() => {
    boundary.setValue(undefined);
  }, [schemaObjectItem, boundary]);

  const content = useMemo(() => {
    if (boundary.object) {
      if (!boundary.object.uid && boundary.object.class) {
        // задан только класс объекта
        return (
          <div className="object-class">
            <span>{getClassTitle(boundary.object.class)}</span>
          </div>
        );
      }
      if (boundary.name) {
        return (
          <div className="object-wrapper">
            <AisObject object={boundary.object} icon={boundary.object.icon} withVersion={true} />
            <Icon
              className={"object-clear"} 
              name="clean-M"
              onClick={onClear}
            />
          </div>
        );
      } else {
        return <AisObject object={boundary.object} icon={boundary.object.icon} withVersion={true} />;
      }      
    } else {
      if (boundary.parameter && boundary.parameter.type === "object") {
        return (
          <DropPlace 
            boundary={boundary} 
          />
        );
      }
    }
  }, [boundary.object, boundary.parameter]);
  return (
    <div 
      className="schema-boundary-item"
    > 
      <div 
        className="schema-boundary-item-title"
      >
        <label>{index + 1}.</label>
        <span>{boundary.title}:</span>
      </div>
      <div 
        className="schema-boundary-item-content"
      >
        {content}
      </div>
    </div>
  );
});

DropPlace.propTypes = {
  index:            PropTypes.number,
  schemaObjectItem: PropTypes.instanceOf(SchemaObjectItemModel),
  boundary:         PropTypes.instanceOf(SchemaBoundaryModel).isRequired
};


export default BoundaryItem;
