import React, { useMemo }  from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FILTERS_CFG } from "../../constants/config";
import { CONDITION_EQUAL, conditionIcon } from "../../constants/condtions";
import SearchStore from "../../stores/searchStore";

import { useStores } from "~/core/utils";

/**
 * Панель для отображения информаци о выставленных фильтрах
 * 
 * @param {Object} params набор параметров
 * @param {String} params.className пользовательский className контрола
 * @param {SearchStore} params.store хранилизе для поиска
 */
const FilterInfo = observer(({ className, store }) => {
  const { kindsStore } = useStores();
  const kindUid = store.getItemConfig(FILTERS_CFG).kind;
  const kind = kindsStore.getKind(kindUid);
  const version = store.getItemConfig(FILTERS_CFG).version;
  const filters = {
    ...(store.getItemConfig(FILTERS_CFG).codes || {}),
    ...(store.getItemConfig(FILTERS_CFG).attributes || {})
  };
  const list = useMemo(() => {
    const res = [];
    if (version && version.value) {
      res.push({
        label:     "Версия",
        value:     version.value,
        condition: version.condition
      });
    }
    Object.keys(filters).forEach((key) => {
      if (filters[key].type === "boolean" || filters[key].value) {
        res.push(filters[key]);
      }
    });
    if (kind) {
      res.unshift({
        label:     "Вид",
        value:     kind.name,
        condition: CONDITION_EQUAL
      });
    }
    return res;
  }, [filters, version, kind]);

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      const val = value.map((item) => {
        return String(item.value).trim();
      }); 
      return `[${val.join(", ")}]`;
    } else if (typeof value === "object") {
      return `[${String(value.value).trim()}]`;
    }

    return String(value).trim();
  };

  return (
    <div className={classNames("filters-info", className)}>
      {list.length === 0 && 
        <div className="filters-info-empty">
          <span>Фильтры не заданы</span>
        </div>
      }
      {
        list.map((item, i) => {
          return (
            <div key={i} className="filters-info-item">
              <div className="label">{`${String(item.label).trim()} ${conditionIcon(item.condition)}`}</div>
              <div className="value">{renderValue(item.value)}</div>
            </div>
          );
        })
      }
    </div>
  );
});

FilterInfo.propTypes = {
  className: PropTypes.string,
  store:     PropTypes.instanceOf(SearchStore) 
};
export default FilterInfo;
