/**
 * Набор констант для сохранения конфигурации
 */
const MODULE_NAME_CFG = "tracer";
const FILTERS_CFG = "filters";

export {
  MODULE_NAME_CFG,
  FILTERS_CFG
};
