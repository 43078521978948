import { observable, action, computed } from "mobx";
import RelationApi from "../api/relationsApi";

import { Relation, Half, Kind } from "../models";
import Library from "../../library/tools/libraryTree/components/Library";
import { isUid } from "~/core/utils/uid";

class RelationStore {
  @observable
  relations = new Map();
  @observable
  starts = new Map();
  @observable
  ends = new Map();
  @observable
  kinds = new Map();
  @observable
  kindsByUid = new Map();

  @observable
  selectedId;

  @observable
  pending = false;

  constructor(root) {
    this.rootStore = root;
    this.objectStore = this.rootStore.objectStore;
    this.api = new RelationApi(root);

    this.kinds.set(0, Kind.create({
      uid:  0,
      name: "Список видов связей пуст"
    }));

    // this.getKinds();
  }

  @action
  getKind(kindId) {
    return this.kinds.get(kindId);
  }

  @action
  getTypeByName(name) {
    return this.kinds.get(name);
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  getKindByUid(kindUid) {
    return this.kindsByUid.get(kindUid);
  }

  @action
  async getKinds() {
    this.setPending(true);
    try {
      const kindData = await this.api.getKinds();
      this.processKinds(kindData);
    } catch (error) {
      this.rootStore.setErrorText(error.message);
    } finally {
      this.setPending(false);
    }
  }

  @action
  processKinds(kindData) {
    this.kinds = new Map();
    kindData.forEach((kind) => {
      const kindObj = Kind.create(kind);
      this.kinds.set(kind.name, kindObj);
      this.kindsByUid.set(kind.uid, kindObj);
    });
  }

  @action
  async connect(start, end, kindName) {
    this.setPending(true);
    try {
      const type = this.getTypeByName(kindName);
      if (type) {
        const data = await this.api.bind(type.uid, start, end);
        await this.processRelation(data);
      }
    } catch (ex) {
      this.rootStore.setErrorText(ex.message);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Загрузить список связей по набору `uid`ов объектов
   * @param {Array<String>} uids массив `uid`ов объектов
   * @param {String} version версия
   * @param {Boolean} loadKinds загружать ли виды у объектов, которые учавствуют в связях. По-умолчанию `true`.
   * Но если объектов много, то загрузку видов объектов, необходимо сделать позже одним bulk запросом - `false`
   * @returns 
   */
  @action
  async getBatchRelations(uids, version, loadKinds = true) {
    this.setPending(true);
    try {
      this.selectedId = undefined;
      await this.fetchRelations(uids, version, loadKinds);
    } finally {
      this.setPending(false);
    }
  }

  @action
  selectItem(uid) {
    this.selectedId = uid;
  }

  @action
  async getRelations(uid, version = 0) {
    this.setPending(true);
    try {
      this.selectedId = undefined;
      await this.fetchRelations([uid], version);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Загрузить список связей по набору `uid`ов объектов
   * @param {Array<String>} uids массив `uid`ов объектов
   * @param {String} version версия
   * @param {Boolean} loadKinds загружать ли виды у объектов, которые учавствуют в связях. По-умолчанию `true`.
   * Но если объектов много, то загрузку видов объектов, необходимо сделать позже одним bulk запросом - `false`
   * @returns 
   */
  @action
  async fetchRelations(uids, version = 0, loadKinds = true) {
    if (!uids) {
      return false;
    }
    let uidsArray = uids;
    if (!uids.length) {
      uidsArray = [uids];
    }
    try { // TODO: change uid to id when backend is ready
      const pairArray = uidsArray.map((id) => {
        return { id, version };
      });

      const relsData = await this.api.getRelations(pairArray);
      await this.processRelations(relsData, loadKinds);
    } catch (error) {
      this.rootStore.setErrorText(error.message);
    }
  }

  @action
  async processRelations(data, loadKinds = true, loadRepresentations = true) {
    const uidPromises = [];
    data &&
      data.forEach((relation) => {
        uidPromises.push(this.processRelation(relation, loadKinds, loadRepresentations));
      });
    const uidArrays = await Promise.all(uidPromises);
    const uids = uidArrays.flat(Infinity);
    
    await this.rootStore.kindsStore.getItems(uids);
  }

  @action
  async getLockInfo(payload) {
    const uid = payload.editable || payload.uid;
    if (!isUid(uid)) {
      // сущность находится вне АИС (нет в nestor)
      return;
    }
    let itemAggr = this.objectStore.get(payload.uid);
    if (!itemAggr) {
      this.objectStore.addVersion(
        { ...payload, etype: payload.objectClass || "text.Indent" },
        Library.domain
      );
      itemAggr = this.objectStore.getVersion(payload.uid);
    }
    const editable = payload && payload.editable;
    if (editable) {
      const editableLock = await this.api.getLockInfo(editable);
      const lock = editableLock.locks;
      if (lock) {
        const { acquired_at: lockedAt, user: lockedBy } = lock;
        let editableAggr = this.objectStore.getVersion(editable);
        if (!editableAggr) {
          this.objectStore.addVersion(
            { uid: editable, etype: "aggr.wm.text" },
            Library.domain
          );
          editableAggr = this.objectStore.getVersion(editable);
        }
        editableAggr &&
          editableAggr.setLockInfo({ lockedBy, lockedAt: new Date(lockedAt) });
      }
    } else if (payload) {
      const itemLock = await this.api.getLockInfo(payload.uid);
      const lock = itemLock.locks;
      if (lock) {
        const { acquired_at: lockedAt, user: lockedBy } = lock;
        itemAggr &&
          itemAggr.setLockInfo({ lockedBy, lockedAt: new Date(lockedAt) });
      }
    }
  }

  // @action
  // async processRelationOld(relation) {
  //   if (!relation) {
  //     return null;
  //   }
  //   const id = relation.uid;
  //   const klass = relation.class;
  //   const start =
  //     relation.source && `${relation.source.uid}-${relation.source.version}`;
  //   const end =
  //     relation.dest && `${relation.dest.uid}-${relation.dest.version}`;
  //   const kind = this.kinds.get(relation.linkType.name);

  //   const startPayload = {
  //     ...relation.source,
  //     domain: relation.source && getDomainByClass(relation.source.class)
  //   };
  //   const endPayload = {
  //     ...relation.dest,
  //     domain: relation.dest && getDomainByClass(relation.dest.class)
  //   };

  //   const items = { [start]: startPayload, [end]: endPayload };
  //   const uids = [];
  //   const promises = [];

  //   if (startPayload.class) {
  //     promises.push(this.getDomainItem(startPayload));
  //     uids.push(relation.source.uid);
  //   }
  //   if (endPayload.class) {
  //     promises.push(this.getDomainItem(endPayload));
  //     uids.push(relation.dest.uid);
  //   }

  //   await Promise.all(promises);

  //   this.setRelation(id, items, start, end, kind, klass);
  //   return uids;
  // }

  // async getDomainItem(itemData) {
  //   try {
  //     await this.objectStore.fetchRepresentation(
  //       itemData.uid,
  //       itemData.domain,
  //       itemData.version,
  //       { withPath: true }
  //     );
  //   } catch (error) {
  //     console.warn(error);
  //   }
  // }

  /**
   * Обработка полученный данных по связи
   *  
   * @param {Object} relationData полученные данные по связям
   * @param {Boolean} loadKinds загружать ли виды у объектов, которые учавствуют в связях. По-умолчанию `true`.
   * Но если связей много, то загрузку видов объектов, необходимо сделать позже одним bulk запросом - `false`
   */
  @action
  async processRelation(relationData, loadKinds = true, loadRepresentations = true) {
    if (!relationData) {
      return null;
    }
    
    const relation = Relation.create(relationData, this);
    await relation.init(this.objectStore, loadKinds, loadRepresentations);
    
    this.setRelation(relation);

    return relation.itemUids;
  }

  @action
  async deleteHalf(item, kind) {
    this.setPending(true);
    try {
      let half;
      const { uid, version } = item;
      if (item.isStart) {
        half = this.starts.get(`${uid}-${version}`);
      } else {
        half = this.ends.get(`${uid}-${version}`);
      }
      let relation;
      if (half) {
        half.rels.forEach((val, key) => {
          const rel = this.relations.get(key);
          if (rel && rel.kind && rel.kind.uid === kind.uid) {
            relation = rel;
          }
        });
      }
      if (relation) {
        await this.api.deleteRelation(relation.id);
        this.relations.delete(relation.id);
      }
    } catch (error) {
      this.rootStore.setErrorText(error.message);
    } finally {
      this.setPending(false);
    }
  }

  // @action
  // setRelationOld(id, items, start, end, kind, klass) {
  //   // сама связь
  //   this.relations.set(
  //     id,
  //     new Relation({ 
  //       uid:    id, 
  //       items, 
  //       starts: [start], 
  //       ends:   [end], 
  //       kind, 
  //       class:  klass
  //     }, this)
  //   );
  //   // хвосты раскладываем в соответствующие мапы для быстрого доступа
  //   // начала - 1 или больше
  //   const relationStart = this.starts.get(start);
  //   if (relationStart) {
  //     // если есть уже инстанс  Half - внутренним методом расширяем
  //     relationStart.extendRels([end], id);
  //   } else {
  //     // если нет инстанцируем Half в мап начал
  //     this.starts.set(
  //       start,
  //       new Half({ id: start, rels: [end], relationId: id }, this)
  //     );
  //   }

  //   // концы(важно знать что есть что тк связи направленные) - 1 или больше
  //   const relationEnd = this.ends.get(end);
  //   if (relationEnd) {
  //     // если есть уже инстанс  Half - внутренним методом расширяем
  //     relationEnd.extendRels([start], id);
  //   } else {
  //     // если нет инстанцируем Half в мап концов
  //     this.ends.set(end, new Half({ id: end, rels: [start], relationId: id }));
  //   }
  // }

  @action
  setRelation(relation) {
    // сама связь
    this.relations.set(relation.id, relation);

    const start = relation.starts[0];
    const end = relation.ends[0];
    // хвосты раскладываем в соответствующие мапы для быстрого доступа
    // начала - 1 или больше
    const relationStart = this.starts.get(start);
    if (relationStart) {
      // если есть уже инстанс  Half - внутренним методом расширяем
      relationStart.extendRels([end], relation.id);
    } else {
      // если нет инстанцируем Half в мап начал
      this.starts.set(
        start,
        new Half({ id: start, rels: [end], relationId: relation.id }, this)
      );
    }

    // концы(важно знать что есть что тк связи направленные) - 1 или больше
    const relationEnd = this.ends.get(end);
    if (relationEnd) {
      // если есть уже инстанс  Half - внутренним методом расширяем
      relationEnd.extendRels([start], relation.id);
    } else {
      // если нет инстанцируем Half в мап концов
      this.ends.set(end, new Half({ id: end, rels: [start], relationId: relation.id }));
    }
  }

  @computed
  get relationKinds() {
    const kinds = [{}];

    this.kinds.forEach((kind) => {
      kinds.push(kind);
    });
    return kinds;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get kindsForSelect() {
    const kindsArray = [];
    this.kinds.forEach((kind) => {
      kindsArray.push({
        value: kind.uid,
        label: kind.name
      });
    });
    return kindsArray;
  }

  @action
  hasConnections(id, version = 0) {
    const connections = this.getGroupedConnections(id, version);
    return connections && connections.length > 0;
  }

  @action
  getConnections(id, version = 0) {
    const starts = this.starts.get(`${id}-${version}`);
    const ends = this.ends.get(`${id}-${version}`);

    return { starts, ends };
  }

  @action
  getGroupedConnections(id, version = 0) {
    const start = this.starts.get(`${id}-${version}`);
    const end = this.ends.get(`${id}-${version}`);

    const connections = {};

    if (start) {
      start.rels.forEach((itemIds, relationId) => {
        if (!connections[relationId]) {
          connections[relationId] = [];
        }
        itemIds.forEach((itemId) => {
          connections[relationId].push({ id: itemId, isStart: false });
        });
      });
    }

    if (end) {
      end.rels.forEach((itemIds, relationId) => {
        if (!connections[relationId]) {
          connections[relationId] = [];
        }
        itemIds.forEach((itemId) => {
          connections[relationId].push({ id: itemId, isStart: true });
        });
      });
    }

    const result = [];
    Object.keys(connections).forEach((relId) => {
      const relation = this.relations.get(relId);
      const itemArray = connections[relId];
      if (relation) {
        const kind = relation.kind;
        const relItems = relation.items;
        const items = [];
        itemArray.forEach((item) => {
          const itemObj = relItems.get(item.id);
          items.push({ ...itemObj, isStart: item.isStart });
        });

        result.push({
          kind,
          items
        });
      }
    });

    return result;
  }

  getTableData(id) {
    const data = [];
    if (this.hasConnections(id)) {
      const grouped = this.getGroupedConnections(id);
      grouped.forEach((kind) => {
        const items = kind.items;
        items.forEach((item) => {
          data.push({
            id:         item.id,
            isStart:    item.isStart,
            kind:       kind.kind,
            kindName:   kind.kind.name,
            item,
            isSelected: item.id === this.selectedId,
            role:       item.isStart ? kind.kind.start : kind.kind.end
          });
        });
      });
    }
    return data;
  }
}

export default RelationStore;
