/**
 * Нвбор констант для сохранения конфигурации
 */
const MODULE_NAME_CFG = "issues";
const FILTERS_CFG = "filters";
const JOURNAL_CFG = "journal";
const TABLE_COLUMNS_CFG = "table-columns";

export {
  MODULE_NAME_CFG,
  FILTERS_CFG,
  JOURNAL_CFG,
  TABLE_COLUMNS_CFG
};
