/**
 * Получить набор стилей из выбранной темы для языка программирования
 * 
 * @param {Object} theme выбранная тема
 * @param {String} language тип языка
 * @returns 
 */
const themeToDict = (theme, language) => {
  const { plain } = theme;

  const base = {};

  const themeDict = theme.styles.reduce((acc, themeEntry) => {
    const { languages, style } = themeEntry;
    if (languages && !languages.includes(language)) {
      return acc;
    }

    themeEntry.types.forEach((type) => {
      const accStyle = { ...acc[type], ...style };

      acc[type] = accStyle;
    });

    return acc;
  }, base);

  themeDict.root = (plain);
  themeDict.plain = ({ ...plain, backgroundColor: null });

  return themeDict;
};

export default themeToDict;
