import { observer } from "mobx-react";
import React, { useCallback, useMemo } from "react";
import AisIcon from "~/core/components/AisIcon";
import TableDimentions from "./TableDimentions";

const PlusMenuItem = observer(({ item, onItemClick }) => {
  const { icon, title, class: className, availableKindsArray } = item;
  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      onItemClick && onItemClick(item);
    },
    [item]
  );

  const onClickWithKind = useCallback(
    (e, kind) => {
      e.stopPropagation();
      onItemClick && onItemClick(item, kind);
    },
    [item]
  );

  const onClickDimention = useCallback(
    (x, y) => {
      onItemClick && onItemClick({ ...item, "@columns": x, "@rows": y });
    },
    [item]
  );

  const iconRender = useMemo(() => {
    if (className === "text.container.EnumerationItem") {
      return (
        <div  
          className="list-bullet-render"
        ></div>
      );
    }
    return <AisIcon className={"add-icon"} icon={icon} />;
  }, [icon, className]);

  const dimentions = useMemo(() => {
    if (className !== "text.element.Table") {
      return null;
    }
    return <TableDimentions onClick={onClickDimention} />;
  }, [className, onClickDimention]);

  if (availableKindsArray && availableKindsArray.length) {
    return (
      <div className="items-holder">
        {availableKindsArray.map((kind) => {
          return (
            <div
              key={kind.kind}
              onClick={(e) => { // eslint-disable-line
                onClickWithKind(e, kind);
              }} 
              className="add-item"
              title={kind.kind === "type-only" ? title : kind.kind}
            >
              {kind.kind === "type-only" ? iconRender : <AisIcon className={"add-icon"} icon={kind.icon} />}
              {title && <div className="number">{title}</div>}
              {dimentions}
            </div>
      
          );
        })}
      </div>
    );
  } else if (availableKindsArray && !availableKindsArray.length) {
    return null;
  }

  return (
    <div 
      onClick={onClick}  
      className="add-item"
    >
      {iconRender}
      {title && <div className="number">{title}</div>}
      {dimentions}
    </div>
  );
});

export default PlusMenuItem;
