import { action, computed } from "mobx";

import Indent from "./Indent";

class CodeLine extends Indent {
  @action
  createChunk() {
    if (!this.store) {
      return null;
    }
    const newId = this.store.getUid();
    const chunk = this.store.createItem(
      { uid: newId, class: "text.chunk.InlineCode", value: "", isNew: true },
      this
    );
    return chunk;
  }

  @computed
  get lang() {
    return (this.parent && this.parent.lang) || null;
  }

  @computed
  get slug() {
    return "code-lines";
  }

  @computed
  get category() {
    return "code-lines";
  }

  @computed
  get innerHierarchyItemsArray() {
    const array = [
      {
        class:     "text.element.CodeLine",
        isSibling: true,
        isNew:     true,
        ancorId:   this.uid
      }
    ];
    return array;
  }

  @computed
  get removedDescendantListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (this.nextSibling && this.nextSibling.diffClass === "removed") {
      return [this.nextSibling, ...this.nextSibling.removedDescendantListItemsArray];
    }
    if (this.diffCompatitor.nextSibling && this.diffCompatitor.nextSibling.diffClass === "removed") {
      return [this.diffCompatitor.nextSibling, ...this.diffCompatitor.nextSibling.removedDescendantListItemsArray];
    }
    return [];
  }
  
  @computed
  get output() {
    return {
      class:       this.className,
      uid:         this.uid,
      chunks:      [],
      "@position": this.position
    };
  }
}

export default CodeLine;
