import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";
import SplitPane from "react-split-pane";

import useStores from "~/core/utils/useStores";

import SearchStore from "../stores/searchStore";
import Toolbar from "../components/toolbar/Toolbar";

import "./css/search-entities.scss";
import FilterInfo from "../components/filters/FilterInfo";
import EntitiesTable from "../components/entities/EntitiesTable";
import FilterPanel from "../components/filters/FilterPanel";


const MIN_WINDTH = 300;

/**
 * Инструмент для поиска сущностей АИС по коду и виду с атрибутами
 * 
 * @param {Object} params набор параметров
 * @param {LayoutItem} params.layoutItem панель layout
 * @param {LayoutStore} params.layoutStore хранилище layout
 */
const SeachEntitiesTool = observer(({ layoutItem, layoutStore }) => {
  const elRef = useRef();
  const [size, setSize] = useState(MIN_WINDTH);

  const { rootStore } = useStores();

  const store = useMemo(() => {
    return new SearchStore(rootStore);
  }, []);

  const maxSize = useMemo(() => {
    if (elRef && elRef.current) {
      const rec =  elRef.current.getBoundingClientRect();
      return rec.width * 0.75;
    }

    return MIN_WINDTH * 2;
  }, [elRef && elRef.current]);

  useEffect(() => {
    init();
  }, []);

  const init = async() => {
    await store.init();
  };

  const onResize = useCallback((newSize) => {
    setSize(newSize);
    // скрываем пикер выбора условий
    store.setConditionPickerData(null);
  }, []);

  return (
    <div 
      className="search-entities-tool"
      ref={elRef}
    >
      <Toolbar store={store} />
      <div className="search-entities-tool-content">
        <SplitPane
          split="vertical"
          minSize={store.isShownFilters ? MIN_WINDTH : 0}
          maxSize={maxSize}
          size={store.isShownFilters ? size : 0}
          style={{ flex: 1 }}
          allowResize={store.isShownFilters}
          onChange={onResize}
          primary="second"
        > 
          <div className="search-entities-tool-body">
            <FilterInfo
              store={store}
            />
            <EntitiesTable
              layoutItem={layoutItem}
              layoutStore={layoutStore}
              store={store}
            />
          </div>
          
          <FilterPanel
            store={store}
          />
        </SplitPane>
      </div>
    </div>
  );
});

SeachEntitiesTool.propTypes = {
  layoutItem:  PropTypes.object,
  layoutStore: PropTypes.object
};

export default SeachEntitiesTool;
