import React from "react";
import PropTypes from "prop-types";
import MarkdownView from "../view/MarkdownView";

/**
 * Компонет для отображения комментария в записи журнала изменений
 * 
 * @param {Object} props набор параметров
 * @param {String} params.notes текст комментария
 * @param {String} params.className пользовательский className
 */
const Notes = ({ notes, className }) => {
  return (
    <div className={`journal-item-notes ${className ? className : ""}`}>
      <div className="journal-item-notes-body">
        <MarkdownView text={notes} />
      </div>
    </div>
  );
};

Notes.propTypes = {
  notes:     PropTypes.string,
  className: PropTypes.string
};

export default Notes;
