import { observable, action, computed, toJS } from "mobx";
import { DOMAIN_LIBRARY } from "../../../core/constants/Domains";
import AisVersion from "../../../core/data/models/AisVersion";

class NodeItem extends AisVersion {
  @observable uid = null;
  @observable domain = "library";
  @observable tool = "library";
  @observable children = null;
  @observable etype = null;
  @observable name = null;
  @observable parentUid = null;
  @observable path = [];
  @observable forms = null;
  @observable number = 0;
  @observable permissions = new Map();


  constructor(props) {
    const etype = props.etype || props.class;
    super({ ...props, etype }, props.objectStore);

    this.etype = etype;
    this.update(props);
  }

  @action
  update({ name, editable, parent, children, forms, path, number, meta }) {
    if (name !== undefined) {
      this.name = name;
    }
    if (editable !== undefined) {
      this.editable = editable;
    }
    if (parent !== undefined) {
      this.parentUid = parent;
    }
    if (children !== undefined) {
      this.children = children;
    }
    if (forms !== undefined) {
      this.forms = forms;
    }
    if (path !== undefined) {
      this.path = path;
    }
    if (number !== undefined) {
      this.number = number;
    }
    if (forms !== undefined) {
      this.forms = forms;
    }
    if (meta && meta.permissions) {
      this.permissions.replace(meta.permissions);
    }
  }

  @computed
  get class() {
    return this.etype;
  }

  @computed
  get contentType() {
    if (!this.forms || !this.forms.length) {
      return null;
    }
    let contentType = null;
    this.forms.forEach((form) => {
      if (form.contentType) {
        contentType = form.contentType.split(";")[0];
      }
    });
    return contentType;
  }

  @computed
  get fileType() {
    const fileType = "download";
    const type = this.contentType.split("/")[0];
    if (this.contentType === "application/pdf" || type === "text") {
      return "iframe";
    }
    if (type === "image") {
      return "image";
    }
    return fileType;
  }

  @computed
  get fileId() {
    if (!this.forms || !this.forms.length) {
      return null;
    }
    let id = null;
    this.forms.forEach((form) => {
      if (form.id) {
        id = form.id;
      }
    });
    return id;
  }

  @computed
  get pathUidSet() {
    return new Set(toJS(this.path));
  }

  @computed
  get title() {
    if (!this.number) {
      return this.name || "";
    }
    return `${this.name || ""} - ${this.parent && this.parent.name}`;
  }

  @computed
  get parent() {
    return (
      this.parentUid &&
      this.objectStore.getVersion(this.parentUid, DOMAIN_LIBRARY)
    );
  }
}

export default NodeItem;
