import { action, computed, observable } from "mobx";
import { DOMAIN_KINDS } from "../../constants/Domains";
import Library from "../../../modules/library/tools/libraryTree/components/Library";

/**
 *
 * @type {AisVersion}
 */
class AisVersion {
  @observable
  uid = null;

  @observable
  domain = null;

  @observable
  version = 0;

  @observable
  pending = null;

  @observable
  objectStore = null; // ObjectStore

  @observable
  validatorsMap = new Map();
  /**
   * Конструктор
   *
   * @param {Object} params параметры объекта
   * @param {ObjectStore} objectStore хранилище
   */

  constructor(params, objectStore) {
    this.objectStore = objectStore;

    this.uid = `${params.uid}`;
    if (params.domain) {
      this.domain = params.domain;
    }
    if (params.version) {
      this.version = params.version;
    }

    this.setValidatorsMap();
  }

  @action
  validate() {
    const myValidation = this.validatorsMap.get(this.objectStore.rootStore.accountStore.uid);
    if (myValidation) {
      this.validatorsMap.set(myValidation.uid, { ...myValidation, isValidated: true });
    }
    localStorage.setItem(`validation-${this.uid}-${this.domain}-${this.version}`, true);
  }

  @action
  setValidatorsMap() {
    this.objectStore.rootStore.userStore.list.forEach((user) => {
      let isValidated = true;
      if (user.isMe) {
        const savedValidation = localStorage.getItem(`validation-${this.uid}-${this.domain}-${this.version}`);
        if (!savedValidation) {
          isValidated = false;
        }
      }
      this.validatorsMap.set(user.uid, { 
        uid:   user.uid,
        email: user.email, 
        label: user.label, 
        image: user.image, 
        isMe:  user.isMe,
        isValidated
      });
    });
  }
  /**
   * Обновить информацию о блокировке. Если передать undefined, то блокировка снимется
   *
   * @param {Object} data инфорация о блокировке
   * @param {Date} data.lockedAt  дата блокировки
   * @param {String} data.lockedBy uid пользователя, который заблокировал
   */

  @action
  setLockInfo(data) {
    this.representation.setLockInfo(data);
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @computed
  get id() {
    return `${this.uid}-${this.domain}-${this.version}`;
  }

  @computed
  get iconString() {
    return this.kindsIconString;
  }

  @computed
  get kindsRepresentation() {
    return this.objectStore.getVersion(this.uid, DOMAIN_KINDS, this.version) || null;
  }

  @computed
  get kindsIconString() {
    return (this.kindsRepresentation && this.kindsRepresentation.iconString) || null;
  }

  @computed
  get representationId() {
    return `${this.uid}-${this.domain}`;
  }

  @computed
  get objectId() {
    return `${this.uid}`;
  }

  @computed
  get representation() {
    return this.objectStore.getRepresentation(this.representationId);
  }

  @computed
  get object() {
    return this.objectStore.getObject(this.objectId);
  }

  @computed
  get isValidatable() {
    return Library.versionEtypes.has(this.class) 
    || (this.isVersion && this.hasKinds && this.className === "text.container.Rubric");
  }

  @computed
  get isProcessingValidation() {
    return !this.isValidated;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get validatorsList() {
    return Array.from(this.validatorsMap.values());
  }

  @computed
  get isValidated() {
    let isValid = true;
    this.validatorsMap && this.validatorsMap.forEach((user) => {
      isValid = isValid && user.isValidated;
    });
    return isValid;
  }

  /**
   * Класс объекта.
   */
  @computed
  get className() {
    return "AisVersion";
  }
}

export default AisVersion;
