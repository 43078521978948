import { observable, computed } from "mobx";

class Attr {
  @observable name = null;
  @observable required = false;
  @observable className = null;
  @observable uid = null;
  @observable options = [];

  constructor(data, kindStore) {
    this.kindStore = kindStore;
    this.uid = data.uid;
    this.name = data.name;
    this.className = data.class;
    if (data.options && data.options.length) {
      this.options = data.options;
    }
    this.required = data.isRequired;
    this.isMultiple = data.isMultiple;
    this.isReadonly = data.isReadonly;
  }

  @computed
  get type() {
    switch (this.className) {
      case "kindsattrs.StringAttribute":
        return "string";
      case "kindsattrs.IntegerAttribute":
        return "integer";
      case "kindsattrs.FloatAttribute":
        return "float";
      case "kindsattrs.EnumAttribute":
        return "enum";
      case "kindsattrs.BooleanAttribute":
        return "boolean";
      case "kindsattrs.DatetimeAttribute":
        return "datetime";
      case "kindsattrs.DateAttribute":
        return "date";
      default:
        return this.className;
    }
  }

  @computed
  get id() {
    return this.uid;
  }

  @computed
  get opts() {
    return this.options.map((option) => {
      return {
        label: option,
        value: option
      };
    });
  }
}

export default Attr;
