import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { Field } from "@ais3p/ui-framework-old";

import AisIcon from "~/core/components/AisIcon";
import useStores from "~/core/utils/useStores";
import AttrField from "./AttrField";

const AttrsForm = observer(
  ({ itemUid, version, kindUid, disabled, isView, onChange }) => {
    const { kindsStore } = useStores();

    const kind = useMemo(() => {
      return kindsStore.getKind(kindUid);
    }, [kindUid]);

    const item = useMemo(() => {
      if (!kindsStore.isPendingData) {
        return kindsStore.getItem(itemUid, version);
      }
    }, [itemUid, kindsStore.isPendingData]);

    const member = useMemo(() => {
      if (!item) {
        return null;
      }
      let member = item.getKind(kindUid);
      if (!member) {
        member = item.addKind(kindUid);
      }
      return member;
    }, [item, item && item.pending, kindUid]);

    const onFieldChange = useCallback(
      (valueArray, valid, fieldProps) => {
        let value = valueArray;
        if (
          fieldProps.isMulti &&
          fieldProps.type === "enum" &&
          valueArray &&
          valueArray.length
        ) {
          value = valueArray.map((val) => {
            return val.value;
          });
        } else if (!fieldProps.isMulti && fieldProps.type === "enum") {
          if (valueArray && valueArray.value) {
            value = [valueArray.value];
          } else {
            value = [];
          }
        }
        const { id } = fieldProps;
        member && member.setAttrValue(id, value, valid);
        if (member && onChange) {
          onChange(member);
        }
      },
      [member, kindUid]
    );

    const fields = useMemo(() => {
      const fields = [];

      kind.attributes.forEach((attr, i) => {
        fields.push(
          <AttrField
            key={attr.id}
            attrUid={attr.id}
            attr={attr}
            index={i}
            kind={kind}
            item={item}
            member={member}
            isView={isView}
            kindUid={kindUid}
            itemUid={itemUid}
            version={version}
            hasUid={member && member.hasUid}
            disabled={disabled || isView}
            onChange={onFieldChange}
          />
        );
      });
      return fields;
    }, [
      kind,
      item,
      onFieldChange,
      disabled,
      member,
      itemUid,
      kindUid,
      version
    ]);

    const showCodes = useMemo(() => {
      return member && member.hasUid;
    }, [member && member.hasUid]);

    const codesRender = useMemo(() => {
      const codes = [];
      kind.codesArray.forEach((code) => {
        if (code) {
          codes.push(
            <Field.String
              leftIcon={<AisIcon icon={"data-integer-M"} />}
              key={code.uid}
              readOnly={true}
              id={code.uid}
              style={{ zIndex: kind.attributes.length || 1 }}
              label={code.name}
              labelOnTop={true}
              value={
                member && member.codesObject && member.codesObject[code.uid]
              }
            />
          );
        }
      });
      return codes;
    }, [member && member.codesObject, kind && kind.codesArray]);

    if (!item) {
      return null;
    }

    return (
      <div className="kind-form">
        {showCodes && codesRender}
        {fields}
      </div>
    );
  }
);

export default AttrsForm;
