import React from "react";
import { humanFileSize } from "~/core/utils";

/**
 * Компонент для отрисовка панели с дополнительной информацией о репозитории
 * 
 * @param {Object} params набор параметров
 * @param {String} params.commit номер коммита
 * @param {String} params.author автор коммита
 * @param {String} params.commitMessage пользователькое сообщение о коммите
 * @param {String} params.dateString датаи время сделанного коммита в строковом представлении
 * @param {Number} params.size размер файла из репозитория в байтах
 * 
 * @type {SideKick}
 * @returns {Component}
 */
const SideKick = ({ commit, author, commitMessage, dateString, size }) => {
  return (
    <>
      <td className={"repo-cell commit"}>
        <div 
          className="repo-cell-item"
          // data-tooltip={`Коммит: ${commit}`}
          // data-tooltip-at={"bottom"}
        >
          {commit}
        </div>
      </td>
      <td className={"repo-cell author"}>
        <div 
          className="repo-cell-item"
          // data-tooltip={`Автор: ${author}`}
          // data-tooltip-at={"bottom"}
        >
          {author}
        </div>
      </td>
      <td className={"repo-cell commit"}>
        <div
          className="repo-cell-item"
          data-tooltip={commitMessage}
          data-tooltip-at={"bottom"}
        >
          <p>{commitMessage}</p>
        </div>
      </td>
      <td className={"repo-cell date"}>
        <div className="repo-cell-item">
          {dateString}
        </div>
      </td>
      <td className={"repo-cell size"}>
        { size > 0 && (
          <div className="repo-cell-item">
            {humanFileSize(size)}
          </div>
        )}
      </td>
    </>
  );
};

export default SideKick;
