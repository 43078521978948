import { action, observable, computed } from "mobx";
import getDomainByClass from "~/core/utils/getDomainByClass";
import Kind from "./Kind";
import RelationsApi from "../api/relationsApi";

class Relation {
  @observable uid;
  @observable class;  
  @observable starts = [];
  @observable ends = [];
  @observable kind;
  @observable items = new Map();
  @observable pending = false;

  /**
   * Статичнй метод создания модели Relation
   * Модель создается на основе полученны данных от сервиса `binder`
   * 
   * @param {Object} params набор параметров
   * @param {String} params.uid uid связи
   * @param {String} params.class class связи
   * @param {Object} params.linkType данные вида связи
   * @param {String} params.linkType.uid  uid вида связи
   * @param {String} params.linkType.name  name название вида связи
   * @param {String} params.linkType.class class вида связи
   * @param {String} params.linkType.sourceCaption sourceCaption название начала связи
   * @param {String} params.linkType.destCaption destCaption название конца связи
   * @param {Object} params.linkTypeUid linkTypeUid uid вида связи. Если передан этот параметр и непередан linkType, 
   * то вид связи будет подгружен при вызове метода init
   * @param {Array<String>} params.starts массив uid-ов объектов начала связи
   * @param {Array<String>} params.ends массив uid-ов объектов на конце связи
   * @param {Array<Object>} params.items массив объектов, которые наодтся на коцах связи
   * @param {RelationStore} store локальное хранилище связей
   */
  static create({
    uid,
    class: klass,
    linkType,
    linkTypeUid,
    source,
    dest
  }, store) {
    const start = source && `${source.uid}-${source.version}`;
    const end = dest && `${dest.uid}-${dest.version}`;

    const startItem = {
      ...source,
      domain: source && getDomainByClass(source.class)
    };
    const endItem = {
      ...dest,
      domain: dest && getDomainByClass(dest.class)
    };

    const items = { [start]: startItem, [end]: endItem };
    
    return new Relation({
      uid, 
      class:  klass,
      kind:   linkType && Kind.create(linkType),
      linkTypeUid,
      items, 
      starts: [start], 
      ends:   [end]
    }, store);
  }

  /**
   * Конструктор модели Relation
   * 
   * @param {Object} params набор параметров
   * @param {String} params.uid uid связи
   * @param {String} params.class class связи
   * @param {Kind}   params.kind вид связи
   * @param {Object} params.linkTypeUid linkTypeUid uid вида связи. Если передан этот параметр и непередан kind, 
   * то вид связи будет подгружен при вызове метода init
   * @param {Array<String>} params.starts массив uid-ов объектов начала связи
   * @param {Array<String>} params.ends массив uid-ов объектов на конце связи
   * @param {Array<Object>} params.items массив объектов, которые наодтся на коцах связи
   * @param {RelationStore} store локальное хранилище связей
   */
  constructor({
    uid,
    class: klass,
    kind,
    linkTypeUid,
    starts,
    ends,
    items
  }, store) {
    this.store = store;

    this.uid = uid;
    this.class = klass;
    this.kind = kind;
    this.linkTypeUid = linkTypeUid;
    this.starts = starts;
    this.ends = ends;
    this.items.replace(items);
  }

  /**
   * Инициализация Relation
   * Здесь мы загружаем информацию об объектах, которые находятся на концах связи
   *  
   * @param {ObjectStore} objectStore глобавльно хранилизе объектов
   * @param {Boolean} loadKinds загружать ли виды у объектов
   */
  async init(objectStore, loadKinds = true, loadRepresentations = true) {
    if (!objectStore) {
      throw new Error("В методе init модели Relation не был передан параметр ObjectStore");
    }

    this.setIsPending(true);
    try {
      // если вид связи не задан, а передан только uid этого вида, то запрашиваем вид связи у сервиса
      if (!this.kind && this.linkTypeUid) {
        const api = new RelationsApi(objectStore.rootStore);
        const data = await api.getRelationKind(this.linkTypeUid);
        this.setKind(Kind.create(data));
      }
      if (loadRepresentations) {
        // Загружаем данные об объектах, которые находятся на концах связи
        const promises = [];
        
        Array.from(this.items.values()).forEach((item) => {
          if (item && item.domain) {
            promises.push(objectStore.fetchRepresentation(item.uid, item.domain, item.version, {}, { loadKinds }));
          }
        });
        
        await Promise.all(promises);
      }
    } finally {
      this.setIsPending(false);
    }
  }

  /**
   * Id модели
   * 
   * @return {String}
   */
  @computed 
  get id() {
    return  this.uid;
  }

  /**
   * Домен модели
   * 
   * @return {String}
   */
  @computed 
  get domain() {
    return  getDomainByClass(this.class);
  }

  /**
   *  Массив uid объектов, которые находятся на концах связи
   * 
   * @return {Array<String>}
   */
  @computed 
  get itemUids() {
    const  uids = [];
    this.items.forEach((item) => {
      if (item.uid) {
        uids.push(item.uid);
      }
    });
    return uids;
  }

  /**
   * Флаг, указывающий, что идет инициализация объекта - подгрузка необходимой информации
   * 
   * @return {Boolean}
   */
  @computed 
  get isPending() {
    return  this.pending;
  }

  /**
   * Флаг, указывающий, что идет инициализация объекта - подгрузка необходимой информации
   * 
   * @return {Boolean}
   */
  @computed 
  get title() {
    return  this.class;
  }

  /**
   * Установка значения флага о процессе инициализации
   * 
   * @param {Boolean} value значение
   */
  @action
  setIsPending(value) {
    this.pending = value;
  }

  /**
   * Задать вид связи
   * 
   * @param {Kind} kind вид связи
   */
  @action
  setKind(kind) {
    this.kind = kind;
  }

  /**
   * Удалить объект из связи
   * 
   * @param {Object} объект, который нужно удалить из связи
   */
  @action
  removeItem(itemData) {
    if (
      (itemData.isStart && this.starts.length === 1) ||
      (!itemData.isStart && this.ends.length === 1)
    ) {
      this.destroy();
    } else {
      let half;
      if (itemData.isStart) {
        half = this.store && this.store.starts.get(itemData.id);
        this.starts.remove(itemData.id);
      } else {
        half = this.store && this.store.starts.get(itemData.id);
        this.ends.remove(itemData.id);
      }
      this.items.delete(itemData.id);
      if (half) {
        half.removeRel(this.id, itemData);
      }
    }
  }

  /**
   * Деструктор
   */
  @action
  destroy() {
    this.store && this.store.relations.delete(this.id);
  }
}

export default Relation;
