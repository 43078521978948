import { ContextMenuTrigger, Dnd } from "@ais3p/ui-framework-old";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import AisIcon from "~/core/components/AisIcon";
import useStores from "~/core/utils/useStores";
const TextItemIcon = observer(({ data, onClick }) => {
  const { layoutStore } = useStores();
  const { isFocusUid, isVersion, shortClass } = data;
  const onClickIcon = useCallback(
    (e) => {
      e.stopPropagation();
      onClick && onClick(data);
    },
    [data]
  );

  const contextMenuCollect = useCallback(
    (props) => {
      let menuItems = [
        {
          icon:  "delete-M",
          title: "Удалить",
          data:  { action: "delete" }
        }
      ];
      if (props.item && props.item.className === "text.element.Picture") {
        menuItems.unshift({
          icon:  "editing-M",
          title: "Изменить подпись",
          data:  { action: "editCaption" }
        });
        menuItems.unshift({
          icon:  "editing-M",
          title: "Изменить рисунок",
          data:  { action: "editPicture" }
        });
      }

      if (isVersion) {
        menuItems = [];
      }
      layoutStore.menuCollect({
        menuItems,
        onItemClick: props.onItemClick
      });
      return props;
    },
    [isVersion]
  );

  const onMenuClick = useCallback((e, contextMenuData) => {
    const { action, item } = contextMenuData;
    switch (action) {
      case "delete":
        item.selfDestruct();
        break;
      case "editPicture":
        item.setEditingPicture(true);
        break;
      case "editCaption":
        item.setEditing(true);
        break;
      default:
        break;
    }
  }, []);

  return (
    <ContextMenuTrigger
      item={data}
      isDisabled={true}
      id={"contextMenuId"}
      menuType={"LOCAL"}
      onItemClick={onMenuClick}
      holdToDisplay={-1}
      collect={contextMenuCollect}
    >
      <Dnd.Source
        isRelative={true}
        data={{
          class:   data.className,
          parent:  null,
          tool:    data.domain,
          title:   data.title,
          uid:     data.uid,
          version: data.version
        }}
        uid={data.uid}
        // eslint-disable-next-line react/jsx-no-bind
        canDrag={() => {
          return !data.isVersion;
        }}
        type={Dnd.types.EDITORITEM}
      >
        <AisIcon
          onClick={onClickIcon}
          className={`icon ${shortClass} ${isFocusUid ? "focus" : ""}`}
          item={data}
        />
      </Dnd.Source>
    </ContextMenuTrigger>
  );
});

export default TextItemIcon;
