import React from "react";
import PropTypes from "prop-types";
import TableValue from "./TableValue";

const TableCell = (props) => {
  const {
    column
  } = props;
  return (
    <div
      className={`entities-table-cell ${column.id}-cell`}
    > 
      <TableValue {...props} />
    </div>
  );
};

TableCell.propTypes = {
  column: PropTypes.object 
};


export default TableCell;
