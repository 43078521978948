import React  from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";
import Table from "./Table";

import TraceStore from "../../stores/traceStore";
import TraceSchemaModel from "../../models/traceSchema";


/**
 * Результат GAP анализа или трассировки.
 * @param {Object} props набор параметров
 * @param {String} props.type тип отчета - трассировка("trace")| GAP анализ ("gap")
 * @param {Array<Object>} props.data набор данных
 * @param {TraceStore} props.store хранилище
 * @param {TraceSchema} props.schema схема трассировки
 */
const SchemaData = observer(({ schema, type, store, data = [] }) => {
  if (!schema) {
    return <div className="empty-list">Схема не задана.</div>;
  }
  
  return (
    <div className="schema-data">
      <Table 
        schema={schema}
        type={type}
        store={store}
        data={data}
      />
      {!data.length && type === "gap" &&
        <div className="empty-list">Нет ошибок GAP анализа.</div>
      }
      {!data.length && type === "trace" &&
      <div className="empty-list">Нет связей.</div>
      }
    </div>
  );
});

SchemaData.propTypes = {
  store:  PropTypes.instanceOf(TraceStore).isRequired,
  schema: PropTypes.instanceOf(TraceSchemaModel),
  type:   PropTypes.string,
  data:   PropTypes.arrayOf(Object)
};


export default SchemaData;
