import SchemaObjectItem from "./schemaObjectItem";
import SchemaLinkItem from "./schemaLinkItem";

import { observable, computed, action } from "mobx";

/**
 * Модель схемы отчетов о трассировке
 *
 * @class TraceSchemaModel
 */
class TraceSchemaModel {
  /**
   * uid схемы отчетов о трассировке
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * Название схемы отчетов о трассировке
   *
   * @type String
   */
  @observable
  name = undefined;

  /**
   * Описание схемы таблицы отчетов о трассировке
   *
   * @type String
   */
  @observable
  description = undefined;

  /**
   * json представление схемы
   *
   * @type Object
   */
  @observable
  trace = undefined;

  /**
   * json представление параметров схемы
   *
   * @type Object
   */
  @observable
  parameters = undefined;

  /**
   * uid Вида, для которого должна быть отобрадена схема
   *
   * @type String
   */
  @observable
  applicableToKind = undefined;

  @observable
  itemsMap = new Map();
  

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid uid схемы
   * @param {String} params.applicableToKind uid Вида, для которого должна быть отобрадена схема
   * @param {String} params.name название схемы
   * @param {String} params.description название схемы
   * @param {Object} params.trace json представление схемы
   * @param {Object} params.parameters json представление парметров схемы
   *
   * @retrun {TraceSchemaModel}
   */
  static create(schema, rootStore) {
    return new  TraceSchemaModel(schema, rootStore);
  }

  constructor(schema, rootStore) {
    this.schema = schema;
    
    this.schema.class = "tracer.ParametrizedTraceScheme";

    this.uid = schema.uid;
    this.name = schema.name;
    this.applicableToKind = schema.applicableToKind;
    this.description = schema.description;
    this.trace = schema.trace;
    this.parameters = schema.parameters || [];

    this.rootStore = rootStore;

    (this.trace || []).forEach((item) => {
      if (item.target) {
        this.addItem(SchemaObjectItem.create(item, this.params, rootStore));
      } else {
        this.addItem(SchemaLinkItem.create(item, rootStore));
      }
    });
  }

  @computed
  get id() {
    return this.uid;
  }

  /**
   * Строковое представление схемы
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }

  /**
   * Набор параметров
   *
   * @return {Array<Object>}
   */
  @computed
  get params() {
    const res = {};
    this.parameters.forEach((p) => {
      res[p.name] = p;
    });

    return res;
  }

  /**
   * Добавить запись схемы с список записей
   *
   * @param {SchemaObjectItem | SchemaLinkItem}
   */
  @action
  addItem(item) {  
    this.itemsMap.set(item.uid, item);
  }

  /**
   * Массив объектов для отображения объектов и связей схемы
   */
  @computed
  get items() {
    return Array.from(this.itemsMap.values());
  }

  @computed
  get items2() {
    const result = [];
    (this.trace || []).forEach((item) => {
      if (item.target) {
        result.push(SchemaObjectItem.create(item, this.params, this.rootStore));
      } else {
        result.push(SchemaLinkItem.create(item, this.rootStore));
      }
    });
    return result;
  }

  /**
   * Признак, что схема валидна для трассировки или анализа связей -  те все необходимые параметры заданы
   * 
   * @returns {Boolean}
   */
  @computed
  get isValid() {
    const b = this.items.every((item) => {
      return item.isValid;
    });
    return b;
  }

  /**
   * Набор выставленных значений пользователем
   * 
   * @returns {Object}
   */
  @computed
  get values() {
    let res = {};
    this.items.forEach((item) => {
      res = { ...res, ...item.values };
    });
    return res;
  }

  /**
   * Сбросить все выставленные значения пользователем в схеме
   */
  reset() {
    this.items.forEach((item) => {
      item.reset();
    });
  }

  /**
   * Задать значение в первом же ограничении, где требуется участие пользователя.
   * Этот метод используется, когда трассировку или анализ связей высывается из контекстного меню
   * версии РМ в Библиотеке. Тогда в ограничение подставляется NodeItem
   * 
   * @param {NodeItem} obj нода версии РМ в Библиотеке
   */
  @action
  setFirstBoundaryValue(obj) {
    if (!obj) {
      return;
    }
    // Получаем первый объект, который требует задания параметра
    const schemaObj = this.items.filter((item) => {
      return item.schemaItemType === "object" && !item.isValid;
    })[0];

    if (!schemaObj) {
      return;
    }

    // Получаем первый  boundary, который требует задания параметра
    const boundary = schemaObj.boundaries.filter((b) => {
      return !b.isValid;
    })[0];

    if (!boundary) {
      return;
    }
    
    boundary.setValue(obj);
  }
}

export default TraceSchemaModel;
