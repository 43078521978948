import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import BoundaryItem from "./SchemaBoundaryItem";
import SchemaObjectItemModel from "../../models/schemaObjectItem";

/**
 * Компонент для отображения записи в последовательном списке объектов
 * 
 * @param {Object} props набор параметров
 * @param {SchemaObjectItemModel} props.item  запись Доли в схеме отчетов о трассировке
 */
const TraceItem = observer(({ item }) => {
  return (
    <div 
      className="schema-trace-item"
    >
      <div 
        className="schema-trace-item-title"
      >
        <span>{item.description}</span>
      </div>
      <div 
        className="schema-trace-item-boundaries"
      >
        {item.boundaries.map((b, index) => {
          return (
            <BoundaryItem 
              key={index} 
              index={index}
              schemaObjectItem={item} 
              boundary={b} 
            />
          );
        })}
      </div> 
    </div>
  );
});

TraceItem.propTypes = {
  item: PropTypes.instanceOf(SchemaObjectItemModel).isRequired
};


export default TraceItem;
