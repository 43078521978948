import { observable, computed } from "mobx";

/**
 * Модель схемы отчетов о трассировке
 *
 * @class TraceSchemaModel
 */
class TraceSchemaModel {
  /**
   * uid схемы отчетов о трассировке
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * Название схемы отчетов о трассировке
   *
   * @type String
   */
  @observable
  name = undefined;

  /**
   * Описание схемы таблицы отчетов о трассировке
   *
   * @type String
   */
  @observable
  description = undefined;

  /**
   * json представление схемы
   *
   * @type Object
   */
  @observable
  trace = undefined;

  
  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid uid схемы
   * @param {String} params.name название схемы
   * @param {String} params.description название схемы
   * @param {String} params.trace json представление схемы
   *
   * @retrun {TraceSchemaModel}
   */
  static create({
    uid,
    name,
    description,
    trace
  }) {
    return new  TraceSchemaModel({
      uid,
      name,
      description,
      trace
    });
  }

  constructor(params) {
    this.uid = params.uid;
    this.name = params.name;
    this.description = params.description;
    this.trace = params.trace;
  }

  
  /**
   * @action
   *
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.name название схемы
   * @param {String} params.description описание схемы
   * @param {String} params.trace json представления схемы
   */
  update(data) {
    this.name = data.name || this.name;
    this.description = data.description || this.name;
    this.trace = data.trace || this.trace;
  }

  /**
   * @computed
   *
   * Строковое представление схемы
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }  
}

export default TraceSchemaModel;
