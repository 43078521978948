import Server from "./server";

class ConfigApi extends Server {
  methods = {
    getRestrictions: {
      url:         this.getServiceUrl("admin", "api/v1/restrictions"),
      method:      "GET",
      textProcess: "Получение ограничений...",
      textSuccess: null,
      textError:   this
        .template`Во время получения ограничений произошла ошибка: ${0}`
    }
  };

  async getRestrictions(fromCache = false) {
    try {
      return await this.request(
        "getRestrictions",
        undefined,
        false,
        true,
        false
      );
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }
}

export default ConfigApi;
