import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useRef } from "react";

import AisIcon from "~/core/components/AisIcon";
import Indicator from "../../../core/components/Indicator";

import InlineFormula from "./InlineFormula";
import PlusMenu from "./PlusMenu";
import TextItemIcon from "./TextItemIcon";

const Formula = observer(({ data, renderItem }) => {
  const { uid, inlineItem, caption, isExpanded, scrollItemId, isFocusUid, diffClass } = data;
  const captionRender = caption && renderItem(caption);

  const onClickIcon = useCallback(() => {
    data.setFocusUid();
  }, [data]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current.scrollIntoView(true);
      }, 10); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div id={uid} ref={element} className={`formula element  ${diffClass}`}>
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <AisIcon
          onClick={toggleExpanded}
          icon={"plus-M"}
          className={`expand-menu ${isExpanded ? "expanded" : ""}`}
        />
        <TextItemIcon onClick={onClickIcon} data={data} />
        <div className="formula-body" onClick={onClickIcon}>
          <div className="formula-content">
            {inlineItem && <InlineFormula data={inlineItem} />}
          </div>
          {caption && captionRender}
        </div>
      </div>
      {isExpanded && <PlusMenu data={data} />}
    </div>
  );
});

export default Formula;
