import React from "react";
import { observer } from "mobx-react";

import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

const Table = observer(({ store }) => {
  return (
    <table>
      <TableHeader isTP={store.isTP} isURS={store.isURS} />
      <tbody>
        {store.dataArray.map((item) => {
          return <TableRow key={item.id} isURS={store.isURS} item={item} />;
        })}
      </tbody>
    </table>
  );
});

export default Table;
