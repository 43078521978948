import { observer } from "mobx-react";
import React, { Fragment, useCallback, useLayoutEffect, useRef } from "react";

const Chunk = observer(({ data }) => {
  const {
    uid,
    isEditable,
    isFirst,
    isLast,
    delta,
    store,
    renderValue,
    diffCompatitor,
    valueLength,
    diffClass
  } = data;

  const elementRef = useRef();

  useLayoutEffect(() => {
    if (elementRef && elementRef.current && isEditable) {
      elementRef.current.focus();
      if (delta < 0) {
        // set caret to last position if came from right chunk
        const place = valueLength;
        setCaretAtPosition(place);
        data.setDelta();
      }
    }
  }, [elementRef, isEditable, delta]);

  const setCaretAtPosition = useCallback(
    (position) => {
      if (elementRef && elementRef.current && isEditable) {
        elementRef.current.focus();
        const element = elementRef.current.childNodes[0] || elementRef.current;
        const range = document.createRange();
        range.setStart(element, position);
        range.setEnd(element, position);
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);
      }
    },
    [elementRef, isEditable]
  );

  const saveValue = useCallback(() => {
    if (elementRef && elementRef.current) {
      const value = elementRef && elementRef.current.textContent;
      data.setValue(value, true);
    }
  }, [elementRef]);

  const changeValue = useCallback(() => {
    saveValue();
    store.unsetEditingChunkId();
    data.checkForEmptinessAndRemove();
  }, []);

  const keyPress = useCallback(
    (e) => {
      const { key: code, shiftKey } = e;
      const selection = window.getSelection();
      const value = elementRef && elementRef.current.textContent;
      const { anchorOffset } = selection;
      const isFirstChar = anchorOffset === 0;
      const isLastChar = anchorOffset === value.length;

      if (code === "Backspace") {
        const didRemove = data.checkForSiblingPictureAndRemoveIt(code);
        if (!didRemove) {
          if (!value.length) {
            e.preventDefault();
            data.setPrevChild();
            data.selfDestruct();
          } else {
            if (isFirstChar) {
              e.preventDefault();
              if (isFirst) {
                data.mergeByCondition(-1);
              } else {
                data.setPrevChild();
              }
            }
          }
        }
      } else if (code === "Delete") {
        const didRemove = data.checkForSiblingPictureAndRemoveIt(code);
        if (!didRemove) {
          if (!value.length) {
            e.preventDefault();
            data.setNextChild();
            data.selfDestruct();
          } else {
            if (isLastChar) {
              e.preventDefault();
              if (isLast) {
                data.mergeByCondition(1);
              } else {
                data.setNextChild();
              }
            }
          }
        }
      } else {
        if (code === "Enter") {
          e.preventDefault();
          saveValue();
          data.splitAtPosition(anchorOffset, shiftKey);
        }

        if (new Set(["ArrowLeft", "ArrowUp"]).has(code) && isFirstChar) {
          e.preventDefault();
          saveValue();
          data.setPrevChild(code);
          data.checkForEmptinessAndRemove();
        } else if (
          new Set(["ArrowDown", "ArrowRight"]).has(code) &&
          isLastChar
        ) {
          e.preventDefault();
          saveValue();
          data.setNextChild(code);
          data.checkForEmptinessAndRemove();
        }
      }
    },
    [valueLength, elementRef]
  );

  const setEditing = useCallback((e) => {
    e.stopPropagation();
    data.setEditing();
  }, []);

  if (diffClass === "changed") {
    return (
      <Fragment>
        <span
          id={uid}
          ref={elementRef}
          onClick={setEditing}
          onBlurCapture={changeValue}
          onKeyDown={keyPress}
          contentEditable={isEditable}
          className={`chunk ${diffClass} added ${isEditable && "current"}`}
        >{renderValue}</span>
        {diffCompatitor && (
          <span
            id={"uid-compatitor"}
            className={`chunk ${diffClass} removed`}
          >{diffCompatitor.renderValue}</span>
        )}
      </Fragment>
    );
  }

  return (
    <span
      id={uid}
      ref={elementRef}
      onClick={setEditing}
      onBlurCapture={changeValue}
      onKeyDown={keyPress}
      contentEditable={isEditable}
      className={`chunk ${diffClass} ${isEditable && "current"}`}
    >{renderValue}</span>
  );
});

export default Chunk;
