import React from "react";
import App from "./App";

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  // componentDidMount() {
  // window.onerror = (msg, url, lineNo, columnNo, error) => {
  //   this.setState({
  //     hasError: true,
  //     error,
  //   });

  //   return false;
  // };
  // }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      error
    });
  }

  render() {
    const { hasError, error } = this.state;
    return <App hasError={hasError} error={error} />;
  }
}

export default ErrorBoundary;
