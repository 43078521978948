import { observable, computed } from "mobx";
import KindModel from "~/modules/relations/models/Kind";

/**
 * Модель записи связи в данных отчета о трассировке
 *
 * @class  DataLinkItemModel
 */
class DataLinkItemModel {
  /**
   * uid связи
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * Тип связи
   *
   * @type Object
   */
  @observable
  type = undefined;

  /**
   * Источник начала связи
   *
   * @type Object
   */
  @observable
  source = undefined;

  /**
   * Цель связи
   *
   * @type Object
   */
  @observable
  dest = undefined;

  /**
   * Объект, который находится в левой колонке от колонки связи
   * По этому объкуту, мы будем потом определять направление связи - in | out
   *
   * @type DataObjectItem
   */
  @observable
  leftObject = undefined;

  
  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid uid связи
   * @param {Object} params.type тип связи
   * @param {Object} params.source источник связи
   * @param {Object} params.dest цель связи
   *
   * @retrun {DataLinkItemModel}
   */
  static create({
    uid,
    type,
    source,
    dest
  }, rootStore, leftObject) {
    return new  DataLinkItemModel({
      uid,
      type,
      source,
      dest
    }, rootStore, leftObject);
  }

  constructor(params, rootStore, leftObject) {
    this.uid = params.uid;
    this.type = KindModel.create(params.type);
    this.source = params.source;
    this.dest = params.dest;

    this.leftObject = leftObject; 
    

    this.rootStore = rootStore;
  }

  get dataType() {
    return "link";
  }

  /**
   * Строковое представление связи
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.type.name;
  }

  /**
   * Направление связи - in | out
   *
   * @return {String}
   */
  @computed
  get direction() {
    if (!this.leftObject) {
      return;
    }
    if (this.dest && this.dest.uid === this.leftObject.uid) {
      return "in";
    }

    if (this.source && this.source.uid === this.leftObject.uid) {
      return "out";
    }
    return;
  }
}

export default DataLinkItemModel;
