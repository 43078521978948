/**
 * Library module
 */
import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";

import { v4 as uuid } from "uuid";

import {
  Tree,
  ToolBar,
  Icon,
  Button,
  Modal,
  Tool,
  TreeStore,
  User,
  ContextMenuTrigger
} from "@ais3p/ui-framework-old";

import Target from "~/core/components/Target";
import AisIcon from "~/core/components/AisIcon";
import LibraryApi from "../../../api/LibraryApi";

import { getTimePeriod } from "~/core/utils";

import "./css/index.scss";
import TreeNode from "./TreeNode";
import WFStatus from "./WFStatus";
import VersionForms from "./VersionForms";
import iconRender from "~/core/utils/IconRender";
import { DOMAIN_TEXT, DOMAIN_WF, DOMAIN_LIBRARY } from "~/core/constants/Domains";
import {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS,
  SIDEPANEL_JOURNAL
} from "~/core/constants/SidePanels";
import {
  CLS_LIBRARY_COLLECTION,
  CLS_LIBRARY_TEXT_MATERIAL,
  CLS_LIBRARY_TEXT_VERSION,
  CLS_TEXT_FORM_TEXT
} from "~/core/constants/Classes";
import { SIDEPANEL_VALIDATION } from "../../../../../core/constants/SidePanels";
/**
 * Библиотека -- приложение для навигации между материалами и их коллекциями.
 */

const menu = {
  STATIC: [
    {
      icon:  "refresh-M",
      title: "Обновить",
      type:  "read",
      data:  { action: "refresh" }
    },
    { isDivider: true },
    {
      icon:  "editing-M",
      title: "Переименовать",
      type:  "write",
      data:  { action: "rename" }
    },
    {
      icon:  "cancel-M",
      title: "Удалить",
      type:  "delete",
      data:  { action: "remove" }
    }
  ],
  REPORT: [
    {
      icon:  "open-M",
      title: "Gap анализ",
      type:  "open",
      data:  { action: "openReport" }
    },
    { isDivider: true },
    {
      icon:  "refresh-M",
      title: "Обновить",
      type:  "read",
      data:  { action: "refresh" }
    },
    { isDivider: true },
    {
      icon:  "cancel-M",
      title: "Удалить",
      type:  "delete",
      data:  { action: "remove" }
    }
  ],
  MATERIAL: [
    {
      icon:  "open-M",
      type:  "read",
      title: "Открыть редакцию",
      data:  { action: "openRedaction" }
    },
    { isDivider: true },
    {
      icon:  "refresh-M",
      title: "Обновить",
      type:  "read",
      data:  { action: "refresh" }
    },
    { isDivider: true },
    {
      icon:  "editing-M",
      title: "Переименовать",
      type:  "write",
      data:  { action: "rename" }
    },
    {
      icon:  "cancel-M",
      title: "Удалить",
      type:  "delete",
      data:  { action: "remove" }
    }
  ],
  KINDS: [
    {
      icon:  "editing-M",
      title: "Изменить",
      type:  "write",
      data:  { action: "edit" }
    },
    {
      icon:  "cancel-M",
      title: "Удалить",
      type:  "delete",
      data:  { action: "delete" }
    }
  ],
  COLLECTION: [
    {
      icon:  "collection-create-M",
      title: "Создать...",
      type:  "create"
    },
    {
      icon:     "plus-M",
      isAction: true,
      type:     "library.Upload",
      title:    "Загрузить файл",
      data:     { action: "upload" }
    },
    { isDivider: true },
    {
      icon:     "plus-M",
      isAction: true,
      type:     "library.Upload",
      title:    "Загрузить URS",
      data:     { action: "importUrs" }
    },
    {
      icon:     "app-tree-M",
      title:    "Добавить репозиторий",
      type:     "library.Upload",
      isAction: true,
      children: [
        {
          icon:     "plus-M",
          isAction: true,
          type:     "library.Upload",
          title:    "Добавить новый",
          data:     { action: "addRepoNew" }
        },
        {
          icon:     "list-M",
          isAction: true,
          type:     "library.Upload",
          title:    "Выбрать из списка",
          data:     { action: "addRepo" }
        }
      ]
    },
    { isDivider: true },
    {
      icon:  "refresh-M",
      title: "Обновить",
      type:  "read",
      data:  { action: "refresh" }
    },
    {
      icon:  "open-M",
      type:  "read",
      title: "Открыть в новом окне",
      data:  { action: "openCollection" }
    },
    { isDivider: true },
    {
      icon:  "editing-M",
      type:  "write",
      title: "Переименовать",
      data:  { action: "rename" }
    },
    {
      icon:  "cancel-M",
      type:  "delete",
      title: "Удалить",
      data:  { action: "remove" }
    }
  ],
  BUILDS: [
    {
      icon:  "editing-M",
      title: "Редактировать",
      data:  { action: "rename" }
    },
    { isDivider: true },
    {
      icon:  "cancel-M",
      title: "Удалить",
      data:  { action: "remove" }
    }
  ],
  VERSION: [
    {
      icon:  "open-M",
      type:  "read",
      title: "Открыть",
      data:  { action: "openVersion" }
    }
  ],
  FILE: [
    {
      icon:  "open-M",
      type:  "read",
      title: "Открыть",
      data:  { action: "openFile" }
    },
    { isDivider: true },
    {
      icon:  "cancel-M",
      title: "Удалить",
      type:  "delete",
      data:  { action: "remove" }
    }
  ],
  VERSION_FORM_FILE_WITH_IMPORT: [
    {
      icon:     "download-M",
      isAction: true,
      type:     "library.Download",
      title:    "Скачать",
      data:     { action: "download" }
    },
    {
      icon:     "import-M",
      isAction: true,
      type:     "library.Import",
      title:    "Импортировать",
      data:     { action: "import" }
    }
  ],
  TEXT_FORM: [
    {
      icon:  "open-M",
      type:  "read",
      title: "Открыть",
      data:  { action: "open" }
    },
    {
      icon:     "file-pdf-M",
      isAction: true, // permission required
      type:     "library.Export", // policy: library.action.Export, execute
      title:    "Экспортировать в PDF",
      data:     { action: "exportPDF" }
    },
    {
      icon:     "file-odt-M",
      isAction: true, // permission required
      type:     "library.Export", // policy: library.action.Export, execute
      title:    "Экспортировать в ODT",
      data:     { action: "exportODT" }
    },
    {
      icon:     "file-doc-M",
      isAction: true, // permission required
      type:     "library.Export", // policy: library.action.Export, execute
      title:    "Экспортировать в DOC",
      data:     { action: "exportDOC" }
    },
    {
      icon:     "file-docx-M",
      isAction: true, // permission required
      type:     "library.Export", // policy: library.action.Export, execute
      title:    "Экспортировать в DOCX",
      data:     { action: "exportDOCX" }
    }
  ],
  "3D": [
    {
      icon:  "open-M",
      type:  "read",
      title: "Открыть",
      data:  { action: "open" }
    }
  ],
  REPO: [
    {
      icon:  "open-M",
      type:  "read",
      title: "Открыть",
      data:  { action: "openRepo" }
    },
    { isDivider: true },
    {
      icon:  "editing-M",
      title: "Переименовать",
      type:  "write",
      data:  { action: "rename" }
    },
    {
      icon:  "cancel-M",
      title: "Удалить",
      type:  "delete",
      data:  { action: "remove" }
    }
  ],
  POF: [
    {
      icon:  "open-M",
      type:  "read",
      title: "Открыть",
      data:  { action: "open" }
    }
  ],
  HEADER: [
    {
      icon:  "save-M",
      title: "Сохранить рабочее пространство",
      data:  { action: "save" }
    },
    {
      icon:  "editing-M",
      title: "Изменить",
      data:  { action: "edit" }
    },
    {
      icon:  "delete-M",
      title: "Удалить",
      data:  { action: "delete" }
    }
  ]
};

@inject(
  "layoutStore",
  "rootStore",
  "objectStore",
  "relationStore",
  "uiStore",
  "userStore",
  "kindsStore",
  "accountStore",
  "configStore"
)
@observer
class Library extends Tool {
  static title = "Библиотека";
  static domain = "library";
  static tool = "library";
  static toolId = "library.Library";

  static alienEtypes = new Set(["library.material.Alien"]);
  static pofNodeTypes = new Set([
    "aggr.pof.object",
    "aggr.pof.icd",
    "aggr.pof.sql"
  ]);
  static wmEtypes = new Set([
    "aggr.wm",
    "library.material.Text",
    "library.TextMaterial",
    CLS_LIBRARY_TEXT_MATERIAL,
    "library.material.3dModel",
    "aggr.text.material"
  ]);
  static versionEtypes = new Set([CLS_LIBRARY_TEXT_VERSION]);
  static collectionEtypes = new Set([
    "aggr.library.collection",
    CLS_LIBRARY_COLLECTION
  ]);
  static fileEtypes = new Set(["files.File", "aggr.common.file"]);
  static imageTypes = new Set([
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/webp"
  ]);
  static pdfTypes = new Set(["application/pdf"]);

  static createItems = {
    COLLECTION: [
      {
        icon:  "collection-create-M",
        title: "Коллекция",
        type:  CLS_LIBRARY_COLLECTION
      },
      {
        icon:  "wmaterial-M",
        title: "Текстовый рабочий материал",
        type:  "library.TextMaterial"
      }
    ]
  };

  static getDerivedStateFromError(error) {
    return {
      isError: true,
      error
    };
  }

  /**
   * canDrop File check. Разрешен дроп в коллекцию (создание нового РМ) и дроп в РМ (это создание)
   * версии РМ из файла.
   *
   * @param {object} target data
   */
  static canDropFile(target) {
    return (
      Library.collectionEtypes.has(target.class) ||
      Library.wmEtypes.has(target.etype)
    );
  }

  /**
   * Лечим старые url, которые легли без http://
   * @param {string} uri
   */
  static normalizeURI(uri) {
    return uri.startsWith("http://") ? uri : `http://${uri}`;
  }

  constructor(props) {
    super(props);

    this.api = new LibraryApi(props.rootStore);
    this.treeRef = React.createRef();

    this.setEditingNode = this.setEditingNode.bind(this);
    this.saveNode = this.saveNode.bind(this);
    this.discardNode = this.discardNode.bind(this);
    this.onNodeBlur = this.onNodeBlur.bind(this);
    this.onNodeFocus = this.onNodeFocus.bind(this);
    this.contextMenuCollect = this.contextMenuCollect.bind(this);
    this.onNodeKeyDown = this.onNodeKeyDown.bind(this);
    this.onNodeChange = this.onNodeChange.bind(this);
    this.renderTreeNode = this.renderTreeNode.bind(this);
    this.getNodeData = this.getNodeData.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.addRepo = this.addRepo.bind(this);
    this.catchFile = this.catchFile.bind(this);
    this.moveItem = this.moveItem.bind(this);
    this.copyItem = this.copyItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.refreshItem = this.refreshItem.bind(this);
    this.canDrop = this.canDrop.bind(this);
    this.doOpenMaterial = this.doOpenMaterial.bind(this);
    this.doOpenRedaction = this.doOpenRedaction.bind(this);
    this.doOpenVersion = this.doOpenVersion.bind(this);

    this.doOpenRepo = this.doOpenRepo.bind(this);
    this.doOpenCollection = this.doOpenCollection.bind(this);
    this.doOpenFile = this.doOpenFile.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onVersionFormFileClicked = this.onVersionFormFileClicked.bind(this);
    this.onRetry = this.onRetry.bind(this);
    this.changeCursor = this.changeCursor.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onFormConfirmClick = this.onFormConfirmClick.bind(this);
    this.getNodeContent = this.getNodeContent.bind(this);
    this.createContent = this.createContent.bind(this);
    this.onConnectionsClick = this.onConnectionsClick.bind(this);
    this.onCreateContent = this.onCreateContent.bind(this);
    this.onHandleFileInput = this.onHandleFileInput.bind(this);
    this.createWm = this.createWm.bind(this);
    this.createCallback = this.createCallback.bind(this);
    this.processNodeUpdate = this.processNodeUpdate.bind(this);
    this.invokePofImportForm = this.invokePofImportForm.bind(this);
    this.performFileLoad = this.performFileLoad.bind(this);
    this.performPofImport = this.performPofImport.bind(this);
    this.performUrsImport = this.performUrsImport.bind(this);
    this.catchUrsFile = this.catchUrsFile.bind(this);

    this.processNodeData = this.processNodeData.bind(this);
    this.exportTraceReportPDF = this.exportTraceReportPDF.bind(this);
    this.makeCreateButtons = this.makeCreateButtons.bind(this);
    this.createWithKind = this.createWithKind.bind(this);
    this.createWithType = this.createWithType.bind(this);

    this.createWmWithKind = this.createWmWithKind.bind(this);
    this.createItemCallback = this.createItemCallback.bind(this);
    this.createItemWithKind = this.createItemWithKind.bind(this);

    this.afterCreateWmWithKind = this.afterCreateWmWithKind.bind(this);

    this.renderToolbar = this.renderToolbar.bind(this);
    this.onCreateInRoot = this.onCreateInRoot.bind(this);
    this.onRefresh = this.onRefresh.bind(this);

    this.importUrs = this.importUrs.bind(this);

    this.iconRender = this.iconRender.bind(this);

    this.open = Library.open.bind(this); // открытие новой вкладки(метод из Tool)
    this.getMenu = this.getMenu.bind(this);
    this.setFocusFunc = this.setFocusFunc.bind(this);

    this.fileInput = React.createRef();

    this.showGAPAnalysis = this.showGAPAnalysis.bind(this);
    this.showTraceAnalysis = this.showTraceAnalysis.bind(this);

    this.showIssues = this.showIssues.bind(this);

    this.showDQTrace = this.showDQTrace.bind(this);
    // this.finishTraceReport = this.finishTraceReport.bind(this);
    // this.saveTraceReport = this.saveTraceReport.bind(this);

    this.onSetCursor = this.onSetCursor.bind(this);
    this.afterCreateItem = this.afterCreateItem.bind(this);

    this.moveItemDrag = this.moveItemDrag.bind(this);
    this.copyItemDrag = this.copyItemDrag.bind(this);
    this.toggleSubPanel = this.toggleSubPanel.bind(this);

    this.processPath = this.processPath.bind(this);

    this.store = new TreeStore(props.id);

    this.state = {
      isTracking:         false,
      editingNode:        false,
      editingNodeUid:     false,
      editingNodeContent: false,
      form:               undefined,
      formIsVisible:      false,
      // map с нодами (версиями) формы которого находятся в процессе (например импорта)
      processingNodes:    {}
    };

    this.componentInit(props.id, props.path);
  }

  componentWillReceiveProps(newProps) {
    const { isGlobal, isTracking, hasTrack } = newProps;
    if (isGlobal !== this.props.isGlobal) {
      this.setState({
        isGlobal
      });
    }
    if (isTracking !== this.props.isTracking) {
      this.setState({
        isTracking
      });
    }
    if (hasTrack !== this.props.hasTrack) {
      this.setState({
        hasTrack
      });
    }

    if (newProps.id !== this.props.id) {
      this.store.init("uid", "children", null);
      this.componentInit(newProps.id, newProps.path);
    }
  }

  componentDidMount() {
    if (!this.props.cursor) {
      this.getNodeData(this.props.id);
    }
  }

  componentDidCatch(errorString) {
    this.props.rootStore.uiStore.setErrorText(errorString);
  }

  async componentInit(uid, path = []) {
    if (path && path.length) {
      await this.processPath(path);
    }
  }

  async processPath(path = []) {
    if (path.length) {
      let i = 1;
      await this.getNodeData();
      while (i < path.length) {
        await this.getNodeData(path[i]);
        i += 1;
      }
      const cursor = `/${path.join("/")}`;
      if (
        this.treeRef &&
        this.treeRef.current &&
        this.state.cursor !== cursor
      ) {
        this.treeRef.current.setCursor(cursor);
      }
    }
  }

  setFocusFunc(fn) {
    this.focusFn = fn;
  }

  processMessage(message, data = null) {
    console.warn("Library: processMessage", message, data);
  }

  onSetCursor(cursor = null) {
    if (cursor !== this.state.cursor) {
      this.setState({
        cursor
      });
    }
  }

  iconRender(item, nodeProps, nodeKey) {
    const { objectStore } = this.props;
    const nodeItem = objectStore.getVersion(item.uid, Library.domain);
    const contextMenuId = this.getContextMenuId(
      nodeItem.class,
      nodeItem.editable
    );

    return (
      <ContextMenuTrigger
        item={nodeProps}
        expandedNodes={nodeProps.expandedNodes}
        toggleFunc={nodeProps.toggleFunc}
        parentId={nodeProps.parent && nodeProps.parent.uid}
        itemKey={nodeKey}
        id={"treeId"}
        menuType={contextMenuId}
        onItemClick={this.onMenuClick}
        holdToDisplay={-1}
        collect={this.contextMenuCollect}
      >
        <AisIcon 
          className={`expander big ${!(nodeItem.permissions && 
              nodeItem.permissions.get("read")) ? "disabled" : "enabled"}`} 
          item={nodeItem} 
        />
      </ContextMenuTrigger>
    );
  }

  sendFiles(url, files, target, position = null) {
    const data = new FormData();
    files.forEach((file, i) => {
      data.append(`file${i}`, file);
    });
    data.append(
      "params",
      JSON.stringify({
        user_uid: "00000000000000000000000000000000",
        parent:   target,
        position
      })
    );
    return new Promise((resolve, reject) => {
      fetch(url, {
        method:  "PUT",
        body:    data,
        mode:    "cors",
        headers: {
          Accept: "application/json"
        }
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.errors);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  toggleModal() {
    this.setState({
      formIsVisible: !this.state.formIsVisible
    });
  }

  async performPofImport(item, name, target, position) {
    this.setState({
      pending: true
    });
    const data = await item.store.api.getObject(item.id);
    const alien = await this.api.importPof(data, name, position, target.uid);
    if (alien) {
      this.store.create(alien, target.uid, position);
    } else {
      const { rootStore } = this.props;
      rootStore.uiStore.setErrorText("Could not perform POF import operation.");
    }

    this.setState({
      formType:      undefined,
      form:          undefined,
      pending:       false,
      formIsVisible: false
    });
  }

  async onFormConfirmClick(e, inputs, isValid) {
    const { fileDropData, pofData, formType, repoTargetNodeUid } = this.state;
    if (formType === "repo-import") {
      const name = inputs.name && inputs.name.value;
      let repository =
        inputs.select && inputs.select.value && inputs.select.value.value;
      if (inputs.url) {
        const repoName = inputs.repoName && inputs.repoName.value;
        const url = inputs.url && inputs.url.value;
        const username = (inputs.username && inputs.username.value) || "";
        const password = (inputs.password && inputs.password.value) || "";
        const type =
          inputs.type && inputs.type.value && inputs.type.value.value;
        const repoData = await this.api.addRepoToList({
          name: repoName,
          url,
          username,
          password,
          type
        });
        if (repoData) {
          repository = repoData.id;
        }
      }
      if (repository && repoTargetNodeUid && name) {
        await this.api.addRepoMaterial({
          targetUid:  repoTargetNodeUid,
          repository: `${repository}`,
          name
        });
        this.toggleModal();
        this.setState({
          formType:          undefined,
          repoTargetNodeUid: undefined,
          form:              undefined
        });
        this.getNodeData(repoTargetNodeUid);
      }
    } else if (formType === "pof-import") {
      const { item, target, position } = pofData;
      const name = inputs.name.value;
      this.performPofImport(item, name, target, position);
    } else if (formType === "urs-import") {
      const names = [];
      Object.keys(inputs).forEach((key) => {
        names.push(inputs[key].value || key);
      });
      const { files, target } = fileDropData;
      this.performUrsImport(files, names, target);
    } else {
      const { files, target } = fileDropData;
      if (isValid) {
        const names = [];
        Object.keys(inputs).forEach((key) => {
          names.push(inputs[key].value || key);
        });
        this.toggleModal();
        this.setState({
          formType: undefined,
          form:     undefined
        });
        this.performFileLoad(files, target, names);
      }
    }
  }

  makeCreateButtons(caller) {
    const { kindsStore, accountStore, configStore } = this.props;
    const { permissions } = accountStore;

    const allowedObjects = permissions.get("object");
    let expander = [];

    if (caller) {
      let callerKindItem = kindsStore.getItemSync(caller.uid, 0);
      if (callerKindItem && callerKindItem.kindsSize === 0) {
        callerKindItem = undefined;
      }

      const additionalItems = configStore.getToolConfig(Library.toolId);

      const additionalExpander = [];
      const limitations =
        additionalItems.typeCreate[caller.class || caller.payload.class] || [];

      let kindLimits = [];

      // for TEST only
      // if (limitations.length) {
      //   limitations.push({
      //     as:   "library.TextMaterial",
      //     name: "Рабочий материал"
      //   });
      // }
      // for TEST only

      if (callerKindItem) {
        kindLimits = additionalItems.kindCreate;

        kindLimits.forEach((item) => {
          const kind = kindsStore.getKindByName(item.kind);
          const perm = allowedObjects.get(item.as);
          if (perm && perm.has("create") && kind) {
            const icon = accountStore.getIcon(kind.name);
            const title = kind.name;
            const data = {
              action: "createWithKind",
              kind:   kind.id,
              type:   item.as
            };
            if (
              !callerKindItem ||
              (callerKindItem.allowedKinds &&
                callerKindItem.allowedKinds.has(item.kind))
            ) {
              additionalExpander.push({
                icon,
                title,
                data
              });
            }
          }
        });
      } else {
        limitations.forEach((item) => {
          const perm = allowedObjects.get(item.as);
          if (perm && perm.has("create")) {
            let icon = iconRender({ class: item.as }, true);
            let title = item.name;
            let data = { action: "createWithType", type: item.as };
            if (item.kind) {
              const kind = kindsStore.getKindByName(item.kind);
              icon = accountStore.getIcon(kind && kind.name);
              title = item.kind;
              data = {
                action: "createWithKind",
                kind:   kind && kind.id,
                type:   item.as
              };
            }
            if (
              title &&
              (!callerKindItem ||
                callerKindItem.etype === "aggr.kindsattrs.item.empty" ||
                (callerKindItem.allowedTypes &&
                  !item.kind &&
                  callerKindItem.allowedTypes.has(item.as)))
            ) {
              additionalExpander.push({
                icon,
                title,
                data
              });
            }
          }
        });
      }

      expander = [...additionalExpander];
    }
    return expander;
  }

  contextMenuCollect(props) {
    if (
      this.treeRef &&
      this.treeRef.current &&
      this.state.cursor !== props.itemKey
    ) {
      this.treeRef.current.setCursor(props.itemKey);
    }

    const menuType = props.menuType;
    const caller = props.item.data;
    const expander = this.makeCreateButtons(caller, menuType);
    const menu = this.getMenu(menuType, "create", expander, caller);

    this.props.layoutStore.menuCollect({
      menuItems:   menu,
      onItemClick: props.onItemClick
    });
    return props;
  }

  getMenu(type, itemType, children, caller) {
    const {
      accountStore,
      kindsStore,
      objectStore
      // traceStore
    } = this.props;
    const { permissions } = accountStore;
    let array = [];
    const allowedObjects = permissions.get("object");
    const allowedActions = permissions.get("action");
    const storedItem = objectStore.getVersion(caller.uid, DOMAIN_LIBRARY);

    if (caller) {
      let className = caller.payload.class;
      if (Library.collectionEtypes.has(className)) {
        className = CLS_LIBRARY_COLLECTION;
      } else if (Library.wmEtypes.has(className)) {
        className = "library.TextMaterial";
      }

      const callerObj = allowedObjects && allowedObjects.get(className);

      const menuForType = [...menu[type]];

      menuForType.forEach((item) => {
        if (type === "REPO" || type === "REPORT" || type === "VERSION") {
          array.push(item);
          return true;
        }
        if (
          item.isDivider &&
          array.length > 0 &&
          !array[array.length - 1].isDivider
        ) {
          array.push(item);
        } else {
          let addToMenu = false;
          if (item.isAction && allowedActions) {
            const actPerms = allowedActions.get(item.type);
            if (actPerms && actPerms.has("execute")) {
              if (caller && caller.nodeData && caller.nodeData.forms) {
                const forms = caller.nodeData.forms;
                if (forms.length) {
                  let allow = true;
                  forms.forEach((form) => {
                    if (
                      form.class === "files.File" &&
                      form.contentType === "application/pdf"
                    ) {
                      allow = true;
                    }
                  });
                  if (allow) {
                    addToMenu = true;
                  }
                }
              } else {
                addToMenu = true;
              }
            }
          } else {
            if (callerObj && callerObj.has(item.type)) {
              addToMenu = true;
            }
          }

          if (addToMenu) {
            if (storedItem && storedItem.permissions && 
                (storedItem.permissions.get(item.type) || storedItem.permissions.get(item.type) === undefined)) {
              if (item.type === itemType && children.length !== 0) {
                array.push({ ...item, children });
              } else {
                array.push(item);
              }
            }
          }
        }
      });
      if (array[array.length - 1] && array[array.length - 1].isDivider) {
        array.splice(array.length - 1, 1);
      }

      const kindItem = objectStore.getVersion(caller.uid, kindsStore.domain);
      if (kindItem) {
        array = array.concat(this.additionalActionsByKind(caller.uid, kindItem, type));
      }
      
      if (className === CLS_LIBRARY_TEXT_VERSION) {
        const parentUid = caller.payload.path[caller.payload.path.length - 1];
        const kindItem = objectStore.getVersion(parentUid, kindsStore.domain);
        if (kindItem) {
          array = array.concat(this.additionalActionsByKind(caller.uid, kindItem, type));
        }
      }
      //
    }
    return array;
  }

  /**
   * Добавить дополнительно возможные варианты действий с активной нодой по ее виду
   * @param {String} uid uid ноды
   * @param {KindItem} kind  вид ноды
   * @param {String} menuType тип меню
   * 
   * @return {Array<MenuItem>} массив дополнительных пунктов для контекстного меню
   */
  additionalActionsByKind(uid, kindItem, menuType) {
    const res = [];
    
    let isDQTemplate = false;
    let isQTemplate = false;
    const redmineProjects = [];
    kindItem.kindsArray.forEach((member) => {
      isDQTemplate = isDQTemplate || member.kindName.indexOf("[DQ]") >= 0;
      isQTemplate = isQTemplate || member.kindName.indexOf("[*Q]") >= 0;
      if (member.hasRedmineProject) {
        redmineProjects.push(member);
      }
    });

    if (redmineProjects.length > 0) {
      if (redmineProjects.length === 1) {
        const member = redmineProjects[0];
        res.push({
          icon:     "app-spzi-M",
          isAction: true,
          type:     "execute",
          title:    "Задачи",
          data:     { action: "showIssues", memberUid: member.memberUid }
        });
      }

      if (redmineProjects.length > 1) {
        redmineProjects.forEach((member) => {
          res.push({
            icon:     "app-spzi-M",
            isAction: true,
            type:     "execute",
            title:    `Задачи - ${member.kindName}`,
            data:     { action: "showIssues", schemaUid: member.memberUid }
          });
        });
      }
    }

    if (isDQTemplate || isQTemplate) {
      const array = [];
      if (isDQTemplate) {
        array.push({
          icon:     "domain-M",
          isAction: true,
          type:     "execute",
          title:    "DQ",
          data:     { action: "showDQTrace", schemaUid: "DQ" }
        });
        array.push({
          icon:     "domain-M",
          isAction: true,
          type:     "execute",
          title:    "URS",
          data:     { action: "showDQTrace", schemaUid: "URS" }
        });
      }
      if (isQTemplate) {
        array.push({
          icon:     "domain-M",
          isAction: true,
          type:     "execute",
          title:    "*Q",
          data:     { action: "showDQTrace", schemaUid: "*Q" }
        });
      }
      res.push({
        icon:     "check-M",
        title:    "Чеклисты",
        type:     "checkLists",
        isAction: true,
        children: array
      });
    }

    if (menuType === "VERSION") {
      if (kindItem.traceSchemas.length > 0) {
        res.push({
          icon:     "tracer-gap-analyser-M",
          type:     "read",
          title:    "GAP анализ",
          children: kindItem.traceSchemas.map((schema) => {
            return {
              icon:  "tracer-gap-analyser-M",
              title: schema.title,
              data:  { action: "showGAPAnalysis", schemaId: schema.uid }
            };
          })
        });

        res.push({
          icon:     "tracer-mode-report-M",
          type:     "read",
          title:    "Трассировка",
          children: kindItem.traceSchemas.map((schema) => {
            return {
              icon:  "tracer-mode-report-M",
              title: schema.title,
              data:  { action: "showTraceAnalysis", schemaId: schema.uid }
            };
          })
        });
      }
    }

    if (res.length > 0 || isDQTemplate) {
      res.unshift({ isDivider: true });
    }

    return res;
  }

  createWithKind(contextMenuData) {
    const { item, kind, type } = contextMenuData;
    if (Library.wmEtypes.has(type)) {
      this.createWmWithKind(item, kind);
    } else if (Library.collectionEtypes.has(type)) {
      this.createItemWithKind(item, kind);
    }
  }

  async createItemWithKind(item, kindId) {
    const { layoutStore, kindsStore } = this.props;
    const kind = kindsStore.getKind(kindId);
    const id = uuid();

    const itemToOpen = {
      name:  kind.name,
      id,
      fixed: true,
      props: {
        id,
        label:     kind.name,
        parent:    item.data.uid,
        onSuccess: this.createItemCallback,
        config:    {
          instantConfirm: false,
          initialName:    kind.name,
          nameTitle:      "Название",
          withName:       true,
          views:          [
            {
              type: "kind",
              kindId
            }
          ]
        }
      }
    };

    const tool = {
      icon:      "wizard-M",
      component: "wizard"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  async createWmWithKind(item, kindId) {
    const { layoutStore, kindsStore } = this.props;

    const kind = kindsStore.getKind(kindId);
    const id = uuid();

    const itemToOpen = {
      name:  kind.name,
      id,
      fixed: true,
      props: {
        id,
        label:     kind.name,
        parent:    item.data.uid,
        onSuccess: this.afterCreateWmWithKind,
        config:    {
          instantConfirm: false,
          initialName:    kind.name,
          nameTitle:      "Название",
          withName:       true,
          views:          [
            {
              type: "kind",
              kindId
            }
          ]
        }
      }
    };

    const tool = {
      icon:      "wizard-M",
      component: "wizard"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  async afterCreateWmWithKind(data, parent) {
    const name = data.name;
    const createdWM = await this.api.createText({
      class:   CLS_TEXT_FORM_TEXT,
      rubrics: []
    });
    const editable = createdWM && createdWM[0].uid;
    const result = await this.api.createAis({ name, parent, editable });
    await this.afterCreateItem(result, parent, data.id);
    return data;
  }

  async createWm(item) {
    const name = "Новый рабочий материал";
    const parent = item.data.uid || this.store.rootID;
    const createdWM = await this.api.createText({
      class:   CLS_TEXT_FORM_TEXT,
      rubrics: []
    });
    const editable = createdWM && createdWM[0].uid;
    const data = await this.api.createAis({ name, parent, editable });
    await this.afterCreateItem(data, parent);
    return data;
  }

  async afterCreateItem(data, parent, newColUid = null) {
    const { objectStore, kindsStore } = this.props;
    if (newColUid && data) {
      await kindsStore.changeItemId(newColUid, data.uid);
    }
    const result = await objectStore.fetchRepresentation(
      parent,
      Library.domain,
      0,
      {},
      { force: true }
    );
    if (data) {
      this.store.expand(result.uid, result);
      const cursor = this.state.cursor || "/";
      const cursorItems = cursor.split("/");
      if (
        data &&
        cursorItems.length > 0 &&
        cursorItems[cursorItems.length - 1] &&
        this.treeRef.current
      ) {
        this.treeRef.current.setCursor(`${cursor}/${data.uid}`);
      }
    }
  }
  /**
   * Creates new Collection Node
   *
   * @param {Object} item - parent of new Node
   */
  async createItem(item) {
    const name = "Новая коллекция";
    const parent = item.data.uid || this.store.rootID;
    const data = await this.api.createNode({
      name,
      parent
    });
    await this.afterCreateItem(data, parent);
  }

  /**
   * Creates new Collection Node as some Kind member
   *
   * @param {Object} data - dataObject from Wizard:{newColUid, nameString, selectedNode}
   */
  async createItemCallback(data, parent) {
    const result = await this.api.createNode({ ...data, parent });
    await this.afterCreateItem(result, parent, data.id);
  }

  /**
   * Creates WM item after Wizard call
   *
   * @param {object} data
   * @param {uid} parent
   */
  async createCallback(data, parent) {
    await this.afterCreateItem(data, parent);
  }

  createWithType(contextMenuData) {
    const { item, itemKey, toggleFunc, expandedNodes, type } = contextMenuData;
    if (Library.wmEtypes.has(type)) {
      this.createWm(item);
    } else if (Library.collectionEtypes.has(type)) {
      if (!expandedNodes[itemKey]) {
        toggleFunc(item.data, itemKey).then(() => {
          this.createItem(item, itemKey);
        });
      } else {
        this.createItem(item, itemKey);
      }
    }
  }

  changeCursor(uid) {
    const { objectStore } = this.props;
    const rootId = this.store.rootID;
    const item = objectStore.getVersion(uid, Library.domain);
    let editable = item.editable;
    if (!editable && item.forms && item.forms.length) {
      item.forms.forEach((form) => {
        if (form.class === CLS_TEXT_FORM_TEXT) {
          editable = form.uid;
        }
      });
    }

    const props = {
      uid:         item.uid,
      version:     item.number,
      editable,
      trackedItem: {
        uid:     item.uid,
        parent:  item.number ? item.parentUid : null,
        version: 0,
        class:   item.class,
        tool:    Library.tool
      }
    };
    this.changeContext(rootId, uid, props);
    // this.sendMessage("cursor", { uid, cursor, tool: "Library" }, false);
  }

  doOpenMaterial(item, parent) {
    const { layoutStore } = this.props;

    if (!item.form.uid) {
      return;
    }

    const nameEditorInstance = this.props.objectStore.getVersion(
      parent.name.uid,
      DOMAIN_TEXT
    );

    let name = "Рабочий материал";
    if (nameEditorInstance) {
      name = nameEditorInstance.title;
    } else if (item.title) {
      name = item.title;
    }
    let itemToOpen = {
      name,
      id:    item.form.uid,
      props: {
        name,
        editable: item.form.uid,
        uid:      item.form.uid,
        id:       item.form.uid,
        version:  {
          timestamp: item.nodeData.timestamp,
          number:    item.nodeData.number,
          uid:       item.nodeData.uid
        },
        wmUid: parent.uid
      }
    };

    let tool = {
      icon:      "app-texteditor-M",
      component: "text"
    };

    if (item.class === "3d.Model") {
      itemToOpen = {
        id:    item.form.uid,
        props: {
          label:   name,
          uid:     item.form.uid,
          id:      item.form.uid,
          content: item.content
        }
      };
      tool = {
        icon:      "object-M",
        component: "viewer3d"
      };
    }

    if (item.class === "aliens.pof.Object") {
      itemToOpen = {
        id:    item.form.uid,
        props: {
          label:   name,
          uid:     item.form.uid,
          id:      item.form.uid,
          content: item.content
        }
      };
      tool = {
        icon:      "proxy-obj-M",
        component: "pof"
      };
    }

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  doOpenCollection(item) {
    const { layoutStore } = this.props;
    let name = "Коллекция";
    if (item.title) {
      name = item.title;
    }

    const itemToOpen = {
      name,
      id:    item.data.uid,
      props: {
        label: name,
        uid:   item.data.uid,
        id:    item.data.uid
      }
    };

    const tool = {
      icon:      "app-library-M",
      component: "library"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  doOpenRedaction(item) {
    const { layoutStore } = this.props;

    if (!item.payload || !item.payload.editable) {
      return;
    }

    const itemToOpen = {
      name:  item.name,
      id:    item.payload.editable,
      props: {
        editable: item.payload.editable,
        version:  0,
        uid:      item.uid
      }
    };

    const tool = {
      component: "text"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  doOpenVersion(item) {
    const { layoutStore, objectStore } = this.props;

    const libraryItem = objectStore.getVersion(item.uid, Library.domain);
    if (!item.payload || !item.payload.forms || !item.payload.forms[0]) {
      return;
    }

    const itemToOpen = {
      id:    item.payload.forms[0].uid,
      name:  (libraryItem && libraryItem.title) || item.name,
      props: {
        editable: item.payload.forms[0].uid,
        version:  item.payload.number,
        uid:      item.uid
      }
    };

    const tool = {
      component: "text"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  createContent(name) {
    const content = { uid: name, name };
    return content;
  }

  /**
   * Вызывается при создании нового объекта редактором. Это происходит, когда редактору
   * не был передан `uid`, а был передан текст в параметре `content`.
   * @param {String} uid созданного объекта
   * @param {Element} object созданнный новый объект. Обычно это объект типа `Indent`. Вернул
   * @param {String} parentUid `uid` узла библиотеки для которого создан Name типа `Indent`
   * на всякий случай. Может вдруг пригодится.
   */
  onCreateContent(uid, object, parentUid) {
    this.store.update(parentUid, { name: object });
    this.api.updateNode(parentUid, { name: uid });
  }

  getNodeContent(name) {
    return name;
  }

  /**
   * Update tree from backend.
   *
   * Запросить ноду, добавить keys в ответ для ноды и ее детей, если ключей там нет, и
   * обновить состояние.
   *
   * NOTE: (ai) сюда передаю data из дерева т.к. дерево ничего не должно знать про uid
   *
   * @param {object} data Node's payload
   * @returns {object} валидированный ответ сервера
   */
  async getNodeData(uid) {
    const { objectStore } = this.props;
    this.setState({
      noRepresentation: false
    });
    const { rootStore } = this.props;
    let result;

    try {
      result = await objectStore.fetchRepresentation(uid, Library.domain, 0, {
        tool:   Library.tool,
        rootID: this.store.rootID
      }, { force: true });
    } catch (error) {
      if (error && error.code === 404) {
        this.setState({
          noRepresentation: true,
          gotError:         false
        });
      } else {
        rootStore.uiStore.setErrorText(error.message);
      }
    }
    this.processNodeData(result, uid);
  }

  processNodeData(result, setUid) {
    const uid = setUid || (result && result.uid);
    if (result) {
      this.store.expand(uid, result);
    } else {
      if (!uid) {
        this.setState({
          gotError: true
        });
      }
    }
  }

  refreshItem(item) {
    this.getNodeData(item.uid, true);
  }

  onRetry() {
    this.setState({
      gotError: false
    });
    this.getNodeData(undefined, true);
  }

  onConnectionsClick(itemId) {
    if (this.state.connectionPopupKey !== itemId) {
      this.setState({ connectionPopupKey: itemId });
    } else {
      this.setState({ connectionPopupKey: undefined });
    }
  }
  getContextMenuId(etype, editable) {
    let contextMenuId = "STATIC";
    if (Library.collectionEtypes.has(etype)) {
      contextMenuId = "COLLECTION";
    } else if (Library.wmEtypes.has(etype)) {
      contextMenuId = editable ? "MATERIAL" : "STATIC";
    } else if (Library.versionEtypes.has(etype)) {
      contextMenuId = "VERSION";
    } else if (etype === "library.material.Report") {
      contextMenuId = "REPORT";
    } else if (etype === "library.RepositoryMaterial") {
      contextMenuId = "REPO";
    } else if (etype === "library.FileMaterial") {
      contextMenuId = "FILE";
    }
    return contextMenuId;
  }
  /**
   * renderNode
   *
   * @param {object} nodeData
   * @param {bool} is node in editing mode
   */
  renderTreeNode(nodeProps, nodeKey) {
    // если это версия то у нее должна быть минимум одна форма
    const { userStore } = this.props;
    const nodeData = nodeProps.data;
    const isProcessingNode = this.state.processingNodes[nodeData.uid];

    const isEditing = nodeProps.editingNode === nodeKey;
    const { uid } = nodeData;

    const contextMenuId = this.getContextMenuId(
      nodeData.payload.class,
      nodeData.payload.editable
    );

    const { objectStore } = this.props;
    const wfData = objectStore.getVersion(nodeData.uid, DOMAIN_WF);
    let status;
    let badge;
    let actor;
    let stateCreated;
    if (wfData) {
      const { context } = wfData;
      status = context.status;
      actor = context.actor;
      badge = context.badge;
      stateCreated = context.stateCreated;
    }

    const owner = userStore.get(actor || nodeData.owner);

    return {
      content: (
        <TreeNode
          isVersion={Library.versionEtypes.has(nodeData.payload.class)}
          item={nodeProps}
          nodeKey={nodeKey}
          onMenuClick={this.onMenuClick}
          collect={this.contextMenuCollect}
          menuType={contextMenuId}
          isEditing={isEditing}
          parentUid={nodeData.uid}
          onBlur={this.onNodeBlur}
          onKeyDown={this.onNodeKeyDown}
          onChange={this.onNodeChange}
          onCreateContent={this.onCreateContent}
        />
      ),
      contentColSpan: 1,
      sideKick:       (
        <Fragment>
          <td title={owner && owner.name} className={"wf-user"}>
            {(status || badge) && (
              <User
                avatar={owner && owner.photo}
                photoOnLeft={true}
                name={getTimePeriod(new Date(stateCreated))}
              />
            )}
          </td>
          <td className={`wf-status ${status || badge || ""}`}>
            <WFStatus uid={uid} />
          </td>
          <td className="vf">
            <VersionForms
              onItemClick={this.onMenuClick}
              collect={this.contextMenuCollect}
              onClick={this.onVersionFormFileClicked}
              nodeProps={nodeProps}
              nodeKey={nodeKey}
              isProcessingNode={isProcessingNode}
            />
          </td>
        </Fragment>
      )
    };
  }

  /**
   * canDrop check
   *
   * @param {object} item
   * @param {object} target
   */
  canDrop(item, target) {
    // логическое правило I
    const { objectStore } = this.props;

    const realItem = objectStore.getVersion(item.uid, Library.domain);
    const realTarget = objectStore.getVersion(target.uid, Library.domain);

    if (!realItem || !realTarget) {
      return false;
    }

    if (
      realItem.class === "aggr.wm.version" &&
      Library.wmEtypes.has(realTarget.class)
    ) {
      return false;
    }
    // логическое правило II
    if (
      Library.pofNodeTypes.has(realItem.class) &&
      (Library.collectionEtypes.has(realTarget.class) ||
        Library.alienEtypes.has(realTarget.class))
    ) {
      return true;
    }

    const storeRule = realTarget && !realTarget.pathUidSet.has(realItem.uid);
    const libraryRule = Library.collectionEtypes.has(realTarget.class);
    return storeRule && libraryRule;
  }

  /**
   * Пользователь кликнул на значке формы файла версии, скачиваем файл
   *
   * @param {object} data
   */
  onVersionFormFileClicked(data, nodeData, parent) {
    const { layoutStore, rootStore } = this.props;
    const { class: className, uid, contentType, id } = data;
    const parentName =
      this.getNodeContent(parent.name.uid) &&
      this.getNodeContent(parent.name.uid).title;

    const name = data.name || `${parentName} - ${nodeData.name}`;
    let tool = {
      icon:      "wmaterial-M",
      component: "viewer"
    };

    const item = {
      name,
      id:    uid,
      props: {
        uid,
        id:                uid,
        content:           data.content,
        editable:          uid,
        useGlobalTracking: false,
        version:           {
          timestamp: nodeData.timestamp,
          number:    nodeData.number,
          uid:       nodeData.uid
        },
        wmUid: parent.uid,
        label: name || "Рабочий материал"
      }
    };

    if (className === "aggr.wm.text") {
      tool = {
        icon:      "app-texteditor-M",
        component: "text"
      };
    } else if (className === "3d.Model") {
      tool = {
        icon:      "object-M",
        component: "viewer3d"
      };
    } else if (className === "aliens.pof.Object") {
      tool = {
        icon:      "proxy-obj-M",
        component: "pof"
      };
    } else if (contentType || contentType === "") {
      if (Library.imageTypes.has(contentType)) {
        item.props = {
          type:      "image",
          className: "test-viewer-image",
          name,
          file:      id
        };
      } else if (Library.pdfTypes.has(contentType)) {
        item.props = {
          type:      "PDF",
          className: "test-viewer-pdf",
          name,
          file:      id
        };
      } else {
        item.props = {
          type:      "download",
          className: "test-viewer-download",
          name,
          file:      id,
          icon:      rootStore.iconString(data)
        };
      }
    }
    layoutStore.open({ ...item, ...tool });
  }

  /**
   * renderNode blur callback
   *
   * @param {object} event
   */
  onNodeBlur(parentUid, value) {
    this.onNodeChange(parentUid, value);
    this.discardNode();
  }

  /**
   * renderNode focus callback
   *
   * @param {object} event
   */
  onNodeFocus() {}

  /**
   * renderNode discard changes
   *
   * @param {object} event
   */
  discardNode() {
    this.setState({
      editingNode:        false,
      editingNodeUid:     false,
      editingNodeContent: false
    });
  }

  /**
   * renderNode editing keyDown callback
   * used to save on Enter key pressed
   *
   * @param {object} event
   */
  onNodeKeyDown(e, parentUid, text) {
    e.stopPropagation();
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onNodeChange(parentUid, text);
    } else if (e.keyCode === 27) {
      e.preventDefault();
      this.discardNode(e.target);
    }
  }

  async onNodeChange(uid, name) {
    const { objectStore } = this.props;
    const data = await this.api.updateNode(uid, { name });
    const item = objectStore.getVersion(uid, Library.domain);
    item && item.update(data);
    // this.store.expand(uid, item);
    this.discardNode();
  }

  onHandleFileInput(e) {
    if (this.state.fileTarget) {
      const fileArray = Object.keys(e.target.files).map((key) => {
        return e.target.files[key];
      });
      if (this.state.formType === "urs-import") {
        this.catchUrsFile(fileArray);
      } else {
        this.catchFile(fileArray, this.state.fileTarget);
      }
      this.setState({ fileTarget: undefined });
    }
  }

  async addRepo(target, type) {
    let form = [
      {
        name:       "name",
        title:      "Наименование РМ",
        type:       "text",
        labelOnTop: true,
        required:   true,
        class:      "ais-input-test"
      }
    ];
    if (type === "new") {
      form = form.concat([
        {
          name:         "repoName",
          title:        "Наименование репозитория",
          type:         "text",
          labelOnTop:   true,
          initialValue: "repo-name",
          class:        "ais-input-test"
        },
        {
          name:       "type",
          title:      "Тип",
          type:       "select",
          labelOnTop: true,
          options:    [
            {
              label: "SVN",
              value: "svn"
            },
            {
              label: "GIT",
              value: "git"
            },
            {
              label: "Mercurial HG",
              value: "hg"
            }
          ],
          canDrop: true,
          loading: false,
          class:   "ais-input-test"
        },
        {
          name:         "url",
          title:        "URL репозитория",
          type:         "text",
          labelOnTop:   true,
          initialValue: "https://sample.domain.com",
          class:        "ais-input-test"
        },
        {
          name:       "username",
          title:      "Пользователь",
          type:       "text",
          labelOnTop: true,
          class:      "ais-input-test"
        },
        {
          name:       "password",
          title:      "Пароль",
          type:       "text",
          labelOnTop: true,
          class:      "ais-input-test"
        }
      ]);
    } else {
      const options = [];
      const repos = await this.api.getRepos();
      repos.forEach((repo) => {
        options.push({ label: repo.name, value: `${repo.id}` });
      });
      form = form.concat([
        {
          name:       "select",
          title:      "Репозиторий",
          type:       "select",
          labelOnTop: true,
          options,
          canDrop:    true,
          loading:    false,
          class:      "ais-input-test"
        }
      ]);
    }
    this.setState({
      form,
      repoTargetNodeUid: target.data.uid,
      formType:          "repo-import",
      formIsVisible:     true
    });
  }

  importUrs(target) {
    this.setState({ fileTarget: target, formType: "urs-import" });
    this.fileInput.current.click();
  }

  uploadFile(target) {
    this.setState({ fileTarget: target });
    this.fileInput.current.click();
  }
  /**
   * Catch File drop
   *
   * @param {array} files array
   * @param {object} target data object
   */
  catchFile(files, target) {
    if (files && files.length > 0) {
      const form = files.map((file) => {
        return (        
          {
            name:         file.name,
            title:        `Наименование версии для ${file.name}`,
            type:         "text",
            labelOnTop:   true,
            initialValue: file.name,
            class:        "ais-input-test"
          }
        );
      });

      this.setState({
        form,
        fileDropData: {
          files,
          target
        },
        formIsVisible: true
      });
    }
  }

  /**
   * Catch File drop
   *
   * @param {array} files array
   * @param {object} target data object
   */
  catchUrsFile(files) {
    const target = this.state.fileTarget;
    if (files && files.length > 0) {  
      const form = files.map((file) => {
        return {
          name:         file.name,
          title:        `Наименование версии для ${file.name}`,
          type:         "text",
          labelOnTop:   true,
          initialValue: file.name.substring(0, file.name.lastIndexOf(".")),
          class:        "ais-input-test"
        };
      });
      this.setState({
        form,
        fileDropData: {
          files,
          target
        },
        formIsVisible: true
      });
    }
  }
  
  /**
   * Catch File drop
   *
   * @param {array} files array
   * @param {object} target data object
   */
  invokePofImportForm(item, target, position) {
    const form = [
      {
        name:         "name",
        title:        "Наименование РМ",
        type:         "text",
        labelOnTop:   true,
        initialValue: item.name,
        class:        "ais-input-test"
      }
    ];

    this.setState({
      form,
      pofData: {
        item,
        target,
        position
      },
      formType:      "pof-import",
      formIsVisible: true
    });
  }

  async performFileLoad(files, target, names) {
    let position = null;
    if (target.position) {
      position = target.position;
    }
    const data = await this.api.loadFile(
      target.data.uid,
      position,
      names,
      files
    );
    if (data) {
      this.getNodeData(target.data.uid);
    }
  }

  async performUrsImport(files, names, target) {
    const uid = (target.data && target.data.uid) || target.uid;
    this.setState({
      formIsPending: true
    });
    let data = null;
    const fnames = files.map((file) => {
      return file.name;
    });
    await this.performFileLoad(files, target, fnames);
    try {
      data = await this.api.importUrs(uid, files, names);
    } catch (error) {
      console.warn(error);
    }
    this.setState({
      formType:      null,
      fileDropData:  null,
      formIsPending: false,
      formIsVisible: false
    });
    if (data) {
      this.getNodeData(uid);
    }
  }

  /**
   * renderNode save changes
   *
   * @param {object} event
   */
  saveNode(value, editingNodeUid) {
    this.updateItem(
      { uid: this.state.editingNodeUid || editingNodeUid },
      { name: value }
    );
    this.setState({
      editingNode:        false,
      editingNodeContent: false
    });
  }

  async updateItem(item, reqData) {
    const dataToSave = reqData;
    if (dataToSave.name && dataToSave.name.uid) {
      dataToSave.name = dataToSave.name.uid;
    }
    const data = await this.api.updateNode({ uid: item.uid, data: dataToSave });
    if (data) {
      this.store.update(item.uid, data);
    }
  }

  dragConfirm(type, item, from, to, pos) {
    const position = pos === -1 ? undefined : pos;
    const { uiStore } = this.props;
    const itemData = this.store.get(item.uid);
    const itemName = item && item.name.value;
    const toData = this.store.get(to.uid);
    const toName = to && to.name.value;
    let confirm = <div> Подтвердите действие </div>;
    if (itemData && toData) {
      confirm = (
        <div>
          Вы действительно хотите поместить
          <br />
          <AisIcon item={itemData} />
          {itemName}
          <br />в
          <AisIcon item={toData} />
          {toName}?
        </div>
      );
      uiStore.setConfirm(
        confirm,
        type === "copy" ? "Копирование" : "Перемещение",
        [
          {
            onPress: async() => {
              uiStore.setConfirmPending(true);
              if (type === "copy") {
                await this.copyItem(item, from, to, position);
              } else {
                await this.moveItem(item, from, to, position);
              }
              uiStore.hideConfirm();
            },
            theme: "success",
            icon:  "ok-M",
            text:  "Ok"
          },
          {
            onPress: () => {
              uiStore.hideConfirm();
            },
            theme: "danger",
            icon:  "cancel-M",
            text:  "Отменить"
          }
        ]
      );
    } else {
      this.invokePofImportForm(item, to, position);
    }
  }

  async moveItem(item, from, to, pos = null) {
    const position = pos === -1 ? undefined : pos;
    if (Library.pofNodeTypes.has(item.class)) {
      this.copyItem(item, from, to, position);
      return;
    }
    if (item.class === "aggr.wm.version") {
      this.copyItem(item, from, to, position);
    } else {
      const data = await this.api.updateNode(item.uid, {
        parent:      to.uid,
        position,
        "@position": position
      });
      if (data) {
        this.store.move(item.uid, from, to.uid, position);
        this.updateNodeData(data);
      }
    }
  }

  updateNodeData(data) {
    const { objectStore } = this.props;
    const item = objectStore.getVersion(data.uid, Library.domain);
    item.update(data);
  }

  async copyItem(item, from, to, pos = null) {
    const position = pos === -1 ? undefined : pos;
    if (!Library.pofNodeTypes.has(item.class)) {
      const data = await this.api.copyNode({
        uid: item.uid,
        from,
        to:  to.uid,
        position
      });
      if (data) {
        this.store.copy(item.uid, to.uid, position);
      }
    } else {
      this.invokePofImportForm(item, to, position);
    }
  }

  confirmDelete(item, from) {
    const { uiStore, objectStore } = this.props;
    const storeItem = objectStore.getVersion(item.uid, Library.domain);
    const confirm = (
      <div>
        Вы действительно хотите удалить
        <br />
        <AisIcon item={storeItem} />
        {item.name}
      </div>
    );
    uiStore.setConfirm(confirm, "Удаление", [
      {
        onPress: async() => {
          uiStore.setConfirmPending(true);
          await this.removeItem(item, from);
          uiStore.hideConfirm();
        },
        theme: "positive",
        icon:  "ok-M",
        text:  "Да"
      },
      {
        onPress: () => {
          uiStore.hideConfirm();
        },
        theme: "negative",
        icon:  "cancel-M",
        text:  "Нет"
      }
    ]);
  }

  async removeItem(item, from) {
    const { objectStore } = this.props;
    const requestData = {
      uid: item.uid,
      from
    };
    const storeItem = objectStore.getVersion(item.uid, Library.domain);
    try {
      if (storeItem.class === CLS_LIBRARY_COLLECTION) {
        await this.api.deleteCollection(requestData);
      } else if (storeItem.class === "library.material.Report") {
        await this.api.deleteReport(requestData);
      } else {
        await this.api.removeNode(requestData);
      }
      this.store.remove(item.uid, from);
      const rootId = this.store.rootID;
      this.changeContext(rootId);
    } catch (error) {
      console.warn(error);
    }
  }

  /**
   * Импортировать файл(?)
   *
   * @param {*} fileUID
   * @param {*} versionUID
   */
  async importFile(materialUID, formUID, versionUID) {
    this.setState({
      processingNodes: { ...this.state.processingNodes, [versionUID]: true }
    });
    const data = await this.api.importForm(materialUID, versionUID, {
      source: formUID,
      to:     "library.form.Text" // во что импортировать
    });
    if (data) {
      const processingNodes = { ...this.state.processingNodes };
      delete processingNodes[versionUID];
      this.setState({ processingNodes });
      this.processNodeUpdate(data);
    }
  }

  processNodeUpdate(data) {
    const { objectStore } = this.props;
    const {uid, name, children, ...payload} = data; // eslint-disable-line
    const item = objectStore.getVersion(uid, Library.domain);
    item && item.update(payload);
    this.store.update(uid, payload);
    if (children) {
      children.forEach((child) => {
        this.processNodeUpdate(child);
      });
    }
  }

  /**
   * Export to PDF/ODT/DOC/DOCX файл(?)
   *
   * @param {*} materialUID
   * @param {*} formUID
   * @param {*} versionUID
   */
  async export(format, materialUID, formUID, versionUID) {
    this.setState({
      processingNodes: { ...this.state.processingNodes, [versionUID]: true }
    });
    const data = await this.api.export(
      format,
      materialUID,
      versionUID,
      formUID
    );
    if (data) {
      const { objectStore } = this.props;
      const result = await objectStore.fetchRepresentation(
        data.uid,
        Library.domain,
        0, 
        {
          tool:   Library.tool,
          rootID: this.store.rootID
        }, { force: true }
      );
      const processingNodes = { ...this.state.processingNodes };
      delete processingNodes[versionUID];
      this.store.expand(materialUID, result);
      this.setState({ processingNodes });
    }
  }

  dummie() {}

  setEditingNode(item, key) {
    if (item.class !== "aggr.wm.text") {
      this.setState({
        editingNode:        key,
        editingNodeUid:     item.uid,
        editingNodeContent: item.name
      });
    }
  }

  /**
   * Пользователь кликнул на значке формы файла версии, скачиваем файл
   *
   * @param {object} data
   */
  onVersionFormClicked(data) {
    const uri = Library.normalizeURI(data.uri);
    const link = document.createElement("a");
    link.setAttribute("href", `${uri}`);
    link.setAttribute("download", "download");
    onload = link.click(); // eslint-disable-line
  }

  /**
   * Render toolbar panel.
   *
   * @param {*} controls
   */
  renderToolbar(config) {
    const {
      isRefreshable,
      isCreate,
      isLinks,
      isKinds,
      isComments,
      isJournal,
      isValidate
      // isTrace
    } = config;

    const toolBarButtons = {
      left:  [],
      right: []
    };

    if (isCreate) {
      const uid = this.store.rootID;
      if (uid) {
        const item = {
          uid,
          class: CLS_LIBRARY_COLLECTION,
          data:  { uid, class: CLS_LIBRARY_COLLECTION }
        };
        const contextMenuId = "COLLECTION";

        const createButtons = this.makeCreateButtons(item, contextMenuId);
        createButtons.forEach((button) => {
          if (button.isDivider) {
            toolBarButtons.left.push({
              id:       "spacer1",
              isSpacer: true
            });
          } else {
            toolBarButtons.left.push({
              id:       button.title,
              icon:     button.icon,
              title:    button.title,
              callback: (e) => {
                this.onMenuClick(e, {
                  ...button.data,
                  item,
                  itemKey:       `/${uid}`,
                  expandedNodes: { [`/${uid}`]: true }
                });
              }
            });
          }
        });
      } else {
        toolBarButtons.left.push({
          id:      "loader",
          loading: true
        });
      }
    }
    if (isRefreshable) {
      toolBarButtons.left.push({
        id:       "spacer1",
        isSpacer: true
      });
      toolBarButtons.left.push({
        id:       "refresh",
        icon:     "refresh-M",
        title:    "Обновить",
        callback: this.onRefresh
      });
    }

    if (isLinks || isKinds || isComments) {
      toolBarButtons.right.push({
        id:       "spacer2",
        isSpacer: true
      });
    }
    if (isLinks === true) {
      const tag = SIDEPANEL_RELATIONS;
      toolBarButtons.right.push({
        pressed:  this.props.isSubVisible[tag],
        id:       tag,
        icon:     "app-relations-M",
        title:    "Связи",
        callback: this.toggleSubPanel
      });
    }
    if (isKinds === true) {
      const tag = SIDEPANEL_KINDS_ATTRS;
      toolBarButtons.right.push({
        pressed:  this.props.isSubVisible[tag],
        id:       tag,
        icon:     "app-attributes-M",
        title:    "Виды и атрибуты",
        callback: this.toggleSubPanel
      });
    }

    if (isJournal === true) {
      const tag = SIDEPANEL_JOURNAL;
      toolBarButtons.right.push({
        pressed:  this.props.isSubVisible[tag],
        id:       tag,
        icon:     "log-M",
        title:    "Журнал изменений",
        callback: this.toggleSubPanel
      });
    }

    // if (isTrace === true) {
    //   const tag = SIDEPANEL_TRACE;
    //   buttons.right.push({
    //     pressed:  this.props.isSubVisible[tag],
    //     id:       tag,
    //     icon:     "tracer-gap-analyser-M",
    //     title:    "Анализ связей",
    //     callback: this.toggleSubPanel
    //   });
    // }

    if (isValidate === true) {
      const tag = SIDEPANEL_VALIDATION;
      toolBarButtons.right.push({
        pressed:  this.props.isSubVisible[tag],
        id:       tag,
        icon:     "ok-M",
        title:    "Согласование",
        callback: this.toggleSubPanel
      });
    }

    return <ToolBar buttons={toolBarButtons} />;
  }

  toggleSubPanel(tag) {
    this.props.layoutStore.toggleSubPanel(this.props.tabId, tag);
  }

  onCreateInRoot() {
    this.createItem({ data: {} });
  }

  onRefresh() {
    const { rootID } = this.store;
    this.getNodeData(rootID);
  }

  async doDownloadFile(uid, name = "download") {
    const blobFile = await this.api.getFile(uid);
    const link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.setAttribute("download", name);
    link.setAttribute("href", blobFile);
    link.click();
  }

  async showGAPAnalysis(schemaId, node) {
    const { layoutStore } = this.props;
    const id = uuid();

    const itemToOpen = {
      name:  "GAP анализ",
      id,
      props: {
        uid:         node.uid,
        label:       "GAP анализ",
        trackedItem: {
          uid:     node.uid,
          tool:    Library.tool,
          domain:  DOMAIN_LIBRARY,
          version: node.payload.version
        },
        traceType: "gap",
        schemaId
      }
    };

    const tool = {
      icon:      "tracer-gap-analyser-M",
      component: "traceAnalyzer"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  async showTraceAnalysis(schemaId, node) {
    const { layoutStore } = this.props;
    const id = uuid();

    const itemToOpen = {
      name:  "Трассировка",
      id,
      props: {
        label:       "Трассировка",
        uid:         node.uid,
        trackedItem: {
          uid:     node.uid,
          tool:    Library.tool,
          domain:  DOMAIN_LIBRARY,
          version: node.payload.version
        },
        traceType: "trace",
        schemaId
      }
    };

    const tool = {
      icon:      "tracer-mode-report-M",
      component: "traceAnalyzer"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  async showIssues(objectUid, memberUid) {
    const { layoutStore } = this.props;

    const itemToOpen = {
      name:  "Задачи",
      id:    memberUid,
      props: {
        id:                memberUid,
        label:             "Задачи",
        useGlobalTracking: false,
        trackedItem:       {
          uid:     objectUid,
          tool:    Library.tool,
          version: 0
        }
      }
    };

    const tool = {
      icon:      "app-spzi-M",
      component: "issues"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  async showDQTrace(objectUid, schemaUid) {
    const { layoutStore } = this.props;

    const itemToOpen = {
      name:  `${schemaUid} Check list`,
      id:    schemaUid,
      props: {
        id:                schemaUid,
        label:             `${schemaUid} Check list`,
        useGlobalTracking: false,
        trackedItem:       {
          uid:     objectUid,
          tool:    Library.tool,
          version: 0
        }
      }
    };

    const tool = {
      icon:      "domain-M",
      component: "checkList"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  async exportTraceReportPDF(data, schemaUid) {
    const wmUid = data.uid;
    if (!wmUid) {
      return false;
    }
    this.setState({
      processingNodes: { ...this.state.processingNodes, [wmUid]: true }
    });
    const processingNodes = { ...this.state.processingNodes };
    delete processingNodes[wmUid];
    await this.api.createTraceAnalysis({ wmUid, schemaUid });
    await this.getNodeData(wmUid, true);
    this.setState({ processingNodes });
  }

  onMenuClick(e, contextMenuData) {
    const {
      action,
      item,
      parentId,
      parent,
      itemKey,
      toggleFunc,
      expandedNodes,
      schemaUid
    } = contextMenuData;

    const { data: formData } = item;
    const fileId = formData && formData.form && formData.form.id;
    const fileName = formData && formData.nodeData && formData.nodeData.name;

    switch (action) {
      case "download":
        if (fileId) {
          this.doDownloadFile(fileId, fileName);
        }
        break;
      case "createWithKind":
        this.createWithKind(contextMenuData);
        break;
      case "createWithType":
        this.createWithType(contextMenuData);
        break;
      case "upload":
        this.uploadFile(item);
        break;
      case "addRepo":
        this.addRepo(item, "list");
        break;
      case "importUrs":
        this.importUrs(item);
        break;
      case "addRepoNew":
        this.addRepo(item, "new");
        break;
      case "import":
        this.importFile(parentId, formData.form.uid, formData.nodeData.uid);
        break;
      case "createWM":
        this.createWm(item);
        break;
      case "exportPDF":
        this.export("pdf", parentId, formData.form.uid, formData.nodeData.uid);
        break;
      case "exportODT":
        this.export("odt", parentId, formData.form.uid, formData.nodeData.uid);
        break;
      case "exportDOC":
        this.export("doc", parentId, formData.form.uid, formData.nodeData.uid);
        break;
      case "exportDOCX":
        this.export("docx", parentId, formData.form.uid, formData.nodeData.uid);
        break;
      case "showGAPAnalysis":
        this.showGAPAnalysis(contextMenuData.schemaId, formData);
        break;
      case "showTraceAnalysis":
        this.showTraceAnalysis(contextMenuData.schemaId, formData);
        break;
      case "showIssues":
        this.showIssues(formData.uid, contextMenuData.memberUid);
        break;
      case "showDQTrace":
        this.showDQTrace(formData.uid, contextMenuData.schemaUid);
        break;
      case "exportTraceReportPDF":
        this.exportTraceReportPDF(item.data, schemaUid);
        break;
      case "remove":
        this.confirmDelete(item.data, parentId);
        break;
      case "refresh":
        this.refreshItem(item.data, parentId);
        break;
      case "open":
        this.doOpenMaterial(item, parent);
        break;
      case "openFile":
        this.doOpenFile(item, parent);
        break;
      case "openCollection":
        this.doOpenCollection(item, parent);
        break;
      case "openRepo":
        this.doOpenRepo(item.data);
        break;
      case "openReport":
        this.doOpenReport(item.data);
        break;
      case "openRedaction":
        this.doOpenRedaction(item.data);
        break;
      case "openVersion":
        this.doOpenVersion(item.data);
        break;

      case "createCollection":
        if (!expandedNodes[itemKey]) {
          toggleFunc(item.data, itemKey).then(() => {
            this.createItem(item, itemKey);
          });
        } else {
          this.createItem(item, itemKey);
        }
        break;
      case "rename":
        this.setEditingNode(item.data, itemKey);
        break;
      default:
        break;
    }
  }

  doOpenRepo(item) {
    const { layoutStore } = this.props;
    const id = uuid();
    const trackedItem = {
      ...item,
      tool: Library.tool
    };

    const itemToOpen = {
      name:  item.title,
      id,
      props: {
        label:    item.name,
        editable: item.payload.editable,
        trackedItem
      }
    };

    const tool = {
      icon:      "app-tree-M",
      component: "sourcecode"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  doOpenFile(item) {
    if (!item || !item.data) {
      return;
    }
    const { layoutStore, objectStore } = this.props;
    const id = uuid();
    const repr = objectStore.getVersion(item.data.uid, Library.domain);

    const itemToOpen = {
      name:  item.title,
      id,
      props: {
        type: repr.fileType,
        name: repr.title,
        icon: iconRender(repr, true),
        file: repr.fileId
      }
    };

    const tool = {
      icon:      "app-viewer-M",
      component: "viewer"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  doOpenReport(item) {
    const { layoutStore } = this.props;

    const data = {
      id:          item.id,
      uid:         item.uid,
      editable:    item.editable,
      objectClass: item.objectClass || item.class || item.etype,
      class:       item.objectClass || item.class || item.etype,
      path:        null,
      rootID:      null,
      parent:      item.parent,
      forms:       null
    };

    const trackedItem = {
      ...data,
      data,
      tool: Library.tool
    };

    const itemToOpen = {
      name:  item.title,
      id:    item.id,
      props: {
        id:           item.id,
        trackedItem,
        label:        item.title,
        repositoryId: item.editable
      }
    };

    const tool = {
      component: "traceAnalyzer"
    };

    layoutStore.open({ ...itemToOpen, ...tool });
  }

  moveItemDrag(a, b, c, d) {
    this.dragConfirm("move", a, b, c, d);
  }
  copyItemDrag(a, b, c, d) {
    this.dragConfirm("copy", a, b, c, d);
  }

  render() {
    const {
      tabId,
      hasTarget,
      isTracking,
      isGlobal,
      layoutStore,
      id,
      path,
      version,
      trackedItem,
      trackedName,
      trackedIcon,
      cursor
    } = this.props;
    const { rootID, tree, pending } = this.store;
    const {
      editingNode,
      gotError,
      form,
      formIsVisible,
      formIsPending,
      fileDropData,
      formType,
      isError,
      error,
      noRepresentation
    } = this.state;

    if (isError) {
      return <div className="library">{error.message}</div>;
    }

    let formContent = (
      <span>Для каждого из добавленных файлов будет создана версия</span>
    );
    let formHeader = "Добавление файла";
    if (formType === "pof-import") {
      formContent = null;
      formHeader = "Добавление внешнего объекта";
    } else if (formType === "repo-import") {
      formContent = null;
      formHeader = "Добавление репозитория";
    } else if (formType === "urs-import") {
      const fileName = (fileDropData && fileDropData.files && fileDropData.files.length && fileDropData.files[0].name) || "";
      formContent = (<span>Для файла &ldquo;{fileName}&rdquo; будет создана URS</span>);
      formHeader = "Загрузка URS";
    }

    let expanded = undefined;

    if (path && path.length) {
      const obj = {};
      let cumulativePath = "";
      path.forEach((entry, i) => {
        cumulativePath += `/${entry}`;
        if (i > 0 && i < path.length - 1) {
          obj[cumulativePath] = true;
        }
      });

      expanded = obj;
    }

    return (
      <div className="library">
        {gotError && (
          <Button
            leftIcon="refresh-M"
            style={{
              borderRadius: "3rem"
            }}
            theme="info"
            size={4}
            onClick={this.onRetry}
          />
        )}
        {!gotError &&
          this.renderToolbar({
            isAddToRoot:   true,
            isRefreshable: true,
            isTracker:     true,
            isGlobal:      true,
            isCreate:      true,
            isLinks:       true,
            isKinds:       true,
            isComments:    true,
            isJournal:     true,
            isTrace:       true,
            isValidate:    true
          })}
        {(isTracking || isGlobal) && (
          <Target
            id={id}
            version={version}
            trackedName={trackedName}
            trackedIcon={trackedIcon}
            trackedItem={trackedItem}
          />
        )}
        {!gotError && noRepresentation && (
          <div className="no-representation-wrapper">
            <Icon style={{ fontSize: "3rem" }} icon={"query-M"} />
            <span className="no-representation-text">
              Объект не имеет подходящего представления
            </span>
          </div>
        )}
        {!gotError && !noRepresentation && (
          <Tree
            ref={this.treeRef}
            treeId={tabId}
            isFocus={tabId === layoutStore.globalContextSource}
            getRef={this.setFocusRef}
            setFocusFunc={this.setFocusFunc}
            tabId={tabId}
            isTracking={isTracking}
            isGlobal={isGlobal}
            beingTracked={hasTarget}
            cursor={cursor}
            setIsTracking={this.toggleTracking}
            setEditingNode={this.setEditingNode}
            editingNode={editingNode}
            expanded={expanded}
            rootId={rootID}
            withToolbar={false}
            data={tree}
            onSetCursor={this.onSetCursor}
            nodeRender={this.renderTreeNode}
            iconRender={this.iconRender}
            getNodeData={this.getNodeData}
            changeCursor={this.changeCursor}
            canDropFunc={this.canDrop}
            canDropFileFunc={Library.canDropFile}
            catchFile={this.catchFile}
            moveItem={this.moveItem}
            copyItem={this.copyItem}
            createItem={this.createItem}
          />
        )}
        {formIsVisible && (
          <Modal
            className={"dark"}
            visible={formIsVisible}
            toggle={this.dummie}
            title={formHeader}
            modalContent={formContent}
            form={form}
            buttons={[
              {
                onClick:  this.onFormConfirmClick,
                size:     2,
                style:    { fontWeight: 100 },
                theme:    "info",
                leftIcon: "ok-M",
                loading:  pending || formIsPending,
                text:     "Принять"
              },
              {
                onClick:  this.toggleModal,
                size:     2,
                style:    { fontWeight: 100 },
                theme:    "danger",
                leftIcon: "cancel-M",
                loading:  pending || formIsPending,
                text:     "Отменить"
              }
            ]}
          />
        )}
        <input
          type="file"
          style={{
            overflow: "hidden",
            width:    "0px",
            height:   "0px",
            opacity:  0
          }}
          ref={this.fileInput}
          onChange={this.onHandleFileInput}
        />
      </div>
    );
  }
}

export default Library;
