import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { components } from "@ais3p/ui-framework";
import { observer } from "mobx-react";

import useStores from "~/core/utils/useStores";
import WSStore from "../../stores/wsStore";

const { toolbar, buttons } = components;

/**
 * @type WSToolbar
 *  
 * Toolbar для web socket
 * 
 * @prop {WSStore} store хранилище для работы с ws пакетами
 */
const WSToolbar = observer((props) => {
  const { accountStore } = useStores();
  const { store } = props;

  const onReconnect = useCallback(() => {
    store.init(accountStore.token);
  }, [store]);

  const rightButtons = useMemo(() => {
    return ([
      <buttons.Button
        key={"status"}
        icon={store.isConnected ? "link-finish-M" : "link-delete-M"}
        tooltip={store.readyState}
        color={store.isConnected ? "positive" : "negative"}
      />
    ]);
  }, [store.isConnected, store.readyState]);

  return (
    <toolbar.ToolBar right={rightButtons}>
      <buttons.Button
        icon="refresh-M"
        tooltip="Переподключиться по web socket"
        onPress={onReconnect}
      />
    </toolbar.ToolBar>
  );
});

WSToolbar.propTypes = {
  store: PropTypes.instanceOf(WSStore).isRequired
};
export default WSToolbar;
