import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import Dimention from "./Dimention";

const TableDimentions = observer(({ onClick }) => {
  const x = 5;
  const y = 5;

  const [hoveredX, setHoveredX] = useState(null);
  const [hoveredY, setHoveredY] = useState(null);

  const dimentionsRender = useMemo(() => {
    const dimentions = [];
    for (let j = 0; j < y; j += 1) {
      for (let i = 0; i < x; i += 1) {
        dimentions.push(
          <Dimention
            key={`${i}-${j}`}
            x={i}
            y={j}
            hoveredX={hoveredX}
            hoveredY={hoveredY}
            setHoveredX={setHoveredX}
            setHoveredY={setHoveredY}
            onClick={onClick}
          />
        );
      }
    }
    return dimentions;
  }, [x, y, hoveredX, hoveredY, setHoveredX, setHoveredY]);

  return (
    <div
      className="dimentions-holder"
      style={{
        gridTemplateColumns: `repeat(${x}, 1fr)`
      }}
    >
      {dimentionsRender}
    </div>
  );
});

export default TableDimentions;
