import React, { useCallback } from "react";
import { observer } from "mobx-react";
import JsonViewer from "./JsonViewer";


/**
 * Компонент для отображения значения Payload записи в журнале
 *
 * @class Payload
 */
const Payload = observer(({ item, className }) => {
  const onToggleShowPayload = useCallback(() => {
    item.toggleShowPayload();
  }, [item && item.isShowPayload]);

  if (!item.payload) {
    return null;
  }
  return (
    <div className={`journal-item-payload ${className ? className : ""}`}>
      <div className={"journal-item-payload-title"}>
        <span className="title-label">
          Payload
        </span>
        <span 
          className="toggle"
          onClick={onToggleShowPayload}
        >
          {item.isShowPayload ? "скрыть" : "отобразить"}
        </span>
      </div>
      {item.isShowPayload && 
        <div className="journal-item-payload-body">
          <JsonViewer json={item.payload} />
        </div>
      }
    </div>
  );
});

export default Payload;
