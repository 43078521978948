/**
 * Конестанты для определения типа состояния репозиторрия
 */
const REPO_STATE_COMMIT = "commitId";
const REPO_STATE_BRANCH = "branch";
const REPO_STATE_TAG = "tag";

/**
 * Нвбор возможных состояний
 */
const repoStateList = [
  REPO_STATE_COMMIT,
  REPO_STATE_TAG,
  REPO_STATE_BRANCH
];

export {
  REPO_STATE_COMMIT,
  REPO_STATE_BRANCH,
  REPO_STATE_TAG,
  repoStateList
};
