import { observer } from "mobx-react";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { ToolBar, Preloader } from "@ais3p/ui-framework-old";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";

import DataStore from "../stores/DataStore";

import PlusMenu from "./PlusMenu";
import RubricTree from "./RubricTree";
import SplitPane from "react-split-pane";

import "../css/text.scss";
import { DOMAIN_TEXT } from "../../../core/constants/Domains";

import {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS,
  SIDEPANEL_JOURNAL
} from "~/core/constants/SidePanels";
import FlatList from "./FlatList";
import { SIDEPANEL_VALIDATION } from "../../../core/constants/SidePanels";
import VersionButton from "./VersionButton";

const Material = observer((props) => {
  const {
    uid,
    editable,
    focusUid: selectedUid,
    version,
    trackedItem,
    isGlobal,
    layoutItem,
    isTracking,
    tabId,
    layoutStore,
    isSubVisible,
    useGlobalTracking
  } = props;
  const { rootStore } = useStores();

  const dataStore = useMemo(() => {
    const dataStore = new DataStore(rootStore, layoutStore);
    return dataStore;
  }, []);

  const [isDiffVisible, setIsDiffVisible] = useState(false);
  const [diffVersion, setDiffVersion] = useState(null);

  const isDiffVersionSelected = useMemo(() => {
    return diffVersion !== null;
  }, [diffVersion]);

  const onSelectDiffVersion = useCallback((versionNumber) => {
    setIsDiffVisible(false);
    setDiffVersion(versionNumber);
  }, []);

  const availableVersionRender = useMemo(() => {
    const versions = [];
    if (isDiffVisible && dataStore.availableDiffVersions) {
      if (!dataStore.availableDiffVersions.length) {
        return (
          <div className="no-versions">
            Объект не имеет версий для сравнения
          </div>
        );
      }
      dataStore.availableDiffVersions.forEach((child) => {
        versions.push(
          <VersionButton
            isSelected={child.number === diffVersion}
            name={child.name}
            version={child.number}
            key={`v-${child.number}`}
            onClick={onSelectDiffVersion}
          />
        );
      });
    }
    return versions;
  }, [
    isDiffVisible,
    isDiffVersionSelected,
    diffVersion,
    dataStore.availableDiffVersions
  ]);

  const isDiffLoading = useMemo(() => {
    return dataStore.isPending || dataStore.isDiffPending;
  }, [dataStore.isPending, dataStore.isDiffPending]);

  const toggleDiffPanel = useCallback(() => {
    if (isDiffVisible) {
      setDiffVersion(null);
    }
    setIsDiffVisible(!isDiffVisible);
  }, [isDiffVisible]);

  useLayoutEffect(() => {
    dataStore.initDiff(diffVersion);
  }, [diffVersion]);

  useEffect(() => {
    useGlobalTracking && !isGlobal && layoutItem.toggleGlobalTracking();
  }, [useGlobalTracking, isGlobal]);

  useLayoutEffect(() => {
    if (editable) {
      dataStore.init(editable, uid, version);
    }
  }, [editable, version]);

  useLayoutEffect(() => {
    if (selectedUid && dataStore.isReady && !dataStore.isPending) {
      dataStore.setFocusUid(selectedUid);
      dataStore.scrollToItemById(selectedUid);
    }
  }, [selectedUid, dataStore.isReady, dataStore.isPending]);

  const [isTreeVisible, setIsTreeVisible] = useState(true);

  const element = useRef(null);

  const {
    flatItemsArray,
    root,
    focusUid,
    focusItem,
    isVersionPending,
    isVersion,
    isPending,
    canSaveVersion
  } = dataStore;

  useEffect(() => {
    if (focusUid && focusItem) {
      layoutItem.changeContext(uid, focusUid, {
        uid:         focusUid,
        editable:    uid,
        version,
        trackedItem: {
          uid:   focusUid,
          version,
          name:  focusItem.title,
          class: focusItem.className,
          tool:  DOMAIN_TEXT
        }
      });
    } else {
      layoutItem.changeContext();
    }
  }, [focusUid, focusItem, uid, version, layoutItem]);

  const isExpanded = root && root.isExpanded;

  const setScrollToIndex = useCallback(
    (index) => {
      const id = flatItemsArray[index] && flatItemsArray[index].id;
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }
    },
    [flatItemsArray, uid, version]
  );

  const itemsLength = useMemo(() => {
    return (flatItemsArray && flatItemsArray.length) || null;
  }, [flatItemsArray]);

  const hasItems = useMemo(() => {
    return itemsLength && itemsLength > 1;
  }, [itemsLength]);

  const onToggleTree = useCallback(() => {
    setIsTreeVisible(!isTreeVisible);
  }, [isTreeVisible]);

  const toggleGlobalTracking = useCallback(() => {
    layoutItem.toggleGlobalTracking();
  }, [layoutItem]);

  const toggleSubPanel = useCallback(
    (tag) => {
      layoutStore.toggleSubPanel(tabId, tag);
    },
    [layoutStore, tabId]
  );

  const isSaveVersion = useMemo(() => {
    return version === undefined || version === null || version === 0;
  }, [version]);

  const onSaveVersion = useCallback(() => {
    dataStore.saveVersion(uid);
  }, [dataStore, uid]);

  const buttons = useMemo(() => {
    const buttons = {
      left: [
        {
          id:       "tree",
          icon:     "app-tree-M",
          pressed:  isTreeVisible,
          title:    "Отобразить дерево рубрик",
          callback: onToggleTree
        }
      ],
      right: []
    };
    if (isSaveVersion) {
      buttons.left.push({
        id:       "spacer0",
        isSpacer: true
      });
      buttons.left.push({
        id:       "save",
        icon:     "save-M",
        title:    "Зафиксировать версию",
        loading:  isVersionPending,
        disabled: !canSaveVersion,
        callback: onSaveVersion
      });
    }

    buttons.right.push({
      pressed:  isDiffVisible,
      loading:  isDiffLoading,
      id:       "diff",
      icon:     "fork-M",
      title:    "Сравнить с версией",
      callback: toggleDiffPanel
    });

    buttons.right.push({
      id:       "spacer2",
      isSpacer: true
    });
    let tag = SIDEPANEL_RELATIONS;
    buttons.right.push({
      pressed:  isSubVisible[tag],
      id:       tag,
      icon:     "app-relations-M",
      title:    "Связи",
      callback: toggleSubPanel
    });

    tag = SIDEPANEL_KINDS_ATTRS;
    buttons.right.push({
      pressed:  isSubVisible[tag],
      id:       tag,
      icon:     "app-attributes-M",
      title:    "Виды и атрибуты",
      callback: toggleSubPanel
    });

    tag = SIDEPANEL_JOURNAL;
    buttons.right.push({
      pressed:  isSubVisible[tag],
      id:       tag,
      icon:     "log-M",
      title:    "Журнал изменений",
      callback: toggleSubPanel
    });

    tag = SIDEPANEL_VALIDATION;
    buttons.right.push({
      pressed:  isSubVisible[tag],
      id:       tag,
      icon:     "ok-M",
      title:    "Согласование",
      callback: toggleSubPanel
    });

    return buttons;
  }, [
    isTreeVisible,
    onToggleTree,
    isGlobal,
    toggleGlobalTracking,
    toggleSubPanel,
    isSubVisible,
    isSaveVersion,
    isVersionPending,
    isDiffVisible,
    isDiffLoading,
    onSaveVersion
  ]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      root.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const hideDiffSelector = useCallback(() => {
    setIsDiffVisible(false);
  }, []);

  const itemsRendered = useMemo(() => {
    if (!dataStore || dataStore.isPending) {
      return null;
    }
    return (
      <FlatList 
        dataStore={dataStore}
        flatItemsArray={flatItemsArray} 
      />
    );
  }, [flatItemsArray, dataStore.isPending]);

  const itemsList = useMemo(() => {
    return (
      <div ref={element} className="items-list">
        {!hasItems && root && (
          <AisIcon
            onClick={toggleExpanded}
            icon={"plus-M"}
            className={`expand-menu ${isExpanded ? "expanded" : ""}`}
          />
        )}
        {hasItems && itemsRendered}
        {!hasItems && root && (
          <PlusMenu layoutStore={layoutStore} data={root} />
        )}
      </div>
    );
  }, [hasItems, root, toggleExpanded, isExpanded, itemsRendered]);

  if (!editable) {
    return (
      <div className={`material ${isVersion ? "version" : ""}`}>
        <ToolBar buttons={buttons} />
        <div className={"editor-container"}>
          {(isGlobal || isTracking) && <Target trackedItem={trackedItem} />}
          <div className="items-list-holder">
            <div className="no-content">
              <AisIcon icon="cancel-M" /> Не текст
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isPending) {
    return (
      <div className={`material ${isVersion ? "version" : ""}`}>
        <ToolBar buttons={buttons} />
        <div className={"editor-container"}>
          {(isGlobal || isTracking) && <Target trackedItem={trackedItem} />}
          <div className="items-list-holder">
            <div className="no-content">
              <Preloader />{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`material ${isVersion ? "version" : ""} ${
        isDiffVersionSelected ? "with-diff" : ""
      }`}
    >
      <ToolBar buttons={buttons} />
      <div onClick={hideDiffSelector} className={"editor-container"}>
        {isDiffVisible && (
          <div className="version-List">{availableVersionRender}</div>
        )}
        {isDiffVersionSelected && (
          <div className="object-holder">
            <div className="target-object">
              <div className="object-name">Сравнение</div>
              <AisIcon
                data-tooltip={
                  dataStore.libraryRepresentation &&
                  dataStore.libraryRepresentation.title
                }
                item={dataStore.libraryRepresentation}
              />
              <div className="object-name">
                {dataStore.libraryRepresentation &&
                  dataStore.libraryRepresentation.title}
              </div>
              <AisIcon
                data-tooltip={
                  dataStore.version === 0
                    ? "Редакция"
                    : `Версия ${dataStore.version}`
                }
                className="added-icon"
                icon={"version-M"}
              />
              <div className="object-name">
                {dataStore.version === 0
                  ? "Редакция"
                  : `Версия ${dataStore.version}`}
              </div>
              <AisIcon backgroundColor="#4d4d4d" icon="arrow-right-M" />
              <AisIcon
                data-tooltip={`Версия ${
                  dataStore.diffStore && dataStore.diffStore.version
                }`}
                className="removed-icon"
                icon={"version-M"}
              />
              <div className="object-name">
                {`Версия ${dataStore.diffStore && dataStore.diffStore.version}`}
              </div>
            </div>
          </div>
        )}
        {!isDiffVersionSelected && dataStore.libraryRepresentation && (
          <div className="object-holder">
            <div
              className="target-object"
            > 
              <AisIcon 
                data-tooltip={dataStore.libraryRepresentation && dataStore.libraryRepresentation.title} 
                item={dataStore.libraryRepresentation}
              />
              <div className="object-name">
                {dataStore.libraryRepresentation && dataStore.libraryRepresentation.title}
              </div>
              &ndash;
              <AisIcon 
                data-tooltip={dataStore.version === 0 ? "Редакция" : `Версия ${dataStore.version}`} 
                icon={"version-M"}
              />
              <div className="object-name">
                {dataStore.version === 0 ? "Редакция" : `${dataStore.version}`}
              </div>
            </div>
          </div>
        )}
        {(isGlobal || isTracking) && (
          <Target trackedItem={trackedItem} />
        )}
        <div className="items-list-holder">
          {isTreeVisible && (
            <SplitPane
              split="vertical"
              minSize={isTreeVisible ? 300 : 0}
              style={{ flex: 1 }}
            >
              <div className="rubric-tree-holder">
                {isTreeVisible && (
                  <RubricTree
                    uid={uid}
                    version={version}
                    setScrollToIndex={setScrollToIndex}
                    store={dataStore}
                  />
                )}
              </div>
              {itemsList}
            </SplitPane>
          )}
          {!isTreeVisible && itemsList}
        </div>
      </div>
    </div>
  );
});

export default Material;
