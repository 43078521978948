import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import AisIcon from "../../../core/components/AisIcon";
import PlusMenu from "./PlusMenu";
import useStores from "~/core/utils/useStores";
import { ContextMenuTrigger, Dnd } from "@ais3p/ui-framework-old";
const TableCell = observer(({ data, renderItem }) => {
  const {
    items,
    uid,
    idsArray,
    isHeaderCell,
    isExpanded,
    isHovered,
    isHoverValid,
    cs,
    rs,
    tableUid,
    scrollItemId,
    diffClass
  } = data;
  const { layoutStore } = useStores();
  const itemsRender = useMemo(() => {
    return items.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, items]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const canDrop = useCallback((props, monitor) => {
    const item = monitor.getItem();
    return item.uid !== props.uid;
  }, []);

  const onHover = useCallback(
    (props, monitor) => {
      const item = monitor.getItem();
      data.setHoveredById(item.uid);
    },
    [data]
  );

  const onMouseLeave = useCallback(() => {
    data.setExpanded(false);
  }, [data]);

  const contextMenuCollect = useCallback(
    (props) => {
      if (isHovered && isHoverValid) {
        layoutStore.menuCollect({
          menuItems: [
            {
              icon:  "table-merge-M",
              title: "Объеденить ячейки",
              data:  { action: "mergeCells" }
            }
          ],
          onItemClick: props.onItemClick
        });
        return props;
      }

      const menuItems = [
        {
          icon:  "table-col-left-M",
          title: "Вставить колонку слева",
          data:  { action: "insertColumnLeft" }
        },
        {
          icon:  "table-col-right-M",
          title: "Вставить колонку справа",
          data:  { action: "insertColumnRight" }
        },
        {
          icon:  "table-row-top-M",
          title: "Вставить строку сверху",
          data:  { action: "insertRowTop" }
        },
        {
          icon:  "table-row-bottom-M",
          title: "Вставить строку снизу",
          data:  { action: "insertRowBottom" }
        }
      ];

      if (rs > 1) {
        menuItems.push({
          icon:  "table-divide-vert-M",
          title: "Разбить ячейку по горизонтали",
          data:  { action: "splitCellVert" }
        });
      } else {
        menuItems.push({
          icon:  "table-row-minus-M",
          title: "Удалить строку",
          data:  { action: "deleteRow" }
        });
      }
      if (cs > 1) {
        menuItems.push({
          icon:  "table-divide-hor-M",
          title: "Разбить ячейку по вертикали",
          data:  { action: "splitCellHor" }
        });
      } else {
        menuItems.push({
          icon:  "table-col-minus-M",
          title: "Удалить колонку",
          data:  { action: "deleteColumn" }
        });
      }

      layoutStore.menuCollect({
        menuItems,
        onItemClick: props.onItemClick
      });
      return props;
    },
    [isHovered, cs, rs]
  );

  const canDrag = useCallback(() => {
    return !data.isVersion;
  }, [data.isVersion]);

  const endDrag = useCallback(
    (props, monitor) => {
      const did = monitor.didDrop();
      if (!did || !isHoverValid) {
        data.setHoveredById();
      }
    },
    [data, isHoverValid]
  );

  const onMenuClick = useCallback((e, contextMenuData) => {
    const { action, item } = contextMenuData;
    switch (action) {
      case "insertColumnLeft":
        item.insertColumnLeft();
        break;
      case "insertColumnRight":
        item.insertColumnRight();
        break;
      case "insertRowTop":
        item.insertRowTop();
        break;
      case "insertRowBottom":
        item.insertRowBottom();
        break;
      case "deleteRow":
        item.deleteRow();
        break;
      case "deleteColumn":
        item.deleteColumn();
        break;
      case "mergeCells":
        item.mergeCells();
        break;
      case "splitCellVert":
        item.splitCell("vertically");
        break;
      case "splitCellHor":
        item.splitCell("horizontally");
        break;

      default:
        break;
    }
  }, []);

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current.scrollIntoView(true);
      }, 10); // set timeout= DIRTY HACK
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div
      ref={element}
      className={`table-cell ${diffClass} ${
        isHeaderCell ? "header-cell" : ""
      } ${isHovered ? "hovered" : ""} ${isHoverValid ? "valid" : "invalid"}`}
      style={{
        gridColumnEnd: cs ? `span ${cs}` : undefined,
        gridRowEnd:    rs ? `span ${rs}` : undefined
      }}
      onMouseLeave={onMouseLeave}
      id={uid}
    >
      <Dnd.Target
        isRelative={true}
        types={[`TABLE-HANDLE-${tableUid}`]}
        canDrop={canDrop}
        hover={onHover}
        uid={uid}
      >
        {itemsRender}
        {!itemsRender.length && (
          <AisIcon
            onClick={toggleExpanded}
            icon={"plus-M"}
            className={`expand-menu ${isExpanded ? "expanded" : ""}`}
          />
        )}
        {isExpanded && <PlusMenu data={data} />}
      </Dnd.Target>
      <div className="corner-holder">
        <ContextMenuTrigger
          item={data}
          id={"contextMenuId"}
          menuType={"LOCAL"}
          onItemClick={onMenuClick}
          holdToDisplay={-1}
          collect={contextMenuCollect}
        >
          <Dnd.Source
            isRelative={true}
            data={{ uid }}
            uid={uid}
            endDrag={endDrag}
            // eslint-disable-next-line
            canDrag={canDrag}
            type={`TABLE-HANDLE-${tableUid}`}
          >
            <AisIcon className={"icon"} item={data} />
          </Dnd.Source>
        </ContextMenuTrigger>
      </div>
    </div>
  );
});

export default TableCell;
