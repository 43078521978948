import Token from "./Token";
import { action, observable, computed } from "mobx";
// import { isDeclareFunction } from "../languages";

/**
 * Модель для хранения информации о строке кода в файле
 * 
 * @class {Line}
 */
class Line {
  /**
   * @type {Array<String>}
   * 
   * Набор токенов в строке кода
   */
  @observable 
  tokens = [];

  /**
   * @type {String}
   * 
   * Тип языка
   */
  @observable 
  language;
  
  /**
   * Статичный конструктор модели.
   * При создании модели, лучше использовать статичный метод, тк если поменяются названия входных параметров,
   * то эти названия можно будет здесь только поменять, не меняя названия в самой модели
   * 
   * @param {Object} tokenData данные по токенам в строке
   * @param {String} language тип языка кода
   *  
   * @returns Line
   */
  static create(tokenData, language) {
    return new Line(tokenData, language);
  }

  /**
   * Конструктор модели.
   * 
   * @param {Object} tokenData данные по токенам в строке
   * @param {String} language тип языка кода
   *  
   * @returns Line
   */
  constructor(tokenData, language) {
    this.tokens = [];
    this.language = language;
    if (tokenData) {
      this.addToken(Token.create(tokenData));
    }
  }

  /**
   * Добавить токен.
   * 
   * @param {Object} params данные по добавляемому токену строке
   * @param {Array<String>} params.types типы токена
   * @param {String} params.content текстовое содержимое токена
   */
  @action 
  addToken({ types, content }) {
    this.tokens.push(Token.create({ types, content }));
  }

  /**
   * Добавить пустой токен.
   */
  @action 
  addEmptyToken() {
    // Пустая строка должны содержать один пустой токен, обозначаемый { empty: true }
    const emptyToken = Token.create().setIsEmpty();
    this.tokens.push(emptyToken);
  }

  /**
   * Получить текстовое представление строки с токенами
   * 
   * @return {String}
   */
  @computed
  get content() {
    return this.tokens.map((token) => {
      return token.content;
    }).join("");
  }

  /**
   * Получить текстовое название строки
   * 
   * @return {String}
   */
  @computed
  get title() {
    return this.content;
  }

  /**
   * Получить тип строки
   * 
   * @return {String}
   */
  @computed
  get type() {
    let result;
    for (let i = 0; i < this.tokens.length - 1; i += 1) {
      const token = this.getToken(i);
      const { types = [] } = token;

      if (types.some((tp) => {
        return String(tp).toLowerCase() === "function";
      })) {
        return "function";
      }

      result = types && types.length > 0 && types[0];
    }

    return result;
  }

  // @computed
  // get icon() {
  //   if (isDeclareFunction(this.content, this.language)) {
  //     return "function-M";
  //   }

  //   return null;
  // } 

  /**
   * Получить токен строки по индексу
   * 
   * @param {Nimber} index индекс токена
   * 
   * @return {Token}
   */
  getToken(index) {
    return this.tokens[index];
  }

  /**
   * Нормализовать набор токенов в строке
   * 
   */
  normalize() {
    if (this.tokens.length === 0) {
      this.addEmptyToken();
    } else if (this.tokens.length === 1 && this.tokens[0].content === "") {
      this.tokens[0].setIsEmpty();
    }
  }

  /**
   * Деструктор модели
   */
  destroy() {
    this.lines = [];
  }
}

export default Line;
