import { v4 as uuid } from "uuid";

const uid = () => {
  return uuid().replace(/-/g, "");
};

const isUid = (val) => {
  const re = new RegExp(/^[0-9a-f]{8}?[0-9a-f]{4}?[0-9a-f]{4}?[0-9a-f]{4}?[0-9a-f]{12}$/gi);
  return re.test(val);
};

export default uid;

export {
  uid,
  isUid
};
