import LineModel from "../models/Line";

const newlineRe = /\r\n|\r|\n/;

const appendTypes = (types, add) => {
  const typesSize = types.length;
  if (typesSize > 0 && types[typesSize - 1] === add) {
    return types;
  }

  return types.concat(add);
};

/** 
 * Получить массив линий, состоящих из токенов
 * Берем массив токенов Prismjs и группируем их по строкам, превращая простые (plain) строки в токены. 
 * В некоторых случаях токены могут стать рекурсивными, что означает, что их типы объединены. 
 * Однако токены с простой строкой всегда имеют тип «обычный»  - "plain".
 * 
 * @param {Array<tokens>} tokens массив Prismjs токенов
 * @param {String} language язык, на котором написан код
 * 
 * @return {Array<LineModel>}
 */
const generateLines = (tokens, language) => {
  const typeArrStack = [[]];
  const tokenArrStack = [tokens];
  const tokenArrIndexStack = [-1];
  const tokenArrSizeStack = [tokens.length];

  let i = 0;
  let stackIndex = 0;
  let currentLine = LineModel.create(undefined, language);

  const result = [currentLine];

  while (stackIndex > -1) {
    while (
      (i = (tokenArrIndexStack[stackIndex] += 1)) < tokenArrSizeStack[stackIndex]
    ) {
      let content;
      let types = typeArrStack[stackIndex];

      const tokenArr = tokenArrStack[stackIndex];
      const token = tokenArr[i];

      // Определяем содержимое и добавляем тип и alias к типам, если это необходимо
      if (typeof token === "string") {
        types = stackIndex > 0 ? types : ["plain"];
        content = token;
      } else {
        types = appendTypes(types, token.type);
        if (token.alias) {
          types = appendTypes(types, token.alias);
        }

        content = token.content;
      }

      // Если token.content является массивом, увеличиваем глубину стека и повторяем это цикле
      if (typeof content !== "string") {
        stackIndex += 1;
        typeArrStack.push(types);
        tokenArrStack.push(content);
        tokenArrIndexStack.push(0);
        tokenArrSizeStack.push(content.length);
        continue;
      }

      // Разделяем по новым строкам
      const splitByNewlines = content.split(newlineRe);
      const newlineCount = splitByNewlines.length;

      currentLine.addToken({ types, content: splitByNewlines[0] });

      // Создаем новую строку для каждой строки в новой строке
      for (let i = 1; i < newlineCount; i += 1) {
        currentLine.normalize();
        currentLine = LineModel.create({ types, content: splitByNewlines[i] }, language);
        result.push(currentLine);        
      }
    }

    // Уменьшаем глубину стека
    stackIndex -= 1;
    typeArrStack.pop();
    tokenArrStack.pop();
    tokenArrIndexStack.pop();
    tokenArrSizeStack.pop();
  }

  currentLine.normalize();
  return result;
};

export default generateLines;
