import Server from "~/core/api/server";

class ViewerApi extends Server {
  methods = {
    file: {
      url: (params) => {
        return this.getServiceUrl(
          "files",
          this.template`api/v1/files/${0}`,
          params
        );
      },
      method:      "GET",
      textProcess: "Запрос файла...",
      textSuccess: null,
      textError:   this.template`Во время запроса файла произошла ошибка: ${0}`
    },
    mathml: {
      url:             this.getServiceUrl("mathml", "api/v1/svg"),
      method:          "POST",
      noJSONStringify: true,
      contentType:     "application/mathml+xml;charset=UTF-8",
      textProcess:     "Запрос svg представления формулы...",
      textSuccess:     null,
      textError:       async(error, resp) => {
        if (resp) {
          const contentType = resp.headers && resp.headers.get("Content-Type");
          const isJSON = contentType && contentType.includes("json");
          const response = await resp[isJSON ? "json" : "text"]();
          if (response.errors) {
            return response.errors.message;
          }
          return (
            response ||
            `Запрос svg представления формулы произошел с ошибкой: ${error}`
          );
        }
        return `Запрос svg представления формулы произошел с ошибкой: ${error}`;
      }
    }
  };

  async getFile(id) {
    const reqData = {
      params: [id]
    };
    try {
      return await this.request("file", reqData);
    } catch (error) {
      this.rootStore.setErrorText(error.message);
    }
  }

  async getMathSvg(mathml) {
    // const reqData = {
    //   body: mathml,
    // };
    return await this.request("mathml", mathml);
  }
}

export default ViewerApi;
