import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";

const shallowEqual = (a, b) => {
  const keys = Object.keys(a);
  return (
    keys.length === Object.keys(b).length &&
      keys.reduce((result, key) => {
        return (
          result &&
          ((typeof a[key] === "function" && typeof b[key] === "function") ||
            a[key] === b[key])
        );
      }, true)
  );
};

/**
 * Всплывающая подсказка `Tooltip`
 *
 * Не нужно добавлять компонент `Tooltip` для каждого элемента, которому необходима всплывающая
 * подсказка. Достаточно его разместить в самый верхний компонент контейнера.
 * Если нужно определить несколько экземпляров `Tooltip`, то необходимо для каждого экземпляра
 * установить свое уникальное название св-ва `attribute`. По назанию этого атрибута, экземпляр
 * `Tooltip` определяет для какого элемента ему срабатывать. Необходимо помнить, что этот атрибут
 * также необходимо указывать в элементах, для которых должен сработать `Tooltip`.
 * Текст всплывающей подсказки указывается в каждом элементе в значении атрибута, указанного
 * в свойствах `attribute`. По умолчанию это - `data-tooltip`. Т.е. в элементе нужно написать
 * примерно следующее: <div data-tooltip="Текст всплывающей подсказки" />
 * `Tooltip` также поддерживает пользовательский контент всплывающей подсказки. Для этого нужно
 * в свойствах прописать метод `onRenderContent`, который должен вернуть компонент, который
 * будет отображен в всплывающей подсказке.
 * Помимо текста подсказки (указывается в data-tooltip), в элементе, для которого необходимо эту
 * подсказку вывести, можно еще указать позицию (top, left, right, bottom) отображения подсказки.
 * Для этого в атрибуте `data-tooltip-at` (или же если был уставнолен другое название в свойстве
 * `attribute`, то  `attribute`-at) необходимо прописать эту позицию.
 * Например <div data-tooltip="Текст всплывающей подсказки" data-tooltip-at="bottom" />
 */
const Tooltip = (props) => {
  const {
    // префикс в имени атрибутов для установки управляющих св-в для отображения tooltip
    attribute =       "data-tooltip",
    // автопозиционирование tooltip по отношению к границам окна
    autoPosition =    true,
    // клас стиля
    className =       "tooltip",
    // Включает / отключает все события или подмножество событий при которых будет отображаться подсказка.
    // Boolean или {click: Boolean, focus: Boolean, hover: Boolean}
    events =          true,
    // метод для отрисовки пользовательского интерфейса
    onRenderContent = null,
    // скрыть подсказку только при внешнем щелчке, наведении и т. д
    persist =         false,
    // где расположить всплывающую подсказку по умолчанию: "Top", "left", "right", "bottom"
    position =        "top"
  } = props;

  const containerRef = useRef(null);
  const tooltipRef = useRef(null);
  const [target, setTarget] = useState(null);
  const [at, setAt] = useState(position);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const content = useMemo(() => {
    if (!target) {
      return null;
    }
    const str = target.getAttribute(attribute) || "";
    return onRenderContent ? 
      onRenderContent(target, str) :
      <div className={`${className}-content`}>{str}</div>;
  }, [target, attribute, onRenderContent]);

  useEffect(() => {
    toggleEvents({ events }, true);
    return () => {
      toggleEvents({ events }, false);
    };
  }, [events]);

  useEffect(() => {
    if (!tooltipRef.current || !containerRef.current) {
      return;
    }

    generateTooltipPosition(containerRef.current, tooltipRef.current);
  }, [target, attribute, position, autoPosition, 
    containerRef && containerRef.current, 
    tooltipRef && tooltipRef.current]);

  const toggleEvents = ({ events, events: { click, focus, hover } }, flag) => {
    const action = flag ? "addEventListener" : "removeEventListener";
    const hasEvents = events === true;

    (click || hasEvents) && document[action]("click", toggleTooltip);
    (focus || hasEvents) && document[action]("focusin", toggleTooltip);
    (hover || hasEvents) && document[action]("mouseover", toggleTooltip);
    (hover || hasEvents) && document[action]("drag", hideTooltip);
    (click || hover || hasEvents) &&
      document[action]("touchend", toggleTooltip);
  };

  const hideTooltip = useCallback(() => {
    setTarget(null);
  }, []);

  const toggleTooltip = useCallback(({ target = null } = {}) => {
    const el = getTooltipEl(target);
    setTarget(el);
  }, [tooltipRef, attribute, persist]);

  const getTooltipEl = (initEl) => {
    let el = initEl;
    while (el) {
      if (el === document) break;
      if (persist && tooltipRef` &&  el === tooltipRef`.current) return target;
      if (el.hasAttribute(attribute)) return el;
      el = el.parentNode;
    }
    return null;
  };

  const generateTooltipPosition = (containerEl, tooltipEl) => {
    let at = target.getAttribute(`${attribute}-at`) || position;

    const {
      top: containerTop,
      left: containerLeft
    } = containerEl.getBoundingClientRect();

    const {
      width: tooltipWidth,
      height: tooltipHeight
    } = tooltipEl.getBoundingClientRect();

    const {
      top: targetTop,
      left: targetLeft,
      width: targetWidth,
      height: targetHeight
    } = target.getBoundingClientRect();

    if (autoPosition) {
      const isHoriz = ["left", "right"].includes(at);

      const { clientHeight, clientWidth } = document.documentElement;

      const directions = {
        left:
          (isHoriz
            ? targetLeft - tooltipWidth
            : targetLeft + ((targetWidth - tooltipWidth) >> 1)) > 0,
        right:
          (isHoriz
            ? targetLeft + targetWidth + tooltipWidth
            : targetLeft + ((targetWidth + tooltipWidth) >> 1)) < clientWidth,
        bottom:
          (isHoriz
            ? targetTop + ((targetHeight + tooltipHeight) >> 1)
            : targetTop + targetHeight + tooltipHeight) < clientHeight,
        top:
          (isHoriz
            ? targetTop - (tooltipHeight >> 1)
            : targetTop - tooltipHeight) > 0
      };

      switch (at) {
        case "left":
          if (!directions.left) at = "right";
          if (!directions.top) at = "bottom";
          if (!directions.bottom) at = "top";
          break;

        case "right":
          if (!directions.right) at = "left";
          if (!directions.top) at = "bottom";
          if (!directions.bottom) at = "top";
          break;

        case "bottom":
          if (!directions.bottom) at = "top";
          if (!directions.left) at = "right";
          if (!directions.right) at = "left";
          break;

        case "top":
        default:
          if (!directions.top) at = "bottom";
          if (!directions.left) at = "right";
          if (!directions.right) at = "left";
          break;
      }
    }

    let top;
    let left;
    switch (at) {
      case "left":
        top = (targetHeight - tooltipHeight) >> 1;
        left = -tooltipWidth;
        break;

      case "right":
        top = (targetHeight - tooltipHeight) >> 1;
        left = targetWidth;
        break;

      case "bottom":
        top = targetHeight;
        left = (targetWidth - tooltipWidth) >> 1;
        break;

      case "top":
      default:
        top = -tooltipHeight;
        left = (targetWidth - tooltipWidth) >> 1;
    }

    setAt(at);
    setTop((top + targetTop - containerTop) | 0);
    setLeft((left + targetLeft - containerLeft) | 0);
  };

  return (
    <div 
      ref={containerRef} 
      style={{ position: "relative" }}
    >
      {target && (
        <div
          className={`${className} ${className}-${at}`}
          ref={tooltipRef}
          role="tooltip"
          style={{ top, left }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

// export default Tooltip;
export default React.memo(Tooltip, (props, nextProps) => {
  // if shallowEqual return true then don't re-render/update
  return shallowEqual(props, nextProps);
});

