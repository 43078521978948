import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { Icon, Button } from "@ais3p/ui-framework-old";
import { REPO_STATE_COMMIT } from "../../constants/repoStateTypes";

/**
 * Компонент для отображения записи о коммите
 *
 * @param {Object} params набор параметров
 * @param {String} params.id id записи с коммитом
 * @param {String} params.commit номер коммита
 * @param {String} params.author автор коммита
 * @param {String} params.commitMessage пользователькое сообщение о коммите
 * @param {String} params.dateString датаи время сделанного коммита в строковом представлении
 * @param {RepoStore} params.store Хранилище для работы с репозиторием
 *
 * @type {Commit}
 * @returns {Component}
 */
const Commit = observer((props) => {
  const { commit, author, commitMessage, dateString, id, store } = props;
  const lastCommitId = store.repoState[REPO_STATE_COMMIT];
  const onClick = useCallback(() => {
    store.setCurrentCommit(id);
  });

  return (
    <div
      key={id}
      className={`commit-card ${id === lastCommitId ? "current" : ""}`}
    >
      <div className="left">
        <div className="date-time">
          <Icon className="expander big" icon={"data-datetime-M"} />
          <span>{dateString}</span>
        </div>
        <div className="space" />
        <div className="commit">
          <Icon className="expander big" icon={"softdev-M"} />
          <span>{commit}</span>
        </div>
        <div className="user">
          <Icon className="expander big" icon={"user-M"} />
          <span>{author}</span>
        </div>
      </div>
      <div className="commit-message">
        <Icon className="expander big" icon={"comment-M"} />
        <span>{commitMessage}</span>
      </div>
      <div className="right">
        <Button
          leftIcon={"relink-M"}
          // isOutline={true}
          className={"go-to-commit-button"}
          theme="transparent"
          text="Перейти"
          size={2}
          onClick={onClick}
        />
      </div>
    </div>
  );
});

export default Commit;
