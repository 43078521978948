import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Field, Components } from "@ais3p/ui-framework";

@inject("accountStore")
@observer
class WorkspaceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input:    "",
      textArea: ""
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }
  componentDidUpdate(prevProps) {
    const { editingName, editingDescription } = this.props.accountStore;
    
    if (editingName !== prevProps.accountStore.editingName) {
      this.setState({ input: editingName });
    }
    if (editingDescription !== prevProps.accountStore.editingDescription) {
      this.setState({ textArea: editingDescription });
    }
  }

  onFormSubmit() {
    const { accountStore } = this.props;
    accountStore.saveWorkspace(this.state.input, this.state.textArea);
  }

  onCancel() {
    const { accountStore } = this.props;
    accountStore.hideForm();
  }

  onInputChange(value, name) {
    if (name === "ws-name") {
      this.setState({ input: value });
    }
    if (name === "ws-description") {
      this.setState({ textArea: value });
    }
  }
  render() {
    const { accountStore } = this.props;

    const pending = false; // accountStore.pending;
    const { formIsVisible } = accountStore;

    const wsButtons = [
      <Components.Button
        key="save"
        text="Сохранить"
        icon="save-M"
        onPress={this.onFormSubmit}
        color="positive"
        isLoading={pending}
      />,
      <Components.Button
        key="cancel"
        text="Отменить"
        icon="cancel-M"
        onPress={this.onCancel}
        color="negative"
        isLoading={pending}
      />
    ];
    return formIsVisible && (
      <Modal.Window
        name="workspace"
        icon="plane-M"
        show={formIsVisible}
        title="Сохранение АРМ"
        buttons={wsButtons}
        onKeyPressEsc={this.onCancel}
        onKeyPressEnter={this.onFormSubmit}
      >
        <Field.String 
          icon=""
          label="Название"
          name="ws-name"
          onChange={this.onInputChange}
          value={this.state.input}
          theme="dark"
        />
        <Field.TextArea 
          icon=""
          label="Описание"
          name="ws-description"
          onChange={this.onInputChange}
          value={this.state.textArea}
          theme="dark"
        />
      </Modal.Window>
    );
  }
}

export default WorkspaceForm;
