import { action, computed } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class List extends BaseBoxClass {
  @action
  async persistCreate() {
    await super.persistCreate();
    await this.createOnlyItem();
  }

  @action
  async createOnlyItem() {
    if (!this.store) {
      return null;
    }
    if (this.idsArray.length < 1) {
      const item = this.store.createItem(
        {
          class: "text.container.EnumerationItem",
          uid:   this.store.getUid(),
          isNew: true
        },
        this
      );
      this.addItemId(item.uid);
    }
  }

  @action
  async checkForEmptinessAndRemove() {
    if (!this.idsArray.length) {
      await this.persistDelete();
      this.selfDestruct(true);
    }
  }

  @computed
  get title() {
    let title = " ";
    this.items.forEach((item) => {
      if (item) {
        title += item.title;
      }
    });
    return title;
  }

  @computed
  get flatItemsArray() {
    return [this];
  }

  @computed
  get slug() {
    return "enumerations";
  }

  @computed
  get category() {
    return "elements";
  }

  @computed
  get innerHierarchyItemsArray() {
    const array = [
      {
        class:    "text.container.EnumerationItem",
        icon:     "paragraph-M",
        isParent: true,
        isNew:    true,
        ancorId:  this.uid
      }
    ];
    return array;
  }

  @computed
  get hierarchyItemsArray() {
    return [].concat(
      this.parent.hierarchyItemsArray,
      this.parent.innerHierarchyItemsArray
    );
  }

  @computed
  get removedFirstChildListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (
      this.diffCompatitor.items && 
      this.diffCompatitor.items.length > 0 && 
      this.diffCompatitor.items[0].diffClass === "removed"
    ) {
      return [this.diffCompatitor.items[0], ...this.diffCompatitor.items[0].removedDescendantListItemsArray];
    }
    return [];
  }

  @computed
  get itemsForRender() {
    let data = [...this.removedFirstChildListItemsArray];
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      data = data.concat([item, ...item.removedDescendantListItemsArray]);
    });
    return data;
  }

  @computed
  get output() {
    return {
      class:       this.className,
      uid:         this.uid,
      elements:    [],
      "@position": this.position
    };
  }
}

export default List;
