import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import Header from "./Header";
import ChangeItem from "./ChangeItem";
import Notes from "./Notes";
import IssueStore from "../../stores/IssueStore";

/**
 * Запись журнала изменений задачи
 * 
 * @param {Object} props набор параметров
 * @param {Object} params.journalItem запись журнала изменений
 * @param {IssueStore} params.store хранилище для работы с задачами
 */
const JournalItem = observer((props) => {
  const { journalItem, store } = props;
  return (
    <div className={"journal-item"}>
      <Header
        journalItem={journalItem}
        store={store}
        className="journal-item-block"
      />
      {journalItem.changes.map((changeItem, i) => {
        return (
          <ChangeItem
            key={i}
            changeItem={changeItem}
            store={store}
            className="journal-item-block"
          />
        );
      })}
      {journalItem.notes &&
        <Notes
          notes={journalItem.notes}
          className="journal-item-block"
        />
      }
    </div>
  );
});

JournalItem.propTypes = {
  journalItem: PropTypes.object.isRequired, 
  className:   PropTypes.string, 
  store:       PropTypes.instanceOf(IssueStore).isRequired
};


export default JournalItem;
