/**
 * A component to show a target item from objectStore in tracker Tools
 */
import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

import AisIcon from "~/core/components/AisIcon";
import useStores from "../utils/useStores";
import { Preloader } from "@ais3p/ui-framework-old";

/**
 * Компоент для отображения информации об отслеживаемом объекте АИС
 *
 * @param {Object}  trackedItem информаци об отслеживаемом объекте АИС
 * @param {Function}  onTargetClick callback ф-я при клике по компоненте
 */
const Target = observer(({ trackedItem, onTargetClick }) => {
  const { objectStore } = useStores();
  const [isPending, setIsPending] = useState(false);
  const [fetched, setFetched] = useState(false);

  const getItem = useCallback(async() => {
    const uid = trackedItem && trackedItem.uid;
    const tool = trackedItem && trackedItem.tool;
    const version = (trackedItem && trackedItem.version) || 0;
    if (uid && tool && !isPending) {
      setIsPending(true);
      const item = objectStore.getVersion(uid, tool, version);
      if (!item && !fetched) {
        await objectStore.fetchRepresentation(uid, tool, version);
        setFetched(true);
      }
      setIsPending(false);
    }
  }, [isPending, trackedItem, fetched]);

  const item = useMemo(() => {
    if (!isPending && trackedItem) {
      const item = objectStore.getVersion(
        trackedItem.uid,
        trackedItem.tool,
        trackedItem.version
      );
      if (!item || !item.title) {
        getItem();
      }
      return item;
    }
  }, [isPending, trackedItem]);

  const onClick = useCallback(() => {
    if (item && onTargetClick) {
      onTargetClick(item);
    }
  }, [item, onTargetClick]);

  const parent = useMemo(() => {
    if (!isPending && trackedItem) {
      const item = objectStore.getVersion(
        trackedItem.parent,
        trackedItem.tool,
        trackedItem.version
      );
      if (!item || !item.title) {
        getItem();
      }
      return item;
    }
  }, [isPending, trackedItem]);

  if (!trackedItem || !trackedItem.tool || !trackedItem.uid) {
    return (
      <div className="object-holder">
        <div className="target-object">
          <AisIcon icon="cancel-M" />
          <div className="object-name">Не задан объект для отслеживания</div>
        </div>
      </div>
    );
  }

  if (isPending) {
    return (
      <div className="object-holder">
        <div className="target-object">
          <Preloader size={1.5} />
        </div>
      </div>
    );
  }

  return (
    <div className="object-holder">
      <div className="target-object" onClick={onClick}>
        <AisIcon backgroundColor="#4d4d4d" item={trackedItem} />
        <div className="object-name">
          {!!parent && `${parent.title} - `}
          {(item && item.title) || "Не задан объект для отслеживания"}
        </div>
      </div>
    </div>
  );
});

export default Target;
