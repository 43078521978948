/**
 * Kinds and Attributes item view
 */
import React from "react";
import { observer } from "mobx-react";
import { Preloader } from "@ais3p/ui-framework-old";
import "react-tabs/style/react-tabs.css";
// import "./css/index.scss";

const ItemView = observer(({ item }) => {
  if (!item || (item && item.kindsSize <= 0)) {
    return (
      <div className="item-content">
        <div className="header">
          <span className="text-holder">Объект не имеет видов</span>
        </div>
      </div>
    );
  }

  if (item.pending) {
    return (
      <div className="item-content">
        <Preloader />
      </div>
    );
  }

  const content = [];
  return <div className="item-content">{content}</div>;
});

export default ItemView;
