import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import File from "../../viewer/components/File";
import useStores from "~/core/utils/useStores";
import { ContextMenuTrigger, Icon, Modal } from "@ais3p/ui-framework-old";

const InlinePicture = observer(({ data, measure }) => {
  const { uid, value, isEditingPicture, diffClass } = data;
  const { layoutStore } = useStores();
  const [loaded, setLoaded] = useState(null);

  const onLoad = useCallback((data) => {
    setLoaded(data);
    measure && measure();
  });

  useEffect(() => {
    onLoad(null);
  }, [value]);

  const contextMenuCollect = useCallback((props) => {
    const menuItems = [
      {
        icon:  "editing-M",
        title: "Изменить рисунок",
        data:  { action: "editPicture" }
      },
      {
        icon:  "delete-M",
        title: "Удалить",
        data:  { action: "delete" }
      }
    ];
    layoutStore.menuCollect({
      menuItems,
      onItemClick: props.onItemClick
    });
    return props;
  }, []);

  const onMenuClick = useCallback((e, contextMenuData) => {
    const { action } = contextMenuData;
    switch (action) {
      case "delete":
        data.selfDestruct();
        break;
      case "editPicture":
        data.setEditingPicture(true);
        break;
      default:
        break;
    }
  }, []);

  const [src, setSrc] = useState(null);
  const refInput = useRef();
  const canSave = null;

  const unsetIsEditingPicture = useCallback(() => {
    data.setEditingPicture();
  }, []);

  const onSelectImage = useCallback(async(e) => {
    const file = e.target.files[0];
    try {
      const src = await data.uploadFile(file);
      setSrc(src);
    } catch (ex) {
      console.warn(ex.message);
    }
  }, []);

  const onOk = useCallback(async() => {
    await data.setValue(src);
    unsetIsEditingPicture();
  }, [src]);

  const onBrowse = useCallback(() => {
    if (refInput && refInput.current) {
      refInput.current.value = null;
      refInput.current.click();
    }
  }, [refInput]);

  return (
    <span
      id={`${uid}`}
      contentEditable={false}
      className={`inline-picture ${diffClass}`}
    >
      <ContextMenuTrigger
        item={data}
        id={"contextMenuId"}
        menuType={"LOCAL"}
        onItemClick={onMenuClick}
        holdToDisplay={-1}
        collect={contextMenuCollect}
      >
        {value && (
          <File
            file={value}
            type={"image"}
            className={"inline-picture"}
            onLoad={onLoad}
            loaded={loaded}
          />
        )}
        {!value && (
          <Icon
            data-tooltip="Нет изображения"
            data-tooltip-at="bottom"
            className="picture-image-empty-icon"
            icon={"image-M"}
          />
        )}
        <Modal
          visible={isEditingPicture}
          toggle={unsetIsEditingPicture}
          title={"Рисунок"}
          className={"picture-editor-modal dark"}
          modalContent={
            <div className={"picture-editor"}>
              <div className={"picture-editor-image"}>
                <a
                  className={"picture-editor-image-browse"}
                  href="#"
                  onClick={onBrowse}
                >
                  Обзор
                </a>
                <input
                  type="file"
                  accept="image/*"
                  ref={refInput}
                  onChange={onSelectImage}
                  style={{ display: "none" }}
                />
                {!src && (
                  <Icon
                    className="picture-editor-image-empty-icon"
                    icon={"image-M"}
                  />
                )}

                {src && <InlinePicture data={{ uid: "temp", value: src }} />}
              </div>
            </div>
          }
          buttons={[
            {
              onClick:  onOk,
              size:     2,
              style:    { fontWeight: 100 },
              theme:    "info",
              leftIcon: "ok-M",
              text:     "Сохранить",
              disabled: canSave
            },
            {
              onClick:  unsetIsEditingPicture,
              size:     2,
              style:    { fontWeight: 100 },
              theme:    "danger",
              leftIcon: "cancel-M",
              text:     "Отмена"
            }
          ]}
        />
      </ContextMenuTrigger>
    </span>
  );
});
export default InlinePicture;
