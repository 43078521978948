import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field } from "@ais3p/ui-framework-old";
import useStores from "../../../../core/utils/useStores";

const AttrContent = ({ name, value, className, onChange, attrName }) => {
  const { kindsStore } = useStores();
  const [isEditMode, setEditMode] = useState(false);
  const selElRef = useRef();
  useEffect(() => {
    window.addEventListener("click", onMouseClick);
    return () => {
      window.removeEventListener("click", onMouseClick);
    };
  }, []);

  const options = useMemo(() => {
    const attr = kindsStore.getAttrByName(attrName);
    if (!attr || !attr.options) {
      return [];
    }
    return attr.options.map((name) => {
      return { label: name, value: name };
    });
  }, [attrName]);

  const onMouseClick = useCallback((e) => {
    const el = e.target.closest(".select");
    if (!el) {
      // кликнули не в поле select
      setEditMode(false);
    }
  }, []);

  const onClick = useCallback((e) => {
    e.stopPropagation();
    setEditMode(true);
  }, []);

  const onChangeValue = useCallback(
    (val) => {
      onChange && onChange(name, val && val.value);
    },
    [name, isEditMode, value, onChange]
  );

  const renderValue = (value) => {
    return value || "Не выбрано";
  };

  return (
    <div
      className={classNames("selectable-value", className, {
        editing: isEditMode,
        yes:     !!value,
        no:      !value
      })}
    >
      {!isEditMode && <div onClick={onClick}>{renderValue(value)}</div>}
      {isEditMode && (
        <Field.Select
          ref={selElRef}
          labelOnTop={false}
          value={{
            value,
            label: renderValue(value)
          }}
          onChange={onChangeValue}
          isMulti={false}
          isClearable={false}
          options={options}
        />
      )}
    </div>
  );
};

AttrContent.propTypes = {
  name:      PropTypes.string.isRequired,
  value:     PropTypes.string,
  attrName:  PropTypes.string,
  onChange:  PropTypes.func,
  className: PropTypes.string
};

export default AttrContent;
