import React from "react";
import PropTypes from "prop-types";
import Prism from "prismjs";
import PrismHilight from "./PrismHilight";

/**
 * Компонент для отображения кода в MarkdownView
 * 
 * @param {Object} props набор параметров
 * @param {Boolean} params.inline
 * @param {String} params.className  пользовательский className
 * @param {Array<Element>} params.children
 * @returns 
 */
const Code = ({ inline, className, children, ...props }) => {
  const match = /language-(\w+)/.exec(className || "");
  const lang = match && String(match[1]);

  if (!inline && lang && Prism.languages[lang]) {
    return (
      <PrismHilight 
        language={lang} 
        // eslint-disable-next-line react/no-children-prop
        children={children} 
        className={className} 
      />
    );
  }

  return (
    <code className={`code ${className ? className : "inline-block"}`} {...props}>
      {children}
    </code>
  );
};

Code.propTypes = {
  inline:    PropTypes.bool,
  className: PropTypes.string,
  children:  PropTypes.arrayOf(Element)
};

export default Code;
