import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ChangeAction from "./ChangeAction";

import { propertyNameText } from "../../constants/propertyNames";
import { propertyTypeText, CHG_PROPERTY_ATTACHMENT } from "../../constants/propertyTypes";
import IssueStore from "../../stores/IssueStore";

/**
 * Компонет для отображения информации о параметре изменений в записи журнала изменений
 * 
 * @param {Object} props набор параметров
 * @param {Object} params.changeItem запись с данными о сделанных изменениях
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {String} params.className пользовательский className
 */
const ChangeItem = ({ changeItem, store, className }) => {
  const { property, name, oldValue, newValue } = changeItem;
  return (
    <div className={`journal-item-change ${className ? className : ""}`}>
      <div className="journal-item-change-body">
        <div className="property-type inline" >
          {propertyTypeText(property)}
        </div>
        {property !== CHG_PROPERTY_ATTACHMENT &&
          <div className={classNames("property-name", "inline", {
            removed: !!oldValue && !newValue
          })}
          >
            {propertyNameText(name)}
          </div>
        }
        <ChangeAction
          store={store}
          property={property}
          name={name}
          oldValue={oldValue}
          newValue={newValue}
        />
      </div>
    </div>
  );
};

ChangeItem.propTypes = {
  changeItem: PropTypes.object.isRequired, 
  store:      PropTypes.instanceOf(IssueStore).isRequired, 
  className:  PropTypes.string
};

export default ChangeItem;
