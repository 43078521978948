import { computed } from "mobx";
import Varchar from "./Varchar";

class CodeLineChunk extends Varchar {
  @computed
  get slug() {
    return "chunks";
  }

  @computed
  get category() {
    return "chunks";
  }
}

export default CodeLineChunk;
