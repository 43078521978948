import { action, computed, observable } from "mobx";
import Chunk from "./Chunk";

class InlineFormula extends Chunk {
  @observable
  captionId = null;

  @action
  init(data, parent) {
    if (parent) {
      this.setParent(parent);
    }
    this.className = data.class;
    this.isNew = !!data.isNew;
    this.setValue(data.value || "", true);
  }

  @action
  setEditing(offset) {
    this.store.setEditingChunkId(this.uid, offset);
  }

  @computed
  get flatItemsArray() {
    return [this];
  }

  @computed
  get isFormulaChunk() {
    return true;
  }
}

export default InlineFormula;
