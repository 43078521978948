import { observable, computed, action } from "mobx";

/**
 * Модель записи журнала действия пользователя
 *
 * @class JournalModel
 */
class JournalModel {
  /**
   * uid записи
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * идентификатор пользователя
   *
   * @type String
   */
  @observable
  subject = undefined;

  /**
   * идентификатор объекта, над которым происходит действие;
   *
   * @type String
   */
  @observable
  object = undefined;

  /**
   * класс объекта в системе АИС ППП;
   *
   * @type String
   */
  @observable
  class = undefined;

  /**
   * версия объекта в системе АИС ППП, если он поддерживает версионность, соответственно параметр опциональный;
   * @type Number
   */
  @observable
  version = undefined;

  /**
   * тип действия (create, read, update, delete);
   * @type String
   */
  @observable
  action = undefined;

  /**
   * временная метка действия;
   * @type DateTime
   */
  @observable
  datetime = undefined;

  /**
   * полезная нагрузка, опциональный параметр, содержит новое состояние объекта;
   * @type Object
   */
  @observable
  payload = undefined;

  /**
   * Хранилище журнала
   *
   * @type JournalStore
   */
  @observable
  journalStore = undefined;

  /**
   * Флаг переключения отображения/скрытия payload
   *
   * @type Boolean
   */
  @observable
  isShowPayload = false;

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid uid записм
   * @param {String} params.subject  uid - идентификатор пользователя;
   * @param {String} params.object uid - идентификатор объекта, над которым происходит действие;
   * @param {String} params.class класс объекта в системе АИС ППП;
   * @param {Number} params.version версия объекта в системе АИС ППП
   * @param {String} params.action тип действия (create, read, update, delete);
   * @param {DateTime} params.datetime временная метка действия;
   * @param {Object} params.payload полезная нагрузка, опциональный параметр, содержит новое состояние объекта;
   * @param {UserStore} userStore хранилище пользователей в АИС
   *
   * @retrun {JournalModel}
   */
  static create({
    uid,
    subject,
    object,
    class:klass,
    version,
    action,
    datetime,
    payload
  }, journalStore) {
    return new JournalModel({
      uid,
      subject,
      object,
      class: klass,
      version,
      action,
      datetime,
      payload
    }, journalStore);
  }

  constructor(params, journalStore) {
    this.uid = params.uid;
    this.subject = params.subject;
    this.object = params.object;
    this.class = params.class;
    this.version = params.version;
    this.action =  params.action;
    this.datetime = params.datetime;
    this.payload = params.payload;
    this.journalStore = journalStore;
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "journalModel";
  }

  /**
   * @computed
   * Строковое представление записи журнала
   *
   * @return {String}
   */
  @computed
  get title() {
    return `uid объекта: "${this.object}"; действие: "${this.action}"`;
  }

  /**
   * @computed
   * Объект АИС пользователя
   *
   * @return {UserModel}
   */
  @computed
  get user() {
    if (!this.journalStore) {
      console.error("У модели JournalModel не задан journalStore!");
      return undefined;
    }

    return this.journalStore.getUser(this.subject);
  }

  /**
   * @action
   * Триггер переключения отображения/скрытия payload
   *
   */
  @action
  toggleShowPayload() {
    this.isShowPayload = !this.isShowPayload;
  }
}

export default JournalModel;
