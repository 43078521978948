import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { ContextMenuTrigger } from "@ais3p/ui-framework-old";

import useStores from "~/core/utils/useStores";
import { DOMAIN_LIBRARY } from "~/core/constants/Domains";

const TreeNode = observer(
  ({
    isVersion,
    item,
    nodeKey,
    onMenuClick,
    collect,
    menuType,
    isEditing,
    parentUid,
    onBlur: onNodeBlur,
    onKeyDown: onNodeKeyDown
  }) => {
    const { objectStore } = useStores();
    const storedItem = objectStore.getVersion(item.data.uid, DOMAIN_LIBRARY);
    if (!storedItem) {
      return null;
    }
    const { name, title, isPending, etype, permissions } = storedItem;
    const inputEl = useRef(null);

    useEffect(() => {
      isEditing && inputEl && inputEl.current && inputEl.current.focus();
    }, [isEditing]);

    const onBlur = function() {
      const text = inputEl && inputEl.current && inputEl.current.textContent;
      if (text) {
        onNodeBlur(parentUid, text);
      }
    };

    const onKeyDown = function(e) {
      const text = inputEl && inputEl.current && inputEl.current.textContent;
      onNodeKeyDown(e, parentUid, text);
    };

    useEffect(() => {
      if (
        !isPending &&
        isPending !== null &&
        etype === "library.TextMaterial"
      ) {
        onMenuClick({}, { action: "refresh", item, parentUid });
      }
    }, [isPending, etype]);

    let content = (
      <div className="version-user">
        <div className="ais-wm-version-number">{name}</div>
      </div>
    );

    if (!isVersion) {
      if (isEditing) {
        content = (
          <div
            className="node-name"
            contentEditable={isEditing}
            onBlur={onBlur} //eslint-disable-line
            onKeyDown={onKeyDown} //eslint-disable-line
            ref={inputEl}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        );
      } else {
        content = <div className="node-name">{isPending ? "..." : name}</div>;
      }
    }

    return (
      <ContextMenuTrigger
        item={item}
        expandedNodes={item.expandedNodes}
        toggleFunc={item.toggleFunc}
        parentId={item.parent && item.parent.uid}
        itemKey={nodeKey}
        id={"treeId"}
        menuType={menuType}
        onItemClick={onMenuClick}
        holdToDisplay={-1}
        collect={collect}
      >
        <div className={`node-inner-content ${!permissions.get("read") ? "disabled" : "enabled"}`}>
          <div className="node-inner ais-wm-node-inner" title={title}>
            {content}
          </div>
        </div>
      </ContextMenuTrigger>
    );
  }
);

export default TreeNode;
