import { observable, computed, action } from "mobx";
import SchemaBoundaryModel from "./schemaBoundary";
import { v4 as uuid } from "uuid";
import { getClassTitle } from "~/core/constants/Classes";

/**
 * Модель записи Доли в схеме отчетов о трассировке
 *
 * @class  SchemaObjectItemModel
 */
class SchemaObjectItemModel {
  /**
   * uid 
   *
   * @type String
   */
  @observable
  uid = undefined;


  /**
   * Target
   *
   * @type Object
   */
  @observable
  target = undefined;

  /**
   * Описание
   *
   * @type String
   */
  @observable
  description = undefined;

  /**
   * Ограничение
   *
   * @type Array<Object>
   */
  @observable
  boundariesMap = new Map();

  /**
   * Описание параметров 
   *
   * @type Object
   */
  @observable
  parameters = {};

  
  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {Array<Object>} params.boundaries ограничения
   * @param {String} params.description направление связи
   * @param {Object} params.target target
   * @param {Object} parameters описание параметров схемы
   *
   * @retrun {SchemaObjectItemModel}
   */
  static create({
    boundaries,
    description,
    target
  }, parameters, rootStore) {
    return new SchemaObjectItemModel({
      boundaries,
      description,
      target
    }, parameters, rootStore);
  }

  constructor(data, parameters, rootStore) {
    this.uid = uuid();
    this.description = data.description;
    this.target = data.target;
    (data.boundaries || []).forEach((b) => {
      this.addBoundary(SchemaBoundaryModel.create(b, parameters, rootStore));
    });
    this.parameters = parameters;

    this.rootStore = rootStore;
  }

  get schemaItemType() {
    return "object";
  }

  @action
  addBoundary(boundary) {
    this.boundariesMap.set(boundary.uid, boundary);
  }

  @computed
  get boundaries() {
    return Array.from(this.boundariesMap.values());
  }

  getBoundary(uid) {
    this.boundariesMap.get(uid);
  }
  
  /**
   * Строковое представление
   *
   * @return {String}
   */
  @computed
  get title() {
    // if (this.target && this.target.class) {
    //   return `${getClassTitle(this.target.class)} - ${this.description}`;
    // }
    return this.description;
  }

  @computed
  get targetTitle() {
    return this.target && this.target.class && getClassTitle(this.target.class);
  }

  /**
   * Флаг, указывающий, что все параметры для ограничений выставлены
   * 
   * @return {Boolean}
   */
  @computed
  get isValid() {
    const res = this.boundaries.every((b) => {
      return b.isValid;
    });
    return res;
  }

  /**
   * Значения, которые задал сам пользователь
   * 
   * @return {Object}
   */
  @computed
  get values() {
    let res = {};
    this.boundaries.forEach((b) => {
      if (b.name && b.value) {
        res = { ...res, [b.name]: {
          class:   b.value.class,
          id:      b.value.uid || b.value.id,
          version: b.value.version
        } };
      }
    });
    return res;
  }

  /**
   * Сброс пользовательских значений
   */
  reset() {
    this.boundaries.forEach((b) => {
      b.reset();
    });
  }
}

export default SchemaObjectItemModel;
