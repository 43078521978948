import React, { useCallback, useState, useMemo } from "react";
import { observer } from "mobx-react";
import { ToolBar } from "@ais3p/ui-framework-old";

import File from "../components/File";

import "./css/index.scss";

/**
 * Инструмент для просмотра файлов: изображение, pdf
 *
 * @param {String} className пользовательский className для просмотра файла
 * @param {String} type тип файла
 * @param {String} name название файла
 * @param {String} icon название файла
 * @param {String} file id файла в файловом хранилище
 * @param {String} fileBlob url локального Blob файла, который уже был загружен.
 * Те это файл, который уже был загружен клиентом и его не нужно брать из файлового хранилища АИС
 */
const AisViewer = observer((props) => {
  const { className, type, name, icon } = props;
  const [file, setFile] = useState(props.file);
  const [loaded, setLoaded] = useState(props.fileBlob);

  const onRefresh = useCallback(() => {
    setFile(undefined);
    setLoaded(undefined);
    setFile(props.file);
    setLoaded(props.fileBlob);
  }, []);

  const onLoad = useCallback((file) => {
    setLoaded(file);
  }, []);

  const buttons = useMemo(() => {
    return {
      left: [
        {
          id:       "refresh",
          icon:     "refresh-M",
          title:    "Обновить",
          callback: onRefresh
        }
      ],
      right: []
    };
  }, [onRefresh]);

  return (
    <div className="viewer">
      <ToolBar buttons={buttons} />
      <div className="viewer-content">
        <File
          file={file}
          type={type}
          className={className}
          name={name}
          icon={icon}
          onLoad={onLoad}
          loaded={loaded}
        />
      </div>
    </div>
  );
});

export default AisViewer;
