import React from "react";
import PropTypes from "prop-types";
import classNames  from "classnames";
import Line from "../../models/Line";

/**
 * Компонент для отображения содержимого линии в коде, состоящей из токенов
 * 
 * @param {Object} params набор параметров
 * @param {Object} params.index индекс линии
 * @param {String} params.className пользовательский className
 * @param {Array<Token>} params.line данные о линии в коде- массив токенов
 * @param {Object} params.getTokenProps callback ф-я которая должна вернуть св-ва, 
 *                                 чтобы выставить их DOM компоненте для отрисовки токена
 * 
 * @type {CodeLineContent}
 * @returns {Component}
 */
const CodeLineContent = ({ className, line, getTokenProps, isFocused }) => {
  return (
    <div
      className={classNames("code-line-content", className, {
        focused: isFocused
      })}
    >
      {line.tokens.map((token, key) => {
        return (
          <span 
            key={key} 
            className="code-token"
            {...getTokenProps({ token, key })} 
          />
        );
      })}
    </div>
  );
};

CodeLineContent.propTypes = {
  className:     PropTypes.string,
  line:          PropTypes.instanceOf(Line),
  getTokenProps: PropTypes.func,
  isFocused:     PropTypes.bool
};


export default CodeLineContent;
