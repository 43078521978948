import { observer } from "mobx-react";
import React, { useCallback, useMemo } from "react";

const Dimention = observer(
  ({ x, y, onClick, hoveredX, hoveredY, setHoveredX, setHoveredY }) => {
    const onClickItem = useCallback(
      (e) => {
        e.stopPropagation();
        onClick && onClick(x + 1, y + 1);
      },
      [x, y, onClick]
    );

    const onMouseEnter = useCallback(
      (e) => {
        e.stopPropagation();
        setHoveredX(x);
        setHoveredY(y);
      },
      [x, y, setHoveredX, setHoveredY]
    );

    const onMouseLeave = useCallback(
      (e) => {
        e.stopPropagation();
        setHoveredX(null);
        setHoveredY(null);
      },
      [x, y, setHoveredX, setHoveredY]
    );

    const hovered = useMemo(() => {
      if (x <= hoveredX && y <= hoveredY) {
        return "hovered";
      }
      return "";
    }, [hoveredX, hoveredY]);

    return (
      <div
        onClick={onClickItem}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`dimention ${hovered}`}
      ></div>
    );
  }
);

export default Dimention;
