import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import ReactTable, { ReactTableDefaults } from "react-table";
import "react-table/react-table.css";

import TableColHeader from "./TableColHeader";
import TableCell from "./TableCell";
import Pagination from "~/core/components/pagination";

import SearchStore from "../../stores/searchStore";

import { getRemSizeInPx } from "~/core/utils";

import { TABLE_COLUMNS_CFG, FILTERS_CFG } from "../../constants/config";

import {
  ENTITIES_TABLE_COLUMNS,
  ENTITY_VALUE_NUM,
  ENTITY_VALUE_VERSION,
  ENTITY_VALUE_TITLE
} from "../../constants/values";

Object.assign(ReactTableDefaults, {
  // Text
  previousText: "Предыдущая",
  nextText:     "Следующая",
  loadingText:  "Загрузка...",
  noDataText:   "Нет записей",
  pageText:     "Страница",
  ofText:       "из",
  rowsText:     "строк",

  // Accessibility Labels
  pageJumpText:    "перейти на страницу",
  rowsSelectorTex: "строк на странице"
});

/**
 * Панель для отображения списка найденных сущностей в табличном представлении
 */
const EntitiesTable = observer((props) => {
  let timer = 0; // таймер для принятия решения - закончено ли изменение ширины столбца
  const delay = 1000; // задержка мс для принятия решения - закончено ли изменение ширины столбца

  const remSizeInPx = getRemSizeInPx();
  const { className, store, canBeEditable, layoutItem } = props;

  const tableActiveColumnsCfg = store.getItemConfig(TABLE_COLUMNS_CFG).active || {};
  const tableWidthColumnsCfg = store.getItemConfig(TABLE_COLUMNS_CFG).width || {};

  const [activeColumns] = useState(
    Object.keys(ENTITIES_TABLE_COLUMNS).reduce((arr, key) => {
      const isActive = Object.prototype.hasOwnProperty.call(tableActiveColumnsCfg, key) ?
        tableActiveColumnsCfg[key] : ENTITIES_TABLE_COLUMNS[key].defaultActive;
      return { ...arr, [key]: isActive };
    }, {})
  );
  const reload = () => {
    const { codes, attributes, version, kind } = store.getItemConfig(FILTERS_CFG);
    store.searchEntities(codes, attributes, version, kind);
  };

  const onPageSizeChange = useCallback((size) => {
    store.setPageSize(size);
    store.setCurrentPage(1);
    reload && reload();
  }, [store.pageSize]);

  const onPageChange = useCallback((p) => {
    store.setCurrentPage(p);
    reload && reload();
  }, [store.currentPage]);

  const getColumnWidth = useCallback((id) => {
    if (Object.prototype.hasOwnProperty.call(tableWidthColumnsCfg, id)) {
      return {
        width: tableWidthColumnsCfg[id]
      };
    }
    switch (id) {
      case ENTITY_VALUE_NUM:
        return {
          width:    remSizeInPx * 2.5,
          minWidth: remSizeInPx * 2.5,
          maxWidth: remSizeInPx * 4
        };
      case ENTITY_VALUE_TITLE: 
        return {
          minWidth: remSizeInPx * 15
        };
      case ENTITY_VALUE_VERSION:
        return {
          width:    remSizeInPx * 4,
          minWidth: remSizeInPx * 2.5,
          maxWidth: remSizeInPx * 6
        };
      default:
        return {
          width: remSizeInPx * 8
        };
    }
  }, [remSizeInPx, tableWidthColumnsCfg]);

  const columns = useMemo(() => {
    const res = Object.keys(activeColumns)
      .filter((key) => {
        return activeColumns[key];
      })
      .map((key) => {
        return {
          Header: TableColHeader,
          Cell:   (props) => {
            return (
              <TableCell
                {...props}
                key={key}
                store={store}
                layoutItem={layoutItem}
                canBeEditable={canBeEditable}
              />
            );
          },
          accessor: key,
          ...getColumnWidth(key)
        };
      });
    if (store.currentKind) {
      (store.currentKind.codesArray || []).forEach((code) => {
        const { uid, name } = code;
        res.push({
          Header:    TableColHeader,
          Cell:      TableCell,
          uid,
          name,
          keyValues: "codeValues",
          ...getColumnWidth(uid)
        });
      });

      (store.currentKind.attributes || []).forEach((attr) => {
        const { uid, name } = attr;
        res.push({
          Header:    TableColHeader,
          Cell:      TableCell,
          uid,
          name,
          keyValues: "values",
          ...getColumnWidth(uid)
        });
      });
    }
    return res;
  }, [canBeEditable, activeColumns, tableWidthColumnsCfg, 
    store.currentKind 
    // store.currentKind && store.currentKind.attributes,
    // store.currentKind && store.currentKind.codesArray
  ]);

  const onResizedChange = useCallback((newResized) => {
    clearTimeout(timer);
    // сделал такую задержку, чтобы исключить множественное сохраненение ширины столбца
    timer = setTimeout(() => {
      const cfg = store.getItemConfig(TABLE_COLUMNS_CFG);
      const { width = {} } = cfg;
      newResized.forEach(({ id, value }) => {
        width[id] = value;
      });
      store.setItemConfig(TABLE_COLUMNS_CFG, {
        ...cfg,
        width
      });
    }, delay);
  }, [tableWidthColumnsCfg]);

  const getPaginationProps = useCallback(() => {
    return {
      startPageIndex: 1,
      canPrevious:    store.canPreviousPage,
      canNext:        store.canNextPage
    };
  }, [store.canPreviousPage,  store.canNextPage]);


  return (
    <div className={classNames("entities-table-wrapper", className)}>
      <div className={classNames("entities-table-content")}>
        <ReactTable
          loading={store.isProcessing}
          manual={true} // данные с сервера
          data={store.entityList}
          columns={columns}
          filterable={false}
          sortable={false}
          onResizedChange={onResizedChange}
          pages={store.pages}
          page={store.currentPage}
          pageSize={store.pageSize}
          minRows={0}
          pageSizeOptions={[20, 50, 100]}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          PaginationComponent={Pagination}
          getPaginationProps={getPaginationProps}
        />
      </div>
    </div>
  );
});

EntitiesTable.propTypes = {
  className:     PropTypes.string, 
  store:         PropTypes.instanceOf(SearchStore), 
  canBeEditable: PropTypes.bool, 
  layoutItem:    PropTypes.object
};
export default EntitiesTable;
