import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import MessageItem from "./MessageItem";
import WSStore from "../../stores/wsStore";

/**
 * @type MessageList
 * 
 * Компонент для отображения списка сообщений, полученных по ws
 * 
 * @prop {WSStore} store хранилище для работы с ws пакетами
 */
const MessageList = observer(({ store }) => {
  return (
    <div className="message-list" >
      {store.messages.length === 0 ?
        <div className="wating-data" > Ожидание данных...</div> :
        store.messages.map((msg, i) => {
          return <MessageItem key={i} num={store.messages.length - i} message={msg} />;
        })
      }
    </div>
  );
});

MessageList.propTypes = {
  store: PropTypes.instanceOf(WSStore)
};

export default MessageList;
