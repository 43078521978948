import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { components } from "@ais3p/ui-framework";
import { observer } from "mobx-react";

import SearchStore from "../../stores/searchStore";


const { buttons, toolbar } = components;
/**
 * Toolbar для поиска объектов АИС
 * 
 * @param {Object} params набор параметров
 * @param {SearchStore} params.store хранилизе для поиска
 
 */
const SearchToolbar = observer(({ store }) => {
  const toggleShowFilters = useCallback(() => {
    store.toggleShowFilters();
  }, []);

  const right = useMemo(() => {
    return [
      <buttons.Button
        key="filter"
        icon="filiter-M"
        tooltip="Фильтр"
        isSelected={store.isShownFilters}
        onPress={toggleShowFilters}
      />
    ];
  }, [store.isShownFilters]);

  return (
    <toolbar.ToolBar right={right} />
  );
});

SearchToolbar.propTypes = {
  store: PropTypes.instanceOf(SearchStore) 
};

export default SearchToolbar;
