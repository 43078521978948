import { observable, action, computed } from "mobx";
import KindsApi from "../api/KindsApi";
import Kind from "../models/Kind";
import Attr from "../models/Attr";
import KindItem from "../models/KindItem";
import TraceSchema from "~/modules/traceReporter/models/traceSchema";

class KindsStore {
  @observable
  domain = "kinds";

  @observable
  rootStore = null;
  @observable
  configStore = null;
  @observable
  newKindFormisVisible = false;

  @observable
  pendingAttributes = false;

  @observable
  pendingItems = false;
  @observable
  pendingMembers = false;

  @observable
  pendingKinds = false;

  @observable
  tools = new Map();

  @observable
  kindsAllows = new Map();

  @observable
  kinds = new Map();

  @observable
  kindsByName = new Map();
  @observable
  attrsByName = new Map();

  @observable
  attrs = new Map();
  @observable
  kindItems = new Map();
  @observable
  kindMembers = new Map();
  @observable
  itemsByKindMap = new Map();
  @observable
  selectedKindItem;

  constructor(root) {
    this.rootStore = root;
    this.configStore = this.rootStore.configStore;
    this.objectStore = this.rootStore.objectStore;
    this.api = new KindsApi(this.rootStore);
  }

  @action
  async init(withLocal = false) {
    await this.getKinds(withLocal);
  }

  getToolConfig(id) {
    const config = this.configStore.getToolConfig(id);
    return config;
  }

  getKindByName(name) {
    return this.kindsByName.get(name);
  }

  getAttrByName(name) {
    return this.attrsByName.get(name);
  }

  @action
  createKind(data) {
    const allows = this.configStore.kindsAllows(data.name);
    let allowedTypes = [];
    let allowedKinds = [];

    if (allows) {
      allowedTypes = allows.allowedTypes || [];
      allowedKinds = allows.allowedKinds || [];
    }

    const kind = new Kind({ ...data, allowedTypes, allowedKinds }, this);
    this.kinds.set(kind.id, kind);
    this.kindsByName.set(kind.name, kind);
    return kind;
  }

  @action
  async save() {
    this.currentItem.save();
  }

  @action
  deleteMemberFromKind(kindUid, memberUid) {
    const member = this.kindItems.get(memberUid);
    member.deleteKind(kindUid);
  }

  @action
  deleteKindMember(memberUid) {
    this.kindMembers.delete(memberUid);
  }

  @action
  deleteKind(kindUid) {
    const members = this.itemsByKindMap.get(kindUid);
    if (members) {
      members.forEach((member) => {
        member.deleteKind(kindUid);
      });
    }
    this.itemsByKindMap.delete(kindUid);
    this.kinds.delete(kindUid);
  }

  @action
  async delete(uid, parentUid, callback) {
    const member = this.kindItems.get(uid);
    const kind = this.kinds.get(uid);
    if (member) {
      await this.api.deleteKindMember(parentUid, uid);
      this.deleteMemberFromKind(parentUid, uid);
    }
    if (kind) {
      await this.api.deleteKind(uid);
      this.deleteKind(uid);
    }
    await callback(parentUid);
  }

  @action
  getAllKindsForTree(uid) {
    const payload = {};
    const children = [];

    this.kinds.forEach((kind) => {
      children.push({
        type:         "kind",
        name:         kind.name,
        uid:          kind.id,
        payload:      {},
        isExpandable: true
      });
    });

    const expandData = {
      name: "Kinds tree root",
      uid,
      children,
      payload
    };

    return expandData;
  }

  @action
  async getAllMembersForKindInTree(uid) {
    await this.getKindItems(uid);

    const children = [];

    const items = this.itemsByKindMap.get(uid);

    if (items) {
      items.forEach((item) => {
        children.push({
          type:         "kind-member",
          etype:        item.etype,
          kind:         uid,
          name:         item.name,
          uid:          item.id,
          children:     [],
          isExpandable: false,
          payload:      {}
        });
      });
    }

    const kind = this.kinds.get(uid);
    const expandData = {
      name:    kind.name,
      uid,
      children,
      payload: {}
    };

    return expandData;
  }

  @action
  addKindToItem(uid, version = 0, kindUidArray) {
    const item = this.kindItems.get(`${uid}-${version}`);
    if (item) {
      item.replaceKinds(kindUidArray);
    } else {
      throw new Error("Такого участника не существует");
    }
  }

  @action
  addKind(uid, version = 0, kindUid) {
    let item = this.kindItems.get(`${uid}-${version}`);
    if (!item) {
      item = this.createEmptyItem(uid, version);
    }
    item.addKind(kindUid);
    return item;
  }

  @action
  changeItemKinds(uid, version = 0, kindUidArray) {
    let item = this.kindItems.get(`${uid}-${version}`);
    if (!item) {
      item = this.createEmptyItem(uid, version);
    }
    item.replaceKinds(kindUidArray);
  }

  @action
  setPendingKinds(pending = false) {
    this.pendingKinds = pending;
  }

  @action
  setPendingAttributes(pending = false) {
    this.pendingAttributes = pending;
  }

  @action
  async getKinds(withLocal = false) {
    this.setPendingKinds(true);
    this.setPendingAttributes(true);
    this.kinds.clear();

    const kinds = await this.api.getAllKinds(withLocal);
    if (kinds && kinds.length) {
      kinds.forEach((kindData) => {
        kindData.attributes &&
          kindData.attributes.forEach((attrData) => {
            this.createAttr(attrData);
          });
        this.createKind(kindData);
      });
    }
    await this.loadTraceSchemas();
    this.setPendingKinds(false);
    this.setPendingAttributes(false);
  }

  @action
  async getAttrAsync(uid, withLocal = false) {
    const attrData = await this.api.getAttr(uid, withLocal);
    if (attrData) {
      this.createAttr(attrData);
    }
  }

  @action
  async setItem() {
    // this.setKindItem();
    // await this.getItem(uid);
    // this.setKindItem(uid);
  }

  /**
   * gets kindItems as array
   *
   * uids {array} - array of requested aggr uids
   */
  @action
  async getItems(uids = [], version = 0) {
    this.setPendingItems(true);
    const data = await this.getMembers(uids, version);
    const array = this.processMembers(data, version);
    this.setPendingItems(false);
    return array;
  }

  @action
  processMembers(data, version = 0) {
    const array = [];
    if (data) {
      if (data) {
        if (data.length) {
          data.forEach((memberData) => {
            if (memberData) {
              array.push(this.createKindItem(memberData, version));
            }
          });
        } else {
          array.push(this.createKindItem(data, version));
        }
      }
    }
    return array;
  }

  @action
  async getMembers(uids = [], version = 0) {
    const pairArray = uids.map((id) => {
      return { id, version };
    });
    return await this.api.getMembers(pairArray);
  }

  @action
  getItem(uid, version = 0) {
    let item = this.kindItems.get(`${uid}-${version}`);
    if (item) {
      return item;
    } else {
      item = this.createEmptyItem(uid, version);
      this.getMember(uid, version); // TODO mock for data
      return item;
    }
  }

  @action
  getItemSync(uid, version) {
    return this.kindItems.get(`${uid}-${version}`);
  }

  @action
  getItemKindNames(uid, version = 0) {
    const item = this.getItemSync(uid, version);
    return item ? item.kindNames : [];
  }

  @action
  async changeItemId(id, newId, version = 0) {
    const item = this.kindItems.get(`${id}-${version}`);
    item && item.setId(newId);
    this.kindItems.set(`${newId}-${version}`, item);
    this.kindItems.delete(`${id}-${version}`);
    const newItem = this.kindItems.get(`${newId}-${version}`);
    newItem && await newItem.save();
    return newItem;
  }

  @action
  setPendingItems(pending = false) {
    this.pendingItems = pending;
  }

  @action
  setPendingMembers(pending = false) {
    this.pendingMembers = pending;
  }

  @action
  async getMember(uid, version = 0) {
    this.setPendingMembers(true);
    this.createEmptyItem(uid, version);
    const data = await this.api.getMember(uid, version);
    this.processMembers(data, version);
    this.setPendingMembers(false);
  }

  @action
  createKindItem(data, version = 0) {
    if (!data || !data.objectId) {
      return null;
    }
    let item = this.objectStore.getVersion(data.objectId, this.domain, version);
    if (!item) {
      item = new KindItem({ ...data, version }, this);
      this.objectStore.addVersion(item);
    } else {
      item.addKindData(data);
    }
    this.kindItems.set(`${item.uid}-${version}`, item);
    return item;
  }

  @action
  addKindMember(kindMember) {
    this.kindMembers.set(kindMember.memberUid, kindMember);
  }

  @action
  getKindMemberSync(uid) {
    return this.kindMembers.get(uid);
  }

  @action
  createEmptyItem(uid, version = 0) {
    if (uid) {
      const item = new KindItem(
        { objectId: uid, version, etype: "aggr.kindsattrs.item.empty" },
        this
      );
      this.objectStore.addVersion(item);
      this.kindItems.set(`${item.uid}-${version}`, item);
      return item;
    }
  }

  @action
  showKindForm() {}

  @action
  hideForm() {}

  @action
  createAttr(data) {
    const attr = new Attr(data, this);
    this.attrs.set(attr.id, attr);
    this.attrsByName.set(attr.name, attr);
  }

  @action
  async getKindItems(kindId) {
    const items = await this.api.getKindMembers(kindId);
    const thisKindItems = [];
    if (items) {
      items.forEach((itemData) => {
        const { uid, etype } = itemData.object;
        let item = this.kindItems.get(uid);
        if (item) {
          item.addKindData(itemData);
        } else {
          const data = {
            uid,
            name:  uid,
            etype,
            kinds: {
              [kindId]: {
                member: itemData.uid,
                values: itemData.values
              }
            }
          };
          item = this.createKindItem(data);
        }
        thisKindItems.push(item);
      });
    }
    this.itemsByKindMap.set(kindId, thisKindItems);
  }

  @action
  setKindItem() {
    // this.selectedKindItem = uid;
  }

  @action
  async createProject() {
    const data = await this.api.createKind({
      name:       "Проект",
      attributes: [
        {
          name: "Индекс",
          type: "string"
        },
        {
          name: "Название проекта",
          type: "string"
        },
        {
          name: "Описание",
          type: "string"
        }
      ]
    });
    this.createKind(data);
  }

  getKind(uid) {
    return this.kinds.get(uid);
  }

  getAttr(uid) {
    return this.attrs.get(uid);
  }

  @computed
  get currentItem() {
    return this.kindItems.get(this.selectedKindItem);
  }

  @computed
  get isPendingData() {
    return this.pendingItems || this.pendingMembers;
  }

  @computed
  get isPendingAttributes() {
    return this.pendingAttributes;
  }

  @computed
  get isPendingKinds() {
    return this.pendingKinds;
  }

  @computed
  get isItemsPending() {
    let pending = false;
    this.kindItems.forEach((item) => {
      if (item && item.isPending) {
        pending = true;
      }
    });
    return pending;
  }

  @computed
  get isPending() {
    return this.isPendingAttributes || this.isPendingKinds;
  }

  @computed
  get kindsForSelect() {
    const kindsArray = [];
    this.kinds.forEach((kind) => {
      kindsArray.push({
        value: kind.id,
        label: kind.name,
        icon:  this.rootStore.accountStore.getIcon(kind.name)
      });
    });
    return kindsArray;
  }

  /**
   * Загружаем набор схем трассировок для Видов
   */
  async loadTraceSchemas() {
    const schemasData = await this.api.loadTraceSchemas() || [];
    this.kinds.forEach((kind) => {
      kind.clearTraceSchemas();
    });

    schemasData.forEach((data) => {
      const schema = TraceSchema.create(data, this.rootStore);
      const kind = this.kinds.get(schema.applicableToKind);
      if (kind) {
        kind.addTraceSchema(schema);
      }
    });
  }
}
export default KindsStore;
