const WS_STATE_PENDING = "pending";
const WS_STATE_OPEN = "open";
const WS_STATE_CONNECTING = "connecting";
const WS_STATE_RECONNECTING = "reconnecting";
const WS_STATE_CLOSED = "closed";

const wsStateTitle = (state) => {
  switch (state) {
    case WS_STATE_PENDING:
      return "Cоединение ещё не установлено";
    case WS_STATE_OPEN:
      return "Соединение установлено";
    case WS_STATE_CONNECTING:
      return "Cоединение устанавливается";
    case WS_STATE_RECONNECTING:
      return "Переподключение соединения";
    case WS_STATE_CLOSED:
      return "Соединение закрыто";
    default:
      return state;
  }
};

export {
  WS_STATE_PENDING,
  WS_STATE_OPEN,
  WS_STATE_CONNECTING,
  WS_STATE_RECONNECTING,
  WS_STATE_CLOSED,
  wsStateTitle
};
