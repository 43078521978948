import React, { useMemo } from "react";
import { observer } from "mobx-react";
import AttrsForm from "~/modules/kindsAndAttrs/components/AttrsForm";
import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";

const KindView = observer(({ uid, kindId, version = 0 }) => {
  const { kindsStore, accountStore } = useStores();  
  const kind = useMemo(() => {
    return kindsStore.getKind(kindId);
  }, [kindId]);

  const kindNameRender = useMemo(() => {
    if (!kind) {
      return null;
    }
    const icon = accountStore.getIcon(kind && kind.name);
    return (
      <div className="kind-name">
        Атрибуты вида
        <AisIcon icon={icon} />
        <div className="name">{kind.name}</div>
      </div>
    );
  }, [kind]);

  return (
    <div className={"wizard-kind-view"}>
      {kindNameRender}
      <div className="divider"></div>
      <AttrsForm itemUid={uid} version={version} kindUid={kindId} />
    </div>
  );
});

export default KindView;
