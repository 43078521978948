import { observable, action, computed } from "mobx";

import TraceApi from "../api/traceApi";
import { MODULE_NAME_CFG } from "../constants/config";
import TraceSchemaModel from "../models/traceSchema";
import DataGapsModel from "../models/dataGaps";
import DataLinkItemModel from "../models/dataLinkItem";
import DataObjectItemModel from "../models/dataObjectItem";
import DataExpectedItemModel from "../models/dataExpectedItem";

/**
 *  Хранилище для анализа связей
 */
export default class TraceStore {
  /**
   * Флаг, указывающий, что идет обработка запроса
   * @type {Boolean}
   */
  @observable 
  pending;

  /**
   * Флаг, указывающий - показана ли панель с конфигурацией схемы или нет
   * @type {Boolean}
   */
  @observable
  showSchemaParams = false;

  /**
   * Список всех схем
   * @type {Boolean}
   */
  @observable
  schemas = new Map();
  
  /**
   * Констурктор
   * 
   * @param {RootStore} root гланвое хранилище приложения
   */
  constructor(root, showSchemaParams = false) {
    this.rootStore = root;
    this.showSchemaParams = showSchemaParams;
    this.api = new TraceApi(root);
  }

  /**
   * Флаг, указывающий, что идет обработка запроса
   * 
   * @return {Boolean}
   */
  @computed
  get isPending() {
    return this.pending;
  }

  /**
   * Задать значение флагу, указывающего, что идет обработка запроса
   * 
   * @param {Boolean} pendig
   */
  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  /**
   * Переключить режим отображения панели с фильтрами - строкова информация о выставленных фильтрах 
   * или панель с набором компонент для установки значений 
   * 
   * @return {Boolean} 
   */
  @action
  toggleShowSchemaParams() {
    this.showSchemaParams = !this.showSchemaParams;
  }

  /**
   * Очистить список записей
   */
  @action
  clear() {
  }

  async init() {
    await this.loadAllSchemas();
  }
  /**
   * Конфигурации инструмента
   *
   * @return {Object}
   */
  @computed
  get config() {
    return this.rootStore.uiStore.getModuleConfig(MODULE_NAME_CFG);
  }

  /**
   * Получить параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @return {Object}
   */
  getItemConfig(name) {
    return (this.config && this.config[name]) || {};
  }

  /**
   * Задать параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @param {Object} params набор параметров
   */
  setItemConfig(name, params) {
    this.rootStore.uiStore.setModuleConfig(MODULE_NAME_CFG, {
      ...this.config,
      [name]: params
    });
  }

  /**
   * Загрузить список базовых схем для анализа связей
   * 
   * @returns {Array<TraceSchema>}
   */
  async loadAllSchemas() {
    this.setPending(true);
    try {
      const data = await this.api.getBaseTraceSchemas();
      data.forEach((item) => {
        this.addSchema(TraceSchemaModel.create(item, this.rootStore));
      });
      return Array.from(this.schemas.values());
    } catch (ex) {
      this.onError(ex.message);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Произвести трассировку связей
   *   
   * @param {TraceSchema} schema  схема трассировки
   * 
   * @returns {Array<Object>}
   */
  async doTraceAnalize(schema) {
    this.setPending(true);
    try {
      const data = await this.api.traceAnalyize(schema.schema, schema.values) || [];
      const res = this.processData(data);
      return res;
    } catch (ex) {
      this.onError(ex.message);
    } finally {
      this.setPending(false);
    }

    return [];
  }

  /**
   * Произвести GAP анализ связей
   *   
   * @param {TraceSchema} schema  схема трассировки
   * 
   * @returns {Array<Object>}
   */
  async doGapAnalize(schema) {
    this.setPending(true);
    try {
      const data = await this.api.gapAnalyize(schema.schema, schema.values);
      const res = this.processData(data);
      return res;
    } catch (ex) {
      this.onError(ex.message);
    } finally {
      this.setPending(false);
    }

    return [];
  }

  /**
   * Произвести разбор данных полученных при трассировке связей или GAP анализе
   * 
   * @param {Array<Object>} data набор полученных данных
   * 
   * @returns {Array<Object>}
   */
  processData(data = []) {
    const res = [];
    data.forEach((item) => {
      const row = [];
      Array.isArray(item) && item.forEach((rec, i) => {
        // eslint-disable-next-line no-underscore-dangle
        switch (rec && rec.__typename) {
          case "GAPs":
            row.push(DataGapsModel.create(rec, this.rootStore));
            break;
          case "Link":
            row.push(DataLinkItemModel.create(rec, this.rootStore, row[i - 1]));
            break;
          case "ObjectWithClass":
            row.push(DataObjectItemModel.create(rec, this.rootStore));
            break;
          case "ExpectedItem":
            row.push(DataExpectedItemModel.create(rec, this.rootStore));
            break;
          default:
            row.push(null);
        }
      });
      res.push(row);        
    });

    return res;
  }

  @action
  addSchema(schema) {
    this.schemas.set(schema.uid, schema);
  }

  getSchema(id) {
    return this.schemas.get(id);
  }

  @computed
  get schemasList() {
    return Array.from(this.schemas.values()); 
  }

  /**
   * Обработать ошибку
   * 
   * @param {String} msg 
   */
  onError(msg) {
    this.rootStore.onError(msg);
  }
}
