// import { REPO_STATE_COMMIT, repoStateList } from "../constants/repoStateTypes";

/**
 * Определить какой параметр задан в entity
 * @param {Object} entity 
 * @returns 
 */
// const detectEntityKey = (entity) => {
//   for (let i = 0; i < repoStateList.length; i += 1) {
//     const key = repoStateList[i];
//     if (Object.keys(entity).includes(key)) {
//       return key;
//     }
//   }

//   return undefined;
// };

/**
 * Создать id для ветки репозитория или для сущности в файле репозитория.
 * Данный id потом будет использоваться в АИС при формирвоании связе и тп с сущностями из репозитория
 * 
 * @param {String} repositoryId id репозитория
 * @param {Object} entity описание сущности { ключ:значение}, где ключ: commitId, branch, tag или непосредетсвенно 
 * просто commitId  в строковом представлении 
 * @param {String} path путь до файла в репозитории
 * @param {String} codeItem описание(сигнатура) сущности в коде, если id создается для сущности в коде
 * 
 * @return {String} 
 */
// const generateRepoId = (repositoryId, entity = {}, path, codeItem) => {
//   let commit = "";
//   if (typeof entity === "string") {
//     commit = entity;
//   } else {
//     const key = detectEntityKey(entity);
//     commit = `${key}:${entity[key]}`;
//   }
const generateRepoId = (repositoryId, commitId = "", path, codeItem) => {
  let id = `${repositoryId}-${commitId}-${path}`;
  if (codeItem) {
    id = `${id}|${codeItem}`;
  }
  return id;
};

/**
 * Поулчить сущность коммита ихз строки
 * @param {String} str  представление entity в строковом представлении - (commitId, tag, branch):value
 * @return {Object} 
 */
// const getEntityFromString = (str) => {
//   if (str === "") {
//     return {};
//   }
//   for (let i = 0; i < repoStateList.length; i += 1) {
//     const key = repoStateList[i];
//     const keyStr = `${key}:`;
//     if (str.indexOf(keyStr) > -1) {
//       const val = str.slice(str.indexOf(keyStr) + keyStr.length);
//       return {
//         [key]: val ? val : undefined
//       };
//     }
//   }

//   // значит commitId задаввался в строковом представлении
//   return {
//     [REPO_STATE_COMMIT]: str
//   };
// };

/**
 * Разобрать id репозитория, чтобы из него получить значения в массиве:
 * repositoryId - id репозитория
 * commit - tag или номер коммита репозитория
 * path - путь до файла в репозитории
 * codeItem описание(сигнатура) сущности в коде, если id создается для сущности в коде
 * 
 * @param {String} id ветки репозитория или сущности в файле репозитория.
 * @return {Array[repositoryId, commit, path, codeItem]}
 */
const parseRepoId = (id) => {
  const [repositoryId, commit] = id.split("-");
  // выделям таким образом path, тк есть вероятность, что в названии файла или директории может
  // присутствовать символ "-". Поэтому метод split вернет неверный path
  const pathTmp = id.slice(`${repositoryId}-${commit}-`.length);
  // определяем, если в path еще описание сущности в файле кода, до которой нужно еще "перейти"
  const [path, codeItem] = pathTmp.split("|");

  // определяем теперь тип entity
  // const entity = getEntityFromString(commit);
  // return [repositoryId, entity, path, codeItem];
  return [repositoryId, commit ? commit : undefined, path, codeItem];
};


export {
  generateRepoId,
  parseRepoId
};
