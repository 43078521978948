import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import JournalItem from "./JournalItem";
import Pagination from "~/core/components/pagination";
import { IssueModel } from "../../models";
import IssueStore from "../../stores/IssueStore";
// import AddNotes from "./AddNotes";

/**
 * Список журнала изменений задачи
 * 
 * @param {Object} props набор параметров
 * @param {IssueModel} params.issue объект задачи
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {String} params.noRecordsText текст на пустой список журанала
 * @param {String} params.modeView режим отображения журанал: all -  все записи; notes - только комментарии
 */
const JournalList = observer((props) => {
  const { 
    issue, 
    store,
    noRecordsText = "Нет записей в журнале изменений",
    modeView = "all"
  } = props;
  const onPageChange = useCallback((page) => {
    issue.journalGoToPage(page);
  }, [issue.journalCurrentPage]);

  const setPageSize = useCallback((size) => {
    issue.journalSetPageSize(size);
  }, [issue.journalSetPageSize]);
  const data = useMemo(() => {
    if (modeView === "notes") {
      return issue.journalData.filter((item) => {
        return !!item.notes;
      });
    }

    return issue.journalData;
  }, [issue.journalData, modeView]);

  return (
    <div className={"issue-journal-list"}>
      {data.length === 0 && !issue.isPending &&
        <div className="no-records">
          {noRecordsText}
        </div>
      }
      {/* <AddNotes store={store} issue={issue} />*/}
      <div className={"issue-journal-list-items"}>
        {
          data.map((j, i) => {
            return (
              <JournalItem
                key={i}
                journalItem={j}
                index={i}
                store={store}
              />
            );
          })
        }
      </div>
      {modeView === "all" &&
        <Pagination
          className="issue-journal-list-pagination"
          startPageIndex={1}
          canPrevious={issue.journalCanPreviousPage}
          canNext={issue.journalCanNextPage}
          pageSize={issue.journalPerPage}
          page={issue.journalCurrentPage}
          pages={issue.journalPageCount}
          onPageChange={onPageChange}
          onPageSizeChange={setPageSize}
          loading={issue.isPending}
        />
      }
    </div>
  );
});

JournalList.propTypes = {
  issue:         PropTypes.instanceOf(IssueModel).isRequired,
  store:         PropTypes.instanceOf(IssueStore).isRequired,
  noRecordsText: PropTypes.string,
  modeView:      PropTypes.string
};
export default JournalList;
