import React, { useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism.css";

/**
 * Компонент для отображения значения Payload записи в журнале в формате JSON
 *
 * @class JsonViewer
 */
const JsonViewer = ({ json, language = "json", className }) => {
  useEffect(() => {
    if (json) {
      Prism.highlightAll();
    }
  }, [json, language]);

  return (
    <pre className={`${className ? className : ""}`}>
      <code className={`code language-${language}`}>
        {json && JSON.stringify(json, null, 2)}
      </code>
    </pre>
  );
};

export default JsonViewer;
