import React, { useCallback, useMemo, useRef,  useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import SearchStore from "../../stores/searchStore";

import {
  conditionStringList,
  conditionNumList,
  conditionBoolList,
  conditionDateList,
  conditionEnumList,
  conditionDefaultList
} from "../../constants/condtions";

import ConditionPickerItem from "./ConditionPickerItem";

/**
 * Контрол с выпадающим списком доступных условий сравнения
 * 
 * @param {Object} params  набор параметров 
 * @param {Any} params.value значение поля ввода
 * @param {String} params.type тип поля ввода
 * @param {Array<String>} params.conditions набор доступных значений для сравнения
 * @param {SearchStore} params.store хранилище для поиска
 * @param {Functiun} params.onChange callback ф-я при изменении значения поля ввода
 * @param {Element} params.conditionRef ссылка на объект компоненты контрола отображения условия
 * @param {Element} params.filtersPanelRef ссылка на объект компоненты панели набра с фильтрами
 *
 * @returns 
 */
const ConditionPicker = ({ 
  value, 
  type, 
  conditions, 
  conditionRef, 
  filtersPanelRef,
  store,
  onChange 
}) => {
  const [style, setStyle] = useState({});
  const [isBottom, setIsBottom] = useState(false);
  const elRef = useRef();

  /**
   * Формируем список досутпных условий сравнения, в зависимости от типа котрола
   */
  const list = useMemo(() => {
    if (conditions) {
      return conditions;
    }
    switch (type) {
      case "textarea":
      case "string":
        return conditionStringList;
      case "integer":
      case "float":
        return conditionNumList;
      case "datetime":
        return conditionDateList;
      case "boolean":
        return conditionBoolList;
      case "enum":
        return conditionEnumList;
      default:
        return conditionDefaultList;
    }
  }, [type, conditions]);

  /**
   * Отрисовываем в нужном положении picker со списком доступных условий
   */
  useLayoutEffect(() => {
    if (elRef.current && 
        filtersPanelRef && filtersPanelRef.current && 
        conditionRef && conditionRef.current
    ) {
      const menuRec = elRef.current.getBoundingClientRect();
      const filtersPanelRec = filtersPanelRef.current.getBoundingClientRect();
      const conditionRec = conditionRef.current.getBoundingClientRect();
      if ((conditionRec.top - menuRec.height) > filtersPanelRec.top) {
        setIsBottom(false);
        setStyle({
          top:  conditionRec.top - menuRec.height - 8,
          left: conditionRec.left - 5
        });
      } else {
        setIsBottom(true);
        setStyle({
          top:  conditionRec.bottom + conditionRec.height,
          left: conditionRec.left - 5
        });
      }
    }
  }, [elRef, conditionRef && conditionRef.current, filtersPanelRef && filtersPanelRef.current]);

  /**
   * Обработчик события нажатия на пункт условия.
   * По нажатию на него, скрываем picker
   */
  const onClickItem = useCallback((val) => {
    store.setConditionPickerData(null);
    onChange && onChange(val);
  }, [onChange]);

  if (list.length === 0) {
    return null;
  }

  return (
    <div
      ref={elRef}
      className={classNames("condition-picker", {
        bottom: isBottom
      })}
      style={style}
    >
      {list.map((item) => {
        return (
          <ConditionPickerItem 
            key={`key-${item}`}
            value={item}
            active={item === value}
            onClick={onClickItem}
          />
        );
      })}
    </div>
  );
};

ConditionPicker.propTypes = {
  value:        PropTypes.any,
  type:         PropTypes.string,
  conditions:   PropTypes.arrayOf(String),
  store:        PropTypes.instanceOf(SearchStore), 
  onChange:     PropTypes.func,
  conditionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]), 
  filtersPanelRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default ConditionPicker;
