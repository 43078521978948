import { observer } from "mobx-react";
import React, { useCallback, useMemo, useState } from "react";
import { Dnd } from "@ais3p/ui-framework-old";

import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";
import DND_ISSUE_TYPE from "../../issues/constants/dnd";
import DND_SOURCECODE_TYPE from "../../repo/constants/dnd";

const AddRelationItem = observer(
  ({ kind, targetInfo, onChangeDirection, relationUIStore }) => {
    const { start, end, name } = kind;
    const { kindsStore, relationStore } = useStores();

    const { relationRules } = relationUIStore;
    const [isStart, setIsStart] = useState(false);
    const [droppedItem, setDroppedItem] = useState(null);
    const [isConnecting, setIsConnecting] = useState(false);

    const direction = useMemo(() => {
      const direction = isStart ? "in" : "out";
      if (onChangeDirection) {
        onChangeDirection(direction);
      }
      return direction;
    }, [isStart]);

    const drop = useCallback((props, monitor) => {
      const item = monitor.getItem();
      if (item && item.data) {
        setDroppedItem(item.data.payload || item.data);
      }
    }, []);

    const canDrop = useCallback(
      (props, monitor) => {
        const item = monitor ? monitor.getItem() : droppedItem;

        if ((!!monitor && !!droppedItem) || !item) {
          return false;
        }

        let canDrop = false;
        const endType = direction !== "in" ? "dest" : "source";

        const rule = relationRules[name] && relationRules[name][endType];

        if (!rule) {
          return true;
        }

        const uid = item.uid || item.id;
        const parentUid = item.parent && item.parent.uid;
        const itemClass = item.data && item.data.class;
        const kindNames = kindsStore.getItemKindNames(uid);
        let parentKindNames = [];

        const names = ["none", ...kindNames];

        if (itemClass === "versioning.Version") {
          parentKindNames = kindsStore.getItemKindNames(parentUid);
          parentKindNames.forEach((name) => {
            names.push(`Версия ${name}`);
          });
        }

        names.forEach((name) => {
          if (rule.has(name)) {
            canDrop = true;
          }
        });

        return canDrop;
      },
      [droppedItem, relationRules, name, direction]
    );

    const onToggleDirection = useCallback(() => {
      setIsStart(!isStart);
      const fits = canDrop();
      if (!fits) {
        setDroppedItem(null);
      }
    }, [isStart, canDrop]);

    const hover = useCallback(() => {}, []);
    const isDragOver = useCallback(() => {}, []);

    const deleteItem = useCallback(() => {
      setDroppedItem(null);
    }, []);

    const canConnect = useMemo(() => {
      return !!droppedItem && !isConnecting && !!kind;
    }, [droppedItem, isConnecting, kind]);

    const onConnect = useCallback(async() => {
      if (canConnect) {
        setIsConnecting(true);
        if (!isStart) {
          await relationStore.connect(targetInfo, droppedItem, kind.id);
        } else {
          await relationStore.connect(droppedItem, targetInfo, kind.id);
        }
        setIsConnecting(false);
        setDroppedItem(null);
      }
    }, [targetInfo, droppedItem, kind, canConnect, isStart]);

    return (
      <div className="add-item-line">
        <AisIcon
          className="direction"
          data-tooltip="Изменить направление"
          icon={isStart ? "arrow-left-M" : "arrow-right-M"}
          onClick={onToggleDirection}
        />
        <div className="item-drop-target">
          <Dnd.Target
            key="dnd-target"
            drop={drop}
            canDrop={canDrop}
            hover={hover}
            isOverHandler={isDragOver}
            types={[
              Dnd.types.NODEITEM,
              Dnd.types.CONNECTION,
              Dnd.types.EDITORITEM,
              Dnd.types.MARKER,
              DND_ISSUE_TYPE,
              DND_SOURCECODE_TYPE
            ]}
          >
            {!droppedItem && (
              <div className="placeholder">Перетащите объект</div>
            )}
            {!!droppedItem && (
              <div
                key={droppedItem.id}
                className="object"
                onClick={deleteItem}
                data-tooltip={isStart ? start : end}
              >
                <AisIcon item={droppedItem.payload || droppedItem} />
                <div className="object-name">
                  {droppedItem.payload &&
                    (droppedItem.payload.name || droppedItem.payload.title)}
                  {!droppedItem.payload &&
                    (droppedItem.name || droppedItem.title)}
                </div>
                <AisIcon className="delete" icon={"delete-M"} />
              </div>
            )}
          </Dnd.Target>
        </div>
        <AisIcon
          className={`confirm ${canConnect ? "" : "disabled"}`}
          data-tooltip="Создать связь"
          icon={"ok-M"}
          loading={isConnecting}
          onClick={onConnect}
        />
      </div>
    );
  }
);

export default AddRelationItem;
