import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import Code from "./Code";

/**
 * Компонент для отображения текста в markdown
 * 
 * @param {Object} props набор параметров
 * @param {String} params.text текст, который нужно отобразить в формате markdown
 * @param {String} params.className  пользовательский className
 * 
 * @returns 
 */
const MarkdownView = ({ text, className }) => {
  return (
    <ReactMarkdown
      className={`markdown-view ${className ? className : ""}`}
      // eslint-disable-next-line react/no-children-prop
      children={text}
      linkTarget={"_blank"}
      components={{
        code: Code
      }}
    />
  );
};

MarkdownView.propTypes = {
  text:      PropTypes.string,
  className: PropTypes.string
};

export default MarkdownView;
