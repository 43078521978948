import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { components } from "@ais3p/ui-framework";

import { FiltersPanel } from "../filters";
import StatusColumn from "./StatusColumn";
import IssueStore from "../../stores/IssueStore";

const { Icon } = components;
/**
 * Панель для отображения списка задач в виде колонок статусов задачи
 * 
 * @param {Object} props набор параметров
 * @param {String} params.projectUid uid проекта
 * @param {String} params.className пользовательский className
 * @param {Boolean} params.canBeEditable признак, можно ли редактирвоать Задачу
 * @param {IssueStore} params.store хранилище для работы с Задачами
 * @param {LayoutItem} params.layoutItem сущность в Layout
 */
const IssuesKanban = observer((props) => {
  const { className, store, canBeEditable, projectUid, layoutItem } = props;

  const onToggleShowFilters = useCallback(() => {
    store.toggleShowFilters();
  }, [store.isShownFilters]);

  return (
    <div
      className={classNames("issues-kanban-wrapper", className, {
        closed:
          store.isShowIssueTabsOnFullView && store.openedIssueList.length > 0
      })}
    >
      <div className={"issues-header"}>
        <div className={"issues-header-panels"}>
          <FiltersPanel store={store} projectUid={projectUid} />
        </div>
        <div className={"issues-header-buttons"}>
          <div
            className={classNames("header-button", {
              active: store.isShownFilters
            })}
            onClick={onToggleShowFilters}
          >
            <Icon size={2} name="filiter-M" />
          </div>
        </div>
      </div>
      <div className={classNames("issues-kanban-content")}>
        {store.statusList.map((status) => {
          return (
            <StatusColumn
              key={status.id}
              status={status}
              store={store}
              layoutItem={layoutItem}
              canBeEditable={canBeEditable}
            />
          );
        })}
      </div>
    </div>
  );
});

IssuesKanban.propTypes = {
  className:     PropTypes.string, 
  store:         PropTypes.instanceOf(IssueStore).isRequired, 
  canBeEditable: PropTypes.bool, 
  projectUid:    PropTypes.string.isRequired, 
  layoutItem:    PropTypes.object
};

export default IssuesKanban;
