import React from "react";
import { observer } from "mobx-react";

import { Button, Dnd } from "@ais3p/ui-framework-old";

@observer
class NormalButton extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.canDrag = this.canDrag.bind(this);
  }

  onClick() {
    const { onClick, item, mode } = this.props;
    if (onClick) {
      if (mode) {
        onClick(mode);
      } else {
        onClick(item);
      }
    }
  }

  canDrag() {
    return this.props.canDrag;
  }

  render() {
    const {
      icon,
      id,
      item,
      mode,
      tool,
      currentMode,
      labelPosition,
      setIsDragging
    } = this.props;

    const inMode = mode && currentMode === mode;
    const attributes = {
      "data-tooltip":    item.name,
      "data-tooltip-at": labelPosition || "bottom"
    };
    return (
      <div className={`button-wrapper ${inMode ? mode : ""}`}>
        <Dnd.Source
          noStore={true}
          setIsDragging={setIsDragging}
          type={Dnd.types.GLOBAL_BUTTON}
          id={id}
          item={item}
          tool={tool}
          canDrag={this.canDrag}
        >
          <div className="button-wrapper" {...attributes}>
            <Button
              size={3}
              onClick={this.onClick}
              leftIcon={icon}
              theme={inMode ? mode : "outline"}
            />
          </div>
        </Dnd.Source>
      </div>
    );
  }
}

export default NormalButton;
