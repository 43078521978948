import { observer } from "mobx-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

const ScrollSpacer = observer(({ dataStore, position }) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const height = useMemo(() => {
    if (position === "top") {
      return dataStore.topSpacerHeight;
    } else if (position === "bottom") {
      return dataStore.bottomSpacerHeight;
    }
    return 0;
  }, [position, dataStore.bottomSpacerHeight, dataStore.topSpacerHeight]);

  const element = useRef();

  const processIntersection = useCallback((entries) => {
    const intersecting = entries[0] && entries[0].isIntersecting;
    if (isIntersecting !== intersecting) {
      setIsIntersecting(intersecting);
      if (intersecting) {
        dataStore.setBoundingIndexFromSpacer(position);
      }
    }
  }, [isIntersecting]);

  const observer = useMemo(() => {
    return new IntersectionObserver(processIntersection);
  }, [processIntersection]);

  useEffect(() => {
    if (element && element.current) {
      if (observer) {
        observer.observe(element.current);
      }
    }
    return () => {
      observer && observer.disconnect();
    };
  }, [observer, element && element.current]);
  
  return (<div ref={element} className={`${position}-spacer`} style={{ height: `${height}px` }}></div>);
});

export default ScrollSpacer;
