/* eslint-disable no-undef */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from "react";
import { observer } from "mobx-react";
import { v4 as uuid } from "uuid";
import { Tree, TreeStore, Preloader } from "@ais3p/ui-framework-old";

import AisIcon from "~/core/components/AisIcon";
import { DOMAIN_REPO } from "~/core/constants/Domains";
import { CLS_REPO_FILE, CLS_REPO_DIR } from "~/core/constants/Classes";
import useStores from "~/core/utils/useStores";
import RepoNode from "./RepoNode";
import SideKick from "./SideKick";
import { changeContext, generateRepoId } from "../../utils";

/**
 * Компонент для дерева репозитория
 *
 * @param {Object} params набор параметров
 * @param {String} params.tabId id таба в Layout
 * @param {LayoutItem} params.layoutItem item Layout
 * @param {RepoStore} params.store Хранилище для работы с репозиторием
 * @param {String} params.path путь рута дерева
 * @param {Number} params.focusCodeLine номер линии, куда нужно выставить фокус при открытии файла.
 * Это необходимо при переходе по ссылке из другого инструмента
 * @param {Boolean} params.isRenderSideKick флаг, указывающий на то, что нужно ли рядом с деревом отображать
 * дополнительную панель SideKick, где будет отображена доп. информация о репозитории
 * @param {Function} params.onNodeClick callback функция при клике на иконку записи ноды
 *
 * @type {RepoTree}
 * @returns {Component}
 */
const RepoTree = observer(
  forwardRef((props, ref) => {
    const treeId = uuid();
    const treeRef = useRef(null);
    const {
      tabId,
      layoutItem,
      store,
      path,
      focusCodeLine,
      isRenderSideKick,
      onNodeClick
    } = props;
    // const { lastCommit, repositoryId } = store;
    const { repositoryId } = store;

    // const commitId = useMemo(() => {
    //   return lastCommit && lastCommit.commit;
    // }, [lastCommit]);
    const commitId = useMemo(() => {
      return store.rootRepoNode && store.rootRepoNode.commit;
    }, [store.rootRepoNode]);

    const generateRootId = (repoId, commit) => {
      return `/${generateRepoId(repoId, commit, "/")}`;
    };
    const rootCursor = useMemo(() => {
      return generateRootId(repositoryId, commitId);
    }, [repositoryId, commitId]);

    const treeStore = useMemo(() => {
      return new TreeStore();
    }, []);

    const { objectStore } = useStores();

    const [expanded, setExpanded] = useState(undefined);
    const [pathPending, setPathPending] = useState(path && path.length > 0);
    const [nodePending, setNodePending] = useState(false);
    const [cursor, setCursor] = useState(undefined);

    const { rootID, tree } = treeStore;

    useImperativeHandle(ref, () => {
      return {
        resetCursor() {
          setCursor(rootCursor);
        }
      };
    });

    // Загрузка данных для дерева
    useEffect(async() => {
      // если задан path, то значит нужно загрузить ноды по этому пути,
      // подгрузить данные этих нод и развернуть все ноды по этому пути
      if (path && path.length > 0) {
        processPath(path);
      } else {
        // иначе загружаем головную ноду
        treeStore.init("");
        const rootNode = await getNodeData("");
        if (rootNode) {
          store.setRootRepoNode(rootNode);
        }
      }
    }, [path, store.repoState]);

    // Устанавливаем курсор в необходимой ноде дерева, если был переход по ссылке
    useEffect(() => {
      // сформировав cursor из path, устанавливаем его
      if (treeRef && treeRef.current) {
        treeRef.current.setCursor(cursor);
      }
    }, [cursor, treeRef && treeRef.current]);

    const processPath = async(path) => {
      // Делаем обработку path. Тк для каждой ноды мб свой commit(svn), необходимо сначала поочередно загрузить
      // каждую ноду из path, чтобы получить корректый commit ноды.
      // Это приходится делать из-за того, что uid каждой ноды состоит из repositoryId и commit
      setPathPending(true);
      let cursor = rootCursor;
      let i = 0;
      let lastNode;
      const expandedObj = {};
      try {
        while (i < path.length) {
          if (path[i] === "") {
            lastNode = await getNodeData("");
            if (lastNode) {
              cursor = generateRootId(lastNode.repositoryId, lastNode.commitId);
              store.setRootRepoNode(lastNode);
            }
          } else {
            if (lastNode) {
              // eslint-disable-next-line no-loop-func
              const node = (lastNode.children || []).find((item) => {
                return item.name === path[i];
              });
              if (node) {
                lastNode = await getNodeData(node.uid);
                cursor += `/${node.uid}`;
                if (lastNode && lastNode.class === CLS_REPO_DIR) {
                  expandedObj[cursor] = true;
                }
              }
            }
          }

          i += 1;
        }
        // Мы получили корректные данные для нод (привильный commit), поэтому мы можем теперь сформирвоать
        // правильные пути до нод, которые должны быть развернуты.
        // Такую инициализацию expanded необходимо произвести до отрисовки Tree, тк потом дерево после инициализации
        // на параметр expanded не реагирует
        setExpanded(expandedObj);

        if (lastNode && lastNode.class === CLS_REPO_FILE) {
          // если путь был до файла, то загружаем его содержимое
          onNodeClick && onNodeClick(lastNode, focusCodeLine);
        }
      } finally {
        setPathPending(false);
      }

      // После того, как был проинициализирован параметр expanded -> отрисовано Tree, то теперь можно выставить cursor
      setCursor(cursor);
    };

    // Загрузка данных ноды дерева
    const getNodeData = async(uid) => {
      setNodePending(true);
      try {
        if (repositoryId !== null) {
          const result = await store.getNode(uid);
          if (result) {
            treeStore.expand(result.uid, result);
            return result;
          } else {
            // const commitField = store.repoState[REPO_STATE_COMMIT] ? REPO_STATE_COMMIT : REPO_STATE_TAG;
            const error = new Error(
              `Запрошен узел с repositoryId="${repositoryId}" path = "${uid}",  не получен ответ`
            );
            store.setError(error);
          }
        }
      } catch (error) {
        console.warn(error);
        store.setError(error);
      } finally {
        setNodePending(false);
      }
      return undefined;
    };

    // Проверка на возможность, что нибудь "принести" в наше дерево через DnD
    const canDrop = useCallback(() => {
      return false;
    });

    // Отрисовка ноды дерева
    const renderNode = useCallback((nodeProps) => {
      const { selected } = props;
      const { name, id, type, payload } = nodeProps.data;
      const item = store.getItem(id);
      const { author, commitId, commitMessage, dateString, size } =
        item || payload;

      const pofNode = (
        <RepoNode
          selected={selected}
          // onClick={onNodeClick}
          name={name}
          commit={commitId}
          id={id}
          payload={payload}
          type={type}
        />
      );
      const sideKick = isRenderSideKick && (
        <SideKick
          commit={commitId}
          author={author}
          commitMessage={commitMessage}
          dateString={dateString}
          size={size}
        />
      );
      // TODO: (ai) убрать передачу из рендера объекта вместо компонента, см. NodeItem в UIF
      return {
        content:        pofNode,
        contentColSpan: 1,
        sideKick
      };
    });

    // Событие на смену курсора в дереве - те выделили ноду в дереве
    const onChangeCursor = useCallback((uid) => {
      const item = objectStore.getVersion(uid, DOMAIN_REPO);
      if (item) {
        onNodeClick && onNodeClick(item);
        changeContext(layoutItem, item.repositoryId, item);
      } else {
        changeContext(layoutItem);
      }
    });

    const onSetCursor = useCallback(() => {});

    // Отрисовка иконки в ноде дерева
    const iconRender = useCallback(
      (item) => {
        return <AisIcon item={item} loading={nodePending} />;
      },
      [nodePending]
    );

    return (
      <div className="repo-tree">
        {pathPending && (
          <div className="preloader-wrapper">
            <Preloader size={3} />
          </div>
        )}
        {!pathPending && (
          <Tree
            ref={treeRef}
            tabId={tabId}
            isTracking={false}
            treeId={treeId}
            rootId={rootID}
            withToolbar={false}
            data={tree}
            expanded={expanded}
            onSetCursor={onSetCursor}
            changeCursor={onChangeCursor}
            nodeRender={renderNode}
            iconRender={iconRender}
            getNodeData={getNodeData} // eslint-disable-line
            canDropFunc={canDrop}
            canDropFileFunc={canDrop}
            toolbarConfig={{}}
          />
        )}
      </div>
    );
  })
);

export default RepoTree;
