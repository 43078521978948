import { observable, action, computed } from "mobx";

import { CLS_KIND } from "~/core/constants/Classes";

import TraceSchemaModel from "../models/traceSchema";
import CheckListApi from "../api/checkListApi";
import TraceItem from "../models/traceItem";
 
class CheckListStore {
  @observable
  id = null;
  @observable
  frs = null;
  @observable
  urs = null;
  @observable
  dataList = [];

  constructor({ rootStore, id }) {
    this.rootStore = rootStore;
    this.api = new CheckListApi(rootStore);
    this.id = id;
  }

  @action
  init() {
    this.frs = null;

    this.clearList();
  }

  @action
  setUrs(urs = null) {
    this.urs = urs;
  }

  @action
  setFrs(frs = null) {
    this.frs = frs;
  }

  async loadSchema(schemaUid) {
    try {
      const data = await this.api.loadSchema(schemaUid);
      const schema = TraceSchemaModel.create(data, "TraceBySchemeUid");
      return schema;
    } catch (ex) {
      this.onError(ex.message);
    }
    return false;
  }

  async loadData(schema, dataName = "Trace") {
    try {
      this.clearList();
      const data = await this.api.loadData(schema.uid);
      this.parseTraceData(schema, data[dataName]);
      return true;
    } catch (ex) {
      this.onError(ex.message);
    }
    return false;
  }

  @computed
  get ursKind() {
    const kindRequirement = this.rootStore.kindsStore.getKindByName("Требование");
    const kindUrsReq = this.rootStore.kindsStore.getKindByName("URSREQ");
    const kindFrsReq = this.rootStore.kindsStore.getKindByName("FRSREQ");
    if (this.urs) {
      let kind = null;
      this.urs.parent.kindsRepresentation.kinds.forEach((member) => {
        if (member.kind && member.kindName === "URS") {
          kind = kindUrsReq; // return id kind."Требование"
        }
        if (member.kind && member.kindName === "FRS") {
          kind = kindFrsReq; // return id kind."Требование"
        }
      });
      if (kind) {
        return kind;
      } else {
        return kindRequirement;
      }
    }
    return kindRequirement;
  }

  @computed
  get frsKind() {
    const kindRequirement = this.rootStore.kindsStore.getKindByName("Требование");
    const kindFrsReq = this.rootStore.kindsStore.getKindByName("FRSREQ");
    const kindTC = this.rootStore.kindsStore.getKindByName("Test Case");
    if (this.ursKind && this.ursKind.name === "URSREQ") {
      return kindFrsReq;
    }
    if (this.ursKind && this.ursKind.name === "FRSREQ") {
      return kindTC;
    }

    return kindRequirement;
  }

  @computed
  get isTP() {
    if (this.ursKind && this.ursKind.name === "FRSREQ") {
      return true;
    }
    return false;
  }

  @computed
  get isURS() {
    return this.id === "URS";
  }

  @computed
  get frsKindName() {
    if (this.ursKind && this.ursKind.name === "URSREQ") {
      return "FRS";
    }
    if (this.ursKind && this.ursKind.name === "FRSREQ") {
      return "Test Program";
    }
    return "Спецификация";
  }

  @computed
  get collectionUid() {
    let collectionUid = "3ac9bfee6b024a8d961444600e331425";
    if (this.ursKind && this.ursKind.name === "URSREQ") {
      collectionUid = "ff601711bda4443d856866def3dca195";
    }

    return collectionUid;
  }

  @computed
  get ursKindId() {
    return this.ursKind && this.ursKind.id;
  }
  
  @computed
  get kindId() {
    return this.frsKind && this.frsKind.id;
  }
  
  @computed
  get attr() {
    let attrName = "Метод проверки";
    if (this.ursKind && this.ursKind.name === "Требование") {
      attrName = "Метод проверки";
    }
    if (this.ursKind && this.ursKind.name === "URSREQ") {
      attrName = "Квалификация";
    }
    return this.rootStore.kindsStore.getAttrByName(attrName);
  }
  
  @action
  async doTraceUrsIsolated(urs) {
    const schema = {
      class: "tracer.TraceScheme",
      trace:
          [
            {
              target: {
                class:   "text.container.Rubric",
                version: urs.version
              },
              boundaries: [
                [
                  {
                    class:     "tracer.Contains",
                    direction: "in",
                    minHops:   1
                  },
                  {
                    id:    urs.id,
                    class: "library.TextVersion"
                  }
                ],
                [
                  {
                    class:     "tracer.BelongsTo",
                    direction: "out"
                  },
                  {
                    id:    this.ursKindId,
                    class: "kindsattrs.Kind"
                  }
                ]
              ],
              label: "URS"
            }
          ],
      config: {
        hide: [
          {
            label: "Test",
            state: "isolated"
          }
        ]
      }
    };
    const data = await this.api.doTraceWithSchema(schema);
    this.parseTraceData(schema, data.Trace);
  }

  @action
  async doTraceUrs(urs, frs) {
    const traceDirection = "in";
    const schema = {
      class: "tracer.TraceScheme",
      trace:
          [
            {
              target: {
                class:   "text.container.Rubric",
                version: urs.version
              },
              boundaries: [
                [
                  {
                    class:     "tracer.Contains",
                    direction: "in",
                    minHops:   1
                  },
                  {
                    id:    urs.id,
                    class: "library.TextVersion"
                  }
                ],
                [
                  {
                    class:     "tracer.BelongsTo",
                    direction: "out"
                  },
                  {
                    id:    this.ursKindId,
                    class: CLS_KIND
                  }
                ]
              ],
              label: "URS"
            },
            {
              class:     "binder.link",
              direction: traceDirection
            },
            {
              target: {
                class:   "text.container.Rubric",
                version: frs.version
              },
              boundaries: [
                [
                  {
                    class:     "tracer.Contains",
                    direction: "in",
                    minHops:   1
                  },
                  {
                    id:    frs.id,
                    class: "library.TextVersion"
                  }
                ],
                [
                  {
                    class:     "tracer.BelongsTo",
                    direction: "out"
                  },
                  {
                    id:    this.kindId,
                    class: CLS_KIND
                  }
                ]
              ],
              label: "FRS"
            }
          ],
      config: {
        hide: [
          {
            label: "Test",
            state: "isolated"
          }
        ]
      }
    };
    const data = await this.api.doTraceWithSchema(schema);
    this.parseTraceData(schema, data.Trace);
  }

  @action
  async doTraceFrs(frs, tp) {
    const traceDirection = "in";
    const schema = {
      class: "tracer.TraceScheme",
      trace:
          [
            {
              target: {
                class:   "text.container.Rubric",
                version: frs.version
              },
              boundaries: [
                [
                  {
                    class:     "tracer.Contains",
                    direction: "in",
                    minHops:   1
                  },
                  {
                    id:    frs.id,
                    class: "library.TextVersion"
                  }
                ],
                [
                  {
                    class:     "tracer.BelongsTo",
                    direction: "out"
                  },
                  {
                    id:    this.ursKindId,
                    class: CLS_KIND
                  }
                ]
              ],
              label: "FRS"
            },
            {
              class:     "binder.link",
              direction: traceDirection
            },
            {
              target: {
                class:   "text.container.Rubric",
                version: tp.version
              },
              boundaries: [
                [
                  {
                    class:     "tracer.Contains",
                    direction: "in",
                    minHops:   1
                  },
                  {
                    id:    tp.id,
                    class: "library.TextVersion"
                  }
                ],
                [
                  {
                    class:     "tracer.BelongsTo",
                    direction: "out"
                  },
                  {
                    id:    this.kindId,
                    class: CLS_KIND
                  }
                ]
              ],
              label: "TP"
            }
          ],
      config: {
        hide: [
          {
            label: "TP",
            state: "isolated"
          }
        ]
      }
    };
    const data = await this.api.doTraceWithSchema(schema);
    this.parseTraceData(schema, data.Trace);
  }

  @action
  async doTrace(urs, frs) {
    this.clearList();
    try {
      if (this.isURS) {
        this.doTraceUrsIsolated(urs);
      } else if (this.ursKind.name === "URSREQ" || this.ursKind.name === "Требование") {
        this.doTraceUrs(urs, frs);
      } else if (this.ursKind.name === "FRSREQ") {
        this.doTraceFrs(urs, frs);
      }
      return true;
    } catch (ex) {
      this.onError(ex.message);
    }
    return false;
  }

  parseTraceData(schema, data) {
    (data || []).forEach((item) => {
      this.parseTraceItem(schema, item);
    });
  }

  async parseTraceItem(schema, item) {
    const traceItem = new TraceItem(this);
    await Promise.all(schema.trace.map(async(schemaItem, i) => {
      const obj = item[i];
      switch (schemaItem.label) {
        case "FRS":{
          if (obj && obj.representation) {
            const frs = await this.rootStore.objectStore.processTextItem(
              obj.representation, obj.version, {}, { loadKind: false }
            );
            traceItem.update({ frs });
          }
          break;
        }
          
        case "URS":{
          if (obj && obj.representation) {
            const urs = await this.rootStore.objectStore.processTextItem(
              obj.representation, obj.version, {}, { loadKind: false }
            );
            if (
              !this.isURS || 
              (
                urs && 
                urs.kindsRepresentation && 
                urs.kindsRepresentation && 
                urs.kindsRepresentation.kinds.has(this.ursKindId)
              )
            ) {
              traceItem.update({ urs });
            }
          }
          break;
        }

        case "TP":{
          if (obj && obj.representation) {
            const tp = await this.rootStore.objectStore.processTextItem(
              obj.representation, obj.version, {}, { loadKind: false }
            );
            traceItem.update({ tp });
          }
          break;
        }

        case "Test":{
          if (obj && obj.representation) {
            const test = await this.rootStore.objectStore.processLibraryItem(
              obj.representation, 
              "library",
              { isPlain: true, tool: "library", rootID: "root" }
            );
            traceItem.update({ test });
          }
          break;
        }
      }
    }));

    if (!this.isURS || !!traceItem.urs) {
      this.addTraceItem(traceItem);
    } else {
      traceItem.checkFromLocalStorage();
    }
  }

  @action 
  clearList() {
    this.dataList = [];
  }

  @computed
  get ursIndexMap() {
    const map = new Map();
    this.dataArray.forEach((traceItem) => {
      if (traceItem.urs && !map.get(traceItem.urs.id)) {
        map.set(traceItem.urs.id, map.size + 1);
      }
    });
    return map;
  }

  @computed
  get tpIndexMap() {
    const map = new Map();
    this.dataArray.forEach((traceItem) => {
      if (traceItem.tp) {
        map.set(traceItem.tp.id, map.size + 1);
      }
    });
    return map;
  }

  @action 
  addTraceItem(traceItem) {
    traceItem.checkFromLocalStorage();
    this.dataList.push(traceItem);
  }

  @action
  saveAllItemsToLocalStorage() {
    this.dataList.forEach((item) => {
      item.saveToLocalStorage();
    });
  }

  @computed
  get dataArray() {
    return this.dataList.slice().sort((a, b) => {
      if (b.num > a.num) {
        return -1;
      };
      return 1;
    });
  }

  @computed
  get traceDataIsComplete() {
    if (this.dataList.length === 0) {
      return false;
    }

    return this.dataList.every((item) => {
      return item.isComplete;
    });
  }

  onError(msg) {
    this.rootStore.onError(msg);
  }
}

export default CheckListStore;
