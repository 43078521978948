import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";

import { HeaderPanel } from "../common";
import JournalList from "./JournalList";
import { IssueModel } from "../../models";
import IssueStore from "../../stores/IssueStore";

/**
 * Панель для отображения журнала изменений задачи
 * 
 * @param {Object} props набор параметров
 * @param {String} params.className пользовательский className
 * @param {IssueModel} params.issue объект задачи
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {String} params.modeView режим отображения журанал: all -  все записи; notes - только комментарии
 */
const JournalPanel = observer((props) => {
  const { className, store, issue, modeView } = props;
  return (
    <div className={classNames("issue-journal-wrapper", className)}>
      { modeView === "notes" &&
        <HeaderPanel
          text={"Комментарии"}
          // icon="log-M"
          className={"issue-journal-header"}
        />
      }
      <div className={classNames("issue-journal-content")}>
        <JournalList 
          issue={issue} 
          store={store} 
          modeView={modeView} 
          noRecordsText={modeView === "notes" ? "Нет комментариев" : "Нет записей в журнале изменений"}
        />
      </div>
    </div>
  );
});

JournalList.propTypes = {
  issue:     PropTypes.instanceOf(IssueModel).isRequired,
  store:     PropTypes.instanceOf(IssueStore).isRequired,
  className: PropTypes.string,
  modeView:  PropTypes.string
};

export default JournalPanel;
