import { observer } from "mobx-react";
import React, { useCallback, useMemo } from "react";
import { ContextMenuTrigger } from "@ais3p/ui-framework-old";

import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";
import AisObject from "~/core/components/AisObject";
import { SIDEPANEL_RELATIONS } from "~/core/constants/SidePanels";

const RelationItem = observer(({ item, onOpenItem, kind, menuCollect }) => {
  const { relationStore, objectStore, uiStore } = useStores();
  const { isStart } = item;
  const { start, end } = kind;

  const onDeleteRelation = useCallback(() => {
    uiStore.setConfirm("Вы уверены что хотите удалить связь?", "Удаление связи", [
      {
        onPress: () => {
          uiStore.setConfirmPending(true);
          onConfirmDelete();
          uiStore.hideConfirm();
        },
        theme: "positive",
        icon:  "ok-M",
        text:  "Да"
      },
      {
        onPress: () => {
          uiStore.hideConfirm();
        },
        theme: "negative",
        icon:  "cancel-M",
        text:  "Нет"
      }
    ]);
  }, []);

  const onConfirmDelete = useCallback(async() => {
    await relationStore.deleteHalf(item, kind);
  }, []);

  const object = useMemo(() => {
    const { uid, domain, version } = item;
    return objectStore.getVersion(uid, domain, version);
  }, [item]);

  const collect = useCallback(() => {
    const menuItems = [
      {
        icon:  "open-M",
        type:  "read",
        title: "Открыть",
        data:  { action: "open" }
      },
      {
        icon:  "delete-M",
        type:  "delete",
        title: "Удалить",
        data:  { action: "delete" }
      }
    ];
    menuCollect({
      menuItems,
      onMenuItemClick
    });
  }, []);

  const onMenuItemClick = useCallback((e, contextMenuData) => {
    const { action } = contextMenuData;
    if (action === "open") {
      onOpenItem(item);
    }
    if (action === "delete") {
      onDeleteRelation();
    }
  }, []);

  if (!object) {
    <div className="item" data-tooltip={isStart ? start : end}>
      <AisIcon icon={isStart ? "arrow-left-M" : "arrow-right-M"} />
      <AisObject
        object={object} withVersion={true} store={relationStore}
        className={"linked-object"}
      />
      <AisIcon
        icon={"delete-M"} className="delete" data-tooltip="Удалить связь"
        onClick={onDeleteRelation}
      />
    </div>;
  }
  return (
    <ContextMenuTrigger
      item={item}
      id={"treeId"}
      menuType={SIDEPANEL_RELATIONS}
      onItemClick={onMenuItemClick}
      holdToDisplay={-1}
      collect={collect}
    >
      <div className="item" data-tooltip={isStart ? start : end}>
        <AisIcon icon={isStart ? "arrow-left-M" : "arrow-right-M"} />
        <AisObject
          object={object} withVersion={true} store={relationStore}
          className={"linked-object"}
        />
        <AisIcon
          icon={"delete-M"}
          className="delete"
          data-tooltip="Удалить связь"
          onClick={onDeleteRelation}
        />
      </div>
    </ContextMenuTrigger>
  );
});

export default RelationItem;
