import React, { useCallback } from "react";
import { components } from "@ais3p/ui-framework";
import "./css/reload-btn.scss";
const { buttons } = components;

const ReloadBtn = ({ onReload, errorText }) => {
  const handleClick = useCallback(() => {
    onReload();
  });
  return (
    <div className="reload-btn-notify">
      {errorText} 
      <span> Требуется перезапуск</span>
      <buttons.Button
        text="Подтвердить"
        onPress={handleClick}
      />
    </div>
  );
};
export default ReloadBtn;
