import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import ShortInfo from "./ShortInfo";
import SchemaParameters from "./SchemaParameters";
import TraceStore from "../../stores/traceStore";
import TraceSchemaModel from "../../models/traceSchema";

/**
 * Панель для задания фильтра при формировании GAP анализа
 * 
 * @param {Object} props набор параметров
 * @param {TraceStore} props.store хранилище TraceStore
 * @param {TraceSchemaModel} props.schema выбранная схема
 * @param {Function} props.onProcessSchema callback функция при задании параметров схемы
 * @param {Function} props.onChangeSchema callback функция при изменении схемы
 */
const Parameters = observer(({ store, schema, onProcessSchema, onChangeSchema }) => {
  if (!store.showSchemaParams) {
    // Пока не понятно как отображать краткую информацию по парметрам схемы.
    // Поэтому будем пока отображить либо сразу схему, либо результат
    return null;
  }
  
  return (
    <div className={classNames("trace-analizer-parameters", {
      "full-page": store.showSchemaParams,
      "no-schema": !schema
    })}
    >
      {!store.showSchemaParams && schema && 
        <ShortInfo 
          store={store} 
          schema={schema}
        />
      }
      {store.showSchemaParams &&
        <SchemaParameters
          store={store} 
          schema={schema}
          onProcessSchema={onProcessSchema}
          onChangeSchema={onChangeSchema}
        />
      }
    </div>
  );
});

Parameters.propTypes = {
  store:           PropTypes.instanceOf(TraceStore).isRequired,
  schema:          PropTypes.instanceOf(TraceSchemaModel),
  onProcessSchema: PropTypes.func.isRequired,
  onChangeSchema:  PropTypes.func.isRequired
};


export default Parameters;
