import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Target from "~/core/components/Target";
import { Preloader } from "@ais3p/ui-framework-old";
import AisIcon from "~/core/components/AisIcon";

const TargetPanel = observer(
  ({ store, repositoryId, trackedItem, onTargetClick }) => {
    if (trackedItem) {
      return <Target trackedItem={trackedItem} onTargetClick={onTargetClick} />;
    }

    const [target, setTarget] = useState(undefined);
    const [pending, setPending] = useState(true);

    useEffect(async() => {
      setPending(true);
      try {
        const repo = await store.loadRepository(repositoryId);
        setTarget(repo);
      } finally {
        setPending(false);
      }
    }, [repositoryId]);

    return (
      <div className="object-holder">
        {pending && <Preloader size={2} />}
        {!pending && (
          <div className="target-object" onClick={onTargetClick}>
            <AisIcon
              backgroundColor="#4d4d4d"
              icon={target ? target.icon : "cancel-M"}
            />
            <div className="object-name">
              {(target && target.title) || "Не задан объект для отслеживания"}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default TargetPanel;
