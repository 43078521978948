import React from "react";
import PropTypes from "prop-types";
import { getEntityValueLabel } from "../../constants/values";

const TableColHeader = ({ column }) => {
  return (
    <div
      className={`entities-table-column-header ${column.id}-header`}
    >
      <span className="entities-table-column-header-text">
        {column.uid && column.name ? column.name : getEntityValueLabel(column.id)}
      </span>
    </div>
  );
};

TableColHeader.propTypes = {
  column: PropTypes.object 
};

export default TableColHeader;
