import Server from "~/core/api/server";

class AboutApi extends Server {
  methods = {
    getStatus: {
      url:         this.getServiceUrl("gateway", "status/version"),
      method:      "GET",
      textProcess: "get status ...",
      textSuccess: null,
      textError:   this.template`Во время get status произошла ошибка: ${0}`
    },
    getUiStatus: {
      url:         "/status/version",
      method:      "GET",
      textProcess: "get UI status ...",
      textSuccess: null,
      textError:   this.template`Во время get UI status произошла ошибка: ${0}`
    }
  };

  /**
   * [getStatus ]
   * @return {Object}          [status object]
   */
  async getStatus() {
    return await this.request("getStatus");
  }
  /**
   * [getStatus ]
   * @return {Object}          [status object]
   */
  async getUiStatus() {
    const result = await this.request("getUiStatus");
    return result;
  }
}

export default AboutApi;
