import React from "react";
import PropTypes from "prop-types";
import classNames  from "classnames";

/**
 * Компонент для отображения номера линии в коде
 * 
 * @param {Object} params набор параметров
 * @param {Object} params.index индекс линии
 * @param {String} params.className пользовательский className
 * @param {Boolean} params.isFocused флаг, указывающий, что линия в коде находитя в фокусе
 * 
 * @type {CodeLineNum}
 * @returns {Component}
 */
const CodeLineNum = ({ index, className, isFocused }) => {
  return (
    <div
      className={classNames("code-line-num", className, {
        focused: isFocused
      })}
    >
      {index + 1}
    </div>
  );
};

CodeLineNum.propTypes = {
  index:     PropTypes.number.isRequired,
  className: PropTypes.string,
  isFocused: PropTypes.bool
};


export default CodeLineNum;
