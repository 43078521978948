import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { components } from "@ais3p/ui-framework";

const { Icon } = components;

/**
 * @type {MenuItem}
 * 
 * Пункт меню выбора действия: Привязать родительскую задачу, привязать подзадачу, создать подзадачу
 * 
 * @param {Object} props набор параметров
 * @param {String} props.text текст пункта меню
 * @param {String} props.icon иконка пункта меню
 * @param {String} props.action тип действия пункта меню
 * @param {Function} props.onClick callback событие на клик по пункту меню
 */
const MenuItem = ({ text, icon, action, onClick }) => {
  const onClickItem = useCallback(() => {
    onClick && onClick(action);
  }, [onClick, action]);

  return (
    <div 
      className={"issue-related-issues-menu-item"}
      onClick={onClickItem}
    >
      <Icon name={icon} className="icon" />
      <span>{text}</span>
    </div>
  );
};
MenuItem.propTypes = {
  text:    PropTypes.string.isRequired,
  icon:    PropTypes.string.isRequired,
  action:  PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default MenuItem;

