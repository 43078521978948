import { observable, computed } from "mobx";
import { CLS_LIBRARY_REPOSITORY } from "~/core/constants/Classes";

/**
 * Модель для хранения информации о репозитории
 * 
 * @class {Repository}
 */
class Repository {
  /**
   * @type {String}
   * id репозитория
   */
  @observable 
  id = false;

  /**
   * @type {String}
   * название репозитория
   */
  @observable 
  name = false;

  /**
   * @type {String}
   * тип репозитория
   */
  @observable 
  type = false;

  /**
   * @type {String}
   * url репозитория
   */
  @observable 
  url = false;

  /**
   * @type {String}
   * пользователь репозитория
   */
  @observable 
  username = false;

  /**
   * Статичный конструктор модели.
   * При создании модели, лучше использовать статичный метод, тк если поменяются названия входных параметров,
   * то эти названия можно будет здесь только поменять, не меняя названия в самой модели
   * 
   * @param {Object} params
   * @param {String} params.id id репозитория
   * @param {String} params.name название репозитория
   * @param {String} params.type типа репозитория
   * @param {String} params.url url репозитория
   * @param {String} params.username пользователь репозитория
   *  
   * @returns {Repository}
   */
  static create({ id, name, type, url, username }) {
    return new Repository({ id, name, type, url, username });
  }

  /**
   * Конструктор модели.
   * 
   * @param {Object} params
   * @param {String} params.id id репозитория
   * @param {String} params.name название репозитория
   * @param {String} params.type типа репозитория
   * @param {String} params.url url репозитория
   * @param {String} params.username пользователь репозитория
   *  
   */
  constructor({ id, name, type, url, username }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.url = url;
    this.username = username;
  }

  @computed
  get title() {
    return this.name;
  }

  @computed
  get class() {
    return CLS_LIBRARY_REPOSITORY;
  }

  @computed
  get icon() {
    return "app-tree-M";
  }
}

export default Repository;
