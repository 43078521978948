import { Field, Icon, Modal } from "@ais3p/ui-framework-old";
import { observer } from "mobx-react";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";

import AisIcon from "~/core/components/AisIcon";
import Indicator from "../../../core/components/Indicator";

import InlinePicture from "./InlinePicture";
import PlusMenu from "./PlusMenu";
import TextItemIcon from "./TextItemIcon";

const Picture = observer(({ data, renderItem }) => {
  const {
    uid,
    inlineItem,
    caption,
    isEditingPicture,
    isExpanded,
    scrollItemId,
    isFocusUid,
    diffClass
  } = data;
  const captionRender = caption && renderItem(caption);

  const hasChunks = useMemo(() => {
    return !!(caption && caption.textId);
  }, [caption && caption.textId]);

  const onClickIcon = useCallback(() => {
    if (!hasChunks) {
      caption && caption.createOnlyChunk();
    } else {
      data.setEditing();
    }
    data.setFocusUid();
  }, [data, hasChunks]);

  const [src, setSrc] = useState(null);
  const [body, setBody] = useState("");
  const refInput = useRef();
  const canSave = null;

  const unsetIsEditingPicture = useCallback(() => {
    data.setEditingPicture();
  }, []);

  const onChangeBody = useCallback((value) => {
    setBody(value);
  }, []);

  const onSelectImage = useCallback(async(e) => {
    const file = e.target.files[0];
    try {
      const src = await data.uploadFile(file);
      setSrc(src);
    } catch (ex) {
      console.warn(ex.message);
    }
  }, []);

  const onOk = useCallback(async() => {
    await data.saveImage(src);
    unsetIsEditingPicture();
  }, [src]);

  const onBrowse = useCallback(() => {
    if (refInput && refInput.current) {
      refInput.current.value = null;
      refInput.current.click();
    }
  }, [refInput]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current.scrollIntoView(true);
      }, 10); // set timeout= DIRTY HACK
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div id={uid} ref={element} className={`image element ${diffClass}`}>
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <AisIcon
          onClick={toggleExpanded}
          icon={"plus-M"}
          className={`expand-menu ${isExpanded ? "expanded" : ""}`}
        />
        <TextItemIcon onClick={onClickIcon} data={data} />
        <div className="image-body" onClick={onClickIcon}>
          <div className="image-content">
            {inlineItem && <InlinePicture data={inlineItem} />}
          </div>
          {caption && captionRender}
        </div>
        <Modal
          visible={isEditingPicture}
          toggle={unsetIsEditingPicture}
          title={"Рисунок"}
          className={"picture-editor-modal dark"}
          modalContent={
            <div className={"picture-editor"}>
              <div className={"picture-editor-image"}>
                <a
                  className={"picture-editor-image-browse"}
                  href="#"
                  onClick={onBrowse}
                >
                  Обзор
                </a>
                <input
                  type="file"
                  accept="image/*"
                  ref={refInput}
                  onChange={onSelectImage}
                  style={{ display: "none" }}
                />
                {!src && (
                  <Icon
                    className="picture-editor-image-empty-icon"
                    icon={"image-M"}
                  />
                )}
                {src && <InlinePicture data={{ uid: "temp", value: src }} />}
              </div>
              <div className={"picture-editor-caption-body"}>
                <Field.String
                  id={"body"}
                  key={"body"}
                  label={"Наименование"}
                  type="text"
                  labelOnTop={true}
                  value={body}
                  onChange={onChangeBody}
                />
              </div>
            </div>
          }
          buttons={[
            {
              onClick:  onOk,
              size:     2,
              style:    { fontWeight: 100 },
              theme:    "info",
              leftIcon: "ok-M",
              text:     "Сохранить",
              disabled: canSave
            },
            {
              onClick:  unsetIsEditingPicture,
              size:     2,
              style:    { fontWeight: 100 },
              theme:    "danger",
              leftIcon: "cancel-M",
              text:     "Отмена"
            }
          ]}
        />
      </div>
      {isExpanded && <PlusMenu data={data} />}
    </div>
  );
});

export default Picture;
