import { action, computed, observable } from "mobx";

/**
 *
 * @type {AisRepresentation}
 */
class AisRepresentation {
  @observable
  uid = null;

  @observable
  domain = null;

  @observable
  store = null; // object store

  @observable
  versions = new Map();

  /**
   * Конструктор
   *
   * @param {Object} params параметры объекта
   * @param {ObjectStore} store хранилище
   */

  constructor(params, store) {
    this.store = store;

    this.uid = params.uid;
    this.domain = params.domain;
  }

  /**
   * Add version to versions Map
   *
   * @param {String} version
   * @memberof AisRepresentation
   */
  @action
  addVersion(version) {
    this.versions.set(version, version);
  }

  /**
   * Delete version from versions Map
   *
   * @param {String} version
   * @memberof AisRepresentation
   */
  @action
  deleteVersion(version) {
    this.versions.delete(version);
  }

  /**
   * Обновить информацию о блокировке. Если передать undefined, то блокировка снимется
   *
   * @param {Object} data инфорация о блокировке
   * @param {Date} data.lockedAt  дата блокировки
   * @param {String} data.lockedBy uid пользователя, который заблокировал
   */
  @action
  setLockInfo(data) {
    this.object.setLockInfo(data);
  }

  @computed
  get id() {
    return `${this.uid}-${this.domain}`;
  }

  @computed
  get objectId() {
    return `${this.uid}`;
  }

  /**
   * Класс объекта.
   */
  @computed
  get className() {
    return "AisRepresentation";
  }

  @computed
  get object() {
    return this.store.getObject(this.objectId);
  }

  @computed
  get versionMap() {
    const map = new Map();
    this.versions.forEach((versionId) => {
      const version = this.store.getVersion(`${this.id}-${versionId}`);
      if (version) {
        map.set(version.id, version);
      }
    });
    return map;
  }
}

export default AisRepresentation;
