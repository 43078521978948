import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import MenuItem from "./MenuItem";
import { IssueModel } from "../../models";

/**
 * @type {Menu}
 * 
 * Выпадающее меню для выбора действия: Привязать родительскую задачу,
 * привязать подзадачу, создать подзадачу
 *
 * @param {Object} props набор параметров
 * @param {IssueModel} props.issue объект Задачи
 * @param {Boolean} props.isDisabled флаг, указывающий, что меню скрыто
 * @param {Function} props.onSelectAction callback ф-я на клик по пункту меню
 */
const Menu = observer(
  ({ issue, isDisabled, onSelectAction }) => {
    /**
     * Событие на выбор пункта меню
     */
    const onSelectItem = useCallback(
      (action) => {
        onSelectAction && onSelectAction(action, issue);
      },
      [onSelectAction]
    );

    
    return (
      <div
        className={classNames("issue-related-issues-menu", {
          disabled: isDisabled,
          show:     !isDisabled
        })}
      >
        <MenuItem
          key="link-parent"
          icon="link-start-M"
          text="Прикрепить родительскую задачу"
          action="link-parent"
          onClick={onSelectItem}
        />
        {!issue.isNew &&
          <MenuItem
            key="link-subtask"
            icon="link-start-M"
            text="Прикрепить подзадачу"
            action="link-subtask"
            onClick={onSelectItem}
          />
        }
        {!issue.isNew &&
          <MenuItem
            key="create-subtask"
            icon="plus-M"
            text="Создать подзадачу"
            action="create-subtask"
            onClick={onSelectItem}
          />
        } 
      </div>
    );
  }
);

Menu.propTypes = {
  issue:          PropTypes.instanceOf(IssueModel).isRequired, 
  isDisabled:     PropTypes.bool,   
  onSelectAction: PropTypes.func  
};
export default Menu;
