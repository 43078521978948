import { observable, computed } from "mobx";

/**
 * Модель найденной сущности АИС
 */
export default class EntityModel {
  @observable
  uid = undefined;

  @observable
  class = undefined;

  @observable
  codeValues = undefined;

  @observable
  kindUid = undefined;

  @observable
  objectId = undefined;

  @observable
  values = undefined;

  @observable
  version = undefined;

  @observable
  json = undefined;
  
  static create({ uid, class: klass, codeValues, kindUid, objectId, values, version }, store) {
    return new  EntityModel({ uid, class: klass, codeValues, kindUid, objectId, values, version }, store);
  }

  constructor(data, store) {
    this.uid = data.uid;
    this.class = data.class;
    this.codeValues = data.codeValues;
    this.kindUid = data.kindUid;
    this.objectId = data.objectId;
    this.values = data.values;
    this.version = data.version;

    this.json = data;

    this.store = store;
  }

  @computed
  get title() {
    return this.class;
  }
}
