import { observer } from "mobx-react";
import React, { useCallback, useState, useMemo } from "react";

import { Button, Field } from "@ais3p/ui-framework-old";
import AddRelationItem from "./AddRelationItem";

const AddRelation = observer(
  ({ targetInfo, relationUIStore, id, parentNodeUid }) => {
    const [kind, setKind] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [direction, setDirection] = useState(null);

    const relationKinds = useMemo(() => {
      return relationUIStore.getRelationKindsForItem(
        id,
        parentNodeUid,
        direction
      );
    }, [id, parentNodeUid, direction]);

    const onToggleForm = useCallback(() => {
      setIsFormVisible(!isFormVisible);
    }, [isFormVisible]);

    const onKindChange = useCallback((kind) => {
      setKind(kind);
    }, []);

    return (
      <div className={`new-item ${isFormVisible ? "form-expanded" : ""}`}>
        <Button
          leftIcon={isFormVisible ? "close-M" : "plus-M"}
          text={isFormVisible ? "Закрыть" : "Добавить связь"}
          theme="info"
          onClick={onToggleForm}
        />
        {isFormVisible && (
          <div className="form-holder">
            <div className="relation-type-holder">
              <Field.DropDown
                selected={kind.id}
                label="Вид связи"
                onChange={onKindChange}
                canDrop={true}
                items={relationKinds}
              />
            </div>
            <AddRelationItem
              relationUIStore={relationUIStore}
              targetInfo={targetInfo}
              kind={kind}
              onChangeDirection={setDirection}
            />
          </div>
        )}
      </div>
    );
  }
);

export default AddRelation;
