import React from "react";
import PropTypes from "prop-types";
import { getIssueValueLabel } from "../../constants/values";

/**
 * Компонент для отображения колонки (группы) управления отображаемыми колонками в табличном представлении списка задач
 * 
 * @param {Object} props набор параметров
 * @param {String} params.title заголовок колонки (группы)
 * @param {Array<Array>} params.groups массив id колонок
 * @param {Array<Object>} params.activeColumns массив объектов, в котором по ключу находится флаг активности колонки
 * @param {Function} params.onCheckColumn callback ф-я на автивизацию/деактивизацию колонки
 */
const ColumnGroups = ({
  title,
  groups,
  activeColumns,
  onCheckColumn
}) => {
  return (
    <div className="issues-table-columns-group">
      <div className="title">{title}</div>
      <div className="groups">
        {groups.map((gr, i) => {
          return (
            <div
              key={`gr-${i}`}
              className="group-column"
            >
              {
                gr.map((id) => {
                  return (
                    <div
                      key={id}
                      className="group-column-checkbox"
                    >
                      <input
                        type="checkbox"
                        id={id}
                        name={id}
                        checked={activeColumns[id]}
                        onChange={onCheckColumn}
                      />
                      <label htmlFor={id}>{getIssueValueLabel(id)}</label>
                    </div>
                  );
                })
              }
            </div>
          );
        })}
      </div>
    </div>
  );
};

ColumnGroups.propTypes = {
  title:         PropTypes.string,
  groups:        PropTypes.arrayOf(Array),
  activeColumns: PropTypes.object,
  onCheckColumn: PropTypes.func
};

export  default ColumnGroups;
