/**
 * Значения переменных в результате поиска
 */
const ENTITY_VALUE_NUM = "num";
const ENTITY_VALUE_UID = "uid";
const ENTITY_VALUE_TITLE = "title";
const ENTITY_VALUE_VERSION = "version";

/**
 * Получить `label` значения переменной в результате поиска
 *
 * @param {String} name название переменной
 * @return {String}
 */
const getEntityValueLabel = (name) => {
  switch (name) {
    case ENTITY_VALUE_NUM: 
      return "№";
    case ENTITY_VALUE_TITLE:
      return "Объект";
    case ENTITY_VALUE_VERSION:
      return "Версия";
    default:
      return name;
  }
};

/**
 * Доступный набор колонок в табличном представлении
 *
 * @return {Object}
 */
const ENTITIES_TABLE_COLUMNS = {
  [ENTITY_VALUE_NUM]:     { label: getEntityValueLabel(ENTITY_VALUE_NUM), defaultActive: true, order: 1 },
  [ENTITY_VALUE_TITLE]:   { label: getEntityValueLabel(ENTITY_VALUE_TITLE), defaultActive: true, order: 2 },
  [ENTITY_VALUE_VERSION]: { label: getEntityValueLabel(ENTITY_VALUE_VERSION), defaultActive: true, order: 3 }
};

export {
  ENTITY_VALUE_NUM,
  ENTITY_VALUE_UID,
  ENTITY_VALUE_TITLE,
  ENTITY_VALUE_VERSION,
  
  ENTITIES_TABLE_COLUMNS,
  
  getEntityValueLabel
};
