import React from "react";
import { observer } from "mobx-react";
import UserAvatar from "~/modules/users/components/avatar";

/**
 * Компонет для отображения пользователя
 */
const UserItem = observer(({
  user,
  className
}) => {
  return (
    <div className={`journal-user ${className ? className : ""}`}>
      <UserAvatar
        className="avatar"
        fileId={user && user.image}
      />
      {user &&
        <div className="user-name">
          {user && user.shortName}
        </div>
      }
      {!user &&
        <div className="user-undefined">
          {"Пользователь не определен!"}
        </div>
      }
    </div>
  );
});

export default UserItem;
