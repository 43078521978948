import { observable, action, computed } from "mobx";

class Kind {
  @observable id;
  @observable type = "kind";
  @observable name;
  @observable attrs = [];
  @observable allowedTypes = null;
  @observable allowedKinds = null;
  @observable codes = new Map();
  @observable traceSchemasMap = new Map();

  constructor(data, kindStore) {
    this.kindStore = kindStore;
    this.id = data.uid;
    this.name = data.name;

    if (data.allowedTypes.length > 0) {
      this.allowedTypes = data.allowedTypes;
    }

    if (data.allowedKinds.length > 0) {
      this.allowedKinds = data.allowedKinds;
    }

    if (data.codes) {
      data.codes.forEach((code) => {
        this.addCode(code);
      });
    }

    data.attributes &&
      data.attributes.forEach((attr) => {
        this.attrs.push(attr.uid);
      });
  }

  @action
  changeName(name) {
    this.name = name;
  }
  
  @action
  addCode(code) {
    if (code) {
      this.codes.set(code.uid, code);
    }
  }
  
  @action
  addAttribute(uid) {
    this.attrs.push(uid);
  }
  
  @action
  deleteAttribute(uid) {
    this.attrs.remove(uid);
  }
  
  @computed
  get attributes() {
    return this.attrs.map((id) => {
      return this.kindStore.getAttr(id);
    });
  }

  @computed
  get codesArray() {
    return Array.from(this.codes.values());
  }

  @action
  clearTraceSchemas() {
    this.traceSchemasMap.clear();
  }

  @action
  addTraceSchema(schema) {
    this.traceSchemasMap.set(schema.id, schema);
  }

  getTraceSchema(schemaId) {
    return this.traceSchemasMap.get(schemaId);
  }

  @computed
  get traceSchemasList() {
    return  Array.from(this.traceSchemasMap.values());
  }
}
export default Kind;
