/**
 * Kinds and Attributes module
 */
import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Preloader } from "@ais3p/ui-framework-old";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";

import ItemView from "../components/ItemView";
import AddKind from "../components/AddKind";
import KindGroup from "../components/KindGroup";

import "react-tabs/style/react-tabs.css";
import "./css/index.scss";

const KindsSidepanel = observer((props) => {
  const { hasTarget, trackedItem } = props;

  const { kindsStore } = useStores();
  const { isPending, isItemsPending, isPendingData } = kindsStore;

  const item = useMemo(() => {
    if (
      !!trackedItem &&
      hasTarget &&
      trackedItem.uid &&
      !isItemsPending &&
      !isPendingData
    ) {
      return kindsStore.getItem(trackedItem.uid, trackedItem.version);
    }
    return null;
  }, [
    trackedItem && trackedItem.uid,
    trackedItem && trackedItem.version,
    isItemsPending,
    isPendingData,
    trackedItem,
    hasTarget
  ]);

  const isThisPending = useMemo(() => {
    return (
      kindsStore.isPending ||
      (item && item.isPending) ||
      isItemsPending ||
      isPendingData
    );
  }, [
    kindsStore.isPending,
    item && item.isPending,
    isItemsPending,
    isPendingData
  ]);

  const kindsRender = useMemo(() => {
    const kinds = [];
    if (!item || isPending) {
      return kinds;
    }
    item.initialKinds.forEach((member) => {
      kinds.push(
        <KindGroup
          key={member.memberUid}
          objectUid={trackedItem.uid}
          version={trackedItem.version}
          member={member}
        />
      );
    });
    return kinds;
  }, [
    trackedItem && trackedItem.uid,
    trackedItem && trackedItem.version,
    item && item.initialKinds,
    item && item.isPending,
    isThisPending,
    isPending
  ]);

  return (
    <div className="k-and-a-sidepanel">
      <Target trackedItem={trackedItem} />
      <div className="k-and-a-content">
        {!isThisPending && <ItemView hasTarget={hasTarget} item={item} />}
        {isThisPending && (
          <div className="preload-wrapper">
            <Preloader
              size={4}
              style={{
                margin:       "0.25rem",
                borderRadius: "3rem"
              }}
              color="#01abfb"
            />
          </div>
        )}
        {!isThisPending && kindsRender}
        {!!trackedItem && !isThisPending && !trackedItem.version && (
          <AddKind id={trackedItem.uid} version={trackedItem.version} />
        )}
      </div>
    </div>
  );
});

export default KindsSidepanel;
