import React, { useMemo, useCallback, useState } from "react";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import { Components, Field, Modal } from "@ais3p/ui-framework";
import "./css/login.scss";

const Login = observer(() => {
  const { accountStore } = useStores();

  const { pending, isLoggedIn, isAutoLoginPending, login } = accountStore;
  const [loginValue, setLoginValue] = useState("");
  const [password, setPassword] = useState("");  

  const onLoginSubmit = useCallback(() => {
    accountStore.performLogin(loginValue, password, true);
  }, [accountStore, loginValue, password]);

  const title = useMemo(() => {
    return isAutoLoginPending ? "Восстановление..." : "Выполните вход";
  }, [isAutoLoginPending]);

  const content = useMemo(() => {
    if (!isAutoLoginPending) {
      return null;
    }

    return (
      <div className="login-modal-content">
        Сессия пользователя <b>{login}</b> будет восстановлена
      </div>
    );
  }, [isAutoLoginPending]);

  const modalButton = (
    <Components.Button
      text="Войти"
      icon="login-M"
      onPress={onLoginSubmit}
      isLoading={pending}
      color="action"
    />
  );
  return (
    <Modal.Window
      name="login"
      icon="plane-M"
      show={!isLoggedIn}
      title={title}
      buttons={modalButton}
      onKeyPressEnter={onLoginSubmit}
    >
      {content ? (
        content
      ) : (
        <div className="login-container">
          <Field.String
            icon=""
            label="Логин"
            name="login"
            onChange={setLoginValue}
            value={loginValue}
            autoFocus={true}
            theme="dark"
          />
          <Field.String
            icon=""
            label="Пароль"
            name="password"
            typeOfInput="password"
            onChange={setPassword}
            value={password}
            theme="dark"
          />
        </div>
      )}
    </Modal.Window>
  );
});

export default Login;
