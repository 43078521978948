import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import moment from "moment";

import { components } from "@ais3p/ui-framework";
import JsonViewer from "../json/JsonViewer";
import UserAvatar from "~/modules/users/components/avatar";
import MessageModel from "../../models/messageModel";


const { Icon } = components;

/**
 * @type MessageItem
 * 
 * Компонент для отображения сообщения, полученного по ws
 * 
 * @prop {MessageModel} message сообщшение по ws
 */
const MessageItem = observer(({ message }) => {
  const { user } = message;

  const toggleShowPayload = useCallback(() => {
    message.toggleShowPayload();
  }, [message]);

  return (
    <div className="message-item">
      <div className="message-item-num">{message.num}</div>
      <div className="message-item-data">
        <div className={"message-item-info"}>
          <div className={"message-item-action"}>
            <div className={"date-time"}>
              {message.datetime &&
                moment(message.datetime).format("DD.MM.YYYY HH:mm:ss")}
            </div>
            <div className={"action"}>{message.action}</div>
          </div>
          <div className={"message-item-object"}>
            <div className={"uid"}>{message.object}</div>
            <div className={"class"}>
              {message.class} {message.version ? `(v. ${message.version})` : ""}
            </div>
          </div>

          <div className={"message-item-user"}>
            {user && (
              <>
                <UserAvatar className="avatar" fileId={user && user.image} />
                <div className="user-name">{user.shortName}</div>
              </>
            )}
            {!user && <div className="user-name">{message.subject}</div>}
          </div>
        </div>
        <div className={"message-item-payload"}>
          {message.payload && (
            <>
              <div className="payload-title">
                <div className="payload-title-body" onClick={toggleShowPayload}>
                  <div className="payload-title-text">Payload</div>
                  <Icon
                    name={
                      message.isShowPayload ? "chevron-down-M" : "chevron-up-M"
                    }
                    className="payload-title-icon"
                  />
                </div>
              </div>
              {message.isShowPayload && (
                <div className="payload-body">
                  <JsonViewer json={message.payload} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
});

MessageItem.propTypes = {
  message: PropTypes.instanceOf(MessageModel).isRequired
};

export default MessageItem;
