import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { observer } from "mobx-react";
import { components } from "@ais3p/ui-framework";
import classNames from "classnames";

import { colorShade } from "~/core/utils";
import useStores from "~/core/utils/useStores";
// import { LUMINANCE_BORDER_COLOR, LUMINANCE_TEXT_COLOR } from "../../constants/luminances";
import {
  SHADE_BACKGROUND_COLOR,
  SHADE_BORDER_COLOR
} from "../../constants/colorShade";
import IssueIcon from "./IssueIcon";

import {
  ISSUE_VALUE_ID,
  ISSUE_VALUE_UID,
  ISSUE_VALUE_AUTHOR,
  ISSUE_VALUE_ASSIGNED_TO,
  ISSUE_VALUE_CREATED_ON,
  ISSUE_VALUE_DESCRIPTION,
  ISSUE_VALUE_DONE_RATIO,
  ISSUE_VALUE_DUE_DATE,
  ISSUE_VALUE_ESTIMATED_HOURS,
  ISSUE_VALUE_PRIORITY_ID,
  ISSUE_VALUE_PROJECT,
  ISSUE_VALUE_START_DATE,
  ISSUE_VALUE_STATUS_ID,
  ISSUE_VALUE_SUBJECT,
  ISSUE_VALUE_TRACKER,
  ISSUE_VALUE_UPDATED_ON,
  ISSUE_VALUE_WATCHERS
  // ISSUE_VALUE_JOURNAL,
  // ISSUE_VALUE_RELATIONS,
} from "../../constants/values";

import UserItem from "../user/UserItem";
import IssueStore from "../../stores/IssueStore";
import { IssueModel } from "../../models";

const { Preloader } = components;

/**
 * Компонент для отображения значений в табличном предтставлении списка задач
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {Object} params.column данные колонки таблицы
 * @param {IssueModel} params.original объект задачи
 * @param {Any} params.value значение, которое должно быть отображено в ячейки таблицы
 * @param {LayoutItem} params.layoutItem сущность в Layout
 */
const TableValue = observer((props) => {
  const { value, column, store, original, layoutItem } = props;

  const { uiStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);

  const onClick = useCallback(async() => {
    if (isLoading) {
      // уже идет загрука задачи
      return;
    }
    setIsLoading(true);
    try {
      await store.openIssue(original.uid);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  switch (column.id) {
    case ISSUE_VALUE_ID:
      return (
        <div className="issues-table-cell-id" onClick={onClick}>
          {isLoading && (
            <div className="issues-table-cell-preloader">
              <Preloader size={1.5} />
            </div>
          )}
          <div className="issues-table-cell-index">
            {original.titlePrefix}
          </div>
        </div>
      );
    case ISSUE_VALUE_SUBJECT:
      return (
        <span
          className="issues-table-cell-text issues-table-cell-title"
          onClick={onClick}
        >
          {value}
        </span>
      );
    case ISSUE_VALUE_UID:
    case ISSUE_VALUE_DESCRIPTION:
      return <span className="issues-table-cell-text">{value}</span>;
    case ISSUE_VALUE_DONE_RATIO:
      return <span className="issues-table-cell-text">{`${value}%`}</span>;
    case ISSUE_VALUE_ESTIMATED_HOURS:
      return (
        <span className="issues-table-cell-text">{value && `${value} ч.`}</span>
      );
    case ISSUE_VALUE_AUTHOR:
    case ISSUE_VALUE_ASSIGNED_TO:
      return value ? (
        <UserItem userId={value && value.id} store={store} />
      ) : null;

    case ISSUE_VALUE_CREATED_ON:
    case ISSUE_VALUE_START_DATE:
    case ISSUE_VALUE_UPDATED_ON:
      return (
        <span className="issues-table-cell-date">
          {value && moment(value).format("DD.MM.YYYY")}
        </span>
      );
    case ISSUE_VALUE_DUE_DATE: {
      return (
        <div
          className={classNames("issues-table-cell-date", {
            overdue: value && value < new Date()
          })}
        >
          {value && moment(value).format("DD.MM.YYYY")}
        </div>
      );
    }

    case ISSUE_VALUE_STATUS_ID: {
      const status = store.getStatus(value);
      return (
        <span className="issues-table-cell-text">
          {(status && status.title) || value}
        </span>
      );
    }
    case ISSUE_VALUE_PRIORITY_ID: {
      const priority = store.getPriority(value);
      if (!priority) {
        return null;
      }
      let priorityStyle;
      if (priority.color) {
        const colorName = uiStore.colors.getBaseColorName(
          uiStore.colors.getColorNameByHex(priority.color)
        );
        const bgColor =
          (colorName && uiStore.colors.getColorByName(`${colorName}1`)) ||
          colorShade(priority.color, SHADE_BACKGROUND_COLOR);
        const borderColor =
          (colorName && uiStore.colors.getColorByName(`${colorName}3`)) ||
          colorShade(priority.color, SHADE_BORDER_COLOR);
        const txtColor =
          (colorName && uiStore.colors.getColorByName(`d${colorName}3`)) ||
          priority.color;

        priorityStyle = {
          backgroundColor: bgColor,
          borderColor,
          color:           txtColor
        };
      }

      return (
        <div className="issues-table-cell-tag" style={priorityStyle}>
          {(value && priority.title) || value}
        </div>
      );
    }

    case ISSUE_VALUE_TRACKER:
      return (
        <IssueIcon store={store} issue={original} layoutItem={layoutItem} />
      );
    case ISSUE_VALUE_PROJECT:
      return (
        <span className="issues-table-cell-text">{value && value.title}</span>
      );

    case ISSUE_VALUE_WATCHERS:
      return (
        value &&
        value.map((user) => {
          return <UserItem key={user.id} userId={value.id} store={store} />;
        })
      );
    // case ISSUE_VALUE_JOURNAL:
    // case  ISSUE_VALUE_RELATIONS:
    default:
      return <span className="issues-table-cell-text">{value}</span>;
  }
});

TableValue.propTypes = {
  value:      PropTypes.any,
  column:     PropTypes.object.isRequired, 
  store:      PropTypes.instanceOf(IssueStore).isRequired, 
  original:   PropTypes.instanceOf(IssueModel).isRequired, 
  layoutItem: PropTypes.object.isRequired
};

export default TableValue;
