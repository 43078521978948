import React, { useEffect, useMemo, useCallback, useState } from "react";
import { observer } from "mobx-react";
import "react-table/react-table.css";

import { Preloader } from "@ais3p/ui-framework-old";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";

import RelationUIStore from "../stores/relationUIStore";
import KindGroup from "../components/KindGroup";
import AddRelation from "../components/AddRelation";

import { getPropsToOpenLayoutTool } from "~/core/utils";

import "./css/index.scss";

const ConnectionSidepanel = observer((props) => {
  const { relationStore, objectStore } = useStores();
  const { uid, version, trackedItem, layoutStore, parentNodeUid } = props;

  const menuCollect = useCallback(
    (props) => {
      layoutStore.menuCollect(props);
    },
    [layoutStore]
  );

  const [expandToAddKindId, setExpandToAddKindId] = useState(null);

  const relationUIStore = useMemo(() => {
    return new RelationUIStore(relationStore);
  }, []);

  const onOpenItem = useCallback(async(item) => {
    const tool = item.tool || item.domain;
    const toolToOpen = {
      component: tool
    };

    const itemToOpen = await getPropsToOpenLayoutTool(item, objectStore);

    if (!itemToOpen) {
      return;
    }

    layoutStore.open({ ...itemToOpen, ...toolToOpen });
  }, []);

  useEffect(() => {
    if (!uid) return;
    relationUIStore.init(uid, version);
  }, [uid, version]);

  const {
    isPending,
    hasConnections,
    relationsByKindArray,
    relationsByKindArrayLength
  } = relationUIStore;

  const plusToggle = useCallback(
    (id) => {
      setExpandToAddKindId(id);
    },
    [setExpandToAddKindId]
  );

  const relations = useMemo(() => {
    if (isPending) {
      return (
        <div className="preload-wrapper">
          <Preloader
            size={4}
            style={{
              margin:       "0.25rem",
              borderRadius: "3rem"
            }}
            color="#01abfb"
          />
        </div>
      );
    }

    if (!hasConnections) {
      return (
        <div className="header">
          <span className="text-holder">Объект не имеет связей</span>
        </div>
      );
    }

    const relations = relationsByKindArray.map(({ kind, items }) => {
      return (
        <KindGroup
          targetInfo={trackedItem}
          menuCollect={menuCollect}
          relationUIStore={relationUIStore}
          key={kind.id}
          kind={kind}
          items={items}
          plusToggle={plusToggle}
          expandToAddKindId={expandToAddKindId}
          onOpenItem={onOpenItem}
        />
      );
    });
    return relations;
  }, [
    relationsByKindArray,
    relationsByKindArrayLength,
    isPending,
    hasConnections,
    expandToAddKindId
  ]);

  return (
    <div className={"connection-tool-holder"}>
      <div className={"connection-tracker"}>
        <Target id={uid} version={version} trackedItem={trackedItem} />
        <div className="list-holder">
          <div className="items-holder">
            {relations}
            {!!trackedItem && !isPending && (
              <AddRelation
                id={uid}
                parentNodeUid={parentNodeUid}
                targetInfo={trackedItem}
                relationUIStore={relationUIStore}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ConnectionSidepanel;
