import React from "react";
import AisIcon from "~/core/components/AisIcon";

class VersionForm extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.form, this.props.nodeData, this.props.parent);
  }

  render() {
    return (
      <span title={this.props.title} onClick={this.onClick}>
        <AisIcon item={this.props.form} />
      </span>
    );
  }
}

export default VersionForm;
