import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Field } from "@ais3p/ui-framework-old";
import classNames from "classnames";
import AisIcon from "~/core/components/AisIcon";

const AttrField = observer(
  ({
    attr,
    kind,
    member,
    isView,
    attrUid,
    index,
    kindUid,
    itemUid,
    hasUid,
    disabled,
    item,
    className,
    labelOnTop = true,
    onChange
  }) => {
    const { isMultiple, type, opts, required, name, isReadonly } = attr;

    const { size, rows, type: itemType } = item;

    const zIndex = useMemo(() => {
      return kind.attributes.length - index;
    }, [kind.attributes.length, index]);

    const memberValue = useMemo(() => {
      if (!member) {
        return null;
      }
      const vals = isView ? member.initValues : member.allValues;
      return vals[attrUid];
    }, [
      member,
      member && member.initValues,
      member && member.allValues,
      isView,
      attrUid
    ]);

    const value = useMemo(() => {
      let value = memberValue;
      if (
        value &&
        isMultiple &&
        type === "enum" &&
        value.length &&
        value.length > 0
      ) {
        value = [];
        memberValue.forEach((val) => {
          opts.forEach((opt) => {
            if (val === opt.value) {
              value.push(opt);
            }
          });
        });
      } else if (value && value[0] && !isMultiple && type === "enum") {
        opts.forEach((opt) => {
          if (memberValue[0] === opt.value) {
            value = opt;
          }
        });
      }

      return value;
    }, [memberValue, opts, isMultiple, type]);

    const leftIcon = useMemo(() => {
      return (
        <AisIcon
          icon={`data-${
            type === "enum"
              ? `${type}-${isMultiple ? "multi" : "single"}`
              : type
          }-M`}
        />
      );
    }, [type, isMultiple]);

    const fieldProps = useMemo(() => {
      return {
        ...attr,
        kindUid,
        itemUid,
        attrUid,
        name:       attrUid,
        value,
        readOnly:   disabled || (isReadonly && hasUid),
        isRequired: required,
        isMulti:    isMultiple,
        options:    opts,
        id:         attrUid,
        labelOnTop,
        style:      { zIndex },
        type,
        className:  classNames(itemType, className, {
          required
        }),
        rows,
        size:  size || 2,
        label: name,
        onChange,
        leftIcon
      };
    }, [
      attr,
      kindUid,
      itemUid,
      attrUid,
      value,
      disabled,
      isReadonly,
      hasUid,
      required,
      isMultiple,
      opts,
      zIndex,
      type,
      itemType,
      required,
      rows,
      size,
      name,
      onChange,
      leftIcon
    ]);

    if (type === "textarea") {
      return <Field.TextArea {...fieldProps} />;
    } else if (type === "string") {
      return <Field.String {...fieldProps} autocomplete="on" />;
    } else if (type === "integer") {
      return <Field.Integer {...fieldProps} autocomplete="on" />;
    } else if (type === "float") {
      return <Field.Float {...fieldProps} autocomplete="on" />;
    } else if (type === "datetime") {
      return <Field.DateTime {...fieldProps} />;
    } else if (type === "enum") {
      return <Field.Select {...fieldProps} />;
    } else if (type === "boolean") {
      return <Field.Boolean {...fieldProps} />;
    }
    return null;
  }
);

export default AttrField;
