import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { CHG_PROPERTY_ATTACHMENT } from "../../constants/propertyTypes";
import { PROPERTY_NAME_DESCIPTION } from "../../constants/propertyNames";

import ChangeValue from "./ChangeValue";
import DiffDescription from "./DiffDescription";
import IssueStore from "../../stores/IssueStore";

/** 
 * Компонент для отображения сделанных изменений в журнале изменений
 * 
 * @param {Object} props набор параметров
 * @param {String} params.property тип параметра Задачи
 * @param {String} params.name название параметра Задачи
 * @param {Any} params.oldValue старое значение
 * @param {Any} params.newValue новое значение
 * @param {IssueStore} params.store хранилище для работы с задачами
 */
const ChangeAction = ({
  property,
  name,
  oldValue,
  newValue,
  store
}) => {
  const [isShownDescription, setIsShownDescription] = useState(false);

  const toggleShowDescription = useCallback(() => {
    setIsShownDescription(!isShownDescription);
  }, [isShownDescription]);

  const isAdded = () => {
    return !oldValue && !!newValue;
  };

  const isRemoved = () => {
    return !!oldValue && !newValue;
  };

  let content = undefined;
  if (property === CHG_PROPERTY_ATTACHMENT) {
    content =  (
      <>
        <ChangeValue
          name={name}
          property={property}
          value={isAdded() ? newValue : oldValue}
          isOldValue={!isAdded()}
          isRemoved={isRemoved()}
          store={store}
        />
        <span className="inline">{isAdded() ? "добавлен" : "удален"}</span>
      </>
    );
  } else if (isAdded()) {
    content =  (
      <>
        <span className="inline">добавлен со значением </span>
        <ChangeValue
          name={name}
          property={property}
          value={newValue}
          isOldValue={false}
          isRemoved={false}
          isShownDescription={isShownDescription}
          toggleShowDescription={toggleShowDescription}
          store={store}
        />

      </>
    );
  } else if (isRemoved()) {
    content =  (
      <>
        <ChangeValue
          name={name}
          property={property}
          value={newValue}
          isOldValue={false}
          isRemoved={false}
          isShownDescription={isShownDescription}
          toggleShowDescription={toggleShowDescription}
          store={store}
        />
        <span className="inline">удален</span>
      </>
    );
  } else if (name === PROPERTY_NAME_DESCIPTION) {
    content =  (
      <>
        <span className="inline">изменен </span>
        <ChangeValue
          name={name}
          property={property}
          value={oldValue}
          isOldValue={false}
          isRemoved={false}
          isShownDescription={isShownDescription}
          toggleShowDescription={toggleShowDescription}
          store={store}
        />
      </>
    );
  } else {
    content =  (
      <>
        <span className="inline">изменен с</span>
        <ChangeValue
          name={name}
          property={property}
          value={oldValue}
          isOldValue={true}
          isRemoved={false}
          store={store}
        />
        <span className="inline">на</span>
        <ChangeValue
          name={name}
          property={property}
          value={newValue}
          isOldValue={false}
          isRemoved={false}
          store={store}
        />
      </>
    );
  }

  return (
    <div className="action">
      {content}
      {isShownDescription &&
        <DiffDescription oldValue={oldValue} newValue={newValue} />
      }
    </div>
  );
};

ChangeAction.propTypes = {
  property: PropTypes.string,
  name:     PropTypes.string,
  oldValue: PropTypes.any,
  newValue: PropTypes.any,
  store:    PropTypes.instanceOf(IssueStore).isRequired
};

export default ChangeAction;
