import Server from "~/core/api/server";

class LibraryApi extends Server {
  methods = {
    getCollection: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v2/nodes${0}`,
          params
        );
      },
      method:      "GET",
      textProcess: "Запрос данных коллекции...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время запроса данных коллекции произошла ошибка: ${0}`
    },
    search: {
      url: (params) => {
        const urlParams = new URLSearchParams(params);
        return this.getServiceUrl("library", `api/v2/nodes?${urlParams}`);
      },
      method:      "GET",
      textProcess: "Поиск...",
      textSuccess: null,
      timeout:     30000,
      textError:   this.template`Во время поиска произошла ошибка: ${0}`
    },
    createText: {
      url: () => {
        return this.getServiceUrl("text", "api/v0/texts");
      },
      method:      "POST",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/vnd.ais3p.app.text[ver.d]+json",
      textProcess: "Создание текста...",
      textSuccess: null,
      timeout:     30000,
      textError:   this.template`Во время создания текста произошла ошибка: ${0}`
    },
    getRepos: {
      url: (params) => {
        return this.getServiceUrl(
          "vcs",
          this.template`api/v1/repositories`,
          params
        );
      },
      method:      "GET",
      textProcess: "Запрос доступных репозиториев...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время запроса доступных репозиториев произошла ошибка: ${0}`
    },
    addRepo: {
      url: (params) => {
        return this.getServiceUrl(
          "vcs",
          this.template`api/v1/repositories`,
          params
        );
      },
      method:      "POST",
      textProcess: "Запрос доступных репозиториев...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время запроса доступных репозиториев произошла ошибка: ${0}`
    },
    pushRepo: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v2/collections/${0}/repository-materials`,
          params
        );
      },
      method:      "POST",
      textProcess: "Создание РМ репозитория...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время создания РМ репозитория произошла ошибка: ${0}`
    },
    file: {
      url: (params) => {
        return this.getServiceUrl(
          "files",
          this.template`api/v1/files/${0}`,
          params
        );
      },
      timeout:     300000,
      method:      "GET",
      textProcess: "Запрос файла...",
      textSuccess: null,
      textError:   this.template`Во время запроса файла произошла ошибка: ${0}`
    },
    export: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this
            .template`api/v1/materials/${0}/versions/${1}/forms/${2}/export?format=${3}`,
          params
        );
      },
      timeout:     300000,
      method:      "POST",
      textProcess: "Экспорт в PDF...",
      textSuccess: null,
      textError:   this.template`Во время экспорта в PDF произошла ошибка: ${0}`
    },
    createNode: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v2/collections/${0}/collections`,
          params
        );
      },
      timeout:     30000,
      method:      "POST",
      textProcess: "Создание узла...",
      textSuccess: null,
      textError:   this.template`Во время создания узла произошла ошибка: ${0}`
    },
    createTraceReportWm: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v1/materials/${0}/versions/${1}/trace-reports`,
          params
        );
      },
      timeout:     30000,
      method:      "POST",
      textProcess: "Создание узла...",
      textSuccess: null,
      textError:   this.template`Во время создания узла произошла ошибка: ${0}`
    },
    exportTraceReport: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v1/materials/trace-reports/${0}/export?schema=${1}`,
          params
        );
      },
      timeout:     30000,
      method:      "POST",
      textProcess: "Создание узла...",
      textSuccess: null,
      textError:   this.template`Во время создания узла произошла ошибка: ${0}`
    },
    copyNode: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v1/nodes/${0}/parent?action=copy`,
          params
        );
      },
      timeout:     30000,
      method:      "PUT",
      textProcess: "Копирование узла...",
      textSuccess: null,
      textError:   this
        .template`Во время копирования узла произошла ошибка: ${0}`
    },
    removeNode: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v2/nodes/${0}`,
          params
        );
      },
      timeout:     30000,
      method:      "DELETE",
      textProcess: "Удаление узла...",
      textSuccess: null,
      textError:   this.template`Во время удаления узла произошла ошибка: ${0}`
    },
    deleteCollection: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v2/nodes/${0}`,
          params
        );
      },
      timeout:     30000,
      method:      "DELETE",
      textProcess: "Удаление коллекции...",
      textSuccess: null,
      textError:   this
        .template`Во время удаления коллекции произошла ошибка: ${0}`
    },
    deleteReport: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v1/materials/trace-reports/${0}?from=${1}`,
          params
        );
      },
      timeout:     30000,
      method:      "DELETE",
      textProcess: "Удаление отчета о трассировке...",
      textSuccess: null,
      textError:   this
        .template`Во время удаления отчета о трассировке произошла ошибка: ${0}`
    },
    updateNode: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v2/nodes/${0}`,
          params
        );
      },
      timeout:     30000,
      method:      "PATCH",
      textProcess: "Изменение данных узла...",
      textSuccess: null,
      textError:   this
        .template`Во время изменения данных узла произошла ошибка: ${0}`
    },
    importForm: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v1/materials/${0}/versions/${1}/forms`,
          params
        );
      },
      timeout:     300000,
      method:      "POST",
      textProcess: "Импорт...",
      textSuccess: null,
      textError:   this.template`Во время импорта произошла ошибка: ${0}`
    },
    loadFile: {
      url: (params) => {
        return this.getServiceUrl("library", this.template`api/v2/collections/${0}/upload.action`, params);
      },
      timeout:     300000,
      method:      "POST",
      textProcess: "Загрузка файла...",
      textSuccess: null,
      textError:   this.template`Во время загрузки файла произошла ошибка: ${0}`
    },
    createAis: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v2/collections/${0}/text-materials`,
          params
        );
      },
      timeout:     30000,
      method:      "POST",
      textProcess: "Создание текста...",
      textSuccess: null,
      textError:   this.template`Во создания текста произошла ошибка: ${0}`
    },
    importPof: {
      url:         this.getServiceUrl("library", "api/v1/aliens"),
      method:      "POST",
      timeout:     300000,
      textProcess: "Импорт данных POF...",
      textSuccess: null,
      textError:   this
        .template`Во время Импорта данных POF произошла ошибка: ${0}`
    },
    importUrs: {
      url: (params) => {
        return this.getServiceUrl(
          "pharma-import",
          this.template`import/${0}`,
          params
        );
      },
      method:      "POST",
      timeout:     300000,
      textProcess: "Импорт шаблона URS...",
      textSuccess: null,
      textError:   this
        .template`Во время Импорта шаблона URS произошла ошибка: ${0}`
    },
    // Получить ноду Библиотеки зная только uid ноды текcтового представления и номер версии
    getTextMaterialNodeVersion: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v2/text-materials/${0}/versions/${1}`,
          params
        );
      },
      timeout:     30000,
      method:      "GET",
      textProcess: "Запрос данных узла версии текстового представления...",
      textSuccess: null,
      textError:   this.template`Во время запрос данных узла версии текстового представления произошла ошибка: ${0}`
    }
  };

  async importPof(data, name, position, parent) {
    const reqData = {
      name,
      position,
      parent,
      data
    };
    try {
      return await this.request("importPof", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async importUrs(parentUid, file, name) {
    const data = {};
    if (file && file.length) {
      file.forEach((fileItem, i) => {
        data[name[i]] = [fileItem];
      });
    }
    const reqData = {
      params: [parentUid],
      data
    };
    try {
      return await this.request("importUrs", reqData, true);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  
  async createText(data) {
    try {
      return await this.request("createText", { data });
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async createTraceReportWm({ wmUid, versionUid, reportUid, name, position }) {
    const reqData = {
      params: [wmUid, versionUid],
      data:   {
        report: reportUid,
        name,
        position
      }
    };
    return this.request("createTraceReportWm", reqData);
  }

  async exportTraceReport({ wmUid, schemaUid }) {
    const reqData = {
      params: [wmUid, schemaUid]
    };
    return this.request("exportTraceReport", reqData);
  }

  async getRepos() {
    const reqData = {
      params: []
    };
    return this.request("getRepos", reqData);
  }

  async addRepoToList(data) {
    const reqData = {
      params: [],
      data
    };
    return this.request("addRepo", reqData);
  }

  async addRepoMaterial(data) {
    const { targetUid, name, repository } = data;
    const reqData = {
      params: [targetUid],
      data:   {
        name,
        editable: repository
      }
    };
    return this.request("pushRepo", reqData);
  }

  async getLockInfo(uid) {
    const user = this.rootStore.accountStore.uid;
    const reqData = { uid, user_uid: user };
    return this.request("getLockInfo", reqData);
  }
  async search(params) {
    return this.request("search", { params });
  }
  
  async getBulkLockInfo(uids) {
    const user = this.rootStore.accountStore.uid;
    const reqData = { uids, user_uid: user };
    return this.request("getBulkLockInfo", reqData);
  }

  async getNode(uid) {
    const reqData = {
      params: uid ? [`/${uid}`] : []
    };
    return this.request("getCollection", reqData);
  }

  getFileUri(fileData) {
    const { id } = fileData;
    if (!id) {
      return false;
    }
    return this.methods.file.url([id]);
  }

  async getFile(id) {
    const reqData = {
      params: [id]
    };
    try {
      return await this.request("file", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async createNode(data) {
    const reqData = {
      params: [data.parent],
      data
    };
    try {
      return await this.request("createNode", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async createAis(dataObject) {
    const { parent, ...data } = dataObject;
    const reqData = {
      params: [parent],
      data
    };
    try {
      const data = await this.request("createAis", reqData);
      return data;
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async copyNode(data) {
    const reqData = {
      params: [data.uid],
      data
    };
    try {
      return await this.request("copyNode", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async removeNode(data) {
    const reqData = {
      params: [data.uid],
      data
    };
    try {
      return await this.request("removeNode", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async deleteCollection(data) {
    const reqData = {
      params: [data.uid, data.from]
    };
    try {
      return await this.request("deleteCollection", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async deleteReport(data) {
    const reqData = {
      params: [data.uid, data.from]
    };
    try {
      return await this.request("deleteReport", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async updateNode(uid, data) {
    const reqData = {
      params: [uid],
      data
    };
    try {
      return await this.request("updateNode", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async importForm(materialUID, versionUID, data) {
    const reqData = {
      params: [materialUID, versionUID],
      data
    };
    try {
      return await this.request("importForm", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async export(format, materialUID, versionUID, formUID) {
    const reqData = {
      params: [materialUID, versionUID, formUID, format],
      data:   { format }
    };
    try {
      return await this.request("export", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  // eslint-disable-next-line no-unused-vars
  async loadFile(parent, position = null, name, file) {
    const reqData = {
      params: [parent],
      data:   {
        file
      }
    };
    const isFormData = true;
    try {
      return await this.request("loadFile", reqData, isFormData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  /**
   * Получить ноду Библиотеки зная только uid ноды текcтового представления и номер версии
   * @param {String} wmUid uid текcтового представления и номер версии
   * @param {*} version номер версии
   * @returns 
   */
  async getTextMaterialNodeVersion(wmUid, version) {
    const reqData = {
      params: [wmUid, version]
    };
    try {
      return await this.request("getTextMaterialNodeVersion", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }
}

export default LibraryApi;
