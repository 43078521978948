import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { components } from "@ais3p/ui-framework";

const { Icon }  = components;

/**
 * Компонент заголовка панели
 * 
 * @param {Object} props набор параметров
 * @param {String} props.text текст заголовка панели
 * @param {String} props.icon название иконки в заголовке панели
 * @param {String} props.className пользовательский className
 * @param {String} props.classNameText пользовательский className для текста заголовка
 * @param {String} props.classNameText пользовательский className для для иконки заголовка
 */
const HeaderPanel = ({
  text, // текст заголовка
  icon, // название иконки, для отображения в заголовке
  className, // пользовательский className для панели заголовка
  classNameText, // пользовательский className для текста заголовка
  classNameIcon // пользовательский className для иконки заголовка
}) => {
  return (
    <div className={classNames("header-panel", className)}>
      {icon && <Icon className={classNameIcon} name={icon} />}
      <div className={classNames("header-panel-text", classNameText)}>
        {text}
      </div>
    </div>
  );
};

HeaderPanel.propTypes = {
  text:          PropTypes.string,
  icon:          PropTypes.string,
  className:     PropTypes.string,
  classNameText: PropTypes.string,
  classNameIcon: PropTypes.string
};
export default HeaderPanel;
