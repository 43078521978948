import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { components } from "@ais3p/ui-framework";
import IssueIcon from "../issues/IssueIcon";
import useStores from "~/core/utils/useStores";
import IssueStore from "../../stores/IssueStore";
import { IssueModel } from "../../models";

const { buttons } = components;

/**
 * Компонент для отображения подзадач Задачи
 * 
 * @param {Object} props набор параметров
 * @param {Object} props.item набор данных о связанной задаче
 * @param {IssueModel} props.issue текущая задача
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {Boolean} props.readOnly флаг указывающий, что файлы в спсиске только можно просмматривать
 * @param {String} props.className пользовательский className
 */
const RelatedIssueItem = observer(
  ({
    item,
    issue,
    store,
    readOnly,
    className
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { uiStore } = useStores();

    const isCurrentIssue = useMemo(() => {
      return issue.uid === item.uid;
    }, [item.uid, issue.uid]);

    const isParentIssue = useMemo(() => {
      return issue.parent && issue.parent.uid === item.uid;
    }, [item.uid, issue.parent]);

    const onUnlinkClick = useCallback(() => {
      uiStore.setConfirm(
        `Вы действительно хотите хотите открепить ${isParentIssue ? "родительскую задачу" : "подзадачу"} - 
        "${item.titlePrefix}" ?`, 
        "Удаление связи с задачей", [
          {
            onPress: async() => {
              uiStore.setConfirmPending(true);
              const uid = isParentIssue ? issue.uid : item.uid;
              if (issue.isNew) {
                issue.setParent(null);
              } else {
                await store.unlinkRelatedIssue(uid, issue);
              }
              
              uiStore.hideConfirm();
            },
            theme: "positive",
            icon:  "ok-M",
            text:  "Да"
          },
          {
            onPress: () => {
              uiStore.hideConfirm();
            },
            theme: "negative",
            icon:  "cancel-M",
            text:  "Нет"
          }
        ]);
    }, [item, issue, isParentIssue, store]);

    const onIssueItemClick = useCallback(async() => {
      if (isLoading) {
        // уже идет загрука задачи
        return;
      }
      setIsLoading(true);
      try {
        await store.openIssue(item.uid);
      } finally {
        setIsLoading(false);
      }
    }, [isLoading, item]);
    
    return (
      <div 
        className={classNames("issue-related-issues-item ", className, {
          "current-issue": isCurrentIssue
        })}
        style={{
          marginLeft: `${1.5 * item.level}rem`
        }}
      >
        <div 
          className={"issue-related-issues-item-title"}
        >
          <div 
            className="title-prefix"
            onClick={onIssueItemClick}
          >
            <IssueIcon 
              issue={item}
              isLoading={isLoading}
            />
            <label>{item.titlePrefix}</label>
          </div>
          <span>{item.title}</span>
        </div>
        {!readOnly &&
          <div className={"issue-related-issues-item-controls"}>
            {!isCurrentIssue &&
              <buttons.Button
                onPress={onUnlinkClick}
                isDisabled={readOnly}
                icon="link-delete-M"
                tooltip={`Открепить ${isParentIssue ? "родительскую задачу" : "подзадачу"} - ${item.titlePrefix}`}
                color="negative"
              />
            }
          </div>
        }
      </div>
    );
  }
);

RelatedIssueItem.propTypes = {
  className: PropTypes.string,
  item:      PropTypes.object, 
  store:     PropTypes.instanceOf(IssueStore), 
  issue:     PropTypes.instanceOf(IssueModel), 
  readOnly:  PropTypes.bool 
};

export default RelatedIssueItem;
