import Server from "~/core/api/server";

export default class UsersApi extends Server {
  methods = {
    list: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/users?withGroups=${0}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка списка пользователей ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка пользователей произошла ошибка: ${0}`
    },
    grouplist: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/groups?withMembers=${0}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка списка групп ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка групп произошла ошибка: ${0}`
    },
    getGroupMembers: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/groups/${0}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка списка членов группы ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка членов группы произошла ошибка: ${0}`
    },
    updateUser: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/users/${0}`, params);
      },
      method:      "PUT",
      textProcess: "Изменение пользователя ...",
      textSuccess: null,
      textError:   this.template`Во время изменения пользователя произошла ошибка: ${0}`
    },
    updatePass: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/users/${0}/password`, params);
      },
      method:      "PUT",
      textProcess: "Изменение пользователя ...",
      textSuccess: null,
      textError:   this.template`Во время изменения пользователя произошла ошибка: ${0}`
    },
    createUser: {
      url:         this.getServiceUrl("users", "api/v2/users"),
      method:      "POST",
      textProcess: "Создание пользователя ...",
      textSuccess: null,
      textError:   this.template`Во время создания пользователя произошла ошибка: ${0}`
    },
    deleteUser: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/users/${0}`, params);
      },
      method:      "DELETE",
      textProcess: "Удаление пользователя ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки удаления пользователя произошла ошибка: ${0}`
    },
    createGroup: {
      url:         this.getServiceUrl("users", "api/v2/groups"),
      method:      "POST",
      textProcess: "Создание группы ...",
      textSuccess: null,
      textError:   this.template`Во время создания группы произошла ошибка: ${0}`
    },
    deleteGroup: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/groups/${0}`, params);
      },
      method:      "DELETE",
      textProcess: "Удаление группы ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки удаления группы произошла ошибка: ${0}`
    },
    updateGroup: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/groups/${0}`, params);
      },
      method:      "PUT",
      textProcess: "Изменение группы ...",
      textSuccess: null,
      textError:   this.template`Во время Изменения группы произошла ошибка: ${0}`
    },
    addGroupMember: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/groups/${0}`, params);
      },
      method:      "PATCH",
      textProcess: "Изменение состава группы ...",
      textSuccess: null,
      textError:   this.template`Во время Изменения состава группы произошла ошибка: ${0}`
    },
    file: {
      url: (params) => {
        return this.getServiceUrl(
          "files",
          this.template`api/v1/files/${0}`,
          params
        );
      },
      method:      "GET",
      textProcess: "Запрос файла...",
      textSuccess: null,
      textError:   this.template`Во время запроса файла произошла ошибка: ${0}`
    }
  };

  /**
   * Загрузить список пользователей
   *
   * @return {Promise}
   */
  async loadUsers(withGroups = false) {
    const reqData = {
      params: [withGroups]
    };
    return await this.request("list", reqData);
  }

  /**
   * Загрузить список групп
   *
   * @return {Promise}
   */
  async loadGroups(withMembers = false) {
    const reqData = {
      params: [withMembers]
    };
    return await this.request("grouplist", reqData);
  }

  /**
   * Изменить группу
   *
   * @return {Promise}
   */
  async updateGroup(uid, data) {
    const reqData = {
      params: [uid],
      data
    };
    return await this.request("updateGroup", reqData);
  }

  /**
   * Изменить user'a
   *
   * @return {Promise}
   */
  async updateUser(uid, data) {
    const reqData = {
      params: [uid],
      data
    };
    return await this.request("updateUser", reqData);
  }

  /**
   * Изменить password user'a
   *
   * @return {Promise}
   */
  async updatePass(uid, data) {
    const reqData = {
      params: [uid],
      data
    };
    return await this.request("updatePass", reqData);
  }

  /**
   * Создание пользователя
   *
   * @return {Promise}
   */
  async createUser(data) {
    return await this.request("createUser", data);
  }

  /**
   * Удаление пользователя
   *
   * @return {Promise}
   */
  async deleteUser(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("deleteUser", reqData);
  }

  /**
   * Создание группы
   *
   * @return {Promise}
   */
  async createGroup(data) {
    return await this.request("createGroup", data);
  }

  /**
   * Удаление группы
   *
   * @return {Promise}
   */
  async deleteGroup(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("deleteGroup", reqData);
  }

  /**
   * Спросить членов группы
   *
   * @return {Promise}
   */
  async getMembers(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("getGroupMembers", reqData);
  }

  /**
   * Добавить члена группы
   *
   * @return {Promise}
   */
  async addMember(uid, membersArray) {
    const data = [
      {
        op:    "add",
        path:  "/members",
        value: membersArray
      }
    ];
    const reqData = {
      params: [uid],
      data
    };
    return await this.request("addGroupMember", reqData);
  }

  /**
   * Удалить члена группы
   *
   * @return {Promise}
   */
  async removeMember(uid, membersArray) {
    const data = [
      {
        op:    "remove",
        path:  "/members",
        value: membersArray
      }
    ];
    const reqData = {
      params: [uid],
      data
    };
    return await this.request("addGroupMember", reqData);
  }

  /**
   * Загрузить аватарку пользователя из файлового хранилища
   *
   * @param {String} fileId  id файлв в файловом хранилище
   * @return {Promise}
   */
  async getUserAvatar(fileId) {
    const reqData = {
      params: [fileId]
    };
    return await this.request("file", reqData);
  }
}
