import React from "react";
import PropTypes from "prop-types";

/**
 * Компонент для отображения ноды в дереве репозитория
 * 
 * @param {Object} params набор параметров
 * @param {String} params.id id записи ноды репозитория
 * @param {Object} params.name текстовое представление названия ноды
 * @param {Boolean} params.selected флаг, указывающий на то, что нода выделена или нет
 * 
 * @type {RepoNode}
 * @returns {Component}
 */
const RepoNode = ({ id, name, selected }) => {
  return (
    <div
      key={id}
      className={`node-inner-content ${selected === id ? "selected" : ""}`}
    >
      {name}
    </div>
  );
};

RepoNode.propTypes = {
  id:       PropTypes.string.isRequired,
  name:     PropTypes.string,
  selected: PropTypes.string
};


export default RepoNode;
