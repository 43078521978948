import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Preloader, ToolBar } from "@ais3p/ui-framework-old";
import SplitPane, { Pane } from "react-split-pane";

// import Target from "~/core/components/Target";
import TargetPanel from "../components/repo/Target";
import RepoTree from "../components/tree";
import { RepoInfo, RepoRevision } from "../components/repo";

import { SIDEPANEL_RELATIONS } from "~/core/constants/SidePanels";

import { CLS_REPO_FILE } from "~/core/constants/Classes";

import "./css/index.scss";

import useStores from "~/core/utils/useStores";
import RepoStore from "../stores/RepoStore";
import CommitsView from "../components/commits";
import SourceView from "../components/code/SourceView";

/**
 * Инструмент для работы с репозиторием
 *
 * @param {Object} props набор параметров
 * @param {String} props.tabId id таба в Layout
 * @param {String} props.editable id репозитория
 * @param {Object} props.trackedItem информация об отселживаемом элементе
 * @param {Array} props.isSubVisible  массив с состоянием SidePanels
 *
 * @type {RepoTool}
 */
const RepoTool = observer((props) => {
  const { tabId, editable: repositoryId, trackedItem, isSubVisible } = props;
  const { rootStore, objectStore, layoutStore } = useStores();

  const repoTreeEl = useRef(null);

  // Здесь храним ширину панели с деревом репозитория.
  // Для каждого режима - своя ширина
  const [splitPos, setSplitPos] = useState({
    repo:    "100%",
    source:  250,
    commits: 250
  });

  // Хранилище для работы с репозиторием
  const store = useMemo(() => {
    const store = new RepoStore(rootStore, objectStore);
    return store;
  }, []);

  const [modeView, setModeView] = useState("repo");

  useEffect(() => {
    // инициализция репозитория
    store.init(repositoryId);
  }, [repositoryId]);

  // переключение SidePanel со связью
  const toggleSubPanel = useCallback(
    (tag) => {
      layoutStore.toggleSubPanel(tabId, tag);
    },
    [tabId]
  );

  // переключение режима отображения инструмента: просмотр репозитория, содержимого фалов, история коммитов
  const toggleMode = useCallback(
    (mode) => {
      setModeView(mode);
    },
    [tabId]
  );

  // Кликнули на иконку в ноде дерева
  const onNodeClick = useCallback((repoNode, focusCodeLine) => {
    if (repoNode.class === CLS_REPO_FILE && 
        (modeView === "source" || modeView === "repo" || focusCodeLine)) {
      if (focusCodeLine || modeView === "repo") {
        setModeView("source");
      }
      store.openFile(repoNode.uid, repoNode.commitId, repoNode.name, focusCodeLine);
    }
    if (modeView === "commits") {
      store.clearCommits();
      store.loadCommits(repoNode.path);
    }
  }, [modeView]);

  // Кликнули по target
  const onTargetClick = useCallback(() => {
    if (modeView === "commits") {
      repoTreeEl.current.resetCursor();
      // загружаем список коммитов для рута
      store.clearCommits();
      store.loadCommits();
    } 
  }, [modeView]);

  // Изменили ширину у дерева репозитория
  const onSplitPosChange = useCallback((size) => {
    setSplitPos((state) => {
      return {
        ...state,
        [modeView]: size
      };
    });
  }, [modeView]);

  // закрыли все вкладки с оторадением содержимого кода
  const onCloseAllSourceTabs = useCallback(() => {
    setModeView("repo");
  }, []);

  // набор кнопок в toolbar инструмента
  const toolbarButtons = useMemo(() => {
    return {
      left: [
        {
          id:       "repo",
          icon:     "repository-M",
          title:    "Репозиторий",
          pressed:  modeView === "repo",
          callback: toggleMode
        },
        {
          id:       "source",
          icon:     "app-redactionviewer-M",
          title:    "Проводник",
          pressed:  modeView === "source",
          callback: toggleMode
        },
        {
          id:       "commits",
          icon:     "log-M",
          title:    "История изменений",
          pressed:  modeView === "commits",
          callback: toggleMode
        }
      ],
      right: [
        {
          id:       SIDEPANEL_RELATIONS,
          icon:     "app-relations-M",
          title:    "Связи",
          pressed:  isSubVisible[SIDEPANEL_RELATIONS],
          callback: toggleSubPanel
        }
      ]
    };
  }, [isSubVisible[SIDEPANEL_RELATIONS], modeView]);

  return (
    <div className="repo-tool">
      <ToolBar buttons={toolbarButtons} />
      <div className="repo-tool-tracker">
        <TargetPanel
          trackedItem={trackedItem}
          repositoryId={repositoryId}
          store={store}
          onTargetClick={onTargetClick}
        />
        <RepoInfo store={store} />
      </div>
      <div className="repo-tool-content">
        {store.pending && (
          <div className="preloader-wrapper">
            <Preloader size={3} />
          </div>
        )}

        <SplitPane
          split="vertical"
          minSize={modeView === "repo" ? "100%" : 200}
          style={{ flex: 1 }}
          allowResize={modeView !== "repo"}
          onChange={onSplitPosChange}
          size={splitPos[modeView]}
        >
          <RepoTree
            ref={repoTreeEl}
            {...props}
            store={store}
            isRenderSideKick={modeView === "repo"}
            onNodeClick={onNodeClick}
          />
          <Pane className="repo-tool-panel">
            <SourceView
              {...props}
              store={store} 
              disabled={modeView !== "source"}
              onCloseAllTabs={onCloseAllSourceTabs}
            />
            <CommitsView 
              store={store} 
              disabled={modeView !== "commits"} 
            />
          </Pane>
        </SplitPane>
        <RepoRevision commitItem={store.lastCommit} />
      </div>
    </div>
  );
});

export default RepoTool;
