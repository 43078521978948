import { observer } from "mobx-react";
import React, { Fragment, useMemo } from "react";

const TableRow = observer(({ data, renderItem }) => {
  const { itemsForRender, idsArray } = data;

  const itemsRender = useMemo(() => {
    return itemsForRender.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, itemsForRender]);

  return <Fragment>{itemsRender}</Fragment>;
});

export default TableRow;
