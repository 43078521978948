import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Icon, Tabs } from "@ais3p/ui-framework-old";
import MenuListItem from "./MenuListItem";

/**
 * Выпадающее меню для выбора ветки/тэга репозитория
 *
 * @type {BranchesAndTagsMenu}
 */
const BranchesAndTagsMenu = observer(
  ({ store, label, disabled, onClose, onSelectItem }) => {
    const [tabIndex, setTabIndex] = useState(0);

    /**
     * Событие на выбор вкладки
     */
    const onSelectTab = useCallback((index) => {
      setTabIndex(index);
    }, []);

    /**
     * Событие на закрытие меню
     */
    const onClickCloseBtn = useCallback(() => {
      onClose && onClose();
    }, [onClose]);

    /**
     * Событие на выбор ветки
     */
    const onSelectBranch = useCallback(
      (branch) => {
        store.setCurrentBranch(branch);
        onSelectItem && onSelectItem("branch", branch);
      },
      [onSelectItem]
    );

    /**
     * Событие на выбор тэга
     */
    const onSelectTag = useCallback(
      (tag) => {
        store.setCurrentTag(tag);
        onSelectItem && onSelectItem("tag", tag);
      },
      [onSelectItem]
    );

    /**
     * Создаем панель со списком веток
     */
    const branchesPanel = useMemo(() => {
      return (
        <div className="list">
          {store.branches.map((item) => {
            return (
              <MenuListItem
                key={item}
                isActive={item === label}
                text={item}
                onClickItem={onSelectBranch}
              />
            );
          })}
        </div>
      );
    }, [store.branches, label, onSelectBranch]);

    /**
     * Создаем панель со списком тэгов
     */
    const tagsPanel = useMemo(() => {
      return (
        <div className="list">
          {store.tags.map((item) => {
            return (
              <MenuListItem
                key={item}
                isActive={item === label}
                text={item}
                onClickItem={onSelectTag}
              />
            );
          })}
        </div>
      );
    }, [store.tags, label, onSelectTag]);

    /**
     * Получаем набор отрытых вкладок
     */
    const tabs = useMemo(() => {
      const result = [];
      result.push({
        tab:   <div className={"tags-tab"}>Ветки</div>,
        panel: branchesPanel
      });
      result.push({
        tab:   <div className={"tags-tab"}>Тэги</div>,
        panel: tagsPanel
      });
      return result;
    }, [branchesPanel, tagsPanel]);

    return (
      <div
        className={classNames("repo-branches-tags-menu", {
          disabled,
          show: !disabled
        })}
      >
        <div className="title">
          <label>Переключить ветку / тэг</label>
          <div className="close-button" onClick={onClickCloseBtn}>
            <Icon icon="close-M" className="close-button-icon" />
          </div>
        </div>
        <div className="body">
          <Tabs
            items={tabs}
            defaultIndex={0}
            selectedIndex={tabIndex}
            onSelect={onSelectTab}
          />
        </div>
      </div>
    );
  }
);

export default BranchesAndTagsMenu;
