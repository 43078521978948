const CONDITION_EQUAL = "$eq";
const CONDITION_NOT_EQUAL = "$ne";
const CONDITION_MORE_OR_EQUAL = "$gte";
const CONDITION_LESS_OR_EQUAL = "$lte";
const CONDITION_MORE = "$gt";
const CONDITION_LESS = "$lt";
const CONDITION_IN = "$in";
const CONDITION_NOT_IN = "$nin";
const CONDITION_LIKE = "$like";
const CONDITION_NOT_LIKE = "$not_like";

const conditionTitle = (val) => {
  switch (val) {
    case CONDITION_EQUAL:
      return "Равно";
    case CONDITION_NOT_EQUAL:
      return "Не равно";
    case CONDITION_MORE_OR_EQUAL:
      return "Больше или равно";
    case CONDITION_LESS_OR_EQUAL:
      return "Меньше или равно";
    case CONDITION_MORE:
      return "Больше";
    case CONDITION_LESS:
      return "Меньше";
    case CONDITION_IN:
      return "Входит в набор";
    case CONDITION_NOT_IN:
      return "Не входит в набор";
    case CONDITION_LIKE: 
      return "Cодержит подстроку (регистр влияет)";
    case CONDITION_NOT_LIKE: 
      return "Не содержит подстроку (регистр влияет)";
    default: 
      return val;
  }
};

const conditionIcon = (val) => {
  switch (val) {
    case CONDITION_EQUAL:
      return "=";
    case CONDITION_NOT_EQUAL:
      return "!=";
    case CONDITION_MORE_OR_EQUAL:
      return "<=";
    case CONDITION_LESS_OR_EQUAL:
      return ">=";
    case CONDITION_MORE:
      return "<";
    case CONDITION_LESS:
      return ">";
    case CONDITION_IN:
      return "[=]";
    case CONDITION_NOT_IN:
      return "[!=]";
    case CONDITION_LIKE: 
      return "~";
    case CONDITION_NOT_LIKE: 
      return "!~";
    default: 
      return val;
  }
};


const conditionStringList = [CONDITION_EQUAL, CONDITION_NOT_EQUAL, CONDITION_LIKE, CONDITION_NOT_LIKE];
const conditionNumList = [
  CONDITION_EQUAL, CONDITION_NOT_EQUAL, CONDITION_MORE_OR_EQUAL, CONDITION_LESS_OR_EQUAL, CONDITION_MORE, CONDITION_LESS
];
const conditionDateList = [
  CONDITION_EQUAL, CONDITION_NOT_EQUAL, CONDITION_MORE_OR_EQUAL, CONDITION_LESS_OR_EQUAL, CONDITION_MORE, CONDITION_LESS
];
const conditionBoolList = [
  CONDITION_EQUAL, CONDITION_NOT_EQUAL
];
// const conditionEnumList = [CONDITION_IN, CONDITION_NOT_IN];
const conditionEnumList = [CONDITION_EQUAL, CONDITION_NOT_EQUAL];

const conditionDefaultList = [CONDITION_EQUAL, CONDITION_NOT_EQUAL];

export {
  CONDITION_EQUAL,
  CONDITION_NOT_EQUAL,
  CONDITION_MORE_OR_EQUAL,
  CONDITION_LESS_OR_EQUAL,
  CONDITION_MORE,
  CONDITION_LESS,
  CONDITION_IN,
  CONDITION_NOT_IN,
  CONDITION_LIKE,
  CONDITION_NOT_LIKE,
  conditionTitle,
  conditionIcon,
  conditionStringList,
  conditionNumList,
  conditionDefaultList,
  conditionBoolList,
  conditionDateList,
  conditionEnumList
};
