import { action, observable } from "mobx";

/**
 * Модель для хранения информации о токене в строке кода
 * 
 * @type{Token}
 */
class Token {
  /**
   * @type {Array<String>}
   * Набор типов токена для определния типа содержимого
   */
  @observable 
  types = [];

  /**
   * @type {String}
   * Строковое представление токена
   */
  @observable 
  content = "";

  /**
   * @type {Bolean}
   * Пустой ли токен
   */
  @observable 
  empty = false;

  /**
   * Статичный конструктор модели.
   * При создании модели, лучше использовать статичный метод, тк если поменяются названия входных параметров,
   * то эти названия можно будет здесь только поменять, не меняя названия в самой модели
   * 
   * @param {Object} params парметры для создания токена
   * @param {Array<String>} params.types типы токена 
   * @param {String} params.content текстовое содержимое токена
   *  
   * @returns Line
   */
  static create({ types, content }) {
    return new Token({ types, content });
  }

  /**
   * Конструктор модели.
   * @param {Object} props парметры для создания токена
   * @param {Array<String>} props.types типы токена 
   * @param {String} props.content текстовое содержимое токена
   *  
   * @returns Line
   */
  constructor(props) {
    this.types = props.types;
    this.content = props.content;
  }

  /**
   * Сделать токен пустым
   */
  @action
  setIsEmpty() {
    this.types = ["plain"];
    this.content = "\n";
    this.empty = true;
  }

  /**
   * Деструктор модели
   */
  destroy() {
    this.types = [];
  }
}

export default Token;
