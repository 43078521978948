import React, { useCallback } from "react";
import classNames from "classnames";

const ListItem = ({ text, isActive, onClickItem }) => {
  const onClick = useCallback(() => {
    onClickItem && onClickItem(text);
  }, [onClickItem, text]);

  return (
    <div 
      className={classNames("list-item", {
        active: isActive
      })}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default ListItem;
