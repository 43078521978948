/**
 * Цветовая палитра системы АИС
 */
class Colors {
  colors = new Map([
    // red
    ["mainred", "#E44F4F"],
    ["lightred", "#FDF6F6"],
    ["red1", "#FBE5E5"],
    ["red2", "#F8D3D3"],
    ["red3", "#F6C3C3"],
    ["halfred", "#EF9595"],
    ["dred1", "#C24343"],
    ["dred2", "#A03737"],
    ["dred3", "#722727"],

    // orange
    ["mainorange", "#F1AD5C"],
    ["lightorange", "#FEFBF7"],
    ["orange1", "#FDF3E7"],
    ["orange2", "#FBEBD6"],
    ["orange3", "#FAE3C8"],
    ["halforange", "#F7CE9D"],
    ["dorange1", "#CD934E"],
    ["dorange2", "#A97940"],
    ["dorange3", "#79572E"],

    // yellow
    ["mainyellow", "#EEDF5B"],
    ["lightyellow", "#FEFDF7"],
    ["yellow1", "#FCFAE6"],
    ["yellow2", "#FBF7D6"],
    ["yellow3", "#F9F4C7"],
    ["halfyellow", "#F5EC9D"],
    ["dyellow1", "#CABE4D"],
    ["dyellow2", "#A79C40"],
    ["dyellow3", "#77702D"],

    // green
    ["maingreen", "#76C84F"],
    ["lightgreen", "#F8FCF6"],
    ["green1", "#EAF7E5"],
    ["green2", "#DDF1D3"],
    ["green3", "#D0ECC3"],
    ["halfgreen", "#ADDE95"],
    ["dgreen1", "#64AA43"],
    ["dgreen2", "#538C37"],
    ["dgreen3", "#3B6427"],

    // cyan
    ["maincyan", "#5FC7BA"],
    ["lightcyan", "#F7FCFC"],
    ["cyan1", "#E7F7F5"],
    ["cyan2", "#D7F1EE"],
    ["cyan3", "#C9ECE8"],
    ["halfcyan", "#9FDDD6"],
    ["dcyan1", "#51A99E"],
    ["dcyan2", "#428B82"],
    ["dcyan3", "#2F645D"],

    // blue
    ["mainblue", "#25AEEE"],
    ["lightblue", "#F4FBFE"],
    ["blue1", "#D9F2FE"],
    ["blue2", "#C9EBFB"],
    ["blue3", "#B5E3F9"],
    ["halfblue", "#7CCEF5"],
    ["dblue1", "#1F94CA"],
    ["dblue2", "#1A7AA7"],
    ["dblue3", "#125777"],

    // violet
    ["mainviolet", "#AA8EE4"],
    ["lightviolet", "#FBF9FE"],
    ["violet1", "#F2EEFB"],
    ["violet2", "#EAE3F8"],
    ["violet3", "#E2D9F6"],
    ["halfviolet", "#CCBBEF"],
    ["dviolet1", "#9079C2"],
    ["dviolet2", "#7763A0"],
    ["dviolet3", "#554772"],

    // pink
    ["mainpink", "#EB709C"],
    ["lightpink", "#FEF8FA"],
    ["pink1", "#FCEAF0"],
    ["pink2", "#FADBE6"],
    ["pink3", "#F8CEDD"],
    ["halfpink", "#F3A9C4"],
    ["dpink1", "#C85F85"],
    ["dpink2", "#A54E6D"],
    ["dpink3", "#76384E"],

    // grayblue
    ["maingrayblue", "#B8C5CA"],
    ["grayblue1", "#FBFDFF"],
    ["grayblue2", "#F3F8FB"],
    ["grayblue3", "#E2F1F9"],
    ["grayblue4", "#D5E4EC"],
    ["halfgrayblue", "#B8C5CA"],
    ["dgrayblue1", "#7D8A8E"],
    ["dgrayblue2", "#535E62"],
    ["dgrayblue3", "#414D52"],
    ["dgrayblue4", "#374043"],
    ["dgrayblue5", "#2D3639"],

    // gray
    ["maingray", "#B3B3B3"],
    ["gray1", "#FCFCFC"],
    ["gray2", "#F8F8F8"],
    ["gray3", "#F3F3F3"],
    ["gray4", "#E6E6E6"],
    ["gray5", "#D9D9D9"],
    ["gray6", "#CCCCCC"],
    ["gray7", "#B3B3B3"],
    ["gray8", "#808080"],
    ["dgray1", "#666666"],
    ["dgray2", "#4D4D4D"],
    ["dgray3", "#404040"],
    ["dgray4", "#333333"],
    ["dgray5", "#272727"],
    ["dgray6", "#1A1A1A"]
  ]);

  /**
   * Получить список названий главных цветов палитры
   *
   * @return {<Array>String}
   */
  get mainColorByNameList() {
    return Array.from(this.colors.keys()).filter((name) => {
      return name.includes("main");
    });
  }

  /**
   * Получить список hex главных цветов палитры
   *
   * @return {<Array>String}
   */
  get mainColorHexList() {
    return Array.from(this.colors.entries())
      .filter(([key]) => {
        return key.includes("main");
      })
      .map(([, value]) => {
        return value;
      });
  }

  /**
   * Получить список названий цветов палитры
   *
   * @return {<Array>String}
   */
  get colorByNameList() {
    return Array.from(this.colors.keys());
  }

  /**
   * Получить список hex цветов палитры
   *
   * @return {<Array>String}
   */
  get colorHexList() {
    return Array.from(this.colors.values());
  }

  /**
   * Получить hex цвета по названию цвета в палитре
   *
   * @param {String} name название цвета
   * @return {String} hex
   */
  getColorByName(name) {
    return this.colors.get(name);
  }

  /**
   * Получить название цвета по hex значению цвета в палитре
   *
   * @param {String} hex значение цвета
   * @return {String} name
   */
  getColorNameByHex(hex) {
    for (const [key, value] of this.colors.entries()) {
      if (value && hex && value.toUpperCase() === hex.toUpperCase())
        return key;
    }
  }

  /**
   * Получить базовое название цвета (red, blue, green и тп) по названиею цвета в палитре
   *
   * @param {String} name значение цвета
   * @return {String} name
   */
  getBaseColorName(name) {
    if (!name) {
      return;
    }
    return String(name).replace(/(main)|(light)|(half)|(^d)|(\d)/gi, "");
  }
}

export default Colors;
