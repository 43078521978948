import { action, computed, observable, toJS } from "mobx";
import {
  DOMAIN_GROUPS,
  DOMAIN_KINDS,
  DOMAIN_LIBRARY,
  DOMAIN_REPO,
  DOMAIN_TEXT,
  DOMAIN_ISSUE
} from "../../constants/Domains";

/**
 * Базовый объект системы.
 *
 * @type {AisObject}
 */
class AisObject {
  @observable
  uid = null;

  @observable
  objectStore = null; // object store

  @observable
  domains = new Map();

  @observable
  lockInfo = null;

  /**
   * Конструктор
   *
   * @param {Object} params параметры объекта
   * @param {ObjectStore} store хранилище
   */

  constructor(params, store) {
    this.objectStore = store;

    this.uid = params.uid;
  }

  /**
   * Add domain to domains Map
   *
   * @param {String} domain
   * @memberof AisObject
   */
  @action
  addDomain(domain) {
    this.domains.set(domain, domain);
  }

  /**
   * Delete domain from domains Map
   *
   * @param {String} domain
   * @memberof AisObject
   */
  @action
  deleteDomain(domain) {
    this.domains.delete(domain);
  }

  /**
   * Обновить информацию о блокировке. Если передать undefined, то блокировка снимется
   *
   * @param {Object} data инфорация о блокировке
   * @param {Date} data.lockedAt  дата блокировки
   * @param {String} data.lockedBy uid пользователя, который заблокировал
   */
  @action
  setLockInfo(data) {
    if (data) {
      if (!this.lockInfo) {
        this.lockInfo = new Map();
      }
      if (data.get) {
        this.lockInfo.set("lockedBy", data.get("lockedBy"));
        this.lockInfo.set("lockedAt", data.get("lockedAt"));
      } else {
        this.lockInfo.set("lockedBy", data.lockedBy);
        this.lockInfo.set("lockedAt", data.lockedAt);
      }
    } else {
      this.lockInfo = null;
    }
  }

  @computed
  get id() {
    return `${this.uid}`;
  }

  @computed
  get domainsSet() {
    return new Set(Array.from(this.domains.keys()));
  }

  @computed
  get iconString() {
    return this.kindsIconString;
  }

  /**
   * Название объекта
   */
  @computed
  get title() {
    if (this.repoRepresentation) {
      return this.repoRepresentation.name;
    }
    if (this.textRepresentation) {
      return this.textRepresentation.title;
    }
    if (this.libraryRepresentation) {
      return this.libraryRepresentation.title;
    }
    if (this.issueRepresentation) {
      return this.issueRepresentation.title;
    }
    return "Нет наименования";
  }

  @computed
  get isLocked() {
    return !!this.lock;
  }

  @computed
  get lock() {
    return (this.editableAggr && this.editableAggr.lock) || toJS(this.lockInfo);
  }

  @computed
  get editableAggr() {
    const editable = this.editableUid && this.objectStore.getObject(this.editableUid);
    return editable || null;
  }

  @computed
  get libraryRepresentation() {
    if (this.domainsSet.has(DOMAIN_LIBRARY)) {
      return this.objectStore.getVersion(this.id, DOMAIN_LIBRARY) || null;
    }

    if (this.domainsSet.has(DOMAIN_GROUPS)) {
      return this.objectStore.getVersion(this.id, DOMAIN_GROUPS) || null;
    }
    return null;
  }

  @computed
  get textRepresentation() {
    if (this.domainsSet.has(DOMAIN_TEXT)) {
      return this.objectStore.getVersion(this.id, DOMAIN_TEXT) || null;
    }
    return null;
  }

  @computed
  get repoRepresentation() {
    if (this.domainsSet.has(DOMAIN_REPO)) {
      return this.objectStore.getVersion(this.id, DOMAIN_REPO) || null;
    }
    return null;
  }

  @computed
  get issueRepresentation() {
    if (this.domainsSet.has(DOMAIN_ISSUE)) {
      return this.objectStore.getVersion(this.id, DOMAIN_ISSUE) || null;
    }
    return null;
  }

  @computed
  get kindsRepresentation() {
    if (this.domainsSet.has(DOMAIN_KINDS)) {
      return this.objectStore.getVersion(this.id, DOMAIN_KINDS) || null;
    }
    return null;
  }

  @computed
  get kindsIconString() {
    return (this.kindsRepresentation && this.kindsRepresentation.iconString) || null;
  }

  @computed
  get editableUid() {
    return this.libraryRepresentation && this.libraryRepresentation.editable;
  }

  /**
   * Класс объекта.
   */
  @computed
  get className() {
    return "AisObject";
  }

  @computed
  get representationMap() {
    const map = new Map();
    this.domains.forEach((domainName) => {
      const representation = this.objectStore.getVersion(this.id, domainName);
      if (representation) {
        map.set(representation.id, representation);
      }
    });
    return map;
  }
}

export default AisObject;
