import { observable, computed } from "mobx";
import { v4 as uuid } from "uuid";

/**
 * Модель записи связи в схеме отчетов о трассировке
 *
 * @class  SchemaLinkItemModel
 */
class SchemaLinkItemModel {
  /**
   * uid 
   *
   * @type String
   */
  @observable
  uid = undefined;


  /**
   * Класс связи
   *
   * @type String
   */
  @observable
  class = undefined;

  /**
   * Направление связи
   *
   * @type String
   */
  @observable
  direction = undefined;

  /**
   * uid типа связи
   *
   * @type String
   */
  @observable
  type = undefined;

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.class class модели
   * @param {String} params.direction направление связи
   * @param {String} params.type uid типа связи
   *
   * @retrun {SchemaLinkItemModel}
   */
  static create({
    class: klass,
    direction,
    type
  }, rootStore) {
    return new  SchemaLinkItemModel({
      class: klass,
      direction,
      type
    }, rootStore);
  }

  constructor(params, rootStore) {
    this.uid = uuid();
    this.class = params.class;
    this.direction = params.direction;
    this.type = params.type;

    this.rootStore = rootStore;
  }

  get schemaItemType() {
    return "link";
  }

  
  /**
   * Строковое представление связи
   *
   * @return {String}
   */
  @computed
  get title() {
    const linkKind = this.rootStore.relationStore.getKindByUid(this.type);
    // return linkKind && `${linkKind.name} - ${linkKind.destCaption}` || this.direction;
    return linkKind && `${linkKind.name}` || this.direction;
  }

  /**
   * Флаг, указывающий, что все параметры для ограничений выставлены
   * 
   * @return {Boolean}
   */
  @computed
  get isValid() {
    return true;
  }

  /**
   * Значения, которые задал сам пользователь
   * 
   * @return {Object}
   */
  @computed
  get values() {
    const res = {};
    return res;
  }

  /**
   * Сброс пользовательских значений
   */
  reset() {
  }
}

export default SchemaLinkItemModel;
