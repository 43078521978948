import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * TimeAxis временная шкала для TimeLine.
 *
 * @param {Object} props набор параметров
 * @param {Date} props.endDate  дата окончания задачи. Если данный параметр не задан, 
 *         то это значит, что срок задачи не указан
 * @param {Array <Object>} props.axisValues массив значений шкалы - { hour, day, month, year }
 * @param {Number} props.axisValueRem ширина шага шкалы в rem
 */
const TimeAxis = ({ endDate, axisValues, axisValueRem }) => {
  return (
    <div
      className={classNames(
        "timeline-timeaxis", {
          "end-undefined": !endDate
        })}
    >
      {axisValues.map(({ hour, day, month, year }, i) => {
        return (
          <div
            key={i}
            className="timeline-timeaxis-value"
            style={{
              maxWidth: `${axisValueRem}rem`,
              width:    `${axisValueRem}rem`
            }}
          >
            {hour && <span className="hour">{`${hour}ч`}</span>}
            {day && <span className="date">{day}</span>}
            {(month || year) && <span className="month">{`${month} ${year}`}</span>}
          </div>
        );
      })}
    </div>
  );
};

TimeAxis.propTypes = {
  endDate:      PropTypes.instanceOf(Date), 
  axisValues:   PropTypes.arrayOf(Object).isRequired, 
  axisValueRem: PropTypes.number
};

export default TimeAxis;
