import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import moment from "moment";
import classNames from "classnames";
import { FILTERS_CFG } from "../../constants/config";
import  { statusTitle } from "../../constants/statuses";
import IssueStore from "../../stores/IssueStore";

/**
 * Текстовые заголовки параметров задачи
 */
const FILTER_TITLES = {
  tracker:    "Трекер",
  status:     "Статус",
  priority:   "Приоритет",
  author:     "Автор",
  assignedTo: "Исполнитель",
  project:    "Проект",
  subject:    "Заголовок задачи",
  createDate: "Дата создания",
  updateDate: "Дата изменения",
  startDate:  "Дата начала",
  dueDate:    "Дата окончания"
};

/**
 * Поля, которые отвечают за период жизни задачи
 */
const FILTER_PERIODS = [
  "createDateFrom", "createDateTo",
  "updateDateFrom", "updateDateTo",
  "startDateFrom", "startDateTo",
  "dueDateFrom", "dueDateTo"
];

/**
 * Панель с для отображения информаци о выстваленных фильтрах
 * 
 * @param {Object} props набор параметров
 * @param {String} params.className  пользовательский className
 * @param {IssueStore} params.store хранилизе для работы с задачами
 */
const FilterInfo = observer(({ className, store }) => {
  const filters = store.getItemConfig(FILTERS_CFG).values || {};
  const data = {};
  Array.from(Object.keys(filters)).forEach((key) => {
    if (FILTER_PERIODS.includes(key)) {
      const dateKey = key.replace("From", "").replace("To", "");
      const from = filters[`${dateKey}From`];
      const to = filters[`${dateKey}To`];
      data[FILTER_TITLES[dateKey]] = [
        from && `с ${moment(from).format("YYYY-MM-DD")}`,
        to && `по ${moment(to).format("YYYY-MM-DD")}`
      ].join(" ");
    } else {
      let val = filters[key];
      if (key === "status" && !Array.isArray(val)) {
        val = statusTitle(val);
      }

      data[FILTER_TITLES[key]] = Array.isArray(filters[key]) ?
        filters[key].map((val) => {
          return val.label;
        }).join(", ")
        : val;
    }
  });

  return (
    <div className={classNames("filters-info", className)}>
      {
        Array.from(Object.keys(data)).map((key, i) => {
          return (
            <div key={i} className="filters-info-item">
              <div className="label">{`${String(key).trim()}:`}</div>
              <div className="value">{String(data[key]).trim()}</div>
            </div>
          );
        })
      }
    </div>
  );
});

FilterInfo.propTypes = {
  className: PropTypes.string,
  store:     PropTypes.instanceOf(IssueStore)
};
export default FilterInfo;
