import { observable, computed, action } from "mobx";
import { getDomainByClass } from "~/core/utils";
import {
  DOMAIN_TEXT,
  DOMAIN_LIBRARY,
  DOMAIN_REPO,
  DOMAIN_ISSUE,
  DOMAIN_BINDER
} from "~/core/constants/Domains";

/**
 * Модель записи объекта в данных отчета о трассировке
 *
 * @class  DataObjectItemModel
 */
class DataObjectItemModel {
  /**
   * uid объекта
   *
   * @type String
   */
  @observable
  id = undefined;

  /**
   * Class объекта
   *
   * @type String
   */
  @observable
  class = undefined;

  /**
   * Версия объекта
   *
   * @type Number
   */
  @observable
  version = undefined;

  /**
   * Представление обхекта
   *
   * @type Array<Object>
   */
  @observable
  represantation = [];

  /**
   * Виды  объекта
   *
   * @type Array<Object>
   */
  @observable
  members = [];

  /**
   * Флаг, уазывающий, что происходит обработка represantation в предствлание объекта АИС
   *
   * @type Boolean
   */
  @observable
  isPending = false;

  /**
   * Объект АИС системы, полученный из represantation
   *
   * @type AisObject
   */
  @observable
  object = undefined;

  
  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id объекта
   * @param {String} params.class class объекта
   * @param {Number} params.version версия объекта
   * @param {Array<Object>} params.representation представление объекта
   * @param {Array<Object>} params.members виды объекта
   *
   * @retrun {DataObjectItemModel}
   */
  static create({
    id,
    class:klass,
    version,
    representation,
    members
  }, rootStore) {
    return new  DataObjectItemModel({
      id,
      class: klass,
      version,
      representation,
      members
    }, rootStore);
  }

  constructor(params, rootStore) {
    this.id = params.id;
    this.class = params.class;
    this.domain = getDomainByClass(this.class);
    this.version = params.version;
    this.representation = params.representation || [];
    this.members = params.members || [];
    
    this.rootStore = rootStore;

    this.processRepresantation();
  }

  /**
   * uid объекта
   * 
   * @returns {String}
   */
  @computed
  get uid() {
    return this.id;
  }

  /**
   * тип данных
   * 
   * @returns {String}
   */
  get dataType() {
    return "object";
  }

  /**
   * Строковое представление связи
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.object && this.object.title;
  }

  /**
   * Задать АИС объект
   * 
   * @param {Object} obj задаваемый объект
   */
  @action
  setObject(obj) {
    this.object = obj;
  }

  /**
   * Задать признак обработки данных
   * 
   * @param {Boolean} val значение признака обработки данных
   */
  @action
  setPending(val) {
    this.isPending = val;
  }

  /**
   * Разбор представления(representation) объекта, полученного от Composer
   * 
   * @param {Boolean} val значение признака обработки данных
   */
  async processRepresantation() {
    this.setPending(true);
    try {
      let obj;
      const { objectStore } = this.rootStore;
      switch (this.domain) {
        case DOMAIN_LIBRARY:{
          obj = await objectStore.processLibraryItem(this.representation, this.domain, {}, { loadKinds: false });
          break;
        }
        case DOMAIN_REPO: {
          obj = await objectStore.processRepositoryItem(this.representation, this.domain);
          break;
        }
        case DOMAIN_ISSUE:
          obj = await objectStore.processIssueItem(this.representation);
          break;
        case DOMAIN_TEXT:
          obj = await objectStore.processTextItem(this.representation, this.version, {}, { loadKinds: false });
          break;
        case DOMAIN_BINDER:
          obj = await objectStore.processRelationItem(this.representation);
          break;
        default:
          throw new Error("No domain specified");
      }
      await this.rootStore.kindsStore.processMembers(this.members, this.version);
      this.setObject(obj);
    } catch (ex) {
      console.error(ex.message, { representation: this.representation, domain: this.domain, version: this.version });
    } finally {
      this.setPending(false);
    }
  }
}

export default DataObjectItemModel;
