import React from "react";
import { observer, inject } from "mobx-react";

import { Button, Dnd, ContextMenuTrigger } from "@ais3p/ui-framework-old";

@inject("accountStore")
@observer
class ChildButton extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.canDrag = this.canDrag.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.contextMenuCollect = this.contextMenuCollect.bind(this);
  }

  onClick() {
    const { onClick, item } = this.props;
    if (onClick) {
      onClick(item);
    }
  }

  canDrag() {
    return this.props.canDrag;
  }

  onMenuClick(e, contextMenuData) {
    const { action, item } = contextMenuData;
    const presset = this.props.getPresset();
    switch (action) {
      case "save":
        this.props.accountStore.saveWorkspaceToItem(item.id, presset);
        break;
      case "edit":
        this.props.accountStore.showForm(item.id, presset);
        break;
      case "delete":
        this.props.accountStore.deleteWorkspace(item.id);
        break;
    }
  }

  contextMenuCollect(props) {
    this.props.menuCollect(props);
    return props;
  }

  render() {
    const { id, icon, item, setIsDragging, tool, labelPosition, loading } =
      this.props;
    const attributes = {
      "data-tooltip":    item.name,
      "data-tooltip-at": labelPosition
    };
    return (
      <div
        key={id}
        className={"button-wrapper"}
        {...(loading ? {} : attributes)}
      >
        <Dnd.Source
          noStore={true}
          setIsDragging={setIsDragging}
          type={Dnd.types.GLOBAL_BUTTON}
          isRelative={true}
          id={id}
          item={item}
          tool={tool}
          canDrag={this.canDrag}
        >
          <ContextMenuTrigger
            key={id}
            id={loading || !item.hasMenu ? "" : "treeId"}
            item={item}
            menuType={"HEADER"}
            holdToDisplay={-1}
            onItemClick={this.onMenuClick}
            collect={this.contextMenuCollect}
          >
            <Button
              size={3}
              onClick={this.onClick}
              leftIcon={icon}
              loading={loading}
              theme={"outline"}
              style={{ width: "auto" }}
            />
          </ContextMenuTrigger>
        </Dnd.Source>
      </div>
    );
  }
}

export default ChildButton;
