import React, { useCallback } from "react";
import PropTypes from "prop-types";
import AisIcon from "../../../core/components/AisIcon";

const VersionButton = ({ name, version, onClick, isSelected }) => {
  const onClickVersion = useCallback((e) => {
    e.stopPropagation();
    onClick(version);
  }, [version, onClick]);
  return (
    <div onClick={onClickVersion} className={`version-button ${isSelected ? "selected" : ""}`}>
      <AisIcon className="expander big" icon="version-M" />{name}
    </div>
  );
};

VersionButton.propTypes = {
  name:       PropTypes.string,
  version:    PropTypes.number,
  onClick:    PropTypes.func,
  isSelected: PropTypes.bool
};

export default VersionButton;


