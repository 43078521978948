import { observable, action } from "mobx";

class Half {
  @observable id = null;
  @observable uid = null;
  @observable rels = new Map();

  constructor(half, store) {
    this.store = store;

    this.id = half.id;
    this.uid = half.uid;
    const rels = half.rels;
    for (let i = rels.length - 1; i >= 0; i -= 1) {
      this.addRel(rels[i], half.relationId);
    }
  }

  @action
  extendRels(relIds, relationId) {
    for (let i = relIds.length - 1; i >= 0; i -= 1) {
      this.addRel(relIds[i], relationId);
    }
  }

  @action
  addRel(relId, relationId) {
    const rel = this.rels.get(relationId);
    if (rel) {
      if (!rel.includes(relId)) {
        rel.push(relId);
      }
    } else {
      this.rels.set(relationId, [relId]);
    }
  }

  @action
  removeRel(relId, itemId) {
    const rel = this.rels.get(relId);
    if (rel) {
      rel.remove(itemId);
      if (rel.length === 0) {
        this.rels.delete(relId);
        if (this.rels.size === 0) {
          this.destroy();
        }
      }
    }
  }

  @action
  destroy() {
    if (
      this.store.starts.has(this.id) &&
      this.store.starts.get(this.id) === this
    ) {
      this.store.starts.delete(this.id);
    } else if (
      this.store.ends.has(this.id) &&
      this.store.ends.get(this.id) === this
    ) {
      this.store.ends.delete(this.id);
    }
  }
}

export default Half;
