import { action, computed, observable } from "mobx";
import BaseTextObject from "./BaseTextObject";

class Chunk extends BaseTextObject {
  @observable
  value = "";

  @action
  init(data, parent) {
    super.init(data, parent);
    this.isNew = !!data.isNew;
    this.setValue(data.value || "", true);
  }

  @action
  async setValue(value = "", noPersist = false) {
    this.value = value;
    if (!noPersist) {
      if (this.isNew) {
        await this.persistCreate();
      } else {
        await this.persistEdit();
      }
    }
  }

  @action
  async persistEdit() {
    if (this.store) {
      await this.store.api.editChunk(this.uid, this.output);
    }
  }

  @action
  async checkForEmptinessAndRemove() {
    if (this.value === "" && !this.parent.isCaption) {
      await this.persistDelete();
      this.selfDestruct(true);
    } else {
      this.setValue(this.value);
    }
  }

  /**
   * Checks on Delete or Backspace signal if there is sibling Picture and deletes iot if necessary
   *
   * @param {*} signal - one of ["ArrowLeft", "ArrowUp", "ArrowDown", "ArrowRight"]
   *
   * @return {boolean} - true if smthng was deleted
   *
   * @memberof Chunk
   */
  @action
  checkForSiblingPictureAndRemoveIt(signal) {
    if (
      signal === "Backspace" &&
      this.prevSibling &&
      this.prevSibling.isPictureChunk
    ) {
      this.prevSibling.selfDestruct(true);
      return true;
    } else if (
      signal === "Delete" &&
      this.nextSibling &&
      this.nextSibling.isPictureChunk
    ) {
      this.nextSibling.selfDestruct(true);
      return true;
    }
    return false;
  }
  /**
   * setPrevChild - moves cursor to prev child
   *
   * @param {*} signal - one of ["ArrowLeft", "ArrowUp", "ArrowDown", "ArrowRight"]
   * @memberof Chunk
   */
  @action
  setPrevChild(signal, offset) {
    this.store.setDelta(-1);
    this.parent && this.parent.setPrevChild(this.uid, signal, offset);
  }

  /**
   * setPrevChild - moves cursor to next child
   *
   * @param {*} signal - one of ["ArrowLeft", "ArrowUp", "ArrowDown", "ArrowRight"]
   * @memberof Chunk
   */
  @action
  setNextChild(signal, offset) {
    this.store.setDelta(1);
    this.parent.setNextChild(this.uid, signal, offset);
  }

  @action
  getPrevId() {
    return null;
  }

  @computed
  get slug() {
    return "chunks";
  }

  @computed
  get category() {
    return "chunks";
  }

  @computed
  get title() {
    return this.value;
  }

  @computed
  get isEditable() {
    return !this.isVersion && this.uid === this.store.editingChunkId;
  }

  @computed
  get idsArray() {
    return [];
  }

  @computed
  get renderValue() {
    return `${this.value}`;
  }

  @computed
  get isEmpty() {
    return this.value === "";
  }

  @computed
  get valueLength() {
    return this.value.length;
  }

  @computed
  get output() {
    return {
      class:       this.className,
      uid:         this.uid,
      value:       this.value || "",
      "@position": this.position || null
    };
  }
}

export default Chunk;
