/**
 * Функция вызова окна-уведомления notification с сообщением об ошибке и обработка
 */
import { layout } from "@ais3p/ui-framework";
const { notification } = layout;
const errorNotify = (content, onDismissError) => {
  notification.error(content, { onClose: () => {
    onDismissError(); 
  } });
};
export default errorNotify;
