import React from "react";
import ReactDOM from "react-dom";
import "@ais3p/ui-framework/bundle.css";
import "./index.scss";
import registerServiceWorker from "./registerServiceWorker";
import ErrorBoundary from "./core/components/ErrorBoundary";

document.oncontextmenu = function(e) {
  e.preventDefault();
  e.stopPropagation();
  return false;
};

ReactDOM.render(<ErrorBoundary />, document.getElementById("ais-root"));
registerServiceWorker();
