import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Dnd } from "@ais3p/ui-framework-old";
import Target from "~/core/components/Target";
import Library from "~/modules/library/tools/libraryTree/components/Library";
import { observer } from "mobx-react";
import useStores from "../../../../core/utils/useStores";
import { DOMAIN_LIBRARY } from "../../../../core/constants/Domains";

const FrsTarget = observer(({ onSetFrs, obj, frsKindName }) => {
  const { objectStore } = useStores();
  const canDrop = useCallback((props, monitor) => {
    const item = monitor.getItem();
    const { data } = item;
    const itemVersion = objectStore.getVersion(item.id, DOMAIN_LIBRARY);
    let isRightKind = false;
    if (itemVersion && itemVersion.parent && itemVersion.parent.kindsRepresentation) {
      const kindNamesSet = new Set(itemVersion.parent.kindsRepresentation.kindNames);
      isRightKind = kindNamesSet.has(frsKindName);
    }
    return item.type === Dnd.types.NODEITEM && data && data.payload && data.payload.number > 0 && isRightKind;
  }, [frsKindName]);

  const trackedItem = useMemo(() => {
    if (!obj) {
      return undefined;
    }

    return {
      uid:  obj.id,
      tool: obj.tool
    };
  }, [obj]);
  // const onHover = useCallback((prps, monitor) => {
  //   if (readOnly) {
  //     return;
  //   }

  //   const { uid: draggedUid, isCard } = monitor.getItem();
  //   // переносить можем только карточки
  //   if (!isCard) {
  //     return;
  //   }

  //   if (draggedUid  && moveIssue) {
  //     moveIssue(draggedUid);
  //   }
  // }, [moveIssue, statusId, readOnly]);

  const onDrop = useCallback((prps, monitor) => {
    const item = monitor.getItem();
    const { data } = item;
    const { id } = data;
    const version = data.payload && data.payload.number;
    const obj = {
      id,
      version,
      tool: Library.domain
    };
    onSetFrs && onSetFrs(obj);
  }, [onSetFrs]);
  
  return (
    <div className="frs-target">
      {!trackedItem && (
        <Dnd.Target
          isRelative={true}
          drop={onDrop}
          // hover={onHover}
          canDrop={canDrop}
          types={[Dnd.types.NODEITEM]}
          className="frs-target-empty"
        >
          <div className="empty">
            версия {frsKindName}
          </div>
        </Dnd.Target>
      )}
      {trackedItem && <Target trackedItem={trackedItem} />}
    </div>
  );
});

FrsTarget.propTypes = {
  obj:      PropTypes.object,
  onSetFrs: PropTypes.func
};

export default FrsTarget;
