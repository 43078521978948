import React, { useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { Icon, Preloader } from "@ais3p/ui-framework-old";
import useStores from "~/core/utils/useStores";
import UserAvatar from "~/modules/users/components/avatar";

const User = observer(() => {
  const { accountStore, uiStore } = useStores();
  const { isPendingTasks } = uiStore;
  const attributes = useMemo(() => {
    return {
      "data-tooltip": `Вы вошли как ${accountStore.user.shortName}. ${
        isPendingTasks ? "" : "Выйти?"
      }`,
      "data-tooltip-at": "bottom"
    };
  }, [accountStore.user && accountStore.user.shortName]);

  const onLogout = useCallback(() => {
    if (uiStore.canLogout) {
      uiStore.setConfirm("Вы действительно хотите выйти?", "Выход из системы", [
        {
          onPress: async() => {
            uiStore.setConfirmPending(true);
            accountStore.performLogout();
            uiStore.hideConfirm();
          },
          theme:   "positive",
          icon:    "ok-M",
          text:    "Да",
          loading: uiStore.isPendingTasks // добавил такое условие, чтобы у пользователя не было
          // возможности нажать `Ok`, если еще идут асинхронные запросы.
          // Такое например возможно, если пользователь делал редакцию в `InputEditor` и после
          // нажал на выход из системы. В этот момент проиcходит событие `onBlur` и идет запрос
          // на принятие редакции - T3251
        },
        {
          onPress: () => {
            uiStore.hideConfirm();
          },
          theme: "negative",
          icon:  "cancel-M",
          text:  "Нет"
        }
      ]);
    }
  }, [uiStore.canLogout, uiStore.isPendingTasks]);

  return (
    <div className={"app-user"} onClick={onLogout} {...attributes}>
      <div className="app-user-avatar">
        <UserAvatar
          className="image"
          fileId={accountStore.user && accountStore.user.image}
        />
      </div>
      {!isPendingTasks && (
        <div className="logout">
          <Icon
            className={`ais-login-icon ${
              accountStore.pending ? "loading" : ""
            }`}
            icon="logout-M"
          />
        </div>
      )}
      {isPendingTasks && <Preloader size={1} className="app-user-preloader" />}
    </div>
  );
});

export default User;
