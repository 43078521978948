import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { Tabs } from "@ais3p/ui-framework-old";

import CodeTab from "./CodeTab";
import CodeView from "./CodeView";

/**
 * Панель для отображения табов для просмотра содержимого файлов
 *
 * @param {Object} props передаваемые св-ва
 * @param {LayoutItem} props.layoutItem панель layout
 * @param {RepoStore} props.store хранилище  для работы с репозиторием
 * @param {Boolean} props.disabled флаг, указывающий, должна ли быть панель отображена или скрыта.
 * Скрытность панели делаем для того, чтобы не сбрасывать рендер и сохранить состояние открытых владок
 *
 * @type {SourceView}
 * @returns {Component}
 */
const SourceView = observer((props) => {
  const { store, disabled, layoutItem, onCloseAllTabs } = props;
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (store.activeCodeFile) {
      const index = store.openedCodeFileList.indexOf(store.activeCodeFile);
      if (index >= 0) {
        setTabIndex(index);
      }
    }

    // если вкладки все закрыли, то переходим в режим просмотра репозитория
    if (store.openedCodeFileList.length === 0) {
      onCloseAllTabs && onCloseAllTabs();
    }
  }, [store.openedCodeFileList, store.activeCodeFile]);

  /**
   * Событие на выбор вкладки
   */
  const onSelectTab = useCallback((index) => {
    store.setActiveCodeFile(store.openedCodeFileList[index]);
  }, []);

  /**
   * Событие на закрытие вкладки
   */
  const onCloseTab = useCallback((codeFile, index) => {
    store.deleteCodeFile(codeFile.id);
    let newIndex = index - 1;
    if (index <= 0) {
      newIndex = 0;
    }
    store.setActiveCodeFile(store.openedCodeFileList[newIndex]);
  }, [store.openedCodeFileList]);

  /**
   * Получаем набор отрытых вкладок
   */
  const items = useMemo(() => {
    const result = [];
    store.openedCodeFileList.forEach((codeFile, index) => {
      result.push({
        tab:   <CodeTab index={index} codeFile={codeFile} closeTab={onCloseTab} />,
        panel: <CodeView store={store} codeFile={codeFile} layoutItem={layoutItem} />
      }); 
    });
    return result;
  }, [store.openedCodeFileList]);

  return (
    <div
      className="source-view"
      style={{
        display: disabled ? "none" : "flex"
      }}
    >
      {store.openedCodeFileList.length === 0 && (
        <div className="source-view-empty-tabs">
          Выберите файл для просмотра его содержимого.
        </div>
      )}
      {store.openedCodeFileList.length > 0 && (
        <Tabs
          items={items}
          // defaultIndex={0}
          selectedIndex={tabIndex}
          onSelect={onSelectTab}
        />
      )}
    </div>
  );
});

export default SourceView;
