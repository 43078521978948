import React, { useMemo, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { components } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import TraceStore from "../stores/traceStore";
import Parameters from "../components/parameters";
import SchemaData from "../components/schema-data";
  
import "./css/index.scss";

const { buttons, toolbar, Preloader } = components;

/**
 * Инструмент для анализа трассировки связей
 * @params {Object} props набор параметров
 * @params {String} props.schemaId id схемы, согласно которой нужно будет делать трассировку или анализ связей
 * @params {String} props.traceType что делать - трассировку "trace" | анализ связей "gap"
 * @params {Object} props.trackedItem информация о ноде в Библиотеке, для которой была вызвана трассировка или 
 * анализ связей
 */
const TraceAnalyzer = observer(({ schemaId, traceType, trackedItem }) => {
  const { rootStore, objectStore } = useStores();

  const [schemaType, setSchemaType] = useState();
  const [schema, setSchema] = useState();
  const [schemaData, setSchemaData] = useState();
  
  const store = useMemo(() => {
    return new TraceStore(rootStore, true);
  }, []);

  useEffect(async() => { 
    await store.init();
    let currentSchema;
    // Если schemaId указана, то значит нужно делать трассировку или анализ связей. 
    // Те инстурмент был вызван через контекстное меню у ноды в Библиотеке
    if (schemaId) {
      currentSchema = store.getSchema(schemaId);
      setSchema(currentSchema);
    }
    
    if (currentSchema && trackedItem) {
      // получаем инофмацию об объекте, для котрого был вызан инструмент
      const obj = objectStore.getVersion(trackedItem.uid, trackedItem.domain, trackedItem.version);
      if (obj) {
        // задаем этот объект в схеме ограничений
        currentSchema.setFirstBoundaryValue(obj);
      }

      // если после задания объекта, схема стала валидной, то производит трассировку или анализ связей, согласно
      // типу traceType
      if (currentSchema.isValid) {
        if (traceType === "gap") {
          const data = await store.doGapAnalize(currentSchema);
          onProcessSchema(traceType, currentSchema, data);
        }

        if (traceType === "trace") {
          const data = await store.doTraceAnalize(currentSchema);
          onProcessSchema(traceType, currentSchema, data);
        }
      } else {
        setSchema(currentSchema);
      }
    }
  }, [schemaId, traceType, trackedItem]);

  /**
   * Callback ф-я на выбор схемы из выпадающего списка
   * 
   * @param {TraceSchema} schema выбранная схема
   */
  const onChangeSchema = useCallback((schema) => {
    setSchema(schema);
  }, []);
  
  /**
   * Callback ф-я на результат трассировки или анализа связей
   * 
   * @param {String} type тип результата - трассировка (trace)  | анализ связей (gap)
   * @param {TraceSchema} schema выбранная схема
   * @param {Array<Object>} data набор данных
   */
  const onProcessSchema = useCallback((type, scheme, data) => {
    setSchemaType(type);
    setSchema(scheme);
    setSchemaData(data);
    
    if (type) {
      store.toggleShowSchemaParams();
    }    
  }, []);

  /** 
   * Callback ф-я для переключения отображения панели с набором параметров для схемы
   */
  const onToggleParams = useCallback(() => {
    store.toggleShowSchemaParams();
  }, []);

  const rightButtons = useMemo(() => {
    return ([
      <buttons.Button
        key="settings"
        icon="settings-M"
        tooltip="Параметры"
        isSelected={store.showSchemaParams}
        onPress={onToggleParams}
      />
    ]
    );
  }, [store.showSchemaParams]);

  return (
    <div className="trace-analyzer-tool">
      <toolbar.ToolBar right={rightButtons} />
      {
        store.isPending &&
        <div className="trace-preloader">
          <Preloader size={3} className="preloader-icon" />
        </div>
      }
      <div className="trace-analyzer-tool-body">
        <Parameters 
          store={store}
          schema={schema}
          onProcessSchema={onProcessSchema} 
          onChangeSchema={onChangeSchema}
        />
        {!store.showSchemaParams &&
          <SchemaData
            type={schemaType} 
            schema={schema} 
            data={schemaData}
            store={store}
          />
        }
      </div>
    </div>
  );
});

TraceAnalyzer.propTypes = {
  schemaId:    PropTypes.string, 
  traceType:   PropTypes.string,
  trackedItem: PropTypes.object
};


export default TraceAnalyzer;
