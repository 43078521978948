import { observer } from "mobx-react";
import React from "react";

const Indicator = observer(({ data }) => {
  const { uid, hasRelations, hasKinds, isUsedInWizard } = data;

  if (isUsedInWizard) {
    return null;
  }
  
  return (
    <div id={`indicator-${uid}`} className="indicator-container">
      <div className={`${hasRelations ? "relations" : ""} ${hasKinds ? "kinds" : ""} indicator`}></div>
    </div>
  );
});

export default Indicator;
