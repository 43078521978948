import { DOMAIN_REPO } from "~/core/constants/Domains";

/**
 * Функция для передачи контекста в Layout
 * @param {LayoutItem} layoutItem  item of layout
 * @param {String} uid головной сущности, в котрой находится focusItem
 * @param {Object} focusItem данные сущности, которая попала в фокус и ее нужно передать в контекст Layout
 */
const changeContext = (layoutItem, uid, focusItem) => {
  if (uid) {
    layoutItem.changeContext(uid, focusItem.uid, {
      uid:         focusItem.uid,
      editable:    uid,
      version:     0,
      trackedItem: {
        uid:     focusItem.uid,
        version: 0,
        name:    focusItem.title,
        class:   focusItem.className,
        tool:    DOMAIN_REPO
      }
    });
  } else {
    layoutItem.changeContext();
  }
};

export default changeContext;
