import React from "react";
import { observer } from "mobx-react";
import { Dnd, ContextMenuTrigger, Preloader } from "@ais3p/ui-framework-old";

import VersionForm from "./VersionForm";
import Library from "./Library";

@observer
class VersionForms extends React.Component {
  render() {
    const {
      nodeProps,
      isProcessingNode,
      nodeKey,
      onItemClick,
      collect,
      onClick
    } = this.props;

    const nodeData = nodeProps.data;
    const parent = nodeProps.parent;
    let forms = [];

    // if (nodeData.etype === "aggr.wm.version") {
    // повесим title не знак
    if (nodeData.forms) {
      forms = nodeData.forms.map((form, idx) => {
        let title = "";
        let menuType = "VERSION_FORM_FILE";
        if (Library.fileEtypes.has(form.etype)) {
          const fileSize = Number((form.size / 1024 / 1024).toFixed(2));
          title = `Скачать файл ${form.name} (${fileSize} Мбайт)`;
          const fileNameChunks = form.name.split(".");
          const fileExtension = fileNameChunks[fileNameChunks.length - 1];
          if (
            fileExtension === "doc" ||
            fileExtension === "docx" ||
            fileExtension === "html" ||
            fileExtension === "c3d"
          ) {
            // запретим повторный импорт
            if (nodeData.forms.length === 1) {
              menuType = "VERSION_FORM_FILE_WITH_IMPORT";
            } else {
              menuType = "VERSION_FORM_FILE";
            }
          }
        } else if (form.etype === "aggr.wm.text") {
          menuType = "TEXT_FORM";
          title = "Текстовая форма версии РМ";
        } else if (form.etype === "3d.Model") {
          menuType = "3D";
          title = "AIS форма 3d модели";
        } else if (form.etype === "aliens.pof.Object") {
          menuType = "POF";
          title = "Импортированный объект POF";
        }
        return (
          <Dnd.Source
            key={idx}
            isRelative={true}
            {...nodeProps}
            id={nodeData.uid}
            formUid={form.uid}
            type={Dnd.types.VERSIONFORM}
          >
            <ContextMenuTrigger
              item={{ data: { form, nodeData } }}
              expandedNodes={nodeProps.expandedNodes}
              toggleFunc={nodeProps.toggleFunc}
              parentId={nodeProps.parent && nodeProps.parent.uid}
              parent={nodeProps.parent}
              itemKey={nodeKey}
              id={"treeId"}
              menuType={menuType}
              holdToDisplay={-1}
              onItemClick={onItemClick}
              collect={collect}
            >
              <VersionForm
                title={title}
                parent={parent}
                onClick={onClick}
                form={form}
                nodeData={nodeData}
              />
            </ContextMenuTrigger>
          </Dnd.Source>
        );
      });
    }
    // }
    if (forms.length < 1) {
      return null;
    }
    return (
      <div className="ais-wm-version-forms">
        {forms}
        {isProcessingNode && (
          <Preloader size={1} style={{ margin: "0.25rem" }} color="#01abfb" />
        )}
      </div>
    );
  }
}

export default VersionForms;
